import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators'

import { SelectOption } from '../../../core/models'
import { CurrencyService, LiquidAppService, UserService } from '../../../core/services'
import { OrganizationService } from '../../../core/services'
import {
    DateIsAfterValidator,
    RequiredIfOtherEqualsValidator,
    RequiredIfOtherNotEqualValidator
} from '../../../validation'
import { CreateWorkOrderService } from '../../liquid/modules/create-work-order/services/create-work-order.service'
import { FormState } from '../../liquid/modules/create-work-order/services/form-state.interface'
import { MadLibInputComponent } from '../../liquid/modules/mad-libs/mad-lib-input/mad-lib-input.component'
import {
    Deliverable,
    DeliverableRate,
    DeliverableRateTier,
    DeliverableStatus,
    LiquidDocument,
    RateType,
    RateTypeSelectOption,
    RecurrenceParameters,
    ShadowRateType,
    TeamMemberAbstract,
    TeamMembership,
    WorkOrder,
    WorkOrderStatus
} from '../../models'
import { DateService } from '../../services'
import { InvoiceFrequency } from '../models'

import { CUSTOM_INVOICE_TRIGGERS, INVOICE_TRIGGERS, NONRECURRING_RATES, SEMI_MONTHLY_TRIGGERS } from './constants'
import { DeliverableFormSteps } from './deliverable-form-steps.enum'

@Component({
    selector: 'app-deliverable-form',
    templateUrl: './deliverable-form.component.html',
    styleUrls: ['./deliverable-form.component.scss'],
})
export class DeliverableFormComponent implements OnInit, AfterViewChecked, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    readonly customInvoiceTriggers: Array<SelectOption<string>> = CUSTOM_INVOICE_TRIGGERS
    readonly flatInvoiceTriggers: Array<SelectOption<string>> = CUSTOM_INVOICE_TRIGGERS.filter(t => t.value !== InvoiceFrequency.MONTHLY)
    readonly workOrderStatuses: typeof WorkOrderStatus = WorkOrderStatus
    readonly rateTypesEnum: typeof RateType & typeof ShadowRateType = this.helper.rateTypesEnum
    readonly steps: typeof DeliverableFormSteps = DeliverableFormSteps
    readonly otherDefault: string = '(Optional) All invoices to be paid in USD... Sales tax additional... Vendor may bill Client for invoice processing (ACH/Wire) fees... Client will reimburse Vendor expenses as follows...'
    readonly invoiceTriggers: Array<SelectOption<string>> = INVOICE_TRIGGERS.filter(t => t.value !== InvoiceFrequency.START_MONTHLY)
    readonly semimonthlyTriggers: SelectOption<string>[] = SEMI_MONTHLY_TRIGGERS
    readonly monthlyTriggers: Array<SelectOption<string>> = INVOICE_TRIGGERS.filter(t => t.value !== InvoiceFrequency.BI_WEEKLY && t.value !== InvoiceFrequency.SEMI_MONTHLY)
    readonly quarterlyTriggers: Array<SelectOption<string>> = INVOICE_TRIGGERS.filter(t => t.value !== InvoiceFrequency.BI_WEEKLY && t.value !== InvoiceFrequency.SEMI_MONTHLY && t.value !== InvoiceFrequency.MONTHLY && t.value !== InvoiceFrequency.START_MONTHLY)
    readonly hourlyCapStrategies: Array<SelectOption<string>> = [
        { value: 'adjust', label: 'the rate will adjust to' },
        { value: 'stop', label: 'Vendor will stop all work' },
    ]

    @ViewChild('descriptionInput') descriptionInput: MadLibInputComponent
    @ViewChild('otherInput') otherInput: MadLibInputComponent
    @ViewChild('titleInput') titleInput: MadLibInputComponent

    @Output() delete: EventEmitter<number> = new EventEmitter<number>()
    @Output() nameChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() save: EventEmitter<Deliverable> = new EventEmitter<Deliverable>()
    @Output() stateChange: EventEmitter<FormState> = new EventEmitter<FormState>()

    @Input() disabled: boolean
    @Input() editable: boolean
    @Input() index: number
    @Input() isInitiator: boolean
    @Input() isWorker: boolean = false
    @Input() loading: boolean
    @Input() workOrder: WorkOrder
    @Input() state: FormState
    @Input() deliverable: Deliverable

    // Description
    description: FormControl = new FormControl(undefined, Validators.required)
    dueDate: FormControl = new FormControl(undefined)
    estimatedDueDate: FormControl = new FormControl(undefined)
    startDate: FormControl = new FormControl(undefined, Validators.required)
    title: FormControl = new FormControl(undefined, Validators.required)
    useDueDate: FormControl = new FormControl(undefined, Validators.required)
    // Rate
    semimonthlyRate: FormControl = new FormControl(undefined)
    dailyRate: FormControl = new FormControl(undefined)
    flatRate: FormControl = new FormControl(undefined)
    hourlyCapAmount: FormControl = new FormControl(undefined, Validators.pattern('^[0-9]*$'))
    hourlyCap: FormControl = new FormControl(undefined)
    hourlyCapStrategy: FormControl = new FormControl(undefined)
    hourlyRate: FormControl = new FormControl(undefined)
    hourlyRateAdjust: FormControl = new FormControl(undefined)
    monthlyRate: FormControl = new FormControl(undefined)
    quarterlyRate: FormControl = new FormControl(undefined)
    rateType: FormControl = new FormControl(undefined, Validators.required)
    rateTypes: Array<RateTypeSelectOption> = this.helper.availableRateTypeOptions
    unitDescription: FormControl = new FormControl(undefined, Validators.maxLength(40))
    weeklyRate: FormControl = new FormControl(undefined)
    // Recurrence
    invoiceSemiMonthlyStart: FormControl = new FormControl(undefined)
    invoiceDate: FormControl = new FormControl(undefined)
    invoiceDescription: FormControl = new FormControl(undefined)
    invoiceMonthlyStart: FormControl = new FormControl(undefined)
    invoiceQuarterlyStart: FormControl = new FormControl(undefined)
    invoiceTrigger: FormControl = new FormControl(undefined)
    invoiceBiWeeklyStart: FormControl = new FormControl(undefined)
    // Other
    other: FormControl = new FormControl(undefined)

    form: FormGroup = new FormGroup({
        description: this.description,
        useDueDate: this.useDueDate,
        dueDate: this.dueDate,
        estimatedDueDate: this.estimatedDueDate,
        rateType: this.rateType,
        startDate: this.startDate,
        title: this.title,
        // Rate
        dailyRate: this.dailyRate,
        flatRate: this.flatRate,
        hourlyCap: this.hourlyCap,
        hourlyCapAmount: this.hourlyCapAmount,
        hourlyCapStrategy: this.hourlyCapStrategy,
        hourlyRate: this.hourlyRate,
        hourlyRateAdjust: this.hourlyRateAdjust,
        monthlyRate: this.monthlyRate,
        quarterlyRate: this.quarterlyRate,
        unitDescription: this.unitDescription,
        weeklyRate: this.weeklyRate,
        // Recurrence
        invoiceSemiMonthlyStart: this.invoiceSemiMonthlyStart,
        invoiceDate: this.invoiceDate,
        invoiceDescription: this.invoiceDescription,
        invoiceMonthlyStart: this.invoiceMonthlyStart,
        invoiceQuarterlyStart: this.invoiceQuarterlyStart,
        invoiceTrigger: this.invoiceTrigger,
        invoiceBiWeeklyStart: this.invoiceBiWeeklyStart,
        // Other
        other: this.other,
    }, [
        RequiredIfOtherEqualsValidator('dueDate', 'useDueDate', [true]),
        RequiredIfOtherEqualsValidator('estimatedDueDate', 'useDueDate', [false]),
        // Flat validators
        RequiredIfOtherEqualsValidator('flatRate', 'rateType', [RateType.Flat]),
        RequiredIfOtherEqualsValidator('flatInvoiceTrigger', 'rateType', [RateType.Flat]),
        RequiredIfOtherEqualsValidator('flatInvoiceDate', 'flatInvoiceTrigger', ['date']),
        RequiredIfOtherEqualsValidator('flatInvoiceDescription', 'flatInvoiceTrigger', ['custom']),
        // New Unit validators
        RequiredIfOtherEqualsValidator('unitDescription', 'rateType', [ShadowRateType.NewFlatUnit]),
        // Hourly Validators
        RequiredIfOtherEqualsValidator('hourlyRate', 'rateType', [RateType.Hourly]),
        RequiredIfOtherEqualsValidator('hourlyCapAmount', 'hourlyCap', [true]),
        RequiredIfOtherEqualsValidator('hourlyCapStrategy', 'hourlyCap', [true]),
        RequiredIfOtherEqualsValidator('hourlyRateAdjust', 'hourlyCapStrategy', ['adjust']),
        // Daily Validators
        RequiredIfOtherEqualsValidator('dailyRate', 'rateType', [RateType.Daily]),
        // Weekly Validators
        RequiredIfOtherEqualsValidator('weeklyRate', 'rateType', [RateType.Weekly]),
        // Semimonthly Validators
        RequiredIfOtherEqualsValidator('semiMonthly', 'rateType', [RateType.SemiMonthly]),
        // Monthly Validators
        RequiredIfOtherEqualsValidator('monthlyRate', 'rateType', [RateType.Monthly]),
        // Quarterly Validators
        RequiredIfOtherEqualsValidator('quarterlyRate', 'rateType', [RateType.Quarterly]),
        // Custom Validators
        RequiredIfOtherEqualsValidator('invoiceDescription', 'rateType', [RateType.Custom]),
        // Triggers
        RequiredIfOtherNotEqualValidator('invoiceTrigger', 'rateType', RateType.Custom),
        RequiredIfOtherEqualsValidator('invoiceDate', 'invoiceTrigger', ['date']),
        RequiredIfOtherEqualsValidator('invoiceBiWeeklyStart', 'invoiceTrigger', ['biweekly']),
        RequiredIfOtherEqualsValidator('invoiceSemiMonthlyStart', 'invoiceTrigger', ['semi-monthly']),
        RequiredIfOtherEqualsValidator('invoiceMonthlyStart', 'invoiceTrigger', ['monthly']),
        RequiredIfOtherEqualsValidator('invoiceQuarterlyStart', 'invoiceTrigger', ['quarterly']),
        RequiredIfOtherEqualsValidator('invoiceDescription', 'invoiceTrigger', ['custom']),
        // Date Validation
        DateIsAfterValidator('dueDate', 'startDate'),
        DateIsAfterValidator('estimatedDueDate', 'startDate'),
        DateIsAfterValidator('invoiceDate', moment().startOf('day')),
    ])

    currentStep: number = 0
    initialized: boolean = false
    initiatedBy: 'client' | 'vendor'
    inputFocused: boolean = false
    isZeroDecimalCurrency: boolean
    maxStep: number = 0
    minDueDate: Date
    minInvoiceDate: Date = moment().startOf('day').toDate()
    minStartDate: Date
    minStartDateMessage: string
    saved: boolean
    showAfterReceiptText: boolean = false
    showAsFollowsText: boolean = false

    // strings
    payoutCurrency: string = this.currencyService.USD
    displayLabel: string = 'Work Order'
    paymentString: string = 'We will pay for the work'
    invoiceString: string = 'Vendor may invoice us for this work'
    invoiceDescriptionErrorString: string = 'You must describe when the Vendor may invoice you for this work'

    get availableTriggers(): Array<SelectOption<string>> { return this.getAvailableTriggers() }
    get currentRate(): number { return this.getCurrentRate() }
    get deleteLabel(): string { return this.deliverable?.id ? 'Delete' : 'Discard' }
    get errorMessageRateInput(): string {
        return `You must enter a ${this.payoutCurrency ?? this.currencyService.USD} amount greater than or equal to 0`
    }
    get errors(): string { return JSON.stringify(this.form.errors) }
    get hasDueDate(): boolean { return !!this.useDueDate.value }
    get hasRecurrence(): boolean { return !NONRECURRING_RATES.includes(this.rateTypeValueType) }
    get isCustom(): boolean { return this.rateTypeValueType === RateType.Custom }
    get isNewFlatUnit(): boolean { return this.rateTypeValueType === ShadowRateType.NewFlatUnit }
    get isCustomFlatUnit(): boolean { return this.rateTypeValueType === ShadowRateType.CustomFlatUnit }
    get isDaily(): boolean { return this.rateTypeValueType === RateType.Daily }
    get isFlat(): boolean { return this.rateTypeValueType === RateType.Flat }
    get isHourly(): boolean { return this.rateTypeValueType === RateType.Hourly }
    get isMonthly(): boolean { return this.rateTypeValueType === RateType.Monthly }
    get isQuarterly(): boolean { return this.rateTypeValueType === RateType.Quarterly }
    get isWeekly(): boolean { return this.rateTypeValueType === RateType.Weekly }

    get showFlatOrCustom(): boolean {
        return (this.isFlat && !!this.flatRate?.value)
            || (this.isNewFlatUnit && !!this.flatRate?.value && !!this.unitDescription?.value)
            || (this.isCustomFlatUnit && !!this.flatRate.value)
            || (this.isCustom && !!this.invoiceDescription.value)
    }

    get rateTypeValueType(): RateType | ShadowRateType { return this.rateType.value?.type }
    get rateTypeValueDescription(): string { return this.rateType.value?.description }

    constructor(
        private app: LiquidAppService,
        private currencyService: CurrencyService,
        private dateService: DateService,
        private helper: CreateWorkOrderService,
        private userService: UserService,
        private organizationService: OrganizationService,
    ) { }

    ngOnInit(): void {
        // if editable then pull data to build options otherwise try to build option from unitOverride
        const organizationFlatUnits$: Observable<string[]> = this.editable
            ? this.organizationService.getOrganizationFlatUnits(this.app.selectedOrganization.id)
            : of(!!this.deliverable?.rate?.unitOverride ? [this.deliverable?.rate?.unitOverride] : [])

        organizationFlatUnits$.pipe(
            take(1),
            takeUntil(this.unsubscribe$),
            tap(flatUnits => {
                this.helper.setAvailableRateTypeFlatUnits(flatUnits)
                this.initialize()
            }),
        )
            .subscribe()

        this.invoiceTrigger.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(val => {
                    if (val === InvoiceFrequency.CONFIRMATION) {
                        this.showAfterReceiptText = true
                        this.showAsFollowsText = false
                    } else if (val === InvoiceFrequency.CUSTOM) {
                        this.showAfterReceiptText = false
                        this.showAsFollowsText = true
                    } else {
                        this.showAfterReceiptText = false
                        this.showAsFollowsText = false
                    }
                }),
            )
            .subscribe()
    }

    initialize(): void {
        this.rateTypes = this.helper.availableRateTypeOptions
        let masterContractActiveFromTimestamp: number
        if (this.isWorker) {
            this.paymentString = 'We will bill the client'
            this.invoiceString = 'We may invoice Client for this work'
            this.invoiceDescriptionErrorString = 'You must describe when you may invoice Client for this work'
            this.invoiceTriggers[0].label = 'after Client has confirmed receipt of the deliverable'
            this.flatInvoiceTriggers[0].label = 'after Client has confirmed receipt of the deliverable'
            this.hourlyCapStrategies[1].label = 'we will stop all work'

            const masterContract: LiquidDocument = this.app.getClientsTeamSnapshot()
                .find(teamMembership => teamMembership.membershipIsToOrganization.id === this.workOrder.clientOrganizationId)
                ?.activeMasterContracts
                .find(mc => mc.id === this.workOrder.masterContractLiquidDocumentId)

            masterContractActiveFromTimestamp = masterContract?.documentActiveFromTimestamp
        } else {
            const masterContract: LiquidDocument = this.app.getVendorsTeamSnapshot()
                .find(v => v?.teamMemberOrganization?.teamMemberOrganizationId === this.workOrder.workerOrganizationId)
                ?.activeMasterContracts
                .find(mc => mc.id === this.workOrder.masterContractLiquidDocumentId)

            masterContractActiveFromTimestamp = masterContract?.documentActiveFromTimestamp
        }

        if (!!masterContractActiveFromTimestamp) {
            this.minStartDate = new Date(masterContractActiveFromTimestamp * 1000)
        }

        this.minStartDateMessage = !!this.minStartDate
            ? 'You must choose a start date after your Master Profile start date'
            : 'You must choose a start date'

        this.initiatedBy = this.workOrder.initiatedByOrganizationId === this.workOrder.workerOrganizationId ? 'vendor' : 'client'
        this.saved = !!this.deliverable.id

        // if work order exists, use work order values
        if (!!this.workOrder) {
            if (!!this.state) {
                this.state.value = undefined
            }
        }
        this.updateControls(this.state?.value)
        this.handleDisabled()

        if (!!this.state?.value && this.editable) {
            this.form.patchValue(this.state.value, { emitEvent: false })
            if (this.state.dirty) {
                this.form.markAsDirty({ onlySelf: true })
            }
        }

        this.calculateStep(true)
        if (this.editable) {
            this.updateMinDate()
            this.updateDateEstimates()
            this.initializeListeners()
        }

        this.setPayoutCurrency()
        this.isZeroDecimalCurrency = this.currencyService.allCurrencyCodesWithoutDecimals.includes(this.payoutCurrency)
    }

    ngAfterViewChecked(): void {
        if (this.editable) {
            this.form.updateValueAndValidity()
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    calculateStep(skipUpdate: boolean = false): void {
        this.inputFocused = false
        this.currentStep = this.getCurrentStep()
        if (this.maxStep < this.currentStep) {
            this.maxStep = this.currentStep
        }
        if (!skipUpdate) {
            this.form.updateValueAndValidity()
        }

        this.setDefaultInvoiceTrigger()

    }

    setDefaultInvoiceTrigger(): void {
        if (this.currentStep !== this.steps.InvoiceTrigger) {
            return
        }

        switch (this.rateTypeValueType) {
            case this.rateTypesEnum.Hourly:
            case this.rateTypesEnum.Daily:
            case this.rateTypesEnum.Weekly:
            case this.rateTypesEnum.Custom:
            case this.rateTypesEnum.CustomFlatUnit:
            case this.rateTypesEnum.NewFlatUnit:
                this.invoiceTrigger.setValue(InvoiceFrequency.MONTHLY)
                break
            case this.rateTypesEnum.Monthly:
                this.invoiceTrigger.setValue(InvoiceFrequency.START_MONTHLY)
                break
            case this.rateTypesEnum.SemiMonthly:
                this.invoiceTrigger.setValue(InvoiceFrequency.SEMI_MONTHLY)
                break
            case this.rateTypesEnum.Quarterly:
                this.invoiceTrigger.setValue(InvoiceFrequency.QUARTERLY)
                break
            case this.rateTypesEnum.Flat:
                this.invoiceTrigger.setValue(InvoiceFrequency.DATE)
                break
            default:
                break
        }
    }

    onFocus(): void {
        this.inputFocused = true
    }

    onDelete(): void {
        this.delete.emit(this.index)
    }

    onNameChange(name: string): void {
        this.nameChange.emit(name)
    }

    onSave(): void {
        this.saved = true
        this.otherInput?.hideTooltip()
        const deliverable: Deliverable = this.getDeliverable()
        if (!deliverable.otherNotes) {
            deliverable.otherNotes = 'None'
        }
        this.save.emit(deliverable)
    }

    private disableControls(): void {
        this.invoiceSemiMonthlyStart.disable()
        this.invoiceMonthlyStart.disable()
        this.invoiceQuarterlyStart.disable()
        this.invoiceBiWeeklyStart.disable()
    }

    private getAvailableTriggers(): Array<SelectOption<string>> {
        switch (this.rateTypeValueType) {
            case RateType.Hourly:
            case RateType.Daily:
            case RateType.Weekly:
                return this.invoiceTriggers.filter(t => t.value !== InvoiceFrequency.QUARTERLY)
            case RateType.Monthly:
                return this.monthlyTriggers
            case RateType.SemiMonthly:
                return this.semimonthlyTriggers
            case RateType.Quarterly:
                return this.quarterlyTriggers
            default:
                return
        }
    }

    private getCurrentRate(): number {
        let value: string

        switch (this.rateTypeValueType) {
            case ShadowRateType.CustomFlatUnit:
            case ShadowRateType.NewFlatUnit:
            case RateType.Flat:
                value = this.flatRate.value
                break
            case RateType.Hourly:
                value = this.hourlyRate.value
                break
            case RateType.Daily:
                value = this.dailyRate.value
                break
            case RateType.Weekly:
                value = this.weeklyRate.value
                break
            case RateType.SemiMonthly:
                value = this.semimonthlyRate.value
                break
            case RateType.Monthly:
                value = this.monthlyRate.value
                break
            case RateType.Quarterly:
                value = this.quarterlyRate.value
                break
            case RateType.Custom:
                return 0
            default:
                return undefined
        }
        return +this.removeMoneyMask(value)
    }

    private getCurrentStep(): number {
        if (!this.editable || this.disabled || this.form.disabled) {
            return DeliverableFormSteps.Finish
        }
        if (!this.title.valid) {
            return DeliverableFormSteps.Name
        }
        if (!this.description.valid) {
            return DeliverableFormSteps.Description
        }
        if (!moment(this.startDate.value).isValid()) {
            return DeliverableFormSteps.Kickoff
        }
        if (this.useDueDate.value === undefined) {
            return DeliverableFormSteps.Due
        }
        if (!!this.useDueDate.value && !this.dueDate.value) {
            return DeliverableFormSteps.Due
        }
        if (this.useDueDate.value === false && !this.estimatedDueDate.value) {
            return DeliverableFormSteps.DueEstimate
        }
        if (this.isNewFlatUnit && !this.unitDescription.value || (this.isCustomFlatUnit && !this.currentRate)) {
            return DeliverableFormSteps.RateType
        }
        if ((!this.rateTypeValueType || !this.currentRate) && this.rateTypeValueType !== RateType.Custom) {
            return DeliverableFormSteps.RateType
        }
        const hasCustomInvoiceTrigger: boolean = this.invoiceTrigger.valid
        if (this.isCustom && !this.invoiceDescription.value && !hasCustomInvoiceTrigger) {
            return DeliverableFormSteps.RateAmount
        }
        if (this.isHourly) {
            if (this.hourlyCap.value === undefined) {
                return DeliverableFormSteps.HourCap
            }
            if (this.hourlyCap.value === true && !this.hourlyCapAmount.value) {
                return DeliverableFormSteps.HourCapAmount
            }
            if (this.hourlyCap.value && !this.hourlyCapStrategy.value) {
                return DeliverableFormSteps.HourCapStrategy
            }
            if (this.hourlyCapStrategy.value === 'adjust' && !this.hourlyRateAdjust.value) {
                return DeliverableFormSteps.HourCapRateAdjust
            }
        }
        if (!this.invoiceTrigger.valid) {
            return DeliverableFormSteps.InvoiceTrigger
        }
        if (
            (this.invoiceTrigger.value === 'date' && !this.invoiceDate.valid)
            || (this.invoiceTrigger.value === 'custom' && !this.invoiceDescription.value)
        ) {
            return DeliverableFormSteps.InvoiceDetail
        }
        return DeliverableFormSteps.Finish
    }

    private removeMoneyMask(val: string | number): number {
        // convert the value to a string and remove all non-numbers characters except dots from it
        const value: string = String(val)?.replace(/[^0-9.]/g, '')

        return Number.parseFloat(value)
    }

    private setPayoutCurrency(): void {
        if (this.saved) {
            this.payoutCurrency = this.deliverable.rate.currency
        } else {
            // if we are dealing in a stable, preferred currency... use it:
            if (this.isWorker) {
                const teamMembership: TeamMembership = this.userService.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships
                    .find(tm => tm.membershipIsToOrganization?.id === this.workOrder.clientOrganizationId)
                if (!!teamMembership && teamMembership.preferredPayoutCurrencyIsStable) {
                    this.payoutCurrency = teamMembership?.preferredPayoutCurrency ?? this.currencyService.USD
                } else {
                    this.payoutCurrency = this.currencyService.USD
                }
            } else {
                const vendor: TeamMemberAbstract = this.helper.selectedVendor
                if (!!vendor && vendor.preferredPayoutCurrencyIsStable) {
                    this.payoutCurrency = vendor.preferredPayoutCurrency ?? this.currencyService.USD
                }
            }
        }
        if (!this.payoutCurrency) {
            this.payoutCurrency = this.currencyService.USD
        }
    }

    private updateControls(state?: { [key: string]: any }): void {
        this.initialized = false
        this.resetControls()

        const deliverableStartValue: Date = this.dateService.convertTZ(moment.unix(this.deliverable?.kickoffTimestamp).toDate(), this.workOrder?.initiatedInTimezone)
        const deliverableDueValue: Date = this.dateService.convertTZ(moment.unix(this.deliverable?.dueTimestamp).toDate(), this.workOrder?.initiatedInTimezone)
        const deliverableEstimateValue: Date = this.dateService.convertTZ(moment(this.deliverable?.estimatedCompletionDate).toDate(), this.workOrder?.initiatedInTimezone)

        this.title.setValue(state?.title || this.deliverable.name, { emitEvent: false })
        this.description.setValue(state?.description || this.deliverable.description, { emitEvent: false })
        this.startDate.setValue(state?.startDate || deliverableStartValue, { emitEvent: false })
        if (!!this.deliverable.otherNotes) {
            this.other.setValue(state?.otherNotes || this.deliverable.otherNotes, { emitEvent: false })
        }
        const useDueDate: boolean = !!this.deliverable.dueTimestamp ? true : !this.deliverable?.estimatedCompletionDate && this.editable ? undefined : false
        this.useDueDate.setValue(this.editable && !!state ? state?.useDueDate : useDueDate, { emitEvent: false })
        this.dueDate.setValue(state?.dueDate || useDueDate ? deliverableDueValue : undefined, { emitEvent: false })
        this.estimatedDueDate.setValue(state?.estimatedDueDate || useDueDate === false ? deliverableEstimateValue : undefined, { emitEvent: false })

        const rate: DeliverableRate = this.deliverable.rate || new DeliverableRate()

        const rateType: { type: RateType | ShadowRateType; description?: string } | undefined
            = rate.unitOverride
                ? this.rateTypes.find(option => option.value.description === (state?.unitOverride || rate.unitOverride))?.value
                : this.rateTypes.find(option => option.value.type === (state?.rateType || rate?.rateType))?.value

        this.rateType.setValue(rateType, { emitEvent: false })

        switch (rate?.rateType) {
            case RateType.Flat:
                this.flatRate.setValue(state?.flatRate || rate.rate, { emitEvent: false })
                break
            case RateType.Hourly:
                this.hourlyRate.setValue(state?.hourlyRate || rate.rate, { emitEvent: false })
                if (this.deliverable?.rate?.rateTiers?.length) {
                    this.hourlyCapStrategy.setValue(state?.hourlyCapStrategy || 'adjust', { emitEvent: false })
                    this.hourlyCapAmount.setValue(state?.hourlyCapAmount || rate.rateTiers[1].minHours, { emitEvent: false })
                    this.hourlyRateAdjust.setValue(state?.hourlyRateAdjust || rate.rateTiers[1]?.rate, { emitEvent: false })
                } else if (!!this.deliverable?.rate?.hoursLimit) {
                    this.hourlyCapStrategy.setValue(state?.hourlyCapStrategy || 'stop', { emitEvent: false })
                    this.hourlyCapAmount.setValue(state?.hourlyCapAmount || rate.hoursLimit, { emitEvent: false })
                }
                this.hourlyCap.setValue(state?.hourlyCap || !!this.hourlyCapStrategy.value, { emitEvent: false })
                break
            case RateType.Daily:
                this.dailyRate.setValue(state?.dailyRate || rate.rate, { emitEvent: false })
                break
            case RateType.Weekly:
                this.weeklyRate.setValue(state?.weeklyRate || rate.rate, { emitEvent: false })
                break
            case RateType.SemiMonthly:
                this.semimonthlyRate.setValue(state?.semimonthlyRate || rate.rate, { emitEvent: false })
                break
            case RateType.Monthly:
                this.monthlyRate.setValue(state?.monthlyRate || rate.rate, { emitEvent: false })
                break
            case RateType.Quarterly:
                this.quarterlyRate.setValue(state?.quarterlyRate || rate.rate, { emitEvent: false })
                break
            case RateType.Custom:
                this.invoiceDescription.setValue(state?.invoiceDescription || rate.customRateDescription, { emitEvent: false })
                break
            default:
                break
        }

        if (!!rate?.unitOverride) {
            this.unitDescription.setValue(rate.unitOverride, { emitEvent: false })
        }

        const trigger: string = this.deliverable.id ? this.getInvoiceTrigger() : undefined
        this.invoiceTrigger.setValue(state?.invoiceTrigger || trigger)
        switch (trigger) {
            case 'date':
                this.invoiceDate.setValue(state?.invoiceDate || this.dateService.convertTZ(moment.unix(rate.recurrenceParameters.startTimestamp).toDate(), this.workOrder?.initiatedInTimezone), { emitEvent: false })
                break
            case 'biweekly':
                this.invoiceBiWeeklyStart.setValue(state?.invoiceBiWeeklyStart || moment.unix(rate.recurrenceParameters.startTimestamp).toDate(), { emitEvent: false })
                break
            case 'semi-monthly':
                this.invoiceSemiMonthlyStart.setValue(state?.invoiceSemiMonthlyStart || moment.unix(rate.recurrenceParameters.startTimestamp).toDate(), { emitEvent: false })
                break
            case 'monthly':
                this.invoiceMonthlyStart.setValue(state?.invoiceMonthlyStart || moment.unix(rate.recurrenceParameters.startTimestamp).toDate(), { emitEvent: false })
                break
            case 'quarterly':
                this.invoiceQuarterlyStart.setValue(state?.invoiceQuarterlyStart || moment.unix(rate.recurrenceParameters.startTimestamp).toDate(), { emitEvent: false })
                break
            case 'custom':
                this.invoiceDescription.setValue(state?.invoiceDescription || rate.customRateDescription, { emitEvent: false })
                break
            default:
                break
        }

        this.initialized = true
        this.calculateStep()
    }

    private getInvoiceTrigger(): string {
        if (this.deliverable?.rate?.customRateDescription && !this.deliverable?.rate?.recurrenceParameters) {
            return 'custom'
        }
        if (this.deliverable?.rate?.recurrenceParameters?.frequency === 'day' && this.deliverable.rate?.recurrenceParameters?.endOccurrences === 1) {
            return 'date'
        }
        if (!this.deliverable.rate || !this.deliverable.rate.rate) {
            return undefined
        }
        if (!this.deliverable?.rate?.recurrenceParameters) {
            return 'confirmation'
        }
        if (this.deliverable?.rate?.recurrenceParameters?.frequency === 'week') {
            return 'biweekly'
        }
        if (this.deliverable?.rate?.recurrenceParameters?.frequency === 'month') {
            if (this.deliverable?.rate?.recurrenceParameters?.daysOfMonth?.length === 2) {
                return 'semi-monthly'
            }

            return this.deliverable?.rate?.recurrenceParameters?.interval === 1
                ? 'monthly'
                : 'quarterly'
        }

        return 'quarterly'
    }

    private getDeliverable(): Deliverable {
        return {
            ...new Deliverable(),
            createdTimestamp: this.deliverable?.createdTimestamp || moment().unix(),
            description: this.description.value || null,
            dueTimestamp: this.useDueDate.value ? moment(this.dueDate.value).unix() : null,
            estimatedCompletionDate: this.useDueDate.value === false ? moment(this.estimatedDueDate.value).toDate() : null,
            finishMilestone: this.description.value || null,
            id: this.deliverable?.id,
            kickoffTimestamp: this.startDate.value ? moment(this.startDate.value).unix() : null,
            name: this.title.value,
            otherNotes: this.other.value,
            rate: this.getDeliverableRate(),
            status: this.deliverable?.status || DeliverableStatus.DRAFT,
        }
    }

    private getDeliverableRate(): DeliverableRate {
        if (!this.rateTypeValueType) {
            return null
        }

        // If shadow type selected then use Flat and send unit override
        const rateType: RateType = (this.isCustomFlatUnit || this.isNewFlatUnit)
            ? RateType.Flat
            : (this.rateTypeValueType as RateType)

        const unitOverride: string | undefined = this.isNewFlatUnit
            ? this.unitDescription.value
            : (this.isCustomFlatUnit
                ? this.rateType.value?.description
                : undefined)

        const rate: DeliverableRate = {
            ...new DeliverableRate(),
            currency: this.payoutCurrency ?? this.currencyService.USD,
            id: this.deliverable?.rate?.id,
            rateType: rateType,
            rate: this.currentRate,
            customRateDescription: this.isCustom ? this.invoiceDescription.value : null,
            rateTiers: [],
            unitOverride: unitOverride,
        }
        if (this.isWorker) {
            rate.agreedByWorkerOnTimestamp = moment().unix()
        } else {
            rate.agreedByClientOnTimestamp = moment().unix()
        }
        rate.recurrenceParameters = !this.invoiceTrigger.value || ['confirmation', 'custom'].includes(this.invoiceTrigger.value)
            ? undefined
            : new RecurrenceParameters()

        if (!!rate.recurrenceParameters) {
            rate.recurrenceParameters.startTimestamp = moment(this.startDate.value).unix()
        }

        if (this.rateTypeValueType === RateType.Hourly && this.hourlyCap.value) {
            switch (this.hourlyCapStrategy.value) {
                case 'adjust':
                    rate.rateTiers = [new DeliverableRateTier(), new DeliverableRateTier()]
                    rate.rateTiers[0] = {
                        ...rate.rateTiers[0],
                        minHours: 0,
                        rate: +this.currentRate,
                        deliverableRateId: rate?.id,
                    }
                    rate.rateTiers[1] = {
                        ...rate.rateTiers[1],
                        minHours: +this.hourlyCapAmount.value,
                        rate: +this.removeMoneyMask(this.hourlyRateAdjust.value),
                        deliverableRateId: rate?.id,
                    }
                    rate.hoursLimit = null
                    break
                case 'stop':
                    rate.hoursLimit = +this.hourlyCapAmount.value
                    rate.rateTiers = null
                    break
                default:
                    break
            }
        }

        switch (this.invoiceTrigger.value) {
            case InvoiceFrequency.DATE:
                rate.recurrenceParameters.startTimestamp = moment(this.invoiceDate.value).unix()
                rate.recurrenceParameters.frequency = 'day'
                rate.recurrenceParameters.interval = 1
                rate.recurrenceParameters.endOccurrences = 1
                break

            case InvoiceFrequency.BI_WEEKLY:
                rate.recurrenceParameters.interval = 2
                rate.recurrenceParameters.frequency = 'week'
                rate.recurrenceParameters.daysOfWeek = ['friday']
                break

            case InvoiceFrequency.SEMI_MONTHLY:
                rate.recurrenceParameters.interval = 1
                rate.recurrenceParameters.frequency = 'month'
                rate.recurrenceParameters.daysOfMonth = [1, 15]
                break
            case InvoiceFrequency.START_MONTHLY:
                rate.recurrenceParameters.interval = 1
                rate.recurrenceParameters.frequency = 'month'
                rate.recurrenceParameters.daysOfMonth = [moment().date()]
                break

            case InvoiceFrequency.MONTHLY:
                rate.recurrenceParameters.interval = 1
                rate.recurrenceParameters.frequency = 'month'
                rate.recurrenceParameters.daysOfMonth = [-1]
                break

            case InvoiceFrequency.QUARTERLY:
                rate.recurrenceParameters.interval = 3
                rate.recurrenceParameters.frequency = 'month'
                rate.recurrenceParameters.daysOfMonth = [-1]
                break

            case InvoiceFrequency.CUSTOM:
                rate.customRateDescription = this.invoiceDescription.value
                break

            default:
                break
        }

        return rate
    }

    private handleDisabled(): void {
        if ((this.disabled || !this.editable) && !this.form.disabled) {
            this.form.disable({ emitEvent: false })
        } else if ((this.editable && !this.disabled) && this.form.disabled) {
            this.form.enable({ emitEvent: false })
        }
        this.disableControls()
    }

    private initializeListeners(): void {
        this.form.valueChanges
            .pipe(
                filter(() => this.initialized),
                tap(() => this.updateState()),
            )
            .subscribe()

        this.form.controls.startDate.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.initialized && this.editable),
                tap(() => this.updateMinDate()),
                tap(() => this.resetDueDates()),
                debounceTime(200),
                tap(() => this.calculateStep()),
            )
            .subscribe()

        this.form.controls.useDueDate.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.initialized && this.editable),
                tap(() => {
                    this.resetDueDates()
                }),
                tap(() => this.calculateStep()),
            )
            .subscribe()

        combineLatest([
            this.form.controls.dueDate.valueChanges,
            this.form.controls.estimatedDueDate.valueChanges,
        ])
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.initialized && this.editable),
                filter(date => !!date),
                tap(() => this.updateDateEstimates()),
                tap(() => this.calculateStep()),
            )
            .subscribe()

        this.form.controls.rateType.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.initialized && this.editable),
                tap(() => this.resetRateDetails()),
                tap(() => this.calculateStep(true)),
            )
            .subscribe()

        this.form.controls.invoiceTrigger.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(() => this.initialized && this.editable),
                tap(() => this.resetInvoiceDates()),
                tap(() => this.calculateStep()),
            )
            .subscribe()

    }

    private resetControls(): void {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].setValue(undefined, { emitEvent: false, onlySelf: true })
            // this.form.controls[key].markAsPristine()
        })
        this.disableControls()
    }

    private resetDueDates(emitEvent: boolean = false): void {
        this.dueDate.setValue(undefined, { emitEvent })
        this.estimatedDueDate.setValue(undefined, { emitEvent })
        this.resetInvoiceDates()
    }

    private resetInvoiceDates(): void {
        this.invoiceDate.setValue(undefined, { emitEvent: false })
        this.invoiceBiWeeklyStart.setValue(undefined, { emitEvent: false })
        this.invoiceSemiMonthlyStart.setValue(undefined, { emitEvent: false })
        this.invoiceMonthlyStart.setValue(undefined, { emitEvent: false })
        this.invoiceQuarterlyStart.setValue(undefined, { emitEvent: false })
        // TODO: find a better way to handle custom rate description and the "as follows" invoice option
        // this.invoiceDescription.setValue(undefined, { emitEvent: false })
        this.updateDateEstimates()
    }

    private resetHourly(): void {
        this.hourlyCap.setValue(undefined, { emitEvent: false })
        this.hourlyCapAmount.setValue(undefined, { emitEvent: false })
        this.hourlyCapStrategy.setValue(undefined, { emitEvent: false })
        this.hourlyRateAdjust.setValue(undefined, { emitEvent: false })
    }

    private resetRateDetails(): void {
        this.unitDescription.setValue(undefined, { emitEvent: false })
        this.flatRate.setValue(undefined, { emitEvent: false })
        this.hourlyRate.setValue(undefined, { emitEvent: false })
        this.dailyRate.setValue(undefined, { emitEvent: false })
        this.weeklyRate.setValue(undefined, { emitEvent: false })
        this.monthlyRate.setValue(undefined, { emitEvent: false })
        this.quarterlyRate.setValue(undefined, { emitEvent: false })
        this.invoiceDescription.setValue(undefined, { emitEvent: false })
        this.invoiceTrigger.setValue(undefined, { emitEvent: false })

        this.resetHourly()
        this.resetInvoiceDates()
    }

    private updateDateEstimates(): void {
        const begin: moment.Moment = moment(this.startDate.value).isBefore(moment(), 'date') ? moment() : moment(this.startDate.value)
        const date: number = begin.date()
        switch (this.invoiceTrigger.value) {
            case 'biweekly':
                // 2nd friday after begin
                const weekDay: number = moment(this.startDate.value).isoWeekday()
                let invoiceDate: moment.Moment
                if (weekDay === 5) {
                    invoiceDate = moment(this.startDate.value).add(2, 'week').isoWeekday(5)
                } else {
                    invoiceDate = weekDay > 5 ? moment(this.startDate.value).add(1, 'week').isoWeekday(5).add(1, 'weeks') : moment(this.startDate.value).isoWeekday(5).add(1, 'week')
                }
                while (invoiceDate.isBefore(moment(), 'date')) {
                    invoiceDate.add(2, 'weeks')
                }
                this.invoiceBiWeeklyStart.setValue(invoiceDate.isoWeekday(5).toDate(), { emitEvent: false })
                break
            case 'semi-monthly':
                if (date < 15) {
                    // the next 15th
                    this.invoiceSemiMonthlyStart.setValue(moment(begin).date(15).toDate(), { emitEvent: false })
                } else {
                    // 1st day of next month
                    this.invoiceSemiMonthlyStart.setValue(moment(begin).startOf('month').add(1, 'month').date(1).toDate(), { emitEvent: false })
                }
                break
            case 'monthly':
                // next last day of month
                this.invoiceMonthlyStart.setValue(date < begin.daysInMonth() ? moment(begin).endOf('month').toDate() : moment(begin).startOf('month').add(1, 'month').endOf('month').toDate(), { emitEvent: false })
                break
            case 'quarterly':
                const diff: number = moment(begin).endOf('quarter').diff(begin)
                this.invoiceQuarterlyStart.setValue(diff > 1 ? moment(begin).endOf('quarter').toDate() : moment(begin).startOf('quarter').add(1, 'quarter').endOf('quarter').toDate(), { emitEvent: false })
                break
            default:
                break
        }
    }

    private updateMinDate(): void {
        if (!moment(this.startDate.value).isValid()) {
            this.minDueDate = undefined
            return
        }
        this.minDueDate = this.startDate.value
    }

    private updateState(): void {
        this.stateChange.emit({
            value: this.form.value,
            index: this.index,
            dirty: this.form.dirty,
            invalid: this.form.invalid,
        })
    }

}
