<app-dialog-template-image
    src="/assets/img/svg/central-bank.svg"
    (close)="cancel()"
    title="Account Holder Information for your Bank Account"
>
    <div class="dialog-content">
        <app-payment-account-holder-info
            [account]="account"
            [form]="form"
            [showAccountTypesSelect]="showAccountTypesSelect"
        ></app-payment-account-holder-info>
    </div>
    <div
        *ngIf="confirmClose"
        class="inline-error error-default cancel-alert dialog-content"
    >
        Click 'x' again if you would like to exit without saving your changes.
    </div>
    <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid || (!form.dirty && !enabledByDefault) "
        (click)="setInfo()"
        class="dialog-button"
    >
        Save
    </button>
</app-dialog-template-image>
