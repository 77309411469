import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FeatherModule } from 'angular-feather'
import { Check, Edit2, HelpCircle, X } from 'angular-feather/icons'

import { PipesModule } from '../pipes/pipes.module'

import { InSituEditTextFieldComponent } from './in-situ-edit-text-field.component'

const icons: { [key: string]: string } = {
    Check,
    Edit2,
    HelpCircle,
    X,
}

@NgModule({
    declarations: [
        InSituEditTextFieldComponent,
    ],
    exports: [
        InSituEditTextFieldComponent,
    ],
    imports: [
        CommonModule,
        FeatherModule.pick(icons),
        FormsModule,
        MatButtonModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        PipesModule,
        ReactiveFormsModule,
    ],
})
export class InSituEditTextFieldModule { }
