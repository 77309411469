export enum InviteVendorStepId {
    AttachWorkOrder = 'attach-work-order-decision',
    InvitationMessage = 'email-invite-message',
    InviteeDescription = 'description',
    InviteeDisplayName = 'displayName',
    InviteeEmail = 'email-invite-email',
    InviteeEmailAddress = 'inviteeEmailAddress',
    InviteeName = 'inviteeName',
    InviteeType = 'inviteeType',
    MasterContractGroup = 'master-contract-group',
    OnboardingProcess = 'onboarding-process-step',
    OnboardingProcessMasterContractPeriod = 'onboarding-process-master-period',
    OnboardingProcessUploadMasterContract = 'onboarding-process-upload-master',
    OnboardingProcessUploadMessage = 'onboarding-process-upload-warning',
    PreInviteInput = 'pre-invite-input',
    SendConfirm = 'send-confirm',
    Sent = 'sent',
}
