import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Address, AddressFactory } from '../../address'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class UserAddressStore {

    constructor(
        private factory: AddressFactory,
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(businessId: string): Observable<Address> {

        return this.http.get<Address>(this.urls.api.address(businessId))
            .pipe(
                map(address => this.factory.create(address)),
            )
    }

    update(addresses: Array<Address>, businessId: string, addressType: string): Observable<Array<Address>> {

        const requests: Array<any> = addresses.map(address => this.factory.createRequest(address, businessId, addressType))

        return this.http.post<Array<Address>>(this.urls.api.address(), requests)
            .pipe(
                map(results => results.map(address => this.factory.create(address))),
            )
    }
}
