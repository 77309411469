// TODO: Move to Core and convert to Interface
export class OrganizationAbstract {
    avatarUrl?: string
    country?: string
    id: string
    logoUrl?: string
    name: string
    shortName: string

    constructor(partial?: Partial<OrganizationAbstract>) {
        if (partial) {
            this.avatarUrl = partial?.avatarUrl
            this.country = partial?.country
            this.id = partial?.id
            this.logoUrl = partial?.logoUrl
            this.name = partial?.name
            this.shortName = partial?.shortName
        }
    }
}
