export enum TableId {
    billingFields = 'billing-fields',
    billingReminders = 'billing-reminders',
    chartOfAccounts = 'chart-of-accounts',
    coaQuickbooks = 'coa-quickbooks',
    contacts = 'contacts',
    invoiceHistoryForClient = 'invoice-history-client',
    invoiceHistoryForVendor = 'invoice-history-vendor',
    invoiceReportIncoming = 'reports-invoice-incoming',
    invoiceReportOutgoing = 'reports-invoice-outgoing',
    invoicesIncoming = 'invoices-incoming',
    invoicesOutgoing = 'invoices-outgoing',
    largeExample = 'design-system-large-example',
    liquidTagList = 'liquid-tag-list',
    liquidVendorList = 'liquid-vendor-list',
    payouts = 'payouts',
    projectLinks = 'project-links',
    purchaseOrders = 'purchase-orders',
    quickbooksFields = 'quickbooks-fields',
    quickbooksVendorList = 'quickbooks-vendor-list',
    reportsWorkOrder = 'reports-work-order',
    reportsWorkOrderVendor = 'reports-work-order-vendor',
    teamClients = 'team-clients',
    teamVendors = 'team-vendors',
    usersExample = 'design-system-users-example',
    vendorWorkOrders = 'vendor-work-orders',
    workOrders = 'work-orders',
}
