import { BillingField } from './billing-field.interface'
import { BusinessTeamMemberStates } from './business-team-member-states.enum'
import { LiquidDocument } from './liquid-document.model'
import { OrganizationTeamMembershipVendorType } from './organization-team-membership-vendor-type.enum'
import { QuickBooksVendor } from './quickbooks-vendor.model'
import { Tag } from './tag.interface'
import { TeamMemberOrganizationAbstract } from './team-member-organization-abstract.model'

export class TeamMemberAbstract {
    activeMasterContracts: LiquidDocument[]
    contractSignerProfileId?: string
    customerAccountingField?: BillingField
    customerAccountingFieldId?: string
    description: string
    displayName: string
    hiddenByProfileId?: string
    hiddenTimestamp?: number
    hiringManagerAvatarUrl: string
    hiringManagerName: string
    hiringManagerProfileId: string
    invitationId?: string
    invitedByAvatarUrl: string
    invitedByName: string
    liquidTags?: Array<Tag>
    masterContractActiveFrom?: Date
    onboardingComplete: boolean = false
    onboardingCompleteTimestamp?: number
    organizationTeamMemberId: string
    preferredPayoutCurrency?: string
    preferredPayoutCurrencyIsStable: boolean
    workOrderAccountingField?: BillingField
    workOrderAccountingFieldId?: string
    status: BusinessTeamMemberStates
    teamMemberOrganization: TeamMemberOrganizationAbstract
    uiQuickBooksVendor?: QuickBooksVendor
    vendorType?: OrganizationTeamMembershipVendorType
}
