import { AbstractControl } from '@angular/forms'

const emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function EmailValidator(control: AbstractControl): { [key: string]: any } | undefined {

    // If there is no email to evaluate, do not add the error b/c this is an email validator, not a required validator.
    // If the email is required, it will be caught by a required validator.
    const output: { invalid: boolean } = !!control && !!control.value && !emailRegex.test(control.value)
        ? { invalid: true }
        : undefined

    return output
}

// TODO: refactor so this is no longer used
export function validateEmail(email: string): boolean {
    if (!email || email.trim().length === 0) {
        return false
    }
    return emailRegex.test(email)
}
