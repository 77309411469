import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, throwError } from 'rxjs'
import { catchError, filter, mergeMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class BusinessTokenInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const oToken: string = localStorage.getItem('business-token')

        if (req.url.indexOf('youUp') >= 0 || !oToken) {
            return next.handle(req)
        }

        const tokenReq: HttpRequest<any> = req.clone({
            setHeaders: { oToken: oToken },
        })

        return next.handle(tokenReq)
    }
}
