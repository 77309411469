import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'

import { CurrencyService, UserService } from '../../../core/services'
import { CreateWorkOrderService } from '../../liquid/modules/create-work-order/services/create-work-order.service'
import { FormState } from '../../liquid/modules/create-work-order/services/form-state.interface'
import { WorkflowService } from '../../liquid/modules/workflow'
import { Deliverable, RateType, TeamMemberAbstract, TeamMembership, WorkOrder } from '../../models'

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject()

    readonly description: FormControl = new FormControl(undefined, [Validators.required])
    payoutCurrency: string
    readonly price: FormControl = new FormControl(undefined, [Validators.required, Validators.min(0)])
    readonly quantity: FormControl = new FormControl(undefined, [Validators.required])

    readonly form: FormGroup = new FormGroup({
        description: this.description,
        price: this.price,
        quantity: this.quantity,
    })

    @Output() delete: EventEmitter<number> = new EventEmitter<number>()
    @Output() nameChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() save: EventEmitter<Deliverable> = new EventEmitter<Deliverable>()
    @Output() stateChange: EventEmitter<FormState> = new EventEmitter<FormState>()

    @Input() disabled: boolean
    @Input() editable: boolean
    @Input() index: number
    @Input() isInitiator: boolean
    @Input() isWorker: boolean = false
    @Input() item: Deliverable
    @Input() loading: boolean
    @Input() state: FormState
    @Input() workOrder: WorkOrder

    get isItemSaved(): boolean { return !!this.item?.id }

    constructor(
        private readonly currencyService: CurrencyService,
        private readonly helper: CreateWorkOrderService,
        private readonly userService: UserService,
        private readonly workflow: WorkflowService,
    ) { }

    ngOnInit(): void {

        this.description.setValue(this.item?.description, { emitEvent: false })
        this.quantity.setValue(this.item?.rate?.quantity, { emitEvent: false })
        this.price.setValue(this.item?.rate?.rate, { emitEvent: false })

        this.setPayoutCurrency()

        if (this.disabled || !this.editable) {
            this.form.disable()
        }

        if (!!this.item?.id) {
            this.form.markAsPristine()
            this.updateState()
        }

        this.description.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(value => {
                    this.onNameChange(value)
                }),
            )

        this.form.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.updateState()),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    getItem(): Deliverable {

        const item: Deliverable = this.item

        this.workflow.isLoading = true
        this.workflow.error = undefined

        item.displayOrder = this.index
        item.workOrderId = this.workOrder.id
        item.description = item.name = this.description.value
        item.kickoffTimestamp = new Date().getTime() / 1000
        item.rate = {
            ...item?.rate,
            currency: this.payoutCurrency,
            quantity: this.quantity.value,
            rate: this.price.value,
            rateType: RateType.Flat,
        }

        if (this.isWorker) {
            item.rate.agreedByWorkerOnTimestamp = moment().unix()
        } else {
            item.rate.agreedByClientOnTimestamp = moment().unix()
        }

        return item
    }

    onDelete(): void {
        this.delete.emit(this.index)
    }

    onNameChange(name: string): void {
        this.nameChange.emit(name)
    }

    onSave(): void {
        this.form.markAsPristine()
        this.updateState()
        this.save.emit(this.getItem())
    }

    private setPayoutCurrency(): void {

        if (this.isItemSaved) {
            this.payoutCurrency = this.item.rate.currency
        } else {
            // if we are dealing in a stable, preferred currency... use it:
            if (this.isWorker) {
                const teamMembership: TeamMembership = this.userService.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships
                    .find(tm => tm.membershipIsToOrganization?.id === this.workOrder.clientOrganizationId)

                if (!!teamMembership && teamMembership.preferredPayoutCurrencyIsStable) {
                    this.payoutCurrency = teamMembership?.preferredPayoutCurrency ?? this.currencyService.USD
                } else {
                    this.payoutCurrency = this.currencyService.USD
                }
            } else {
                const vendor: TeamMemberAbstract = this.helper.selectedVendor
                if (!!vendor && vendor.preferredPayoutCurrencyIsStable) {
                    this.payoutCurrency = vendor.preferredPayoutCurrency ?? this.currencyService.USD
                }
            }
        }

        if (!this.payoutCurrency) {
            this.payoutCurrency = this.currencyService.USD
        }
    }

    private updateState(): void {
        this.stateChange.emit({
            value: this.form.value,
            index: this.index,
            dirty: this.form.dirty,
            invalid: this.form.invalid,
        })
    }

}
