import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AuthService } from '../../auth/services'
import { ConstGlobal } from '../../common/global'
import { UserService } from '../services'

@Injectable({
    providedIn: 'root',
})
export class BusinessTokenExpirationInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private users: UserService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(request)
            .pipe(
                catchError(error => {

                    // if this is  not the expired business token, just throw the error
                    if (error.error !== ConstGlobal.EXPIRED_BUSINESS_TOKEN_MESSAGE) {
                        return throwError(error)
                    }

                    // refresh the token and retry the request
                    return this.refreshToken(request, next)
                }),
            )
    }

    private refreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.users.refreshBusinessToken()
            .pipe(
                // note: this clone does not hit the auth interceptor
                // so we have to manually set the headers
                map(token => request.clone({
                    setHeaders: {
                        Authorization: this.auth.getAuthorizationHeaderValue(),
                        oToken: token,
                    },
                })),
                switchMap(newRequest => next.handle(newRequest)),
            )
    }
}
