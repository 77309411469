import { Injectable } from '@angular/core'

@Injectable()
export class DateService {

    constructor(
    ) { }

    public convertTZ(date: Date | string, tzString?: string): Date {
        return !!tzString ? new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString })) : new Date((typeof date === 'string' ? new Date(date) : date))
    }
}
