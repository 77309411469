import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UserTimeZoneInterceptor implements HttpInterceptor {

    constructor(
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // adds user TimeZone to all requests (specially important for uploads and date/time handling)
        const requestWithTz = request.clone({
            headers: request.headers.set('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        });

        return next.handle(requestWithTz);
    }
}
