import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {

    private readonly createAccountPrefix: string = 'create-account'

    constructor() { }

    addVerificationCode(item: { [key: string]: string }): void {
        localStorage.setItem(this.getItemNameWithPrefix('code', item.verificationType), item.code)
    }

    addCreateAccountItems(item: { [key: string]: string }): void {
        this.addItemsWithPrefix(item, this.createAccountPrefix)
    }

    addItemsWithPrefix(item: { [key: string]: string }, prefix: string): void {
        for (const [key, value] of Object.entries(item)) {
            localStorage.setItem(this.getItemNameWithPrefix(key, prefix), value)
        }
    }

    private getItemNameWithPrefix(name: string, prefix: string): string {
        return `${prefix}-${name}`
    }
}
