import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'inviteStatusColor',
})
export class InviteStatusColorPipe implements PipeTransform {

    transform(status: string): string {

        switch (status) {
            case 'Invited':
                return 'accent'
            case 'Onboarded':
                return 'primary'
            case 'Onboarding':
                return 'warn'
            default:
                return status?.startsWith('Removed') ? 'removed' : undefined
        }
    }
}
