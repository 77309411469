import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Organization } from '../../modules/models'
import { CreateBusinessRequest } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessStore {

    constructor(
        private readonly http: HttpClient,
        private readonly urls: UrlService,
    ) { }

    createBusiness(request: CreateBusinessRequest): Observable<Organization> {
        return this.http.post<Organization>(this.urls.api.organizations(), request)
    }

    updateBusiness(business: Organization, notifyOnSuccess?: boolean): Observable<Organization> {
        const request: any = {
            ...business,
            notifyOnSuccess,
        }
        return this.http.put<Organization>(this.urls.api.organizations(), request)
    }
}
