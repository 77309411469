import { OrganizationMembershipAbstractMember } from '../../auth/models'

export class InvoiceApprover {

    approvalResponse?: boolean
    approvalResponseExplanation?: string
    approverName?: string
    approverOrganizationMembershipId: string
    clientInvoiceId?: string
    createdByName?: string
    deleted?: boolean
    deletedOnTimestamp?: number
    id?: string
    isMe?: boolean
    img?: string
    order: number
    organizationClientInvoiceApprovalPolicyId?: string

    constructor(
        member: OrganizationMembershipAbstractMember,
        order?: number,
    ) {
        this.approverName = `${member.firstName} ${member.lastName}`,
            this.approverOrganizationMembershipId = member.membershipId,
            this.isMe = member.isMe
        this.img = member.avatarUrl
        this.order = order
    }
}
