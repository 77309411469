export enum DashboardAction {
    CONTRACT_SIGNER_PENDING_SETUP = 'CONTRACT_SIGNER_PENDING_SETUP',
    EXPRESS_CLIENT_CHOOSE_ACTION = 'EXPRESS-CLIENT-CHOOSE-ACTION',
    FINANCE_SETUP = 'FINANCE-SETUP',
    FINANCE_SETUP_VENDOR = 'FINANCE-SETUP-VENDOR',
    LEGAL_SETUP = 'LEGAL-SETUP',
    LEGAL_SETUP_CONTRACT_SIGNER = 'LEGAL-SETUP-CONTRACT-SIGNER',
    ONBOARDING_SETUP_CONTRACT_SIGNER = 'ONBOARDING-SETUP-CONTRACT-SIGNER',
    OPEN_CLIENT_VENDORS_WITH_GENERIC_CONTRACT = 'OPEN-CLIENT-VENDORS-WITH-GENERIC-CONTRACT',
    PROMOTE_SERVICE_PLAN = 'PROMOTE-SERVICE-PLAN',
}
