import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { FileItem, UploadFileResponse } from '../../../modules/liquid/modules/file-uploads/models'
import { PaymentScheduleUpdateRequest } from '../../../modules/liquid/modules/invoice-detail/models'
import { Invoice } from '../../../modules/liquid/modules/invoices/models'
import {
    BillingField,
    ClientInvoice,
    FXRateQuote,
    InvoiceApprover,
    InvoiceLineItem,
    InvoiceUpdatePropertiesRequest,
    LiquidFinancialAccountAssignment,
    VendorInvoicePaymentCount
} from '../../../modules/models'
import { InvoiceAttachmentType } from '../../../modules/models/invoice-attachment-type.enum'
import { InvoiceFactory } from '../../factories'
import { UrlService } from '../url.service'

@Injectable({ providedIn: 'any' })
export class InvoiceDetailsStore {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly invoiceFactory: InvoiceFactory,
        private readonly urls: UrlService,
    ) { }

    cancelInvoice(clientInvoiceId: string, reason: string): Observable<boolean> {
        return this.httpClient.post<boolean>(this.urls.api.invoiceCancel(), { clientInvoiceId, reason })
    }

    createFileForInvoice(file: FileItem): Observable<FileItem> {
        return this.httpClient.post<FileItem>(this.urls.api.invoiceFileCreate(), file)
    }

    createInvoiceMessage(invoiceId: string, message: string): Observable<boolean> {
        return this.httpClient.post<boolean>(
            this.urls.api.invoiceMessages(),
            { invoiceId, message },
        )
    }

    createInvoiceNotification(clientInvoiceId: string): Observable<boolean> {
        return this.httpClient.post<boolean>(
            this.urls.api.invoiceNotifications(),
            { clientInvoiceId },
        )
    }

    downloadFile(invoiceId: string, fileId: string, fileName: string): Observable<boolean> {
        return this.httpClient.get(this.urls.api.invoiceFileDownload(invoiceId, fileId), { responseType: 'blob' })
            .pipe(
                tap(response => FileSaver.saveAs(response, fileName)),
                map(() => true),
            )
    }

    getFilesForInvoice(invoiceId: string, bizId: string): Observable<Array<FileItem>> {
        return this.httpClient.get<Array<FileItem>>(this.urls.api.invoiceFileGet(invoiceId, bizId))
    }

    getInvoice(invoiceId: string, businessId: string): Observable<ClientInvoice> {
        return this.httpClient.get<ClientInvoice>(
            this.urls.api.invoiceDetails(invoiceId, businessId),
        ).pipe(
            map(invoice => this.invoiceFactory.create(invoice)),
        )
    }

    getInvoiceAttachment(invoiceId: string, attachmentType: InvoiceAttachmentType): Observable<Blob> {
        return this.httpClient.get(
            this.urls.api.invoiceAttachment(invoiceId, attachmentType),
            { responseType: 'blob' },
        )
    }

    getInvoiceLineItems(invoiceId: string, businessId: string): Observable<InvoiceLineItem[]> {
        return this.httpClient.get<InvoiceLineItem[]>(this.urls.api.invoiceLineItems(invoiceId, businessId))
    }

    getInvoicesFXRateQuotes(invoiceIds: string[], businessId: string): Observable<FXRateQuote[]> {
        return this.httpClient.post<FXRateQuote[]>(
            this.urls.api.invoicesFXRateQuotes(businessId),
            {
                invoiceIds,
            })
    }

    getVendorInvoicePaymentCount(businessId: string, invoiceId: string): Observable<VendorInvoicePaymentCount> {
        return this.httpClient.get<VendorInvoicePaymentCount>(this.urls.api.vendorInvoicePaymentCount(businessId, invoiceId))
    }

    updateChartOfAccountAssociations(
        clientInvoiceId: string,
        liquidFinancialAccountAssignments: LiquidFinancialAccountAssignment[],
    ): Observable<Invoice> {
        return this.httpClient.post<Invoice>(
            this.urls.api.invoiceChartOfAccountAssociations(),
            { clientInvoiceId, liquidFinancialAccountAssignments },
        )
    }

    updateInvoiceApprovalStatus(
        clientInvoiceApproverId: string,
        approvalResponse: boolean,
        approvalResponseExplanation?: string,
    ): Observable<InvoiceApprover> {
        const body: object = {
            clientInvoiceApproverId,
            approvalResponse,
            approvalResponseExplanation,
        }

        return this.httpClient.post<InvoiceApprover>(
            this.urls.api.invoiceApprovalStatus(),
            body,
        )
    }

    updateInvoiceApprovers(invoiceId: string, approvers: InvoiceApprover[]): Observable<InvoiceApprover[]> {
        return this.httpClient.post<InvoiceApprover[]>(
            this.urls.api.invoiceApprovers(invoiceId),
            approvers,
        )
    }

    updateInvoiceProcessingDecision(clientInvoiceId: string, accepted: boolean, noteToInvoicer?: string): Observable<boolean> {
        return this.httpClient.post<boolean>(
            this.urls.api.invoiceProcessingDecision(),
            { clientInvoiceId, accepted, noteToInvoicer },
        )
    }

    updateInvoiceProperties(request: InvoiceUpdatePropertiesRequest): Observable<ClientInvoice> {
        return this.httpClient.patch<ClientInvoice>(this.urls.api.invoices(), request)
    }

    updateSchedulePayment(invoiceId: string, request: PaymentScheduleUpdateRequest): Observable<ClientInvoice> {
        return this.httpClient.put<ClientInvoice>(this.urls.api.invoicePaymentSchedule(invoiceId), request)
    }

    uploadFileForInvoice(invoiceId: string, file: FormData, fileName: string): Observable<UploadFileResponse> {
        return this.httpClient.post<UploadFileResponse>(this.urls.api.invoiceFileUpload(invoiceId, fileName), file)
    }

    getInvoiceBillingFieldsAssociations(invoiceId: string): Observable<BillingField[]> {
        return this.httpClient.get<BillingField[]>(this.urls.api.invoiceBillingFieldAssociations(invoiceId))
    }

    associateInvoiceBillingField(invoiceId: string, billingFieldId: string): Observable<boolean> {
        return this.httpClient.put<boolean>(this.urls.api.invoiceBillingFieldAssociation(invoiceId, billingFieldId), null)
    }

    disassociateInvoiceBillingField(invoiceId: string, billingFieldId: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(this.urls.api.invoiceBillingFieldAssociation(invoiceId, billingFieldId))
    }
}
