import { CdkOverlayOrigin, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-help-tooltip',
    templateUrl: './help-tooltip.component.html',
    styleUrls: ['./help-tooltip.component.scss'],
})
export class HelpTooltipComponent implements AfterViewInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject<void>()

    @ViewChild(CdkOverlayOrigin) _overlayOrigin: CdkOverlayOrigin
    @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<any>

    @Input() maxHeight?: number = 500
    @Input() maxWidth?: number = 800
    @Input() name?: string = 'help-circle'

    overlayRef?: OverlayRef
    templatePortal?: TemplatePortal<any>
    isOpen: boolean = false

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
    ) { }

    ngAfterViewInit(): void {
        this.templatePortal = new TemplatePortal(
            this.templatePortalContent,
            this._viewContainerRef,
        )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    open(): void {
        const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this._overlayOrigin.elementRef)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom',
                },
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                },
            ])
        this.overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: true,
            panelClass: 'help-tooltip-panel',
            backdropClass: 'help-tooltip-backdrop',
        })
        this.overlayRef.backdropClick()
            .pipe(
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.close()
            })
        this.overlayRef.attach(this.templatePortal)
        this.isOpen = true
    }

    close(): void {
        this.overlayRef?.dispose()
        this.isOpen = false
    }

}
