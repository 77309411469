import { Component, OnInit } from '@angular/core'

import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-hubspot',
    templateUrl: './hubspot.component.html',
    styleUrls: ['./hubspot.component.scss'],
})
export class HubspotComponent implements OnInit {
    ngOnInit(): void {
        if (!environment.internalSettings.enableHubspot) {
            return
        }

        // HubSpot Embed Code initialization
        const e: HTMLScriptElement = document.createElement('script')
        e.id = 'hs-script-loader'
        e.async = !0
        e.src = 'https://js.hs-scripts.com/20203563.js'
        document.head.appendChild(e)

        window['hsConversationsSettings'] = {
            loadImmediately: false,
        }

        window['hsConversationsOnReady'] = [
            () => {
                // tslint:disable-next-line:no-console
                console.log('Hubspot is ready')
            },
        ]
    }
}
