<app-dialog-template-small
    [title]="title"
    [hideButton]="true"
>

    <div
        *ngIf="!!description"
        class="dialog-content"
        [innerHTML]="description | safeHtml "
    ></div>

    <div
        *ngIf="!!isError && !description"
        class="dialog-content"
    >
        <p class="primary100">
            Whoops! We've found a bug.
        </p>

        <p *ngIf="!chatEnabled">We’re on it!</p>

        <p *ngIf="!!chatEnabled">
            We’re on it but if you’d like to help us troubleshoot your problem,
            <a
                class="track-error-chat-click"
                (click)="openChat()"
            >
                chat with Support
            </a>
            now.
        </p>

        <p
            *ngIf="!!chatEnabled && !chat"
            class="low-contrast"
        >
            Chat is not enabled, so clicking the link will only close the dialog.
            If chat were enabled, it would also toggle open automatically.
        </p>
    </div>

</app-dialog-template-small>