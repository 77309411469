import { Injectable } from '@angular/core'

import { DateType, DefaultTemplateFieldNames, DocumentSignStepDefinition, DocumentUploadStepDefinition, LongStringType, SignatureType, StringType } from '../../modules/models'

@Injectable({
    providedIn: 'root',
})
export class StepDefinitionFactory {

    createCiiaStepDefinition(): DocumentSignStepDefinition {
        return Object.assign(new DocumentSignStepDefinition(), {
            isMasterContractDocument: true,
            templateName: 'FormConfidentialandIPAgreementJan2020.docx',
            originalFilename: '[Liquid Confidential And IP Agreement Template]',
            templateValues: [
                {
                    name: 'Company Name',
                    fieldName: DefaultTemplateFieldNames.companyName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Verify your company name.',
                    valueType: StringType.HiringOrgCompanyName,
                },
                {
                    name: 'Company Incorporation Info',
                    fieldName: DefaultTemplateFieldNames.companyIncInfo,
                    required: false,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter your company incorporation info (such as a Delaware Corp or a California LLC). If working as yourself, leave blank.',
                    valueType: StringType.ClientStructure,
                },
                {
                    name: 'Governing State',
                    fieldName: DefaultTemplateFieldNames.governingState,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the state you want to resolve conflicts in. Most companies pick the location  of their HQ.',
                    valueType: StringType.GoverningState,
                },
                {
                    name: 'Company Representative Name',
                    fieldName: DefaultTemplateFieldNames.companyRepName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the individual\'s name who will sign documents representing your company.',
                    valueType: StringType.HiringOrgSignerName,
                },
                {
                    name: 'Company Representative Title',
                    fieldName: DefaultTemplateFieldNames.companyRepTitle,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the signing representative\'s title.',
                    valueType: StringType.HiringOrgSignerTitle,
                },
                {
                    name: 'Company Representative Signature',
                    fieldName: DefaultTemplateFieldNames.companyRepSignature,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Sign here. This signature will only be attached to fully executed documents.',
                    valueType: SignatureType.CompanyRepSignature,
                },
                {
                    name: 'Agreement Date',
                    fieldName: 'agreementDate',
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'This is the current date that will be used for execution.',
                    valueType: DateType.CurrentDate,
                },
                {
                    name: 'Consultant Name',
                    fieldName: DefaultTemplateFieldNames.consultantName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Verify your name or your company\'s name to be used in this Agreement. Please use official and full names.',
                    valueType: StringType.SigningOrgName,
                },
                {
                    name: 'Signer\'s Name',
                    fieldName: DefaultTemplateFieldNames.signerName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter name of signer. If company, enter the individual\'s name who will sign documents representing your company.',
                    valueType: StringType.SignerName,
                },
                {
                    name: 'Signer\'s Title',
                    fieldName: DefaultTemplateFieldNames.signerTitle,
                    required: true,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the signer\'s title.',
                    valueType: StringType.SignerTitle,
                },
                {
                    name: 'Signer\'s Signature',
                    fieldName: 'signerSignature1,signerSignature2,signerSignature3',
                    required: true,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Sign here.',
                    valueType: SignatureType.SignerSignature,
                },
                {
                    name: 'Signature Date',
                    fieldName: DefaultTemplateFieldNames.signerSignatureDate,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'This is the current date that will be used for execution.',
                    valueType: DateType.CurrentDate,
                },
                {
                    name: 'Inventions List',
                    fieldName: DefaultTemplateFieldNames.inventionList,
                    required: false,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter a list of your inventions. Include details such as title, date, and identifying number or description.',
                    valueType: LongStringType.SigningOrgPriorInventionsList,
                },
                {
                    name: 'Conflicts List',
                    fieldName: DefaultTemplateFieldNames.conflictList,
                    required: false,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter a list of companies with the name of agreements and dates where you may have conflicts with performing your Services.',
                    valueType: LongStringType.SigningOrgConflictingCompaniesList,
                },
            ],
        })
    }

    createConsultingAgreementStepDefinition(): DocumentSignStepDefinition {
        return Object.assign(new DocumentSignStepDefinition(), {
            templateName: 'FormConsultingAgreementJan2020.docx',
            originalFilename: '[Liquid Consulting Agreement Template]',
            uploadedTimestamp: 0,
            templateValues: [
                {
                    name: 'Company Name',
                    fieldName: DefaultTemplateFieldNames.companyName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Verify your company name.',
                    valueType: StringType.HiringOrgCompanyName,
                },
                {
                    name: 'Company Incorporation Info',
                    fieldName: DefaultTemplateFieldNames.companyIncInfo,
                    required: false,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter your company incorporation info (such as a Delaware Corp or a California LLC). If working as yourself, leave blank.',
                    valueType: StringType.ClientStructure,
                },
                {
                    name: 'Governing State',
                    fieldName: DefaultTemplateFieldNames.governingState,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the state you want to resolve conflicts in. Most companies pick the location  of their HQ.',
                    valueType: StringType.GoverningState,
                },
                {
                    name: 'Company Representative Name',
                    fieldName: DefaultTemplateFieldNames.companyRepName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the individual\'s name who will sign documents representing your company.',
                    valueType: StringType.HiringOrgSignerName,
                },
                {
                    name: 'Company Representative Title',
                    fieldName: DefaultTemplateFieldNames.companyRepTitle,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the signing representative\'s title.',
                    valueType: StringType.HiringOrgSignerTitle,
                },
                {
                    name: 'Company Representative Signature',
                    fieldName: DefaultTemplateFieldNames.companyRepSignature,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Sign here. This signature will only be attached to fully executed documents.',
                    valueType: SignatureType.CompanyRepSignature,
                },
                {
                    name: 'Agreement Date',
                    fieldName: 'agreementDate',
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'This is the current date that will be used for execution.',
                    valueType: DateType.CurrentDate,
                },
                {
                    name: 'Consultant Name',
                    fieldName: DefaultTemplateFieldNames.consultantName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Verify your name or your company\'s name to be used in the Consulting Agreement. Please use official and full names.',
                    valueType: StringType.SigningOrgName,
                },
                {
                    name: 'Signer\'s Name',
                    fieldName: DefaultTemplateFieldNames.signerName,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter name of signer. If company, enter the individual\'s name who will sign documents representing your company.',
                    valueType: StringType.SignerName,
                },
                {
                    name: 'Signer\'s Title',
                    fieldName: DefaultTemplateFieldNames.signerTitle,
                    required: true,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Enter the signer\'s title.',
                    valueType: StringType.SignerTitle,
                },
                {
                    name: 'Signer\'s Signature',
                    fieldName: DefaultTemplateFieldNames.signerSignature,
                    required: true,
                    isInput: true,
                    isPreInviteInput: false,
                    inputInstruction: 'Sign here.',
                    valueType: SignatureType.SignerSignature,
                },
                {
                    name: 'Signature Date',
                    fieldName: DefaultTemplateFieldNames.signerSignatureDate,
                    required: true,
                    isInput: false,
                    isPreInviteInput: false,
                    inputInstruction: 'This is the current date that will be used for execution.',
                    valueType: DateType.CurrentDate,
                },
            ],
        })
    }

    createDocumentUploadStepDefinition(rawStepDef: string): DocumentUploadStepDefinition {
        const stepDef: any = JSON.parse(rawStepDef)
        const output: DocumentUploadStepDefinition = {
            documentName: stepDef.documentName || stepDef.DocumentName,
            fileName: stepDef.fileName || stepDef.Filename,
            instructions: stepDef.instructions || stepDef.Instructions,
        }
        return output
    }

    createGenericMasterContractStepDefinition(): DocumentSignStepDefinition {
        return Object.assign(new DocumentSignStepDefinition(), {
            isMasterContractDocument: true,
            templateName: 'FormGenericContractApril2021.docx',
            originalFilename: '[Liquid Generic Consulting Agreement]',
            templateValues: [{
                isInput: false,
                isPreInviteInput: false,
                fieldName: DefaultTemplateFieldNames.vendorSignatureDate,
                name: 'Vendor Signature Date',
                required: true,
                valueType: DateType.VendorSignatureDate,
            }],
        })
    }

}
