<span
    class="wrapper body1 textbox"
    (click)="focus()"
    [ngClass]="{ 'empty': isEmpty,  'error': (!!control.errors && touched) && editable, 'primary100': (!control.errors && !control.disabled) || !editable, 'low-contrast': control.disabled && editable, disabled: control.disabled && editable, 'readonly': control.disabled  }"
>
    <span
        #input
        #tooltip="matTooltip"
        [matTooltip]="errorMessage"
        [matTooltipDisabled]="(!control.errors && !forceTooltip) || control.disabled || !editable"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'madlib-error'"
        role="textbox"
        class="input"
        (click)="focus()"
        (blur)="onBlur()"
        [contentEditable]="!control.disabled"
    ></span>
</span>
<span *ngIf="isMoney">&nbsp;{{currencyForMoney}}&nbsp;</span>
<img
    *ngIf="editable"
    class="tab-button"
    src="/assets/img/icons/tab-button.svg"
    (click)="tabAccessibilityForTouchScreen();"
>