export enum DashboardItemType {
    CONTRACT_SIGNER_PENDING_SETUP = 1,
    COMPLETED_ONBOARDS = 2,
    HAS_UNVERIFIED_PAYMENT_METHOD = 3,
    INCOMPLETE_ONBOARDS = 4,
    INVOICES_PENDING_PROCESSING_COUNT = 5,
    MY_APPROVAL_INVOICE_COUNT = 6,
    ONBOARDED_TEAM_MEMBER_COUNT = 7,
    OUR_INCOMPLETE_ONBOARDS = 8,
    UNPAID_INVOICE_COUNT = 9,
    WORKER_WORK_ORDER_STATUS_COUNT = 10,
    OUTSTANDING_INVITES = 11,
    DRAFT_WORK_ORDER_COUNT = 12,
    WAITING_CLIENT_ACCEPTANCE_COUNT = 13,
    WAITING_VENDOR_ACCEPTANCE_COUNT = 14,
    ACTIVE_WORK_ORDER_COUNT = 15,
    SHORTCUT_WORK_ORDER = 16,
    SHORTCUT_SEND_PROPOSAL = 17,
    SHORTCUT_CREATE_WORK_ORDER = 18,
    SHORTCUT_CREATE_INVOICE = 19,
    ACTIVE_VENDOR_WORK_ORDER_COUNT = 20,
    DRAFT_VENDOR_WORK_ORDER_COUNT = 21,
    SHORTCUT_INVITE_VENDOR = 22,
    CLIENT_INVOICE_INTEGRATION_ERRORS = 23,
    LIQUID_INVOICE_INTEGRATION_ERRORS = 24,
    FINANCIAL_INTEGRATION_DISCONNECTED = 25,
    INVOICE_OUTSTANDING_COUNT = 26,
    FINANCE_SETUP_REQUIRED = 27,
    LEGAL_SETUP_REQUIRED = 28,
    FINANCE_SETUP_REQUIRED_VENDOR = 29,
    LEGAL_SETUP_CONTRACT_SIGNER_REQUIRED = 30,
    ONBOARDING_SETUP_CONTRACT_SIGNER_REQUIRED = 31,
    CLIENT_PROMOTE_SERVICE_PLAN = 32,
    CLIENT_VENDORS_WITH_GENERIC_CONTRACT = 33,
    DEPOSIT_VERIFICATION_FAILED = 34,
    CLIENT_VENDORS_WAITING_FOR_COUNTERSIGNING = 35,
    ACTIVE_PURCHASE_ORDER_COUNT = 36,
    PURCHASE_ORDER_WAITING_VENDOR_ACCEPTANCE_COUNT = 37,
    PURCHASE_ORDER_WAITING_CLIENT_ACCEPTANCE_COUNT = 38,
    DRAFT_PURCHASE_ORDER_COUNT = 39,
    EXPLAINER_SHOW_CREATE_INVOICE_VENDOR = 40,
    EXPLAINER_SHOW_CREATE_WO = 41,
    EXPLAINER_SHOW_INVITE_VENDOR = 42,
    SHOW_FIRST_TIME_SETUP = 43,
    SHORTCUT_CLIENT_UPLOAD_INVOICE = 44,
}
