<div
    [ngClass]="{ 'narrow-row': !!narrow }"
    class="field-row street-fields"
>

    <mat-form-field>
        <mat-label>{{billing}}Street Address</mat-label>
        <input
            matInput
            [formControl]="street1"
            [errorStateMatcher]="dirtyErrorMatcher"
            [required]="!isOptional('street1')"
        >
        <mat-error *ngIf="!!street1?.errors?.required">
            You must enter your address
        </mat-error>
        <mat-error *ngIf="!!street1?.errors?.minLength">
            Address must be at least {{street1.errors.minLength}} characters
        </mat-error>
        <mat-error *ngIf="!!street1?.errors?.maxlength">
            Address must be not longer than {{street1.errors.maxlength.requiredLength}} characters
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{billing}}Street Address 2</mat-label>
        <input
            matInput
            [formControl]="street2"
            [errorStateMatcher]="dirtyErrorMatcher"
            [required]="street2Required"
        >
        <mat-error *ngIf="!!street1?.errors?.required">
            You must enter your address line 2
        </mat-error>
    </mat-form-field>

</div>

<div
    [ngClass]="{ 'narrow-row': !!narrow }"
    class="state-row"
>

    <mat-form-field class="city">
        <mat-label>{{cityFieldName}}</mat-label>
        <input
            matInput
            [formControl]="city"
            [errorStateMatcher]="dirtyErrorMatcher"
            [required]="cityRequired"
            minlength="2"
        >
        <mat-error *ngIf="!!city?.errors?.required">
            {{cityFieldName}} is required
        </mat-error>
        <mat-error *ngIf="!!city?.errors?.minLength">
            City must be at least {{city.errors.minLength}} characters
        </mat-error>
    </mat-form-field>

    <app-state-form
        [country]="country"
        [state]="state"
        [abbrv]="stateAbbrv"
        [forceInternational]="showNonUs"
        [required]="stateRequired"
    >
    </app-state-form>

    <mat-form-field *ngIf="isUsa && !showNonUs">
        <mat-label>Zip</mat-label>
        <input
            matInput
            [formControl]="zip"
            [errorStateMatcher]="dirtyErrorMatcher"
            (blur)="formatZipControl()"
            [required]="zipRequired"
        >
        <mat-error *ngIf="!!zip?.errors?.required">
            Zip is required
        </mat-error>
        <mat-error *ngIf="!!zip?.errors?.invalidZip">
            Zip is invalid (e.g. 00000 or 00000-0000)
        </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!isUsa || showNonUs">
        <mat-label>{{postalCodeFieldName}}</mat-label>
        <input
            matInput
            [formControl]="postalCode"
            [errorStateMatcher]="dirtyErrorMatcher"
            [required]="postalCodeRequired"
        >
        <mat-error *ngIf="!!postalCode?.errors?.required">
            {{postalCodeFieldName}} is required
        </mat-error>
    </mat-form-field>

</div>

<div
    *ngIf="!hideCountry || showOtherLocations"
    [ngClass]="{ 'narrow-row': !!narrow }"
    class="field-row"
>

    <app-country-form
        *ngIf="!hideCountry"
        [country]="country"
        [required]="countryRequired"
        [hideUs]="showNonUs"
    ></app-country-form>

    <div
        *ngIf="showOtherLocations"
        class="location-container"
    >

        <mat-form-field>
            <mat-label>Other Offices and/or Employee Locations</mat-label>
            <mat-chip-list #locList>
                <input
                    matInput
                    [formControl]="locations"
                    [matChipInputFor]="locList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addOperatingLocation()"
                    autocomplete="off"
                >
            </mat-chip-list>
        </mat-form-field>

        <div
            *ngIf="!!otherOperatingLocations.length"
            class="chips-group"
        >
            <app-chip-list
                [options]="otherOperatingLocations"
                (removeOption)="removeOperatingLocation($event)"
            >
            </app-chip-list>
        </div>

    </div>
</div>
<div
    *ngIf="showPhoneNumber"
    [ngClass]="{ 'narrow-row': !!narrow }"
    class="field-row"
>
    <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <input
            matInput
            [formControl]="phoneNumber"
            (blur)='formatNumber($event)'
            type="text"
            required
        />
        <mat-error *ngIf="phoneNumber.errors?.required">Phone number is required.</mat-error>
        <mat-error *ngIf="phoneNumber.errors?.phone">Phone number is invalid.</mat-error>
    </mat-form-field>
</div>