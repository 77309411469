import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UrlService } from '../../core/services/url.service'
import { PagedSet, PageRequest } from '../../table/models'
import { LiquidInvoiceAbstract } from '../models'

@Injectable({
    providedIn: 'root',
})
export class LiquidInvoiceStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    download(invoice: LiquidInvoiceAbstract): Observable<boolean> {
        return this.http.get(this.urls.api.liquidInvoiceDownload(invoice.id), { observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const invoiceDate: Date = new Date(invoice.created * 1000)
                    FileSaver.saveAs(response.body, `liquid-invoice-${invoiceDate.getFullYear()}-${this.formatMonth(invoiceDate)}.pdf`)
                    return true
                }),
            )
    }

    getPage(businessId: string, page: PageRequest): Observable<PagedSet<LiquidInvoiceAbstract>> {
        let params: HttpParams = new HttpParams()
        Object.keys(page).forEach(key => params = params.set(key, page[key]))
        return this.http.get<PagedSet<LiquidInvoiceAbstract>>(this.urls.api.liquidInvoices(businessId), { params })
    }

    updateIntegration(liquidInvoiceId: string): Observable<boolean> {
        return this.http.post(this.urls.api.liquidInvoicesUpload(), { liquidInvoiceId })
            .pipe(
                map(() => true),
            )
    }

    private formatMonth(date: Date): string {
        let month: string = '' + (date.getMonth() + 1)
        if (month.length < 2) {
            month = '0' + month
        }

        return month
    }
}
