<app-dialog-template-small [title]="title">
    <div class="dialog-input">
        <div *ngIf="!!description?.length">
            <div class="mtv-10 mbv-10">{{description}}</div>
            <br><br>
        </div>
        <div *ngIf="!isLongString">
            <mat-form-field class="w-100">
                <mat-label>{{placeholder}}</mat-label>
                <input
                    [formControl]="form"
                    matInput
                >
            </mat-form-field>
        </div>
        <div *ngIf="isLongString">
            <mat-form-field class="w-100">
                <mat-label>{{placeholder}}</mat-label>
                <textarea
                    [formControl]="form"
                    matInput
                    [cdkTextareaAutosize]="true"
                    cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="6"
                ></textarea>
            </mat-form-field>
        </div>
        <mat-error *ngIf="!!form.dirty && !!form.invalid">{{errorText}}</mat-error>
    </div>

    <button
        mat-flat-button
        color="primary"
        (click)="okClicked()"
        [disabled]="!!form.invalid"
        class="dialog-button"
    >
        {{okButtonText}}
    </button>
</app-dialog-template-small>
