<i-feather
    #portal
    cdk-overlay-origin
    [name]="name"
    class="trigger"
    (click)="open()"
></i-feather>

<ng-template #templatePortalContent>
    <div class="content">
        <ng-content></ng-content>
    </div>
</ng-template>
