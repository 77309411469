import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ContractSignerInvitation } from '../../modules/models'

import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable()
export class LegalSetupStore {

    constructor(
        private readonly userService: UserService,
        private readonly httpClient: HttpClient,
        private readonly urls: UrlService,
    ) { }

    getContractSingerInvitations(): Observable<ContractSignerInvitation[]> {
        const url: string = this.urls.api.contractSignerInvitations(this.userService.businessSnapshot.id, true)
        return this.httpClient.get<ContractSignerInvitation[]>(url)
    }
}
