export enum Claim {
    AcceptVendorSow = 'accept_vendor_sow',
    BrowseVendors = 'browse_vendors',
    ManageAccounting = 'manage_accounting',
    ManageBusiness = 'manage_business',
    ManageClients = 'manage_clients',
    ManageContracts = 'manage_contracts',
    ManageInvoicePolicies = 'manage_invoice_policies',
    ManageIncomingInvoices = 'manage_incoming_invoices',
    ManageWorkOrders = 'manage_projects',
    ManageSowPolicies = 'manage_sow_policies',
    ManageVendors = 'manage_vendors',
    PayInvoices = 'pay_invoices',
    ProcessInvoices = 'process_invoices',
    ProcessSows = 'process_sows',
    SignContracts = 'sign_contracts',
    ViewIncomingInvoices = 'view_incoming_invoices',
}
