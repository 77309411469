import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { DialogModule } from '../dialogs'

import { SystemHealthGuard, SystemHealthService, UpdownDebouncerService } from './services'
import { SystemDownComponent } from './system-down/system-down.component'

const routes: Routes = [
    {
        path: '',
        component: SystemDownComponent,
    },
]
@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        SystemDownComponent,
    ],
    exports: [
        SystemDownComponent,
    ],
    providers: [
        UpdownDebouncerService,
        SystemHealthGuard,
        SystemHealthService,
    ],
})
export class SystemHealthModule {
    static forRoot(): ModuleWithProviders<SystemHealthModule> {
        return {
            ngModule: SystemHealthModule,
            providers: [
                SystemHealthGuard,
                SystemHealthService,
                UpdownDebouncerService,
            ],
        }
    }
}
