import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

import { ParseStrategies } from './parse-strategies.enum'
import { StorageUsage } from './storage-usage.enum'

/**
 * Manages items in the local storage and in-memory stream and keep these storages in sync
 *
 * @class BrowserStorageService
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
    private localStorageEnabled: boolean = false

    private inMemoryStorage: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>()

    public constructor() {
        this.ensureLocalStorageEnabled()
    }

    /**
     * Retrieves value with a given key from a storage.
     * At first it searches for given key in in-memory stream, if nothing found it falls back to local storage
     * @param {string} key specifies object's key
     * @param {ParseStrategies} parseStrategy specifies if an object retrieved from the local storage should be parsed as number, object, number or it shouldn't be parsed at all
     * @returns {TValue | undefined} undefined if nothing is found
     */
    public getItem<TValue>(key: string, parseStrategy: ParseStrategies = ParseStrategies.Object): TValue | undefined {
        const associatedInMemorySteam: BehaviorSubject<TValue> | undefined = this.inMemoryStorage.get(key)
        if (associatedInMemorySteam !== undefined) {
            return associatedInMemorySteam.value
        }

        if (this.localStorageEnabled) {
            const localStorageItem: string = localStorage.getItem(key)

            // Converting from null to undefined to keep return type consistent
            // Note, local storage returns null if nothing found
            return localStorageItem ? this.parseItem(localStorageItem, parseStrategy) as TValue : undefined
        }

        return undefined
    }

    /**
     * Retrieves associated in-memory stream
     * @param {string} key specifies object's key
     * @returns {Observable} returns associated in-memory stream or undefined if nothing is found
     */
    public getImMemorySteam<TValue>(key: string): Observable<TValue> | undefined {
        const associatedInMemoryStream: BehaviorSubject<TValue> | undefined = this.inMemoryStorage.get(key)
        return !!associatedInMemoryStream
            ? associatedInMemoryStream.asObservable()
            : undefined
    }

    /**
     * Used to add new items. If items with such a key already exists, does nothing.
     * Note, before adding new item, it will check in-memory and local storages both!
     * @param {string} key specifies key which is used to access given value
     * @param {TValue} value specifies value to be added
     * @param {StorageUsage} usage argument is used to specify whether item should be added to the local storage, in-memory stream or both.
     * @returns {boolean} Returns true if item was added successfully, otherwise returns false.
     */
    public addItem<TValue>(key: string, value: TValue, usage: StorageUsage = StorageUsage.Local): boolean {
        const associatedInMemoryStream: BehaviorSubject<TValue> | undefined = this.inMemoryStorage.get(key)
        if (associatedInMemoryStream !== undefined) {
            return false
        }

        if (this.localStorageEnabled) {
            const associatedLocalStorageItem: string = localStorage.getItem(key)
            if (associatedLocalStorageItem !== null) {
                return false
            }
        }

        this.setItem(key, value, usage)
        return true
    }

    /**
     * Used to add items to local/in-memory storage in the unsafe way.
     * Unlike addItem(), it will override value in a storage
     * @param {string} key specifies key which is used to access given value
     * @param {TValue} value specifies value to be added
     * @param {StorageUsage} usage argument is used to specify whether item should be set in the local storage, in-memory stream or both.
     * @returns {boolean} Returns true if item was overwritten.
     */
    public setItem<TValue>(key: string, value: TValue, usage: StorageUsage = StorageUsage.Local): boolean {
        switch (usage) {
            case StorageUsage.Local: {
                return this.setLocalStorageItem(key, value)
            }
            case StorageUsage.InMemory: {
                return this.setToInMemoryStream(key, value)
            }
            default: {
                const itemOverwritten: boolean = this.setLocalStorageItem(key, value)
                const streamExists: boolean = this.setToInMemoryStream(key, value)
                return itemOverwritten || streamExists
            }
        }
    }

    /**
     * Removes stored object with a given key from both in-memory stream and local storage
     * @param key specify object key to remove
     */
    public removeItem(key: string): boolean {
        let itemRemoved: boolean = false
        const associatedInMemoryStream: BehaviorSubject<any> | undefined = this.inMemoryStorage.get(key)
        if (associatedInMemoryStream !== undefined) {
            associatedInMemoryStream.complete()
            this.inMemoryStorage.delete(key)
            itemRemoved = true
        }

        if (this.localStorageEnabled) {
            const associatedLocalStorageItem: string | null = localStorage.getItem(key)
            if (associatedLocalStorageItem !== null) {
                localStorage.removeItem(key)
                itemRemoved = true
            }
        }

        return itemRemoved
    }

    private setLocalStorageItem(key: string, value: any): boolean {
        if (!this.localStorageEnabled) {
            return false
        }
        const itemExists: boolean = !!localStorage.getItem(key)
        localStorage.setItem(key, this.stringifyItem(value))
        return itemExists
    }

    private setToInMemoryStream(key: string, value: any): boolean {
        let associatedInMemoryStream: BehaviorSubject<any> | undefined = this.inMemoryStorage.get(key)
        const streamExists: boolean = !!associatedInMemoryStream

        if (!associatedInMemoryStream) {
            associatedInMemoryStream = new BehaviorSubject<any>(value)
        } else {
            associatedInMemoryStream.next(value)
        }

        this.inMemoryStorage.set(key, associatedInMemoryStream)

        return streamExists
    }

    private parseItem(item: string, parseStrategy: ParseStrategies): any {
        switch (parseStrategy) {
            case ParseStrategies.Date:
                return new Date(item)
            case ParseStrategies.Object:
                return JSON.parse(item)
            case ParseStrategies.Number:
                return Number.parseFloat(item)
            case ParseStrategies.None:
            default:
                return item
        }
    }

    private stringifyItem(item: any): string {
        switch (typeof item) {
            case 'string':
                return item
            case 'object':
                return JSON.stringify(item)
            case 'undefined':
                return ''
            default:
                return String(item)
        }
    }

    private ensureLocalStorageEnabled(): void {
        if (typeof localStorage === 'undefined') {
            this.localStorageEnabled = false
            return
        }

        const dummyStorageKey: string = String(+ new Date())
        try {
            localStorage.setItem(dummyStorageKey, '')
            localStorage.removeItem(dummyStorageKey)
            this.localStorageEnabled = true
        } catch (ex: any) {
            this.localStorageEnabled = false
        }
    }
}
