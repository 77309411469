import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { StatusCodes } from 'http-status-codes'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { CreateBusinessWorkflow } from '../../core/models'
import { UrlService, UserService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { CreateBusinessStepId } from '../liquid/modules/create-business-common/models'
import { PartialBusiness } from '../models'

import { PartialBusinessStore } from './partial-business.store'

@Injectable({
    providedIn: 'root',
})
export class PartialBusinessService {

    private currentPartialSubject: BehaviorSubject<PartialBusiness> = new BehaviorSubject({})

    get partialBusinessSnapshot(): PartialBusiness { return this.currentPartialSubject.getValue() }

    constructor(
        private dialogs: DialogService,
        private router: Router,
        private store: PartialBusinessStore,
        private urls: UrlService,
        private users: UserService,
    ) { }

    get(forceRefresh?: boolean): Observable<PartialBusiness> {

        if (!!this.partialBusinessSnapshot.id && !forceRefresh) {
            return of(this.partialBusinessSnapshot)
        }

        return this.store.get()
            .pipe(
                take(1),
                map(partial => partial || {}),
                tap(partial => this.currentPartialSubject.next(partial)),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createOrUpdate(business: PartialBusiness): Observable<PartialBusiness> {

        const request: Observable<PartialBusiness> = !!business.id
            ? this.store.update(business)
            : this.store.create(business)

        return request
            .pipe(
                take(1),
                tap(updated => this.currentPartialSubject.next(updated)),
                catchError(error => {
                    // if we got a 429, which is too many requests,
                    // or we got a 409, which is creating an existing biz
                    // it means we tried to update the biz quickly in succession
                    // so just bury it and return the existing business
                    if ([StatusCodes.TOO_MANY_REQUESTS, StatusCodes.CONFLICT].includes(error.status)) {
                        return of(business)
                    }
                    return this.dialogs.error(error)
                }),
            )
    }

    delete(): Observable<void> {
        return this.store.delete()
            .pipe(
                take(1),
                tap(() => this.currentPartialSubject.next({})),
                catchError(error => this.dialogs.error(error)),
            )
    }

    resolveRoute(url: string): Observable<PartialBusiness> {

        return this.get(true)
            .pipe(
                map(partialBusiness => {
                    const createBusinessRoute: string = this.urls.route.createBusiness()
                    const createBusinessExpressRoute: string = this.urls.route.createBusiness({ workflow: CreateBusinessWorkflow.ExpressSetup })
                    const clientAcceptExpressRoute: string = `${this.urls.route.createBusiness({ workflow: CreateBusinessWorkflow.ExpressSetup })}/client-accept`
                    const isCreateBusinessLandingRoute: boolean = url === createBusinessRoute
                    const isClientAccept: boolean = url.startsWith(clientAcceptExpressRoute)
                    const isOrgAccept: boolean = this.urls.route.isOrgAccept(url)

                    // if user accepts invitation he should not be redirected to create business page
                    if (isOrgAccept) {
                        partialBusiness = {}
                    }

                    if (isClientAccept) {
                        partialBusiness = {}
                    }

                    // if we don't have a partial,
                    // OR we're already going to a page w/in the workflow,
                    // OR we're going to the create biz landing page and we don't have the biz func assigned,
                    // OR we're not going to the landing page and the partial biz isn't the current biz
                    // there's nothing to more to do
                    if (
                        !partialBusiness.id
                        || (url.startsWith(createBusinessRoute) && !isCreateBusinessLandingRoute)
                        || (isCreateBusinessLandingRoute && !partialBusiness.isWorker && !partialBusiness.isHirer)
                        || (!isCreateBusinessLandingRoute && partialBusiness.businessId !== this.users.businessSnapshot?.id)
                        || (url.startsWith(createBusinessExpressRoute) && !url.startsWith(clientAcceptExpressRoute) && !!partialBusiness.isHirer)
                    ) {
                        return partialBusiness
                    }

                    // we have an active workflow for this biz, so go to it
                    const workflow: CreateBusinessWorkflow = partialBusiness[CreateBusinessStepId.Type] ?? CreateBusinessWorkflow.CreateBusiness
                    this.router.navigateByUrl(this.urls.route.createBusiness({ workflow: workflow, func: partialBusiness.isWorker ? 'vendor' : 'client' }))

                    return undefined
                }),
            )
    }
}
