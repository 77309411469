import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { SelectOption } from '../../core/models'
import { DialogService } from '../../dialogs'
import { Address } from '../models/address.interface'
import { Country } from '../models/country.interface'

import { AddressStore } from './address.store'

@Injectable({
    providedIn: 'root',
})
export class AddressService {

    private readonly allStates: Array<{ name: string, abbreviation: string }> = [
        {
            name: 'Alabama',
            abbreviation: 'AL',
        },
        {
            name: 'Alaska',
            abbreviation: 'AK',
        },
        {
            name: 'Arizona',
            abbreviation: 'AZ',
        },
        {
            name: 'Arkansas',
            abbreviation: 'AR',
        },
        {
            name: 'California',
            abbreviation: 'CA',
        },
        {
            name: 'Colorado',
            abbreviation: 'CO',
        },
        {
            name: 'Connecticut',
            abbreviation: 'CT',
        },
        {
            name: 'Delaware',
            abbreviation: 'DE',
        },
        {
            name: 'District Of Columbia',
            abbreviation: 'DC',
        },
        {
            name: 'Florida',
            abbreviation: 'FL',
        },
        {
            name: 'Georgia',
            abbreviation: 'GA',
        },
        {
            name: 'Hawaii',
            abbreviation: 'HI',
        },
        {
            name: 'Idaho',
            abbreviation: 'ID',
        },
        {
            name: 'Illinois',
            abbreviation: 'IL',
        },
        {
            name: 'Indiana',
            abbreviation: 'IN',
        },
        {
            name: 'Iowa',
            abbreviation: 'IA',
        },
        {
            name: 'Kansas',
            abbreviation: 'KS',
        },
        {
            name: 'Kentucky',
            abbreviation: 'KY',
        },
        {
            name: 'Louisiana',
            abbreviation: 'LA',
        },
        {
            name: 'Maine',
            abbreviation: 'ME',
        },
        {
            name: 'Maryland',
            abbreviation: 'MD',
        },
        {
            name: 'Massachusetts',
            abbreviation: 'MA',
        },
        {
            name: 'Michigan',
            abbreviation: 'MI',
        },
        {
            name: 'Minnesota',
            abbreviation: 'MN',
        },
        {
            name: 'Mississippi',
            abbreviation: 'MS',
        },
        {
            name: 'Missouri',
            abbreviation: 'MO',
        },
        {
            name: 'Montana',
            abbreviation: 'MT',
        },
        {
            name: 'Nebraska',
            abbreviation: 'NE',
        },
        {
            name: 'Nevada',
            abbreviation: 'NV',
        },
        {
            name: 'New Hampshire',
            abbreviation: 'NH',
        },
        {
            name: 'New Jersey',
            abbreviation: 'NJ',
        },
        {
            name: 'New Mexico',
            abbreviation: 'NM',
        },
        {
            name: 'New York',
            abbreviation: 'NY',
        },
        {
            name: 'North Carolina',
            abbreviation: 'NC',
        },
        {
            name: 'North Dakota',
            abbreviation: 'ND',
        },
        {
            name: 'Ohio',
            abbreviation: 'OH',
        },
        {
            name: 'Oklahoma',
            abbreviation: 'OK',
        },
        {
            name: 'Oregon',
            abbreviation: 'OR',
        },
        {
            name: 'Pennsylvania',
            abbreviation: 'PA',
        },
        {
            name: 'Rhode Island',
            abbreviation: 'RI',
        },
        {
            name: 'South Carolina',
            abbreviation: 'SC',
        },
        {
            name: 'South Dakota',
            abbreviation: 'SD',
        },
        {
            name: 'Tennessee',
            abbreviation: 'TN',
        },
        {
            name: 'Texas',
            abbreviation: 'TX',
        },
        {
            name: 'Utah',
            abbreviation: 'UT',
        },
        {
            name: 'Vermont',
            abbreviation: 'VT',
        },
        {
            name: 'Virginia',
            abbreviation: 'VA',
        },
        {
            name: 'Washington',
            abbreviation: 'WA',
        },
        {
            name: 'West Virginia',
            abbreviation: 'WV',
        },
        {
            name: 'Wisconsin',
            abbreviation: 'WI',
        },
        {
            name: 'Wyoming',
            abbreviation: 'WY',
        },
        {
            name: 'American Samoa',
            abbreviation: 'AS',
        },
        {
            name: 'Guam',
            abbreviation: 'GU',
        },
        {
            name: 'Northern Mariana Islands',
            abbreviation: 'MP',
        },
        {
            name: 'Puerto Rico',
            abbreviation: 'PR',
        },
        {
            name: 'U.S. Virgin Islands',
            abbreviation: 'VI',
        },
    ]
    private readonly countriesSubject: BehaviorSubject<Array<SelectOption<string>>> = new BehaviorSubject(undefined)

    countries: Array<SelectOption<string>> = []
    countriesRaw: Array<Country> = []
    countryNames: Array<SelectOption<string>> = []
    readonly stateOptions: Array<SelectOption<string>> = this.allStates.map(state => ({ label: `${state.abbreviation} - ${state.name}`, value: state.name }))
    readonly stateAbbrvOptions: Array<SelectOption<string>> = this.allStates.map(state => ({ label: `${state.abbreviation}`, value: state.name }))

    get canada(): string { return 'Canada' }
    get canadaMexicoAndUk(): Array<string> { return [this.canada, this.mexico, this.uk] }
    get countries$(): Observable<Array<SelectOption<string>>> { return this.countriesSubject.asObservable() }
    get mexico(): string { return 'Mexico' }
    get uk(): string { return 'United Kingdom' }
    get usa(): string { return 'United States of America' }

    constructor(
        private dialogs: DialogService,
        private store: AddressStore,
    ) { }

    initialize(): Observable<void> {

        // if we've already gotten the countries, don't create another subsription
        if (!!this.countryNames.length) {
            return of(undefined)
        }

        return combineLatest([
            !!this.countryNames.length ? of(this.countryNames) : this.getCountryOptions(),
            this.getCountryOptions(true),
        ])
            .pipe(
                tap(([countries, countryIds]) => {
                    this.countryNames = countries
                    this.countries = countryIds
                    this.countriesSubject.next(this.countries)
                }),
                map(() => undefined),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getAddressString(address: Address): string {
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!! DO NOT CHANGE THE FORMATTING OF THIS STRING... DO NOT CHANGE INDENTATION!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        return !address?.street1
            ? undefined
            : `${address.street1} ${address.street2 ? address.street2 : ''}
${address.city ? address.city : ''}, ${address.state ? address.state : ''} ${address.postalCode ? address.postalCode : ''}
${address.country ? address.country : ''}`
    }

    getCountryById(countryId: string): Country {
        return this.countriesRaw.find(c => c.id === countryId)
    }

    private getCountryOptions(includeId: boolean = false): Observable<Array<SelectOption<string>>> {

        return this.store.getCountries()
            .pipe(
                take(1),
                map(countries => {
                    countries.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
                    this.moveToStart(countries, this.uk)
                    this.moveToStart(countries, this.mexico)
                    this.moveToStart(countries, this.canada)
                    this.moveToStart(countries, this.usa)
                    return countries
                        .filter(country => country.visible)
                        .map(country => {
                            this.countriesRaw.push(country)
                            return {
                                label: country.name, // TODO: [LQD-6969] use the display name if it is set, otherwise, use the base name
                                value: includeId ? country.id : country.name,
                            }
                        })
                }),
            )
    }

    private moveToStart(orig: Array<Country>, value: string): void {
        const index: number = orig.findIndex(c => c.name === value)
        if (index >= 0) {
            // move it to the front of the list:
            const countryToMove: Country = orig[index]
            orig.splice(index, 1)
            orig.unshift(countryToMove)
        }
    }
}
