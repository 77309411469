import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatTooltip } from '@angular/material/tooltip'
import moment from 'moment'
import { Subject } from 'rxjs'
import { filter, takeUntil, tap } from 'rxjs/operators'
import { Helpers } from '../helpers.class'

@Component({
    selector: 'app-mad-lib-date',
    templateUrl: './mad-lib-date.component.html',
    styleUrls: ['./mad-lib-date.component.scss', '../shared.scss'],
})
export class MadLibDateComponent implements AfterViewInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @Output() changed: EventEmitter<void> = new EventEmitter()

    @Input() autofocus: boolean = false
    @Input() control: FormControl
    @Input() editable: boolean
    @Input() errorMessage?: string
    @Input() max?: Date
    @Input() min?: Date
    @Input() saved: boolean = false

    @ViewChild('picker') picker: MatDatepicker<Date>
    @ViewChild('tooltip') tooltip: MatTooltip
    @ViewChild('trigger') trigger: ElementRef<HTMLSpanElement>

    touched: boolean = false

    get isValid(): boolean { return !!this.control.value && moment(this.control.value).isValid() }

    ngAfterViewInit(): void {
        this.picker?.closedStream
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.touched = true),
                tap(() => this.changed.emit()),
                filter(() => !!this.control.errors && !!this.errorMessage),
                tap(() => this.tooltip?.show()),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    open(): void {
        if (this.control.disabled) {
            return
        }
        this.picker.open()
    }

    tabAccessibilityForTouchScreen(): void {
        Helpers.simulateTabKeyPress(this.trigger.nativeElement)
    }

}
