import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { SiteStatusService, UrlService } from '../services'

@Injectable({
    providedIn: 'root',
})
export class SiteStatusInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private urls: UrlService,
        private status: SiteStatusService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const resp: HttpResponse<any> = event
                    const siteStatus: string = resp.headers.has(this.status.SiteStatusKey) ? resp.headers.get(this.status.SiteStatusKey) : undefined
                    const begin: number = resp.headers.has(this.status.SiteStatusBeginKey) ? +resp.headers.get(this.status.SiteStatusBeginKey) : 0
                    const until: number = resp.headers.has(this.status.SiteStatusUntilKey) ? +resp.headers.get(this.status.SiteStatusUntilKey) : 0

                    this.status.setMaintenance(siteStatus, begin, until)

                    if (this.status.siteStatusIsMaintenance(siteStatus)) {
                        this.router.navigateByUrl(this.urls.route.upDownError())
                        return
                    }

                    return event
                }
            }),
        )
    }
}
