import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { AuthModule } from '../auth/auth.module'
import { BusinessTokenInterceptor } from '../auth/interceptors/business-token.interceptor'
import { SearchService } from '../search/services'
import { UserTimeZoneInterceptor } from './interceptors/user-timezone.interceptor'

import {
    AfterActionRedirectService,
    BusinessExpressService,
    BusinessUsersResolver,
    BusinessUsersService,
    ClientVendorRelationshipService,
    FileFormService,
    InvoiceApprovalsService,
    InvoiceDetailsService,
    InvoiceDetailsStore,
    LegalSetupService,
    LegalSetupStore,
    LiquidAppService,
    LiquidRolesService,
    LocalStorageService,
    OnboardingService,
    OrganizationService,
    PaymentAccountService,
    PaymentStore,
    ProfileService,
    ProfileSettingsService,
    PromoCodeService,
    SiteStatusService,
    UrlService,
    UserAddressService,
    UserService,
} from './services'

@NgModule({
    exports: [],
    imports: [
        AuthModule,
        CommonModule,
    ],
})
export class CoreModule {

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AfterActionRedirectService,
                BusinessExpressService,
                BusinessTokenInterceptor,
                BusinessUsersResolver,
                BusinessUsersService,
                ClientVendorRelationshipService,
                FileFormService,
                InvoiceApprovalsService,
                InvoiceDetailsService,
                InvoiceDetailsStore,
                LegalSetupService,
                LegalSetupStore,
                LiquidAppService,
                LiquidRolesService,
                LocalStorageService,
                OnboardingService,
                OrganizationService,
                PaymentAccountService,
                PaymentStore,
                ProfileService,
                ProfileSettingsService,
                PromoCodeService,
                SearchService,
                SiteStatusService,
                UrlService,
                UserAddressService,
                UserService,
                UserTimeZoneInterceptor,
            ],
        }
    }
}
