import { Pipe, PipeTransform } from '@angular/core'

import { BusinessTeamMemberStates } from '../modules/models'

@Pipe({
    name: 'businessStatusLabel',
})
export class BusinessStatusLabelPipe implements PipeTransform {

    transform(status: BusinessTeamMemberStates): string {

        switch (status?.toLowerCase()) {
            case BusinessTeamMemberStates.COUNTERSIGNING_IN_PROCESS.toLowerCase():
                return 'Waiting for Countersign'
            case BusinessTeamMemberStates.HIDDEN.toLowerCase():
                return 'Hidden'
            case BusinessTeamMemberStates.NOT_SENT.toLowerCase():
                return 'Not Sent'
            case BusinessTeamMemberStates.ONBOARDED.toLowerCase():
                return 'Onboarded'
            case BusinessTeamMemberStates.IN_PROCESS.toLowerCase():
                return 'In Process'
            case BusinessTeamMemberStates.UPDATING.toLowerCase():
                return 'Updating'
            default:
                return 'Invited'
        }
    }

}
