import { AbstractControl } from '@angular/forms'

import { ValidatorHelper } from './validator-helper.service'

export function TaxIdValidator(control: AbstractControl): { [key: string]: any } | undefined {

    // If there is no ein to evaluate, do not add the error b/c this is an ein validator, not a required validator.
    // If the ein is required, it will be caught by a required validator.
    const output: { [key: string]: any } = !!control?.value
        && !/^[0-9]{2}-?[0-9]{7}$/.test(control.value) // check EIN
        && !/^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/.test(control.value) // check SSN
        && !/^[X]{2}-?[X]{5}-?[0-9]{2}$/.test(control.value) // handle masked eins
        && !/^[X]{3}-?[X]{2}-?[X]{2}-?[0-9]{2}$/.test(control.value) // handle masked ssns
        ? { 'invalid': true }
        : undefined

    return output
}

export function formatEin(control: AbstractControl): void {
    new ValidatorHelper().formatNumber(control, [2])
}

export function formatSsn(control: AbstractControl): void {
    // NOTE: the 2nd slash goes after the 5th number,
    // which is 6th after the first hyphen is inserted
    new ValidatorHelper().formatNumber(control, [3, 6])
}

export function getMaskedTaxId(taxId: string, isSsn: boolean): string {

    if (!taxId) {
        return undefined
    }

    const lastTwoCharacters: string = taxId?.slice(-2)
    return isSsn ? `XXX-XX-XX${lastTwoCharacters}` : `XX-XXXXX${lastTwoCharacters}`
}
