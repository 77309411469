import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

import { ValidatorHelper } from './validator-helper.service'

export function FormArrayValidator(): ValidatorFn {

    return (formArray: FormArray): ValidationErrors | null => {
        // if there's no form array, return undefined
        if (!formArray) {
            return undefined
        }
        const hasInvalidGroups: boolean = formArray.controls.reduce((agg, group: FormGroup) => {
            return group?.invalid || agg
        }, false)
        if (hasInvalidGroups) {
            return { formArray: true }
        }
        return undefined
    }
}
