import { Component, Inject, Input } from '@angular/core'
import { FormControl, ValidatorFn, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { SelectOption } from '../../core/models'

@Component({
    selector: 'app-radio-input',
    templateUrl: './radio-input.component.html',
    styleUrls: ['./radio-input.component.scss'],
})
export class RadioInputComponent {

    private otherValidators: ValidatorFn[]

    @Input() defaultValue: any
    @Input() description: string
    @Input() errorText: string
    form: FormControl
    @Input() hideCloseButton: boolean
    @Input() icon: string
    @Input() okButtonText: string
    @Input() options: SelectOption<string>[]
    @Input() placeholder: string
    @Input() title: string
    @Input() titleInline: boolean

    constructor(
        private readonly dialogRef: MatDialogRef<RadioInputComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.otherValidators = data.otherValidators || []
        if (!!data.required) {
            this.otherValidators.push(Validators.required)
        }

        this.form = new FormControl(data.value || this.defaultValue, this.otherValidators)
        this.hideCloseButton = data.hideCloseButton || this.hideCloseButton
        this.icon = data.icon || this.icon
        this.titleInline = data.titleInline || this.titleInline
        this.description = data.description || this.description
        this.errorText = data.errorText || this.errorText
        this.placeholder = data.placeholder || this.errorText
        this.okButtonText = data.okButtonText ? data.okButtonText : this.okButtonText
        this.options = data.options || this.options
        this.title = data.title || this.title
    }

    okClicked(): void {
        this.dialogRef.close(this.form.value)
    }
}
