import { Component, OnInit } from '@angular/core'

import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-google-analytics',
    templateUrl: './google-analytics.component.html',
    styleUrls: ['./google-analytics.component.scss'],
})
export class GoogleAnalyticsComponent implements OnInit {

    private readonly enabledGoogleAnalytics: Array<string> = environment.internalSettings.enabledGoogleAnalytics

    ngOnInit(): void {
        this.enabledGoogleAnalytics?.forEach((id, index) => this.initTagMangaer(id, index))
    }

    private initTagMangaer(id: string, index: number): void {

        const dataLayer: string = `dataLayer${index}`

        // Global site tag (gtag.js) - Google Analyticss
        const e: HTMLScriptElement = document.createElement('script')
        e.id = `ga-script-loader-${dataLayer}`
        e.async = true
        e.src = `https://www.googletagmanager.com/gtag/js?id=${id}&l=${dataLayer}`
        document.head.appendChild(e)

        window[dataLayer] = window[dataLayer] || []

        function gtag(key: string, value: string | Date): void {
            window[dataLayer].push(arguments)
        }

        gtag('js', new Date())
        gtag('config', id)
    }
}
