import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { StorageKeyAuth } from '../../auth/models'
import { AuthService } from '../../auth/services'
import { StorageKey } from '../../common/global'
import { DialogService } from '../../dialogs'
import { Organization, OrganizationTeamConfiguration, OrganizationTeamInvitation, OrganizationTeamMembershipVendorType, PartialBusiness, TeamMemberAbstract, UserSetup } from '../../modules/models'
import { GuestRequest } from '../../modules/models/guest-request.interface'
import { GuestResponse } from '../../modules/models/guest-response.interface'
import { BusinessFactory, OnboardingFactory } from '../factories'
import { BusinessFunction, UserRoles } from '../models'

import { OrganizationService } from './organization.service'
import { ProfileService } from './profile.service'
import { UrlService } from './url.service'
import { UserStore } from './user.store'
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly guestFeatureEnabled: boolean = environment.internalSettings.enableGuestCheckout
    private invitationsVerified: boolean = false
    private readonly lastBusinessKey: string = 'lastSelectedBusinessId'
    private readonly teamConfigSubject: BehaviorSubject<OrganizationTeamConfiguration | undefined> = new BehaviorSubject(undefined)
    private readonly userSubject: BehaviorSubject<UserSetup | undefined> = new BehaviorSubject(undefined)
    readonly currentBusiness$: Observable<Organization | undefined> = this.userSubject.asObservable()
        .pipe(
            map(user => user?.currentOrganization),
        )
    readonly teamConfig$: Observable<OrganizationTeamConfiguration | undefined> = this.teamConfigSubject.asObservable()
    readonly user$: Observable<UserSetup | undefined> = this.userSubject.asObservable()
    get businessFunction(): BusinessFunction {
        return this.isClient ? 'client' : 'vendor'
    }
    get businessSnapshot(): Organization { return this.userSubject.getValue()?.currentOrganization }
    get teamConfigSnapshot(): OrganizationTeamConfiguration { return this.teamConfigSubject.getValue() }
    get userSnapshot(): UserSetup { return this.userSubject.getValue() }
    get isClient(): boolean {
        return this.businessSnapshot.isHirer
    }
    get isUserGuest(): boolean {
        if (!this.guestFeatureEnabled) {
            return false
        }
        return !!this.businessSnapshot?.isGuest
    }
    get isNonServiceVendor(): boolean {
        return this.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships
            .some(tm => tm.vendorType === OrganizationTeamMembershipVendorType.NonServiceVendor)
    }
    get isServiceVendor(): boolean {
        return this.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships
            .some(tm => tm.vendorType === OrganizationTeamMembershipVendorType.ServiceVendor) || !this.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships.length
    }
    get isVendor(): boolean {
        return this.businessSnapshot.isWorker
    }
    constructor(
        private readonly auth: AuthService,
        private readonly businessFactory: BusinessFactory,
        private readonly dialogs: DialogService,
        private readonly http: HttpClient,
        private readonly onboardingFactory: OnboardingFactory,
        private readonly orgs: OrganizationService,
        private readonly profiles: ProfileService,
        private readonly router: Router,
        private readonly urls: UrlService,
        private readonly userStore: UserStore,
    ) { }

    getGuestUser(request: GuestRequest): Observable<GuestResponse | void> {
        console.error('Guest user not enabled')
        return of()
        // return this.http.get<GuestResponse>(this.urls.api.guestUser(request.invitationId, request.emailHistoryId))
        //     .pipe(
        //         catchError(err => {
        //             switch (err.status) {
        //                 case StatusCodes.FORBIDDEN:
        //                     return this.auth.startAuthentication()
        //                 case StatusCodes.NOT_FOUND:
        //                     return throwError(err)
        //                 default:
        //                     return this.dialogs.error(err)
        //             }
        //         }),
        //     )
    }

    initializeUser(refresh: boolean = false): Observable<UserSetup> {
        // if we have an existing user, just return it
        const existingUser: UserSetup = this.userSubject.getValue()
        if (!!existingUser && !refresh) {
            return this.user$
                .pipe(
                    take(1),
                )
        }
        return this.userStore.getUserConfig()
            .pipe(
                tap((user) => {
                    this.setUser(user)
                }),
                switchMap((user) => this.initializeTeamConfig(false, user.currentOrganization)),
                take(1),
                tap((teamConfig) => {
                    const user: UserSetup = this.userSnapshot
                    if (user && teamConfig) {
                        this.setBusinessInfoIntoStorage(this.teamConfigSnapshot)
                    }
                    localStorage.setItem(StorageKeyAuth.PROFILE_ID, user?.profileId)
                }),
                map(() => this.userSnapshot),
            )
    }
    initializeTeamConfig(refresh: boolean, org?: Organization): Observable<OrganizationTeamConfiguration> {
        // if we're already initalizing, return the team
        if (!refresh && !!this.teamConfigSubject.getValue()) {
            return this.teamConfig$
                .pipe(
                    take(1),
                )
        }
        // if there's no selected organization, return undefined
        let currentOrg: Organization = org
        if (!currentOrg) {
            const currentUser: UserSetup | undefined = this.userSubject.getValue()
            if (!currentUser || !currentUser.currentOrganization) {
                return of(undefined)
            }
            currentOrg = currentUser.currentOrganization
        }
        return this.orgs.getTeamConfigConfiguration(currentOrg.id)
            .pipe(
                take(1),
                tap(orgConfig => {
                    // set the org token into local storage
                    localStorage.setItem(StorageKey.businessToken, orgConfig['organizationToken'])
                    // send the onboarding processes through the factory
                    orgConfig.onboardingProcesses = this.onboardingFactory.createProcesses(orgConfig.onboardingProcesses)
                    this.teamConfigSubject.next(orgConfig)
                    this.setBusinessInfoIntoStorage(orgConfig)
                }),
                filter(orgConfig => !!orgConfig),
                tap(orgConfig => this.setAvatars(orgConfig)),
            )
    }
    isBizEmailVerified(): boolean {
        return !!this.teamConfigSnapshot?.verifiedNotificationEmail ?? false
    }
    isAdmin(): boolean {
        return this.teamConfigSnapshot.myRoles.includes(UserRoles.ADMINISTRATOR)
    }
    isHiringManager(): boolean {
        return this.teamConfigSnapshot.myRoles.length === 1 && this.teamConfigSnapshot.myRoles[0] === UserRoles.HIRING_MANAGER
    }
    setCurrentOrg$(org?: Organization, initializing: boolean = false): Observable<OrganizationTeamConfiguration | undefined> {
        if (!org) {
            return of(undefined)
        }

        return this.setCurrentOrgAndRefreshTeam(org, initializing)
            .pipe(
                take(1),
            )
    }
    setCurrentOrg(org?: Organization, initializing: boolean = false): void {
        this.setCurrentOrg$(org, initializing)
            .subscribe()
    }
    setCurrentOrgAndRefreshTeam(org: Organization, initializing: boolean = false): Observable<OrganizationTeamConfiguration | undefined> {
        const currentUser: UserSetup = this.userSubject.getValue()
        if (!currentUser) {
            throw new Error('cannot set the org for a user that does not exist')
        }
        const existingIndex: number = currentUser.organizations?.findIndex(o => o.id === org?.id)
        if (existingIndex > -1) {
            currentUser.organizations[existingIndex] = org
        }
        currentUser.currentOrganization = org
        // save the selected business in local storage
        if (!!org) {
            localStorage.setItem(this.lastBusinessKey, org?.id)
        } else {
            localStorage.removeItem(this.lastBusinessKey)
        }
        const output: Observable<OrganizationTeamConfiguration> = initializing ? this.teamConfig$ : this.initializeTeamConfig(true, org)
        return output
            .pipe(
                // wait to set the user until the team is initialized
                tap(() => this.userSubject.next(currentUser)),
            )
    }
    addBusiness(business: Organization): void {
        this.userSnapshot.organizations = this.userSnapshot.organizations || []
        this.userSnapshot.organizations.push(business)
        this.sortOrganizations(this.userSnapshot)
    }
    refreshBusinessToken(): Observable<string> {
        localStorage.removeItem(StorageKey.businessToken)
        const url: string = this.urls.api.businessToken(this.userSubject.getValue()?.currentOrganization?.id)
        // specify the response type as a string
        return this.http.get(url, { responseType: 'text' })
            .pipe(
                tap(token => localStorage.setItem(StorageKey.businessToken, token)),
            )
    }
    refreshTeamConfigRestrictAccessOnError(): Observable<OrganizationTeamConfiguration> {
        return this.initializeTeamConfig(true)
            .pipe(
                take(1),
                catchError(error => {
                    // if we can't initialize the team config, restrict access and go to the home page
                    this.businessSnapshot.restrictAccess = true
                    this.router.navigateByUrl(this.urls.route.home())
                    return throwError(error)
                }),
            )
    }
    verifyIncomingInvitations(partialBusiness: PartialBusiness, url: string): Observable<void> {
        const invitationsVerified: boolean = this.invitationsVerified
        this.invitationsVerified = true
        if (invitationsVerified
            || url.includes(this.urls.path.acceptVendorInvite)
            || url.includes(this.urls.path.acceptOrgInvite)
            || url.includes(this.urls.path.createBusinessClient)
            || url.includes(this.urls.path.createBusinessExpress)
            || (!!partialBusiness && Object.entries(partialBusiness).length > 0)
        ) {
            return of(undefined)
        }
        return this.userStore.getOrganizationTeamInvitationsForUserEmail(
            this.profiles.profileSnapshot.emailAddress.address,
            false,
            false,
        )
            .pipe(
                tap(invitations => {
                    if (invitations.length > 0) {
                        const oldestInvitation: OrganizationTeamInvitation = [...invitations]
                            .sort((first, second) => first.sentTimestamp - second.sentTimestamp)
                        [0]
                        this.router.navigateByUrl(
                            this.urls.route.acceptVendorInvite(oldestInvitation.id),
                        )
                    }
                }),
                map(() => undefined),
            )
    }
    private sortOrganizations(user: UserSetup): void {
        user.organizations = user.organizations
            .sort((a, b) => {
                if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
                    return -1
                }
                if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
                    return 1
                }
                return 0
            })
    }
    private getCurrentOrganization(user: UserSetup): Organization | undefined {
        // if the user doesn't have any orgs to choose from, just return
        if (!user?.organizations?.length) {
            return undefined
        }
        // if there is an org saved in local storage, use it;
        // otherwise, if there is a default org, use it;
        // otherwise, use the first org in the list
        return user.organizations.find(o => o.id === localStorage.getItem(this.lastBusinessKey))
            || user.organizations.find(o => o.id === user.defaultOrganizationId)
            || user.organizations[0]
    }
    private setAvatars(config: OrganizationTeamConfiguration | undefined): void {
        if (!config) {
            return
        }
        this.setTeamsAvatars(config)
        this.setGroupsAvatars(config)
        this.setInvitationAvatars(config)
        this.setTeamMembershipAvatars(config)
        this.setRemovedAvatars(config)
    }
    private setBusinessInfoIntoStorage(orgConfig: OrganizationTeamConfiguration | undefined = null): void {
        orgConfig = orgConfig || this.teamConfigSnapshot
        if (!orgConfig) {
            return
        }
        const isHirer: boolean = this.businessSnapshot.isHirer
        const isWorker: boolean = this.businessSnapshot.isWorker
        let teamMemberOrganizationsName: string = ''
        if (isHirer) {
            teamMemberOrganizationsName = orgConfig.teamMembershipAbstract.teams.map(team => {
                return team.members.map(member => member.teamMemberOrganization.name)
            }).join(', ')
        }
        if (isWorker) {
            const orgs: string = orgConfig.organizationMembershipConfiguration.teamMemberships.map(team => {
                return team.membershipIsToOrganization.name
            }).join(', ')
            teamMemberOrganizationsName += teamMemberOrganizationsName ? ', ' + orgs : orgs
        }
        const organizations: string = this.userSnapshot?.organizations.map(org => org.name).join(', ') || ''
        const businessInfo = {
            'Business Id': this.businessSnapshot.id,
            'Name': this.businessSnapshot.name,
            'Created Date': new Date(this.businessSnapshot.createdTimestamp * 1000),
            'Business Function': isHirer && isWorker
                ? 'Service Org'
                : isWorker ? 'Vendor' : 'Client',
            'Is Full Bucket': this.businessSnapshot.isFullBucket,
            'User has access to businesses': organizations,
            '# of accessed businesses': this.userSnapshot?.organizations.length,
        }
        if (isWorker && isHirer) {
            businessInfo['Clients & Vendors'] = teamMemberOrganizationsName
        } else if (isWorker) {
            businessInfo['Clients'] = teamMemberOrganizationsName
        } else {
            businessInfo['Vendors'] = teamMemberOrganizationsName
        }
        if (isHirer) {
            businessInfo['Service Plan Name'] = orgConfig.servicePlan.plan.name
        }
        localStorage.setItem(StorageKeyAuth.BUSINESS_INFO, JSON.stringify(businessInfo))
    }
    private setUser(user: UserSetup): void {
        if (!user) {
            throw new Error('invalid credentials')
        }
        if (!user.organizations?.length) {
            this.userSubject.next(user)
        } else {
            user.organizations = user.organizations.map(biz => this.businessFactory.create(biz))
            this.sortOrganizations(user)
            user.currentOrganization = this.getCurrentOrganization(user)
            this.userSubject.next(user)
        }
    }
    private setTeamsAvatars(config: OrganizationTeamConfiguration): void {
        if (!config.teamMembershipAbstract) {
            return
        }
        config.teamMembershipAbstract.teams
            .forEach(team => team.members
                .forEach(member => this.setMemberAvatar(member)))
    }
    private setMemberAvatar(member: TeamMemberAbstract): void {
        if (!!member && !!member.teamMemberOrganization && !!member.teamMemberOrganization.avatarUrl) {
            member.teamMemberOrganization.avatarUrl = this.urls.api.avatarUrl(member.teamMemberOrganization.avatarUrl)
        }
    }
    private setRemovedAvatars(config: OrganizationTeamConfiguration): void {
        if (!config.teamMembershipAbstract || !config.teamMembershipAbstract.removedTeamMembers) {
            return
        }
        config.teamMembershipAbstract.removedTeamMembers
            .forEach(member => this.setMemberAvatar(member))
    }
    private setTeamMembershipAvatars(config: OrganizationTeamConfiguration): void {
        if (!config.organizationMembershipConfiguration || !config.organizationMembershipConfiguration.teamMemberships) {
            return
        }
        config.organizationMembershipConfiguration.teamMemberships
            .forEach(team => team.membershipIsToOrganization.avatarUrl = this.urls.api.avatarUrl(team.membershipIsToOrganization.avatarUrl))
    }
    private setGroupsAvatars(config: OrganizationTeamConfiguration): void {
        if (!config.myGroupAbstract || !config.myGroupAbstract.groups) {
            return
        }
        config.myGroupAbstract.groups
            .forEach(g => g.members
                .filter(member => !!member.teamMemberOrganization && !!member.teamMemberOrganization.avatarUrl)
                .forEach(member => member.teamMemberOrganization.avatarUrl = this.urls.api.avatarUrl(member.teamMemberOrganization.avatarUrl)))
    }
    private setInvitationAvatars(config: OrganizationTeamConfiguration): void {
        if (!config.outstandingInvitations) {
            return
        }
        config.outstandingInvitations
            .filter(invite => !!invite.inviteeOrganization)
            .forEach(invite => invite.inviteeOrganization.avatarUrl = this.urls.api.avatarUrl(invite.inviteeOrganization.avatarUrl))
    }
}
