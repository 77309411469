import { Injectable } from '@angular/core'

import { QuickBooksVendor, QuickBooksVendorRequest } from '../../../../models'

@Injectable({
    providedIn: 'root',
})
export class QuickBooksVendorFactory {

    create(vendorRequest: QuickBooksVendorRequest): QuickBooksVendor {

        if (!vendorRequest) {
            return undefined
        }

        const vendor: QuickBooksVendor = {
            ...vendorRequest,
            isLiquidInvoiceVendor: vendorRequest.isLiquidIncVendor,
        }

        return vendor
    }

    createRequest(vendor: QuickBooksVendor): QuickBooksVendorRequest {
        const requestVendor: QuickBooksVendorRequest = {
            ...vendor,
            isLiquidIncVendor: vendor.isLiquidInvoiceVendor,
        }
        return requestVendor
    }
}
