import { Component, Inject } from '@angular/core'
import { FormControl, ValidatorFn, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-text-input-dialog',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {

    private otherValidators: ValidatorFn[]

    cancelButtonText: string
    description: string
    errorText: string
    form: FormControl
    isLongString: boolean = false
    okButtonText: string
    placeholder: string = null
    title: string

    constructor(
        private dialogRef: MatDialogRef<TextInputComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.otherValidators = data.otherValidators
        if (!!data.required) {
            this.otherValidators.push(Validators.required)
        }
        this.form = new FormControl(data.value, this.otherValidators)
        this.title = data.title
        this.description = data.description
        this.errorText = data.errorText
        this.placeholder = data.placeholder
        this.otherValidators = this.otherValidators
        this.okButtonText = data.okButtonText ? data.okButtonText : this.okButtonText
        this.cancelButtonText = data.cancelButtonText ? data.cancelButtonText : this.cancelButtonText
        this.isLongString = data.isLongString
    }

    okClicked(): void {
        this.dialogRef.close(this.form.value)
    }
}
