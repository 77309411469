import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { OrderImportData } from '../models'

import { UrlService } from './url.service'

@Injectable({ providedIn: 'root' })
export class OrdersImportStore {

    constructor(
        private readonly httpClient: HttpClient,
        private readonly urlService: UrlService,
    ) { }

    downloadTemplate(): Observable<Blob> {
        return this.httpClient.get(
            this.urlService.api.ordersImportTemplate(),
            { responseType: 'blob' },
        )
    }

    extractVendorsFromFile(organizationId: string, file: File): Observable<OrderImportData[]> {
        const formData: FormData = new FormData()
        formData.append(file.name, file, file.name)

        const headers: HttpHeaders = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data')

        return this.httpClient.post<OrderImportData[]>(
            this.urlService.api.ordersImportFile(organizationId),
            formData,
            { headers },
        )
    }

    uploadOrders(organizationId: string, orders: OrderImportData[]): Observable<void> {
        return this.httpClient.post<void>(
            this.urlService.api.ordersImport(organizationId),
            orders,
        )
    }

}
