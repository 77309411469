import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { SearchResults } from '..//models'

@Injectable()
export class SearchService {

    constructor(
        private http: HttpClient,
    ) { }

    public adminSearch(query: string, pageNumber: number, numPerPage: number): Observable<SearchResults> {
        const queryObject: any = {
            query,
            numPerPage,
            pageNumber,
        }
        return this.http.post<SearchResults>(environment.liquidApiSettings.apiServicePrefix + '/admin/businesses/searches', queryObject)
    }

    public clientInvoiceSearch(query: string, pageNumber: number, numPerPage: number): Observable<SearchResults> {
        const queryObject: any = {
            query,
            numPerPage,
            pageNumber,
        }
        return this.http.post<SearchResults>(environment.liquidApiSettings.apiServicePrefix + '/admin/client-invoices/searches', queryObject)
    }

    public organizationSearch(query: string, pageNumber: number, numPerPage: number): Observable<SearchResults> {
        const queryObject: any = {
            query,
            numPerPage,
            pageNumber,
        }
        return this.http.post<SearchResults>(environment.liquidApiSettings.apiServicePrefix + '/searches/organizations', queryObject)
    }

    public autoCompleteSearch(query: string, searchingAsOrganizationId: string): Observable<SearchResults> {
        const queryObject: any = {
            query,
            searchingAsOrganizationId,
        }
        return this.http.post<SearchResults>(environment.liquidApiSettings.apiServicePrefix + '/searches/unified/autocompletes', queryObject)
    }
}
