import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { FeatherModule } from 'angular-feather'
import { allIcons } from 'angular-feather/icons'

import { ChipListModule } from '../chip-list/chip-list.module'

import { IntersectionObserverDirective } from './intersection-observer.directive'
import { TableViewPreferencesService } from './services/table-view-preferences.service'
import { TableComponent } from './table.component'

@NgModule({
    declarations: [
        IntersectionObserverDirective,
        TableComponent,
    ],
    exports: [
        TableComponent,
    ],
    imports: [
        ChipListModule,
        CommonModule,
        FeatherModule.pick(allIcons),
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        RouterModule,
        MatCheckboxModule,
        MatListModule,
    ],
    providers: [
        TableViewPreferencesService,
    ],
})
export class TableModule { }
