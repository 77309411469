<form [formGroup]="form">
    <p
        class="big3 low-contrast center-aligned"
        [ngClass]="{ 'primary100': !editable }"
    >
        Deliverable Summary
        <app-help-tooltip *ngIf="isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>In this section, the Deliverable must be given a title and descriptions for the work you will
                    perform.</li>
                <li>Invoices generated based on work orders will display the Work Order Name and Deliverable title on
                    each
                    line item.</li>
            </ul>
        </app-help-tooltip>
        <app-help-tooltip *ngIf="!isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>The Deliverable must be given a title and descriptions for the work to be performed by the Vendor.
                </li>
                <li>Invoices generated based on work orders will display the Work Order Name and Deliverable title on
                    each line item.</li>
            </ul>
        </app-help-tooltip>
    </p>

    <p
        *ngIf="initialized"
        class="low-contrast"
        [ngClass]="{ 'primary100': !editable }"
        (click)="titleInput?.focus()"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.Name }"
            name="chevron-right"
        ></i-feather>
        The title for this service or deliverable is
        <app-mad-lib-input
            [editable]="editable"
            #titleInput
            errorMessage="You must enter a title for this service or deliverable"
            [saved]="saved"
            [autofocus]="!saved && currentStep === steps.Name"
            [control]="title"
            (blur)="calculateStep()"
            (change)="onNameChange($event)"
            (focused)="onFocus()"
        ></app-mad-lib-input>
    </p>
    <p
        class="low-contrast"
        *ngIf="maxStep >= steps.Description"
        [ngClass]="{ 'primary100': !editable }"
    >
        The description of this service or deliverable is
    </p>
    <p
        *ngIf="maxStep >= steps.Description"
        class="low-contrast"
        [ngClass]="{ 'primary100': !editable }"
        (click)="descriptionInput?.focus()"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.Description }"
            name="chevron-right"
        ></i-feather>
        <app-mad-lib-input
            [editable]="editable"
            #descriptionInput
            errorMessage="You must enter a summary for this service or deliverable"
            [saved]="saved"
            (blur)="calculateStep()"
            (focused)="onFocus()"
            [autofocus]="true"
            [forceFocus]="currentStep === steps.Description"
            [control]="description"
            [multiline]="true"
        >
        </app-mad-lib-input>
    </p>

    <p
        class="big3 low-contrast center-aligned"
        *ngIf="maxStep >= steps.Kickoff"
        [ngClass]="{ 'primary100': !editable }"
    >
        Deliverable Schedule
        <app-help-tooltip *ngIf="isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>A Start, or Kick-off, date must be chosen for each deliverable. It is possible to set this date in
                    the past for work that has already begun.</li>
                <li>If the deliverable has a Due Date, one must be indicated. This date cannot be before the Deliverable
                    Start/Kick-off date.</li>
                <li>If the deliverable does not have a Due Date, an Estimated Completion Date must be indicated instead.
                    This date cannot be before the Deliverable Start/Kick-off date.</li>
            </ul>
        </app-help-tooltip>
        <app-help-tooltip *ngIf="!isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>A Start, or Kick-off, date must be chosen for each deliverable. It is possible to set this date in
                    the past for work that has already begun.</li>
                <li>If the deliverable has a Due Date, one must be indicated. This date cannot be before the Deliverable
                    Start/Kick-off date.</li>
                <li>If the deliverable does not have a Due Date, an Estimated Completion Date must be indicated instead.
                    This date cannot be before the Deliverable Start/Kick-off date.</li>
            </ul>
        </app-help-tooltip>
    </p>

    <p
        *ngIf="maxStep >= steps.Kickoff"
        class="low-contrast"
        [ngClass]="{ 'primary100': !editable }"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.Kickoff }"
            name="chevron-right"
        ></i-feather>
        This service or deliverable will start on
        <app-mad-lib-date
            [editable]="editable"
            #startDateInput
            [errorMessage]="minStartDateMessage"
            [autofocus]="true"
            [saved]="saved"
            (changed)="calculateStep()"
            [control]="startDate"
            [min]="minStartDate"
        ></app-mad-lib-date>
    </p>

    <p
        class="low-contrast"
        [ngClass]="{ 'primary100': !editable }"
        *ngIf="maxStep >= steps.Due"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.Due && (useDueDate.value === undefined || (useDueDate.value === true && !dueDate.value)) }"
            name="chevron-right"
        ></i-feather>
        And
        <app-mad-lib-select
            [editable]="editable"
            #useDueDateInput
            errorMessage="You must make a selection"
            [saved]="saved"
            [autofocus]="true"
            [control]="useDueDate"
            (changed)="calculateStep()"
            [options]="[{ value: true, label: 'has'}, { value: false, label: 'does not have' }]"
        ></app-mad-lib-select>
        a due date
        <ng-container *ngIf="hasDueDate">
            of
            <app-mad-lib-date
                [editable]="editable"
                #dueDateInput
                errorMessage="You must choose a date on or after your start date"
                [saved]="saved"
                (changed)="calculateStep()"
                [autofocus]="true"
                [control]="dueDate"
                [min]="minDueDate"
            >
            </app-mad-lib-date>
        </ng-container>

        <ng-container *ngIf="useDueDate.value === false">
            <span>.&nbsp;</span>
        </ng-container>

    </p>

    <ng-container *ngIf="useDueDate.value === false && maxStep >= steps.DueEstimate">
        <p
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.DueEstimate && useDueDate.value === false }"
                name="chevron-right"
            ></i-feather>
            The estimated deliverable completion date is&nbsp;
            <app-mad-lib-date
                [editable]="editable"
                #estimatedDueDateInput
                errorMessage="You must choose a date on or after your start date"
                [saved]="saved"
                (changed)="calculateStep()"
                [autofocus]="true"
                [control]="estimatedDueDate"
                [min]="minDueDate"
            >
            </app-mad-lib-date>
        </p>
    </ng-container>
    <p
        class="big3 low-contrast center-aligned"
        *ngIf="maxStep >= steps.RateType"
        [ngClass]="{ 'primary100': !editable }"
    >
        Fee Structure
        <app-help-tooltip *ngIf="isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>A rate structure must be chosen for each deliverable. Options for this field include:
                    <ul>
                        <li>Flat rate (one-time amount charged)</li>
                        <li>Hourly</li>
                        <li>Daily</li>
                        <li>Weekly</li>
                        <li>Monthly</li>
                        <li>Quarterly</li>
                        <li>
                            As follows (Custom) - use this option if none of the other structures are appropriate
                            for your deliverable.
                        </li>
                    </ul>
                </li>
                <li>
                    The user must enter a dollar amount or rate paid by the Client to the Vendor that is greater than
                    zero.
                </li>
                <li>
                    If none of the rate structures in the dropdown are appropriate, or the rate structure is
                    complicated, choose “As follows” in the Fee Structure dropdown. A description of a custom rate
                    structure is required, and the more detail the better. If you need to create deliverables with a $0
                    payment, use “As follows”
                </li>
                <li>
                    A deliverable with an hourly rate may be subject to a cap on the number of hours worked. When the
                    cap is reached, there are 2 options:
                    <ul>
                        <li>We will stop all work.</li>
                        <li>The hourly rate will adjust; if this option is selected, an applicable rate greater than
                            than zero must be entered.</li>
                    </ul>
                </li>
                <li>
                    Once the rate structure has been selected and the payment amount has been entered, you must indicate
                    when you will invoice the Client for this work. Options here are dependent on the Rate Structure
                    chosen above and include:
                    <ul>
                        <li>
                            After the Client has confirmed to you receipt of the deliverable
                            <ul>
                                <li>Available for all rate structures selections.</li>
                            </ul>
                        </li>
                        <li>
                            On a Specific Date - this date may not be in the past, but may be before the Start/Kick-off
                            date to accommodate a pre-payment or deposit.
                            <ul>
                                <li>Available for all rate structures selections.</li>
                            </ul>
                        </li>
                        <li>
                            For recurring billing structures, Liquid will calculate and display the first date you may
                            invoice the Client for the deliverable. This date will be based on the billing structure
                            chosen and the later of 1) Deliverable start date or 2) Date the Work Order is sent.
                            <ul>
                                <li>
                                    every other week on Friday - the second Friday following the Start date. If the
                                    Start date is in the past, then based on the “every other” schedule, the first
                                    appropriate Friday after the Sent date.
                                    <ul>
                                        <li>Available for hourly, daily, weekly and custom rate structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    on the 1st and 15th of the month - the first occurrence (after today) of a 1st or
                                    15th of the month following the Start or Sent date.
                                    <ul>
                                        <li>Available for hourly, daily and custom rate structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    on the last day of each month - the last day of the Sent date month, or if Start
                                    date is in the past, the last day of the current month.
                                    <ul>
                                        <li>
                                            Available for hourly, daily, weekly, monthly and custom rate structures.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    on the last day of each quarter - the last day of the Sent date quarter, or if Start
                                    date is in the past, the last day of the current quarter.
                                    <ul>
                                        <li>Available for hourly, daily, weekly, monthly, quarterly and custom rate
                                            structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    If none of the invoice timing options in the dropdown are appropriate, or the timing
                                    is complicated, choose “As follows” in the Invoice timing dropdown. A description of
                                    when you may invoice you is required, and the more detail the better.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </app-help-tooltip>
        <app-help-tooltip *ngIf="!isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>A rate structure must be chosen for each deliverable. Options for this field
                    include:
                    <ul>
                        <li>Flat rate (one-time amount charged)</li>
                        <li>Hourly</li>
                        <li>Daily</li>
                        <li>Weekly</li>
                        <li>Monthly</li>
                        <li>Quarterly</li>
                        <li>As follows (Custom) - use this option if none of the other structures are appropriate for
                            your
                            deliverable.</li>
                    </ul>
                </li>
                <li>
                    The user must enter a dollar amount or rate paid by the Client to the Vendor that is greater than
                    zero.
                </li>
                <li>
                    If none of the rate structures in the dropdown are appropriate, or the rate structure is
                    complicated, choose “As follows” in the Fee Structure dropdown. A description of a custom rate
                    structure is required, and the more detail the better. If you need to create deliverables with a $0
                    payment, use “As follows”
                </li>
                <li>
                    A deliverable with an hourly rate may be subject to a cap on the number of hours worked. When the
                    cap is reached, there are 2 options:

                    <ul>
                        <li>Vendor will stop all work.</li>
                        <li>The hourly rate will adjust; if this option is selected, an applicable rate greater than
                            than zero must be entered.</li>
                    </ul>
                </li>
                <li>
                    Once the rate structure has been selected and the payment amount has been entered, you must indicate
                    when the Vendor may invoice you for this work. Options here are dependent on the Rate Structure
                    chosen above and include:
                    <ul>
                        <li>
                            After the Client has confirmed to the Vendor receipt of the deliverable
                            <ul>
                                <li>Available for all rate structures selections.</li>
                            </ul>
                        </li>
                        <li>
                            On a Specific Date - this date may not be in the past, but may be before the Start/Kick-off
                            date to accommodate a pre-payment or deposit.
                            <ul>
                                <li>Available for all rate structures selections.</li>
                            </ul>
                        </li>
                        <li>
                            For recurring billing structures, Liquid will calculate and display the first date Vendor
                            may invoice Client for the deliverable. This date will be based on the billing structure
                            chosen and the later of 1) Deliverable start date or 2) Date the Work Order is sent.
                            <ul>
                                <li>
                                    every other week on Friday - the second Friday following the Start date. If the
                                    Start date is in the past, then based on the “every other” schedule, the first
                                    appropriate Friday after the Sent date.
                                    <ul>
                                        <li>Available for hourly, daily, weekly and custom rate structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    on the 1st and 15th of the month - the first occurrence (after today) of a 1st or
                                    15th of the month following the Start or Sent date.
                                    <ul>
                                        <li>Available for hourly, daily and custom rate structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    on the last day of each month - the last day of the Sent date month, or if Start
                                    date is in the past, the last day of the current month.
                                    <ul>
                                        <li>Available for hourly, daily, weekly, monthly and custom rate structures.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    on the last day of each quarter - the last day of the Sent date quarter, or if Start
                                    date is in the past, the last day of the current quarter.
                                    <ul>
                                        <li>Available for hourly, daily, weekly, monthly, quarterly and custom rate
                                            structures.</li>
                                    </ul>
                                </li>
                                <li>
                                    If none of the Vendor invoice timing options in the dropdown are appropriate, or the
                                    timing is complicated, choose “As follows” in the Invoice timing dropdown. A
                                    description of when the Vendor may invoice you is required, and the more detail the
                                    better.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </app-help-tooltip>
    </p>

    <p
        class="low-contrast"
        [ngClass]="{ 'primary100': !editable }"
        *ngIf="maxStep >= steps.RateType"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.RateType }"
            name="chevron-right"
        ></i-feather>
        {{ paymentString }}
        <app-mad-lib-select
            [editable]="editable"
            #rateTypeInput
            errorMessage="You must select a fee structure"
            [saved]="saved"
            [autofocus]="true"
            [control]="rateType"
            (changed)="calculateStep(true)"
            [options]="rateTypes"
        ></app-mad-lib-select>

        <ng-container [ngSwitch]="rateTypeValueType">
            <!-- Flat -->
            <ng-container *ngSwitchCase="rateTypesEnum.Flat">
                <app-mad-lib-input
                    [editable]="editable"
                    #flatRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="flatRate"
                ></app-mad-lib-input>
            </ng-container>

            <!-- Hourly -->
            <ng-container *ngSwitchCase="rateTypesEnum.Hourly">

                <app-mad-lib-input
                    [editable]="editable"
                    #hourlyRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="hourlyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per hour.</span>
            </ng-container>

            <!-- Daily -->
            <ng-container *ngSwitchCase="rateTypesEnum.Daily">
                <app-mad-lib-input
                    [editable]="editable"
                    #dailyRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="dailyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per day.</span>
            </ng-container>

            <!-- Weekly -->
            <ng-container *ngSwitchCase="rateTypesEnum.Weekly">
                <app-mad-lib-input
                    [editable]="editable"
                    #weeklyRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="weeklyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per week.</span>
            </ng-container>

            <ng-container *ngSwitchCase="rateTypesEnum.SemiMonthly">
                <app-mad-lib-input
                    #bimonthlyRateInput
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [editable]="editable"
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    [control]="semimonthlyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per half a month.</span>
            </ng-container>

            <!-- Monthly -->
            <ng-container *ngSwitchCase="rateTypesEnum.Monthly">
                <app-mad-lib-input
                    [editable]="editable"
                    #monthlyRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="monthlyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per month.</span>
            </ng-container>

            <!-- Quarterly -->
            <ng-container *ngSwitchCase="rateTypesEnum.Quarterly">
                <app-mad-lib-input
                    [editable]="editable"
                    #quarterlyRateInput
                    [errorMessage]="errorMessageRateInput"
                    [saved]="saved"
                    [autofocus]="true"
                    [isMoney]="true"
                    [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                    [currencyForMoney]="payoutCurrency"
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    [control]="quarterlyRate"
                ></app-mad-lib-input>
                <span>&nbsp;per quarter.</span>
            </ng-container>

            <!-- Custom -->
            <ng-container *ngSwitchCase="rateTypesEnum.Custom">
                <span>with the following terms:</span>
            </ng-container>

            <!-- New Flat Unit -->
            <ng-container *ngSwitchCase="rateTypesEnum.NewFlatUnit">
                <p>
                    <span>at a rate of </span>
                    <app-mad-lib-input
                        [editable]="editable"
                        #quarterlyRateInput
                        [errorMessage]="errorMessageRateInput"
                        [saved]="saved"
                        [autofocus]="true"
                        [isMoney]="true"
                        [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                        [currencyForMoney]="payoutCurrency"
                        (blur)="calculateStep()"
                        (focused)="onFocus()"
                        [control]="flatRate"
                    ></app-mad-lib-input>
                    <span>
                        &nbsp;per
                        <app-mad-lib-input
                            [editable]="editable"
                            #customDescriptionInput
                            (blur)="calculateStep()"
                            (focused)="onFocus()"
                            errorMessage="You must describe the unit"
                            [saved]="saved"
                            [autofocus]="true"
                            [control]="unitDescription"
                            [multiline]="true"
                        ></app-mad-lib-input>
                    </span>
                </p>
            </ng-container>

            <!-- Custom Flat Unit -->
            <ng-container *ngSwitchCase="rateTypesEnum.CustomFlatUnit">
                <p>
                    <span>at a rate of </span>
                    <app-mad-lib-input
                        [editable]="editable"
                        #quarterlyRateInput
                        [errorMessage]="errorMessageRateInput"
                        [saved]="saved"
                        [autofocus]="true"
                        [isMoney]="true"
                        [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                        [currencyForMoney]="payoutCurrency"
                        (blur)="calculateStep()"
                        (focused)="onFocus()"
                        [control]="flatRate"
                    ></app-mad-lib-input>
                    <span>
                        &nbsp;per {{rateType.value.description}}
                    </span>
                </p>
            </ng-container>
        </ng-container>
    </p>

    <!-- Custom -->
    <p
        *ngIf="isCustom && maxStep >= steps.RateAmount && invoiceTrigger.value !== 'custom'"
        [ngClass]="{ 'primary100': !editable }"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: isCustom && currentStep === steps.RateAmount }"
            name="chevron-right"
        ></i-feather>
        <app-mad-lib-input
            [editable]="editable"
            #customDescriptionInput
            (blur)="calculateStep()"
            (focused)="onFocus()"
            errorMessage="You must describe the fee structure for this work"
            [saved]="saved"
            [autofocus]="true"
            [control]="invoiceDescription"
            [multiline]="true"
        ></app-mad-lib-input>
    </p>

    <!-- Flat or custom unit with description -->
    <ng-container *ngIf="showFlatOrCustom && maxStep >= steps.InvoiceTrigger">
        <p
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.InvoiceTrigger || (currentStep === steps.InvoiceDetail && invoiceTrigger.value !== 'custom') }"
                name="chevron-right"
            ></i-feather>
            &nbsp;{{ invoiceString }}:&nbsp;
            <app-mad-lib-select
                [editable]="editable"
                #invoiceTriggerInput
                errorMessage="You must make a selection"
                [saved]="saved"
                [autofocus]="true"
                [control]="invoiceTrigger"
                (changed)="calculateStep()"
                [options]="isFlat ? flatInvoiceTriggers : customInvoiceTriggers"
            ></app-mad-lib-select>

            <ng-container [ngSwitch]="invoiceTrigger.value">
                <ng-container *ngSwitchCase="'date'">
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceDateFlatInput
                        errorMessage="You must choose a date that is after your start date"
                        [saved]="saved"
                        (changed)="calculateStep()"
                        [autofocus]="true"
                        [control]="invoiceDate"
                        [min]="minInvoiceDate"
                    >
                    </app-mad-lib-date>
                </ng-container>
                <ng-container *ngSwitchCase="'custom'">
                    :
                </ng-container>
            </ng-container>

        </p>

        <p
            class="low-contrast"
            *ngIf="showAfterReceiptText"
        >We will remind you of invoice payment after client has marked work order
            complete.</p>

        <p
            class="low-contrast"
            *ngIf="showAsFollowsText"
        >With this option, we will not be sending you a reminder to send an invoice.</p>
        <p
            *ngIf="invoiceTrigger.value === 'custom'"
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.InvoiceDetail }"
                name="chevron-right"
            ></i-feather>
            <app-mad-lib-input
                [editable]="editable"
                #flatInvoiceDescInput
                (blur)="calculateStep()"
                (focused)="onFocus()"
                [autofocus]="true"
                [control]="invoiceDescription"
                [saved]="saved"
                [errorMessage]="invoiceDescriptionErrorString"
            ></app-mad-lib-input>
        </p>

    </ng-container>

    <!-- Recurring -->
    <ng-container *ngIf="hasRecurrence && maxStep >= steps.HourCap && !!currentRate">
        <p
            *ngIf="isHourly"
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.HourCap }"
                name="chevron-right"
            ></i-feather>
            There
            <app-mad-lib-select
                [editable]="editable"
                #hourlyCapInput
                errorMessage="You must make a selection"
                [saved]="saved"
                [autofocus]="true"
                [control]="hourlyCap"
                (changed)="calculateStep()"
                [options]="[{ value: true, label: 'will'}, { value: false, label: 'will not' }]"
            ></app-mad-lib-select>
            &nbsp;be a maximum hours cap<span *ngIf="!hourlyCap.value">.</span>
            <span *ngIf="!!hourlyCap.value">
                &nbsp;of&nbsp;
                <app-mad-lib-input
                    [editable]="editable"
                    #hourlyCapAmountInput
                    (blur)="calculateStep()"
                    (focused)="onFocus()"
                    errorMessage="You must enter a number of hours"
                    [saved]="saved"
                    [autofocus]="true"
                    [control]="hourlyCapAmount"
                    [isNumber]="true"
                ></app-mad-lib-input>
                &nbsp;hours.
            </span>
        </p>
        <p
            *ngIf="isHourly && currentStep !== steps.HourCapAmount && currentStep !== steps.HourCap && maxStep >= steps.HourCapStrategy && !!currentRate"
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.HourCapStrategy || currentStep === steps.HourCapRateAdjust }"
                name="chevron-right"
            ></i-feather>
            <span *ngIf="!!hourlyCap.value">
                <span *ngIf="maxStep >= steps.HourCapStrategy">
                    After which &nbsp;
                    <app-mad-lib-select
                        [editable]="editable"
                        #hourlyCapStrategyInput
                        errorMessage="You must make a selection"
                        [saved]="saved"
                        [autofocus]="true"
                        [control]="hourlyCapStrategy"
                        (changed)="calculateStep()"
                        [options]="hourlyCapStrategies"
                    >
                    </app-mad-lib-select>
                    <app-mad-lib-input
                        [editable]="editable"
                        *ngIf="maxStep >= steps.HourCapRateAdjust && hourlyCapStrategy.value === 'adjust'"
                        #hourlyRateAdjustInput
                        errorMessage="You must enter a $ amount greater than or equal to 0"
                        [saved]="saved"
                        [autofocus]="true"
                        [currencyForMoney]="payoutCurrency"
                        [isMoney]="true"
                        [isZeroDecimalCurrency]="isZeroDecimalCurrency"
                        (blur)="calculateStep()"
                        (focused)="onFocus()"
                        [control]="hourlyRateAdjust"
                    ></app-mad-lib-input>
                    <span *ngIf="maxStep >= steps.HourCapRateAdjust && hourlyCapStrategy.value === 'adjust'"> per
                        hour.</span>
                </span>
            </span>
        </p>
        <p
            class="low-contrast"
            [ngClass]="{ 'primary100': !editable }"
            *ngIf="(maxStep >= steps.InvoiceTrigger
                && !!currentRate
                && currentStep !== steps.RateType && currentStep !== steps.RateAmount && currentStep !== steps.HourCap && currentStep !== steps.HourCapAmount && currentStep !== steps.HourCapStrategy && currentStep !== steps.HourCapRateAdjust
            )"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.InvoiceTrigger || (currentStep === steps.InvoiceDetail && invoiceTrigger.value === 'date') }"
                name="chevron-right"
            ></i-feather>
            <span>{{ invoiceString }}&nbsp;</span>
            <app-mad-lib-select
                [editable]="editable"
                #invoiceTriggerRecurringInput
                errorMessage="You must make a selection"
                [saved]="saved"
                [autofocus]="true"
                [control]="invoiceTrigger"
                (changed)="calculateStep()"
                [options]="availableTriggers"
            ></app-mad-lib-select>
            <ng-container [ngSwitch]="invoiceTrigger.value">

                <ng-container *ngSwitchCase="'date'">
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceDateInput
                        (changed)="calculateStep()"
                        errorMessage="You must choose a date that is after your start date and not before today"
                        [saved]="saved"
                        [autofocus]="true"
                        [control]="invoiceDate"
                        [min]="minInvoiceDate"
                    >
                    </app-mad-lib-date>
                </ng-container>

                <ng-container *ngSwitchCase="'biweekly'">
                    <span>&nbsp;, starting on&nbsp;</span>
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceBiWeeklyStartInput
                        (changed)="calculateStep()"
                        [control]="invoiceBiWeeklyStart"
                    ></app-mad-lib-date>
                </ng-container>

                <ng-container *ngSwitchCase="'semi-monthly'">
                    <span>&nbsp;, starting on&nbsp;</span>
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceSemiMonthlyStartInput
                        (changed)="calculateStep()"
                        [control]="invoiceSemiMonthlyStart"
                    ></app-mad-lib-date>
                </ng-container>

                <ng-container *ngSwitchCase="'monthly'">
                    <span>&nbsp;, starting on&nbsp;</span>
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceMonthlyStartInput
                        (changed)="calculateStep()"
                        [control]="invoiceMonthlyStart"
                    ></app-mad-lib-date>
                </ng-container>

                <ng-container *ngSwitchCase="'quarterly'">
                    <span>&nbsp;, starting on&nbsp;</span>
                    <app-mad-lib-date
                        [editable]="editable"
                        #invoiceQuarterlyStartInput
                        (changed)="calculateStep()"
                        [control]="invoiceQuarterlyStart"
                    ></app-mad-lib-date>
                </ng-container>

                <ng-container *ngSwitchCase="'custom'">
                    :
                </ng-container>

            </ng-container>
        </p>
        <p
            *ngIf="maxStep >= steps.InvoiceDetail && invoiceTrigger.value === 'custom'"
            [ngClass]="{ 'primary100': !editable }"
        >
            <i-feather
                *ngIf="!disabled"
                class="indicator primary100"
                [ngClass]="{ active: currentStep === steps.InvoiceDetail }"
                name="chevron-right"
            ></i-feather>
            <app-mad-lib-input
                [editable]="editable"
                #hourlyInvoiceDescInput
                (blur)="calculateStep()"
                (focused)="onFocus()"
                [autofocus]="true"
                [control]="invoiceDescription"
                [saved]="saved"
                [errorMessage]="invoiceDescriptionErrorString"
                [multiline]="true"
            ></app-mad-lib-input>
        </p>
    </ng-container>

    <p
        class="big3 low-contrast center-aligned"
        *ngIf="maxStep >= steps.Other"
        [ngClass]="{ 'primary100': !editable }"
    >
        Additional Terms & Conditions
        <app-help-tooltip *ngIf="isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>
                    User may enter any additional terms not governing this deliverable that have not been clearly
                    documented and described in the previous sections.
                </li>
                <li>
                    This field is optional.
                </li>
            </ul>
        </app-help-tooltip>
        <app-help-tooltip *ngIf="!isWorker">
            <ul class="subtitle2 black100 help-text">
                <li>
                    User may enter any additional terms not governing this deliverable that have not been clearly
                    documented and described in the previous sections.
                </li>
                <li>
                    This field is optional.
                </li>
            </ul>
        </app-help-tooltip>
    </p>

    <p
        class="low-contrast"
        *ngIf="maxStep >= steps.Other"
        [ngClass]="{ 'primary100': !editable }"
    >
        The following additional definitions also apply
    </p>

    <p
        *ngIf="maxStep >= steps.Other"
        [ngClass]="{ 'primary100': !editable }"
        (click)="otherInput.focus()"
    >
        <i-feather
            *ngIf="!disabled"
            class="indicator primary100"
            [ngClass]="{ active: currentStep === steps.Other }"
            name="chevron-right"
        ></i-feather>
        <app-mad-lib-input
            [editable]="editable"
            #otherInput
            [saved]="saved"
            [errorMessage]="otherDefault"
            (blur)="calculateStep()"
            (focused)="onFocus()"
            [control]="other"
            [multiline]="true"
            [forceTooltip]="true"
        >
        </app-mad-lib-input>
    </p>
    <div class="audit">
        <p
            *ngIf="!editable && initiatedBy === 'vendor' && !!deliverable?.rate?.agreedByWorkerOnTimestamp"
            class="label2 warning-dark"
        >
            <strong>{{deliverable.rate.agreedByWorkerName}} ({{workOrder?.workerOrganization?.name}})</strong> sent this
            {{displayLabel}} to
            {{workOrder.clientOrganization?.name}} on
            <strong>
                {{(deliverable.rate.agreedByWorkerOnTimestamp * 1000 | date: 'shortDate')}} at
                {{(deliverable.rate.agreedByWorkerOnTimestamp * 1000 | date: 'longTime')}}
            </strong>
        </p>
        <p
            *ngIf="!editable && initiatedBy === 'client' && !!deliverable?.rate?.agreedByClientOnTimestamp"
            class="label2 warning-dark"
        >
            <strong>{{deliverable.rate.agreedByClientName}} ({{workOrder?.clientOrganization?.name}})</strong> sent this
            {{displayLabel}} to
            {{workOrder.workerOrganization?.name || workOrder.vendorNameOverride}} on
            <strong>
                {{(deliverable.rate.agreedByClientOnTimestamp * 1000 | date: 'shortDate')}} at
                {{(deliverable.rate.agreedByClientOnTimestamp * 1000 | date: 'longTime')}}
            </strong>
        </p>
        <p
            *ngIf="!editable && initiatedBy === 'client' && !!deliverable?.rate?.agreedByWorkerOnTimestamp"
            class="label2 warning-dark"
        >
            <strong>{{deliverable.rate.agreedByWorkerName}} ({{workOrder?.workerOrganization?.name}})</strong> accepted
            this
            {{displayLabel}} on
            <strong>
                {{(deliverable.rate.agreedByWorkerOnTimestamp * 1000 | date: 'shortDate')}} at
                {{(deliverable.rate.agreedByWorkerOnTimestamp * 1000 | date: 'longTime')}}
            </strong>
        </p>
        <p
            *ngIf="!editable && initiatedBy === 'vendor' && !!deliverable?.rate?.agreedByClientOnTimestamp"
            class="label2 warning-dark"
        >
            <strong>{{deliverable.rate.agreedByClientName}} ({{workOrder?.clientOrganization?.name}})</strong> accepted
            this
            {{displayLabel}} on
            <strong>
                {{(deliverable.rate.agreedByClientOnTimestamp * 1000 | date: 'shortDate')}} at
                {{(deliverable.rate.agreedByClientOnTimestamp * 1000 | date: 'longTime')}}
            </strong>
        </p>
        <p
            *ngIf="!editable && workOrder?.status === workOrderStatuses.COMPLETED && !!workOrder?.completedOn"
            class="label2 warning-dark"
        >
            <strong>{{workOrder?.completedByName}} ({{workOrder?.completedByOrganizationName}})</strong> completed this
            {{displayLabel}} on
            <strong>
                {{(workOrder?.completedOn | date: 'shortDate')}} at
                {{(workOrder?.completedOn | date: 'longTime')}}
            </strong>
        </p>
        <p
            *ngIf="!editable && workOrder?.status === workOrderStatuses.CANCELLED && !!workOrder?.cancelledOn"
            class="label2 warning-dark"
        >
            <strong>{{workOrder?.cancelledByName}} ({{workOrder?.cancelledByOrganizationName}})</strong> cancelled this
            {{displayLabel}} on
            <strong>
                {{(workOrder?.cancelledOn | date: 'shortDate')}} at
                {{(workOrder?.cancelledOn | date: 'longTime')}}
            </strong>
        </p>
    </div>
</form>
<div
    *ngIf="!disabled && editable"
    class="actions"
>
    <button
        mat-stroked-button
        color="primary"
        tabindex="-1"
        [disabled]="inputFocused"
        (click)="onDelete()"
    >
        Delete
    </button>
    <button
        *ngIf="maxStep >= steps.Estimate"
        mat-flat-button
        color="primary"
        [disabled]="disabled || loading || (form.invalid || !form.dirty)"
        (click)="onSave()"
    >
        Finish
    </button>
</div>