<span
    class="wrapper body1"
    (click)="select.open()"
    [ngClass]="{ 'empty': control.value === undefined, 'error': !!control.errors && (touched || saved) && !control.disabled && editable, 'primary100': !control.errors || !editable, disabled: control.disabled && editable, 'readonly': control.disabled  }"
>
    <span
        #trigger
        class="input"
        #tooltip="matTooltip"
        [matTooltip]="!!errorMessage && !control.disabled && !!control.errors && editable ? errorMessage : undefined"
        [matTooltipClass]="'madlib-error'"
        [matTooltipPosition]="'above'"
    >
        {{displayText}}
    </span>
    <i-feather
        *ngIf="!control.disabled"
        [ngClass]="{'error': !!control.errors && (touched || saved) && !control.disabled && editable, 'primary100': !control.errors || !editable }"
        name="chevron-down"
    ></i-feather>
</span>

<mat-select
    #select
    class="hidden-input"
    [formControl]="control"
>
    <mat-option
        *ngFor="let option of options"
        [value]="option.value"
    >
        {{option.label}}
    </mat-option>
</mat-select>