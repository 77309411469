import { Injectable } from '@angular/core'
import { ClientInvoice, ClientInvoicePreview, ClientInvoiceStatus, InvoiceApprover, InvoiceLineItem, InvoiceValidationIssue, OrderType, Organization, RateType } from '../../modules/models'
import { CurrencyService } from '../services/currency.service'

@Injectable({
    providedIn: 'root',
})
export class InvoiceFactory {

    constructor(
        private currencyService: CurrencyService,
    ) {

    }

    create(invoice: ClientInvoice): ClientInvoice {

        invoice.approvers = this.normalizeApprovers(invoice.approvers)
        invoice.rateTypes = this.getRateTypes(invoice)
        invoice.validationIssues = this.getIssues(invoice)
        invoice.invoiceMatchesWorkOrder = !invoice.validationIssues.length
        return invoice
    }

    createPreview(invoice: ClientInvoicePreview): ClientInvoicePreview {
        invoice = { ...this.create(invoice), lineItems: invoice.lineItems }
        return invoice
    }

    createShellClientInvoice(invoicer: Organization, invoiceNumber: string): ClientInvoice {

        const i: ClientInvoice = new ClientInvoice()
        i.invoiceNumber = invoiceNumber
        i.clientName = invoicer.name
        i.invoicerName = `Your Vendor's Name`

        const totalInvoiced: number = this.convertMoneyMaskToDecimal(0)
        i.status = ClientInvoiceStatus.DRAFT
        i.invoiceCurrency = this.currencyService.USD
        i.payoutTotal = totalInvoiced
        i.isDraft = true
        i.paid = false
        i.pastDue = false
        i.amountInvoiced = totalInvoiced
        i.amountPaid = 0

        return i
    }

    createShellLineItem(i: ClientInvoice): Array<InvoiceLineItem> {
        const li: InvoiceLineItem = {
            description: `Invoice ${i.invoiceNumber}`,
            order: 0,
            qty: 1,
            taxable: false,
            unitPrice: this.convertMoneyMaskToDecimal(0),
            uom: RateType.Flat,
        }

        const lineItems: InvoiceLineItem[] = [li]

        return lineItems
    }

    normalizeApprovers(approvers: Array<InvoiceApprover>): Array<InvoiceApprover> {
        return !approvers ? [] : approvers
            .filter(app => !app.deleted)
            .sort((a, b) => a.order - b.order)
            .map((app, index) => {
                app.order = index + 1
                return app
            })
    }

    private convertMoneyMaskToDecimal(val: any): number {
        if (typeof val === 'string' || val instanceof String) {
            return Number(val.replace('$', '').replace(',', ''))
        }
        return val
    }

    private getRateTypes(invoice: ClientInvoice): Array<RateType> {
        const types: Array<Array<string>> = invoice.associatedWorkOrders?.map(workOrder => workOrder.deliverables.map(t => t.rate.rateType)) || []
        return !types.length
            ? []
            : [
                ...[].concat(...types),
            ]
    }

    private getIssues(invoice: ClientInvoice): Array<InvoiceValidationIssue> {
        return invoice.validationIssues
            // per LQD-6591, we're hiding some issues for now
            .filter(i => ![
                InvoiceValidationIssue.BILLING_DATE_MISMATCH,
                InvoiceValidationIssue.TIME_PERIOD_MISMATCH,
                InvoiceValidationIssue.NONE,
            ].includes(i))
    }
}
