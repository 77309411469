import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { ApprovalPolicy } from '../../modules/models'
import { OrganizationApprovals } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class InvoiceApprovalsDataStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    approveInvoices(invoiceIds: string[]): Observable<void> {
        const url: string = this.urls.api.invoicesBulkApproval()
        return this.http.post<void>(url, { invoiceIds })
    }

    getProcessingSettings(organizationId: string): Observable<OrganizationApprovals> {
        const url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/invoiceProcessorConfigurations`
        return this.http.get<OrganizationApprovals>(url)
    }

    setProcessingSettings(organizationId: string, settings: OrganizationApprovals): Observable<OrganizationApprovals> {
        const url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/invoiceProcessorConfigurations`
        const request = {
            organizationId: organizationId,
            ...settings,
        }
        return this.http.post<OrganizationApprovals>(url, request)
    }

    getApprovalPolicies(organizationId: string): Observable<Array<ApprovalPolicy>> {
        const url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/invoiceApprovalProcesses`
        return this.http.get<Array<ApprovalPolicy>>(url)
    }

    saveApprovalPolicy(organizationId: string, policy: ApprovalPolicy): Observable<Array<ApprovalPolicy>> {
        const url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/invoiceApprovalProcesses`
        const request: ApprovalPolicy = {
            ...policy,
            organizationId: organizationId,
        }
        return this.http.post<Array<ApprovalPolicy>>(url, request)
    }

    deleteApprovalPolicy(invoiceApprovalProcessId: string): Observable<Array<ApprovalPolicy>> {
        const url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/invoiceApprovalProcesses/${invoiceApprovalProcessId}`
        return this.http.delete<Array<ApprovalPolicy>>(url)
    }
}
