import { Injectable } from '@angular/core'

import { OnboardingProcess } from '../../modules/models'

@Injectable({
    providedIn: 'root',
})
export class OnboardingFactory {

    createProcesses(processes?: ReadonlyArray<OnboardingProcess>): ReadonlyArray<OnboardingProcess> {
        return ([...processes] || []).sort((a, b) => a.createdTimestamp - b.createdTimestamp)
    }
}
