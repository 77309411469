import { Pipe, PipeTransform } from '@angular/core'

import { InvoiceValidationIssue } from '../modules/models'

@Pipe({
    name: 'invoiceValidationIssue',
})
export class InvoiceValidationIssuePipe implements PipeTransform {

    transform(issue: InvoiceValidationIssue, mode: 'short' | 'long' = 'long', orderLabel: string): string {

        const short: boolean = mode === 'short'

        switch (issue) {

            case InvoiceValidationIssue.BILLING_DATE_MISMATCH:
                return short ? 'Billing Date Mismatch' : `A deliverable’s billing date does not match the current invoice date.`

            case InvoiceValidationIssue.BILLING_DATE_DUPLICATE:
                return short ? 'Billing Date Duplicate' : `Multiple invoices submitted on same date. Confirm they are not duplicates.`

            case InvoiceValidationIssue.END_DATE_BEYOND_AGREED:
                return short ? 'End date beyond agreed to dates' : `Invoice work period end date is later than agreed to ${orderLabel} end date.`

            case InvoiceValidationIssue.HOURS_EXCEEDED:
                return short ? 'Max Hours Exceeded' : `An invoice line item exceeds a deliverable’s hours cap.`

            case InvoiceValidationIssue.LINE_ITEM_DESC_MISMATCH:
                return short ? 'Description Mismatch' : `An invoice line item's description is different than what was agreed to in ${orderLabel}.`

            case InvoiceValidationIssue.LINE_ITEM_NOT_FOUND:
                return short ? `Line Item Not In ${orderLabel}` : `An invoice line item has been added directly on the invoice and not through the ${orderLabel} page. Please verify if it matches the agreement.`

            case InvoiceValidationIssue.LINE_ITEM_QTY_INVALID:
                return short ? 'Invalid Quantity' : `An invoice line item's quantity is less than 1 or the unit of measurement is Flat and the quantity is greater than 1.`

            case InvoiceValidationIssue.LINE_ITEM_RATE_MISMATCH:
                return short ? 'Rate Mismatch' : `An invoice line item's rate does not match the corresponding deliverable’s rate.`

            case InvoiceValidationIssue.LINE_ITEM_UOM_MISMATCH:
                return short ? 'Unit of Measurement Mismatch' : `An invoice line item's unit of measurement does not match the corresponding deliverable’s unit of measurement.`

            case InvoiceValidationIssue.PERFORMANCE_PERIOD_OVERLAP:
                return short ? 'Period of performance overlap' : `The period of performance includes at least one day that overlaps the period of performance of an earlier submitted invoice.`

            case InvoiceValidationIssue.TIME_PERIOD_MISMATCH:
                return short ? 'Invalid Invoice Date' : `The invoice date does not fall within a deliverable’s start and end dates.`

            case InvoiceValidationIssue.WORK_ORDER_NOT_FOUND:
                return short ? 'No Matching Deliverables' : 'No corresponding deliverable(s) can be found for this invoice.'

            default:
                return undefined
        }
    }
}
