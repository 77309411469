import { AbstractControl } from '@angular/forms'

export function PasswordValidator(control: AbstractControl): { [key: string]: any } | undefined {
    const regex: RegExp = /^(?=.*[a-z])(?=.*\d)(?=.*[-_@!#$%^&*()])[^\s]{6,}$/i
    return !!control && !!control.value && !regex.test(control.value)
        ? {
            invalid: 'Must be at least 6 characters including at least 1 letter, 1 number, and 1 of these special characters: @ ! # $ % ^ & * ( ) - _',
        }
        : undefined
}
