import { OrganizationMembershipAbstractMember } from '../../auth/models'

export class OrganizationProcessor {

    avatarUrl?: string
    firstName: string
    isMe: boolean
    lastName: string
    organizationMembershipId: string

    constructor(
        member?: OrganizationMembershipAbstractMember,
        avatarUrl?: string,
    ) {
        if (!member) {
            return
        }
        this.firstName = member.firstName
        this.isMe = member.isMe
        this.lastName = member.lastName
        this.organizationMembershipId = member.membershipId
        this.avatarUrl = avatarUrl
    }
}
