import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators'

import { SelectOption } from '../../core/models'
import { AddressService } from '../services/address.service'

@Component({
    selector: 'app-country-autocomplete-form',
    templateUrl: './country-autocomplete-form.component.html',
    styleUrls: ['./country-autocomplete-form.component.scss'],
})
export class CountryAutocompleteFormComponent implements OnInit, OnChanges, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @Output() optionSelected: EventEmitter<Array<any>> = new EventEmitter()

    @Input() countryOptions: Array<SelectOption<string>>
    @Input() country: FormControl
    @Input() countryIds: Array<string>
    @Input() enableMultipleSelection: boolean
    @Input() foreignOnly: boolean

    countries: Array<SelectOption<string>>
    countriesSelected: Array<SelectOption<string>> = []
    initialCountries: Array<SelectOption<string>> = []

    constructor(
        private addresses: AddressService,
    ) { }

    ngOnInit(): void {

        if (!!this.countryOptions) {
            this.initializeCountries()

        } else {
            this.addresses.initialize().subscribe()
            this.addresses.countries$
                .pipe(
                    filter(optionValues => !!optionValues),
                    tap(optionValues => {
                        this.countryOptions = optionValues
                        this.initializeCountries()
                    }),
                )
                .subscribe()
        }

        this.country.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter(value => !value || typeof value === 'string'),
                tap(value => this.refreshOptions(value)),
            )
            .subscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.assignSelectedCountries()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    displayText(option: SelectOption<{ label: string, value: string }>): string | undefined {
        return !!option ? option.label : undefined
    }

    onSelectOption(country: MatAutocompleteSelectedEvent): void {
        const option: SelectOption<string> = country.option.value

        if (this.countriesSelected.some(selected => selected.value === option.value)) {
            return
        }
        this.convertToChip(option.label, option.value)
        this.country.setValue(undefined)
        this.enableSelection()
        this.optionSelected.emit(this.countriesSelected)
    }

    removeCountry(country: string): void {
        const item: SelectOption<string> = this.countriesSelected.find(selected => selected.value === country)
        const addedIndex: number = this.countriesSelected.indexOf(item)

        if (addedIndex > -1) {
            this.countriesSelected.splice(addedIndex, 1)
            this.enableSelection()
            this.optionSelected.emit(this.countriesSelected)
        }
    }

    private assignSelectedCountries(): void {
        this.countriesSelected = []
        this.countryIds = this.countryIds || []
        if (!!this.countryIds.length && !!this.initialCountries.length) {
            this.countryIds.forEach(id => {
                const selectedCountry: SelectOption<string> = this.initialCountries.find(country => country.value === id)
                const alreadyAdded: boolean = !!selectedCountry ? this.countriesSelected.some(selected => selected.value === selectedCountry.value) : false
                if (!!selectedCountry && !alreadyAdded) {
                    this.convertToChip(selectedCountry.label, selectedCountry.value)
                }
            })
        }
    }

    private convertToChip(label: string, value: string): void {
        this.countriesSelected.push({ label, removable: true, value })
    }

    private enableSelection(): void {
        !this.enableMultipleSelection && !!this.countriesSelected.length ? this.country.disable() : this.country.enable()
    }

    private refreshOptions(value: string): void {
        this.countries = this.initialCountries
            .filter(option => !value || option.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            .map(option => ({
                ...option,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    private initializeCountries(): void {
        this.countries = this.countryOptions.filter(opt => !this.foreignOnly || opt.label !== this.addresses.usa)
        this.initialCountries = this.countryOptions.filter(opt => !this.foreignOnly || opt.label !== this.addresses.usa)
        this.assignSelectedCountries()
    }
}
