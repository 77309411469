import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import {
    ClientVendorRelationship,
    ClientVendorRelationshipUpdatePropertiesRequest,
    LiquidDocument,
    LiquidDocumentUpdateRequest,
    OrganizationTeamInvitation,
    OrganizationTeamMemberUpdateRequest,
    VendorInvitationUpdatePropertiesRequest,
} from '../../modules/models'
import { CountersigningStatus } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class ClientVendorRelationshipStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    getClientVendorRelationship(requesterOrganizationId: string, clientVendorRelationshipId: string): Observable<ClientVendorRelationship> {
        return this.http.get<ClientVendorRelationship>(this.urls.api.clientVendorRelationshipGet(requesterOrganizationId, clientVendorRelationshipId))
    }

    getDocumentsForClientVendorRelationship(clientVendorRelationshipId: string, countersigningStatuses?: CountersigningStatus[]): Observable<Array<LiquidDocument>> {
        return this.http.get<Array<LiquidDocument>>(
            this.urls.api
                .clientVendorRelationshipDocuments(clientVendorRelationshipId, { countersigningStatuses }),
        )
    }

    getMasterContractDocumentForOrganizationTeamMember(organizationTeamMemberId: string): Observable<LiquidDocument | null> {
        return this.http.get<LiquidDocument | null>(this.urls.api.masterContractDocumentForOrganizationTeamMember(organizationTeamMemberId))
    }

    hasTeamMembers(organizationId: string): Observable<boolean> {
        return this.http.get<boolean>(this.urls.api.hasTeamMembers(organizationId))
    }

    updateClientVendorRelationship(request: OrganizationTeamMemberUpdateRequest): Observable<{ organizationTeamMemberId: string, description: string }> {
        return this.http.put<{ organizationTeamMemberId: string, description: string }>(this.urls.api.clientVendorRelationshipUpdate(), request)
    }

    updateClientVendorRelationshipDisplayName(request: OrganizationTeamMemberUpdateRequest): Observable<{ organizationTeamMemberId: string, displayName: string }> {
        return this.http.put<{ organizationTeamMemberId: string, displayName: string }>(this.urls.api.clientVendorRelationshipUpdate(), request)
    }

    updateClientVendorRelationshipDocument(clientVendorRelationshipId: string, documentId: string, request: LiquidDocumentUpdateRequest): Observable<LiquidDocument> {
        const url: string = this.urls.api.clientVendorRelationshipDocument(clientVendorRelationshipId, documentId)
        return this.http.patch<LiquidDocument>(url, request)
    }

    updateClientVendorRelationshipProperties(request: ClientVendorRelationshipUpdatePropertiesRequest): Observable<ClientVendorRelationship> {
        return this.http.patch<ClientVendorRelationship>(this.urls.api.clientVendorRelationshipUpdateProperties(), request)
    }

    updateVendorInvitationProperties(request: VendorInvitationUpdatePropertiesRequest): Observable<OrganizationTeamInvitation> {
        return this.http.patch<OrganizationTeamInvitation>(this.urls.api.invitationUpdateProperties(), request)
    }

    updateMasterContractAssociatedWorkOrders(clientVendorRelationshipId: string, documentId: string): Observable<LiquidDocument[]> {
        const url: string = this.urls.api.clientVendorRelationshipDocumentAssociateWorkOrders(clientVendorRelationshipId, documentId)
        return this.http.post<LiquidDocument[]>(url, undefined)
    }

    uploadClientVendorRelationshipMasterContract(clientVendorRelationshipId: string, filename: string, data: FormData): Observable<LiquidDocument> {
        const url: string = this.urls.api.clientVendorRelationshipMasterContract(clientVendorRelationshipId, filename)
        return this.http.post<LiquidDocument>(url, data)
    }
}
