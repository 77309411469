import { SelectOption } from '../../../core/models'
import { RateType, ShadowRateType } from '../../models'

export const NONRECURRING_RATES: (RateType | ShadowRateType)[] = [
    RateType.Flat,
    RateType.Custom,
    ShadowRateType.NewFlatUnit,
    ShadowRateType.CustomFlatUnit,
]

export const CUSTOM_INVOICE_TRIGGERS: SelectOption<string>[] = [
    { value: 'confirmation', label: 'after we have confirmed receipt of the deliverable' },
    { value: 'date', label: 'on the specific date of' },
    { value: 'monthly', label: 'on the last day of each month' },
    { value: 'custom', label: 'as follows' },
]

export const INVOICE_TRIGGERS: SelectOption<string>[] = [
    { value: 'confirmation', label: 'after we have confirmed receipt of the deliverable' },
    { value: 'date', label: 'on the specific date of' },
    { value: 'biweekly', label: 'every other week on Friday' },
    { value: 'semi-monthly', label: 'on the 1st and 15th of each month' },
    { value: 'start-monthly', label: 'every month from start date' },
    { value: 'monthly', label: 'on the last day of each month' },
    { value: 'quarterly', label: 'on the last day of each quarter' },
    { value: 'custom', label: 'as follows' },
]
export const SEMI_MONTHLY_TRIGGERS: SelectOption<string>[] = [
    { value: 'confirmation', label: 'after we have confirmed receipt of the deliverable' },
    { value: 'date', label: 'on the specific date of' },
    { value: 'semi-monthly', label: 'on the 1st and 15th of each month' },
    { value: 'custom', label: 'as follows' },
]
