import { Pipe, PipeTransform } from '@angular/core'

import { LoggingService } from '../auth/services'
import { ReassignItem, ReassignItemType } from '../core/models'

@Pipe({
    name: 'userReassignmentItem',
})
export class UserReassignItemPipe implements PipeTransform {

    public transform(item: ReassignItem): string {

        // if the item doesn't affect anything, just return undefined
        if (item.count < 1) {
            return undefined
        }

        switch (item.itemType) {

            case ReassignItemType.INVOICE_APPROVAL_POLICY:
                return `${item.count} Invoice Approval Polic${item.count === 1 ? 'y' : 'ies'}`

            case ReassignItemType.INVOICE_APPROVER:
                return `${item.count} Invoice${item.count === 1 ? '' : 's'} to Approve`

            case ReassignItemType.INVOICE_PROCESSOR:
                return `Invoice Processor`

            case ReassignItemType.VENDOR_HIRING_MANAGER:
                return `Hiring Manager for ${item.count} Vendor${item.count === 1 ? '' : 's'}`

            case ReassignItemType.WORK_ORDER_HIRING_MANAGER:
                return `Hiring Manager for ${item.count} outstanding Work Order${item.count === 1 ? '' : 's'}`

            case ReassignItemType.VENDOR_INVITE_HIRING_MANAGER:
                return `Hiring Manager for ${item.count} Invited Vendor${item.count === 1 ? '' : 's'}`

            default:
                LoggingService.notify(new Error(`Invalid ReassignItemType: ${item.itemType} `))
                return undefined
        }
    }
}
