import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { OrderImportData } from '../models'

import { OrdersImportStore } from './orders-import.store'
import { UserService } from './user.service'

@Injectable({ providedIn: 'root' })
export class OrdersImportService {

    constructor(
        private readonly ordersImportStore: OrdersImportStore,
        private readonly usersService: UserService,
    ) { }

    downloadTemplate(): Observable<void> {
        return this.ordersImportStore.downloadTemplate()
            .pipe(
                tap((file) => FileSaver.saveAs(file, 'Orders Import Template.csv')),
                map(() => undefined),
            )
    }

    extractVendorsFromFile(file: File): Observable<OrderImportData[]> {
        return this.ordersImportStore.extractVendorsFromFile(
            this.usersService.businessSnapshot.id,
            file,
        ).pipe(
            take(1),
        )
    }

    uploadOrders(orders: OrderImportData[]): Observable<void> {
        return this.ordersImportStore.uploadOrders(
            this.usersService.businessSnapshot.id,
            orders,
        )
    }
}
