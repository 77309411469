export enum WorkOrderStatus {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
    DORMANT = 'Dormant',
    DRAFT = 'Draft',
    HOLDING = 'Holding',
    IN_APPROVALS = 'In Approvals',
    NOT_SENT = 'Not Sent',
    PROPOSED = 'Proposed',
    REJECTED = 'Rejected',
    SENT = 'Sent',
    SYSTEM = 'System',
    REMOVED = 'Removed',
}
