import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { CountryCode, formatNumber, NumberFormat, parseNumber } from 'libphonenumber-js'
import { Observable, Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'

import { SelectOption } from '../../core/models'
import { DirtyOrTouchedErrorStateMatcher, formatZip, MinLengthValidator, PhoneValidator, RequiredIfOtherEqualsValidator, RequiredIfOtherNotEqualValidator, ZipValidator } from '../../validation'
import { Address } from '../models/address.interface'
import { AddressService } from '../services/address.service'

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, AfterViewInit, OnDestroy {

    private readonly countryControlName: string = 'country'
    private currentAddress?: Address
    private optionalFieldNames: Array<string>
    private readonly phoneFormat: NumberFormat = 'NATIONAL'
    private requiredFieldNames: Array<string>
    private readonly requiredStateWithCountries: Array<string> = [this.addresses.usa, ...this.addresses.canadaMexicoAndUk]
    private unsubscribe$: Subject<void> = new Subject()
    private readonly US_Code: CountryCode = 'US'

    private get countryName(): string { return this.country?.value }

    @Input() addressForm: FormGroup
    @Input() addressInput$: Observable<Address>
    @Input() defaultCountry: string
    @Input() disableCountry: boolean
    @Input() hideCountry: boolean
    @Input() isBilling: boolean
    @Input() narrow: boolean
    @Input() optionalFields: Array<string>
    @Input() requiredFields: Array<string>
    @Input() showNonUs: boolean
    @Input() showOtherLocations: boolean
    @Input() showPhoneNumber: boolean
    @Input() stateAbbrv: boolean
    @Input() otherOperatingLocations: Array<SelectOption<string>> = []
    @Input() set disabled(disabled: boolean) {
        if (disabled) {
            this.addressForm?.disable()
        } else {
            this.addressForm?.enable()
        }
        this.enableAllExceptCountry(!disabled)
    }

    city: FormControl
    country: FormControl
    readonly dirtyErrorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()
    locations: FormControl
    otherLocations: FormControl
    phoneNumber: FormControl
    postalCode: FormControl
    readonly separatorKeysCodes: number[] = [ENTER, COMMA]
    state: FormControl
    street1: FormControl
    street2: FormControl
    zip: FormControl
    stateOptions: Array<SelectOption<string>> = this.addresses.stateOptions

    get address(): Address { return this.getAddress() }
    get billing(): string { return this.isBilling ? 'Billing ' : '' }
    get cityFieldName(): string { return this.countryName === this.addresses.uk ? 'Locality' : (this.countryName === this.addresses.mexico ? 'City/Town' : 'City') }
    get cityRequired(): boolean { return !this.isOptional('city') && (this.countryName !== this.addresses.uk || this.isRequired('city')) }
    get countryRequired(): boolean { return !this.isOptional('country') }
    get isUsa(): boolean { return this.countryName === this.addresses.usa }
    get postalCodeFieldName(): string { return this.countryName === this.addresses.uk ? 'Postcode' : 'Postal Code' }
    get postalCodeRequired(): boolean { return !this.isOptional('postalCode') && (this.addresses.canadaMexicoAndUk.includes(this.countryName) || this.isRequired('postalCode')) }
    get stateRequired(): boolean { return this.isRequired('state') || (!this.isOptional('state') && this.requiredStateWithCountries.includes(this.countryName)) }
    get street2Required(): boolean { return this.isRequired('street2') }
    get zipRequired(): boolean { return !this.isOptional('postalCode') && (this.isUsa || this.isRequired('postalCode')) }

    constructor(
        private addresses: AddressService,
    ) { }

    ngOnInit(): void {

        this.optionalFieldNames = this.optionalFields?.map(field => field.toLocaleLowerCase()) || []
        this.requiredFieldNames = this.requiredFields?.map(field => field.toLocaleLowerCase()) || []

        this.country = new FormControl(this.currentAddress?.country || this.addresses.usa, this.countryRequired ? Validators.required : undefined)

        if (!this.currentAddress?.country && !!this.defaultCountry) {
            this.country.setValue(this.defaultCountry)
        }

        this.street1 = new FormControl(this.currentAddress?.street1, this.isOptional('street1') ? [Validators.maxLength(70)] : [Validators.required, MinLengthValidator(5), Validators.maxLength(70)])
        this.street2 = new FormControl(this.currentAddress?.street2, this.street2Required ? Validators.required : undefined)
        this.city = new FormControl(this.currentAddress?.city, MinLengthValidator(2))
        this.state = new FormControl(this.currentAddress?.state, this.stateRequired ? Validators.required : undefined)
        this.zip = new FormControl(this.isUsa ? this.currentAddress?.postalCode : undefined, this.zipRequired ? [Validators.required, ZipValidator] : ZipValidator)
        this.postalCode = new FormControl(this.isUsa ? undefined : this.currentAddress?.postalCode)
        this.locations = new FormControl()
        this.otherLocations = new FormControl(!!this.currentAddress && !!this.otherOperatingLocations ? JSON.stringify(this.otherOperatingLocations.map(loc => loc.value)) : null)

        const number: any = parseNumber(this.currentAddress?.phoneNumber || '', this.US_Code)
        this.phoneNumber = new FormControl(!!number.phone ? formatNumber(number, this.phoneFormat) : null, [Validators.required, PhoneValidator])

        this.initializeForm()
        this.addressInput$
            ?.pipe(
                takeUntil(this.unsubscribe$),
                tap(address => {
                    this.currentAddress = address
                    this.setFormValues()
                }),
            )
            .subscribe()
    }

    ngAfterViewInit(): void {

        this.country.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => {
                    this.addressForm.updateValueAndValidity()
                    if (this.currentAddress?.country !== this.country.value) {
                        this.addressForm.markAsDirty()
                    }
                }),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    enableAllExceptCountry(enable: boolean = true): void {
        if (enable) {
            this.street1?.enable()
            this.street2?.enable()
            this.city?.enable()
            this.state.enable()
            this.zip?.enable()
            this.postalCode?.enable()
            this.phoneNumber?.enable()
            this.locations?.enable()
            this.otherLocations?.enable()
        } else {
            this.street1?.disable()
            this.street2?.disable()
            this.city?.disable()
            this.state?.disable()
            this.zip?.disable()
            this.postalCode?.disable()
            this.phoneNumber?.disable()
            this.locations?.disable()
            this.otherLocations?.disable()
        }
    }

    formatNumber(): void {
        const number: any = parseNumber(this.phoneNumber.value || '', this.US_Code)
        this.phoneNumber.setValue(!!number.phone ? formatNumber(number, this.phoneFormat) : this.phoneNumber.value || null)
    }

    formatZipControl(): void {
        formatZip(this.zip)
    }

    resetForm(): void {
        this.initializeForm()
    }

    addOperatingLocation(): void {
        this.addChip(this.locations.value, this.otherOperatingLocations)
        this.otherLocations.setValue(JSON.stringify(this.otherOperatingLocations.map(loc => loc.value)))
        this.locations.setValue(undefined)
    }

    removeOperatingLocation(loc: string): void {
        this.removeChip(loc, this.otherOperatingLocations)
        this.otherLocations.setValue(JSON.stringify(this.otherOperatingLocations.map(l => l.value)))
    }

    initializeForm(): void {

        const formDisabled: boolean = this.addressForm.disabled

        this.addressForm.addControl('street1', this.street1)
        this.addressForm.addControl('street2', this.street2)
        this.addressForm.addControl('city', this.city)
        this.addressForm.addControl('state', this.state)
        this.addressForm.addControl('zip', this.zip)
        this.addressForm.addControl('postalCode', this.postalCode)
        this.addressForm.addControl(this.countryControlName, this.country)
        this.addressForm.addControl('locations', this.locations)
        this.addressForm.addControl('otherLocations', this.otherLocations)

        if (this.showPhoneNumber) {
            this.addressForm.addControl('phoneNumber', this.phoneNumber)
        }

        if (formDisabled) {
            this.addressForm.disable()
        }

        if (this.disableCountry) {
            this.country.disable()
        }

        const validators: Array<ValidatorFn> = [
            this.isOptional('city') ? undefined : RequiredIfOtherNotEqualValidator('city', this.countryControlName, this.addresses.uk),
            this.isOptional('state') ? undefined : RequiredIfOtherEqualsValidator('state', this.countryControlName, this.requiredStateWithCountries),
            this.zipRequired ? RequiredIfOtherEqualsValidator('zip', this.countryControlName, [this.addresses.usa]) : undefined,
            this.isOptional('postalCode') ? undefined : RequiredIfOtherEqualsValidator('postalCode', this.countryControlName, this.addresses.canadaMexicoAndUk),
        ]
            .filter(val => !!val)
        this.addressForm.setValidators(validators)
    }

    setFormValues(): void {

        if (!Object.keys(this.addressForm.controls)?.length) {
            this.initializeForm()
        }

        if (!this.currentAddress) {
            return
        }

        this.street1.setValue(this.currentAddress.street1)
        this.street2.setValue(this.currentAddress.street2)
        this.city.setValue(this.currentAddress.city)
        this.state.setValue(this.currentAddress.state)
        this.zip.setValue(this.currentAddress.country !== this.addresses.usa ? null : this.currentAddress?.postalCode)
        this.postalCode.setValue(this.currentAddress?.country !== this.addresses.usa ? this.currentAddress?.postalCode : null)
        this.otherLocations.setValue(JSON.stringify(this.otherOperatingLocations.map(loc => loc.value)))
        this.country.setValue(this.currentAddress.country)

        const number: any = parseNumber(this.currentAddress?.phoneNumber || '', this.US_Code)
        this.phoneNumber.setValue(!!number.phone ? formatNumber(number, this.phoneFormat) : null)

        this.addressForm.markAsPristine()
    }

    isOptional(field: string): boolean {
        return this.optionalFieldNames.includes(field.toLocaleLowerCase())
    }

    isRequired(field: string): boolean {
        return this.requiredFieldNames.includes(field.toLocaleLowerCase())
    }

    private getAddress(): Address {
        return this.addressForm.invalid
            ? undefined
            : {
                ...this.addressForm.value,
                postalCode: this.countryName === this.addresses.usa ? this.zip.value : this.postalCode.value,
                country: this.country.value,
            }
    }

    private addChip(chip: string, chipCollection: Array<SelectOption<string>>): void {

        // if we don't have a chip or it already exists in the list, don't do anything
        const safeChip: string = chip?.trim()?.toLocaleLowerCase()
        if (!chip || chipCollection.some(existing => existing.value.toLocaleLowerCase() === safeChip)) {
            return
        }

        chipCollection.push({ label: chip, value: chip, removable: true })
        this.addressForm.markAsDirty()
    }

    private removeChip(chip: string, chipCollection: Array<SelectOption<string>>): void {
        const existing: SelectOption<string> = chipCollection.find(c => chip === c.value)
        if (!existing) {
            return
        }
        chipCollection.splice(chipCollection.indexOf(existing), 1)
        this.addressForm.markAsDirty()
    }
}
