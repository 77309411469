import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { UserService } from '../../core/services/user.service'
import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models'
import { LiquidInvoiceAbstract } from '../models'

import { LiquidInvoiceStore } from './liquid-invoice.store'

@Injectable({
    providedIn: 'root',
})
export class LiquidInvoiceService {

    constructor(
        private dialogs: DialogService,
        private store: LiquidInvoiceStore,
        private users: UserService,
    ) { }

    download(invoice: LiquidInvoiceAbstract): Observable<boolean> {
        return this.store.download(invoice)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPage(page: PageRequest): Observable<PagedSet<LiquidInvoiceAbstract>> {
        return this.store.getPage(this.users.businessSnapshot.id, page)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    updateIntegration(liquidInvoiceId: string): Observable<boolean> {
        return this.store.updateIntegration(liquidInvoiceId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
