import { Injectable } from '@angular/core'

import { DeliverableStatus, WorkOrder, WorkOrderAbstract, WorkOrderProjectInfo, WorkOrderStatus } from '../models'

@Injectable({
    providedIn: 'root',
})
export class WorkOrderFactory {

    create<T extends WorkOrderAbstract>(workOrder: T): T {
        workOrder.createdOn = !!workOrder.createdOn ? new Date(workOrder.createdOn) : undefined
        workOrder.endOn = !!workOrder.endOn ? new Date(workOrder.endOn) : undefined
        this.setStatus(workOrder)
        this.setEstimatePercent(workOrder)
        return workOrder
    }

    createProjectInfo<T extends WorkOrderProjectInfo>(workOrder: T): T {
        workOrder.createdOn = !!workOrder.createdOn ? new Date(workOrder.createdOn) : undefined
        workOrder.endOn = !!workOrder.endOn ? new Date(workOrder.endOn) : undefined
        this.setStatus(workOrder)
        this.setEstimatePercent(workOrder)
        workOrder.startDate = !!workOrder.startDate ? new Date(workOrder.startDate) : undefined

        return workOrder
    }

    private setEstimatePercent<T extends WorkOrderAbstract>(workOrder: T): void {
        workOrder.percentPaid = workOrder.totalEstimate === 0 ? 0 : (workOrder.totalPaid / workOrder.totalEstimate) * 100
    }

    private setStatus<T extends WorkOrderAbstract>(workOrder: T): void {

        // if this was created from an invoice, always change the status to "System"
        if (!workOrder?.createdFromClientInvoiceId) {
            return
        }

        workOrder.status = WorkOrderStatus.SYSTEM

        if (workOrder.hasOwnProperty('deliverables')) {
            (<WorkOrder><unknown>workOrder).deliverables.forEach(t => t.status = DeliverableStatus.SYSTEM)
        }
    }
}
