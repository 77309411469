import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'

import { Address, AddressService } from '../../address'
import { DialogService } from '../../dialogs'
import { Organization } from '../../modules/models'

import { UserAddressStore } from './user-address.store'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class UserAddressService {

    private readonly currentAddressSubject: BehaviorSubject<Address | undefined> = new BehaviorSubject(undefined)
    private initialized: boolean

    get currentAddress(): Address { return this.currentAddressSubject.getValue() }
    get currentAddress$(): Observable<Address> { return this.currentAddressSubject.asObservable() }
    get isInternational(): boolean { return !!this.currentAddress?.country && this.currentAddress.country !== this.addresses.usa }

    constructor(
        private addresses: AddressService,
        private dialogs: DialogService,
        private store: UserAddressStore,
        private users: UserService,
    ) { }

    initialize(): Observable<Address> {

        if (this.initialized) {
            return this.currentAddress$
        }

        this.initialized = true
        let retrievingAddress: boolean = true

        this.users.currentBusiness$
            .pipe(
                filter(biz => !biz || biz.id !== this.currentAddress?.organizationId),
                switchMap(() => this.getCurrentAddress()),
                tap(address => {
                    retrievingAddress = false
                    this.currentAddressSubject.next(address)
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()

        return this.currentAddress$
            .pipe(
                filter(() => !retrievingAddress),
            )
    }

    update(address: Address): Observable<Address> {

        return this.store.update([address], this.users.businessSnapshot?.id, 'Organization')
            .pipe(
                take(1),
                map(response => response[0]),
                tap(firstAddress => this.currentAddressSubject.next(firstAddress)),
                catchError(error => this.dialogs.error(error)),
            )
    }

    reset(): Observable<Address> {
        return this.getCurrentAddress()
            .pipe(
                tap(address => this.currentAddressSubject.next(address)),
            )
    }

    private getCurrentAddress(): Observable<Address> {
        const biz: Organization = this.users.businessSnapshot
        return !biz || this.currentAddress?.organizationId === biz.id
            ? of(!biz ? undefined : this.currentAddress)
            : this.store.get(biz.id).pipe(take(1))
    }
}
