import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'

import { SelectOption } from '../../core/models'
import { DirtyOrTouchedErrorStateMatcher } from '../../validation'
import { AddressService } from '../services/address.service'

@Component({
    selector: 'app-state-form',
    templateUrl: './state-form.component.html',
    styleUrls: ['./state-form.component.scss'],
})

export class StateFormComponent implements OnInit {

    private get countryName(): string { return this.country?.value }

    @Input() country: FormControl
    @Input() label: string
    @Input() required: boolean
    @Input() state: FormControl
    @Input() abbrv: boolean
    @Input() forceInternational: boolean

    readonly errorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()
    stateOptions: Array<SelectOption<string>>

    get isInternational(): boolean { return this.forceInternational || this.countryName !== this.addresses.usa }
    get stateFieldName(): string { return this.label || (this.countryName === this.addresses.canada ? 'Province' : (this.countryName === this.addresses.uk ? 'Post Town' : ([this.addresses.usa, this.addresses.mexico].includes(this.countryName) ? 'State' : 'State/Region'))) }

    constructor(
        private addresses: AddressService,
    ) { }

    ngOnInit(): void {
        this.stateOptions = this.abbrv ? this.addresses.stateAbbrvOptions : this.addresses.stateOptions
    }
}
