export enum BusinessStructureType {
    Sole = 'Sole Proprietor',
    CCorp = 'C-Corporation',
    SCorp = 'S-Corporation',
    Partnership = 'Partnership',
    Trust = 'Trust/Estate',
    LLC_SM = 'Single-member LLC',
    LLC_C = 'LLC C-Corporation',
    LLC_S = 'LLC S-Corporation',
    LLC_P = 'LLC Partnership',
    Other = 'Other',
}
