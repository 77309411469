import { StorageKey } from '../../common/global'
import { UrlService } from '../../core/services'
import { OrderType, OrganizationTeamMembershipVendorType } from '../models'

export type OrderTypeConfig = {
    clientDashboardUrl: string,
    clientFiltersStorageKey: string
    membershipVendorTypes: ReadonlyArray<OrganizationTeamMembershipVendorType>
    type: OrderType,
    vendorDashboardUrl: string,
    clientOrderCreateUrlProvider: (vendor?: string, id?: string, step?: string, inviteId?: string) => string,
    clientOrderDetailsUrlProvider: (id: string, businessId: string, openCta?: boolean, editEstimatedFee?: boolean) => string,
    vendorOrderCreateUrlProvider: (vendor?: string, id?: string, step?: string, inviteId?: string) => string,
    vendorOrderDetailsUrlProvider: (workOrderId: string, businessId: string, addInvoice?: boolean) => string,
}
    & OrderTypeLabelsConfig

export interface OrderTypeLabelsConfig {
    itemLowerCaseLabel: string,
    itemLowerCasePluralLabel: string,
    itemUpperCaseLabel: string,
    itemUpperCasePluralLabel: string,
    lowerCaseLabel: string
    lowerCasePluralLabel: string
    upperCaseLabel: string,
    upperCasePluralLabel: string,
}

const urlService: UrlService = new UrlService()

const _getOrderTypeLabelsConfig: (type: OrderType) => OrderTypeLabelsConfig = type => {

    if (type === OrderType.PurchaseOrder
    ) {
        const label: string = 'Purchase Order'
        const lowerCaseLabel: string = label.toLowerCase()
        const itemLabel: string = 'Item'
        const lowerCaseItemLabel: string = itemLabel.toLowerCase()

        return {
            itemLowerCaseLabel: lowerCaseItemLabel,
            itemLowerCasePluralLabel: lowerCaseItemLabel + 's',
            itemUpperCaseLabel: itemLabel,
            itemUpperCasePluralLabel: itemLabel + 's',
            lowerCaseLabel: lowerCaseLabel,
            lowerCasePluralLabel: lowerCaseLabel + 's',
            upperCaseLabel: label,
            upperCasePluralLabel: label + 's',
        }
    }

    if (type === OrderType.WorkOrder
    ) {
        const label: string = 'Work Order'
        const lowerCaseLabel: string = label.toLowerCase()
        const itemLabel: string = 'Deliverable'
        const lowerCaseItemLabel: string = itemLabel.toLowerCase()

        return {
            itemLowerCaseLabel: lowerCaseItemLabel,
            itemLowerCasePluralLabel: lowerCaseItemLabel + 's',
            itemUpperCaseLabel: itemLabel,
            itemUpperCasePluralLabel: itemLabel + 's',
            lowerCaseLabel: lowerCaseLabel,
            lowerCasePluralLabel: lowerCaseLabel + 's',
            upperCaseLabel: label,
            upperCasePluralLabel: label + 's',
        }

    }

    throw Error(`unknown ${type} has been specified`)
}

const _getOrderTypeConfig: (type: OrderType) => OrderTypeConfig = (type) => {

    const labelsConfig: OrderTypeLabelsConfig = _getOrderTypeLabelsConfig(type)

    if (type === OrderType.PurchaseOrder
    ) {

        return {
            ...labelsConfig,
            clientDashboardUrl: urlService.route.purchaseOrdersDashboard(),
            clientFiltersStorageKey: StorageKey.workOrderFiltersClient,
            clientOrderCreateUrlProvider: urlService.route.purchaseOrderCreate.bind(urlService),
            clientOrderDetailsUrlProvider: urlService.route.purchaseOrderDetails.bind(urlService),
            membershipVendorTypes: [OrganizationTeamMembershipVendorType.NonServiceVendor, OrganizationTeamMembershipVendorType.ServiceVendor],
            type,
            vendorDashboardUrl: urlService.route.vendorPurchaseOrderDashboard(),
            vendorOrderCreateUrlProvider: urlService.route.vendorPurchaseOrderCreate.bind(urlService),
            vendorOrderDetailsUrlProvider: urlService.route.vendorPurchaseOrderDetails.bind(urlService),
        }
    }

    if (type === OrderType.WorkOrder
    ) {
        return {
            ...labelsConfig,
            clientDashboardUrl: urlService.route.workOrdersDashboard(),
            clientFiltersStorageKey: StorageKey.purchaseOrderFiltersClient,
            clientOrderCreateUrlProvider: urlService.route.workOrderCreate.bind(urlService),
            clientOrderDetailsUrlProvider: urlService.route.workOrderDetails.bind(urlService),
            membershipVendorTypes: [OrganizationTeamMembershipVendorType.ServiceVendor],
            type,
            vendorDashboardUrl: urlService.route.vendorWorkOrderDashboard(),
            vendorOrderCreateUrlProvider: urlService.route.vendorWorkOrderCreate.bind(urlService),
            vendorOrderDetailsUrlProvider: urlService.route.vendorWorkOrderDetails.bind(urlService),
        }

    }

    throw Error(`unknown ${type} has been specified`)
}

const orderTypeConfigByOrderType: Map<OrderType, OrderTypeConfig> = new Map([
    [OrderType.PurchaseOrder, _getOrderTypeConfig(OrderType.PurchaseOrder)],
    [OrderType.WorkOrder, _getOrderTypeConfig(OrderType.WorkOrder)],
])

export const getOrderTypeConfig: (type: OrderType) => OrderTypeConfig = (type) => {
    const config: OrderTypeConfig = orderTypeConfigByOrderType.get(type)
    if (!config) {
        throw Error(`unknown ${type} has been specified`)
    }

    return config
}

export const getOrderTypeLabelsConfig: (type: OrderType) => OrderTypeLabelsConfig = type => {
    return getOrderTypeConfig(type)
}

export const getOrderType: (type: OrganizationTeamMembershipVendorType) => OrderType = (type) => {
    if (type === OrganizationTeamMembershipVendorType.NonServiceVendor
    ) {
        return OrderType.PurchaseOrder
    }

    if (type === OrganizationTeamMembershipVendorType.ServiceVendor
    ) {
        return OrderType.WorkOrder
    }

    throw Error(`unknown ${type} has been specified`)
}

export const getOrganizationTeamMembershipVendorType: (type: OrderType) => OrganizationTeamMembershipVendorType = (type) => {
    if (type === OrderType.PurchaseOrder
    ) {
        return OrganizationTeamMembershipVendorType.NonServiceVendor
    }

    if (type === OrderType.WorkOrder
    ) {
        return OrganizationTeamMembershipVendorType.ServiceVendor
    }

    throw Error(`unknown ${type} has been specified`)
}
