import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'

@Component({
    selector: 'app-sign-up',
    template: '',
})
export class SignUpComponent {

    constructor(
        private auth0: AuthService,
    ) {
        this.auth0.loginWithRedirect({ screen_hint: 'signup' })
    }
}
