<form
    [formGroup]="form"
    class="container"
>
    <div class="step-content">
        <div>
            <p class="subtitle1 primary100">What’s the title for this item?</p>
            <mat-form-field class="full-width">
                <mat-label>Item title</mat-label>
                <input
                    matInput
                    required
                    [formControl]="description"
                />
            </mat-form-field>
        </div>
        <div>
            <p class="subtitle1 primary100">What’s the quantity of this item?</p>
            <mat-form-field class="full-width">
                <mat-label>Quantity amount</mat-label>
                <input
                    type="number"
                    matInput
                    required
                    min="1"
                    inputmode="numeric"
                    [formControl]="quantity"
                />
            </mat-form-field>
            <p class="subtitle1 primary100">What’s the price per item?</p>
            <mat-form-field class="full-width">
                <mat-label>Price in {{payoutCurrency}}</mat-label>
                <input
                    matInput
                    type="number"
                    min="0"
                    required
                    [formControl]="price"
                />
            </mat-form-field>

        </div>
    </div>
</form>
<div
    *ngIf="!disabled && editable"
    class="actions"
>
    <button
        mat-stroked-button
        color="primary"
        tabindex="-1"
        (click)="onDelete()"
    >
        Delete
    </button>
    <button
        mat-flat-button
        color="primary"
        [disabled]="disabled || loading || (form.invalid || !form.dirty)"
        (click)="onSave()"
    >
        Finish
    </button>
</div>
