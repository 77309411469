<app-dialog-template-small
    [title]="title"
    [hideCloseButton]="hideCloseButton"
    [titleInline]="titleInline"
    [contentClass]="'flex-row'"
>
    <div
        *ngIf="!!icon"
        class="dialog-content icon-container mrv-24"
    >
        <img [src]="icon" />
    </div>
    <div
        [ngClass]="{ 'mtv-10': !titleInline}"
        class="dialog-input"
    >
        <div>
            <div *ngIf="!!description?.length">
                <div class="mbv-10">
                    {{description}}
                </div>
            </div>
            <div>
                <mat-label>
                    {{placeholder}}
                </mat-label>
                <mat-radio-group
                    [formControl]="form"
                >
                    <mat-radio-button
                        *ngFor="let option of options"
                        [value]="option.value"
                        color="primary"
                    >
                        {{option.label}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-error *ngIf="!!form.dirty && !!form.invalid">{{errorText}}</mat-error>
        </div>
    </div>

    <button
        mat-flat-button
        color="primary"
        (click)="okClicked()"
        [disabled]="!!form.invalid"
        class="dialog-button"
    >
        {{okButtonText}}
    </button>
</app-dialog-template-small>
