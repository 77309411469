import { ClientInvoiceIntegrationError } from '../liquid/modules/invoice-detail/models'

import { ClientInvoiceStatus } from './client-invoice-status.enum'
import { FXRateQuote } from './fx-rate-quote.interface'
import { InvoiceApprover } from './invoice-approver.model'
import { InvoiceValidationIssue } from './invoice-validation-issue.enum'
import { LiquidFinancialAccountAssignment } from './liquid-financial-account-assignment.model'
import { OrderType } from './order-type.enum'
import { OrganizationTeamInvitation } from './organization-team-invitation.model'
import { RateType } from './rate-type.enum'
import { WorkOrder } from './work-order.interface'

// TODO: convert this to an interface
export class ClientInvoice {
    amountInvoiced: number
    amountPaid: number
    approvers: Array<InvoiceApprover>
    associatedWorkOrderIds: string[] = []
    associatedWorkOrderInfo: Array<{
        id: string
        name: string
        rateTypes: Array<RateType>
    }>
    associatedWorkOrders: WorkOrder[]
    billingFieldId?: string
    canAcceptPayment: boolean
    canReschedulePayment: boolean
    clientEmailAddressOverride: string
    clientInformation: string
    clientName: string
    clientOrganizationId: string
    comments: string
    commentsForClient: string
    createdByOpenId: string
    createdByOrganizationId: string
    createdTimestamp: number
    deleted: boolean
    dueDateTime: Date
    dueDateTimestamp?: number
    estimatedFXAmount?: number
    estimatedFXAmountMultiplier?: number
    estimatedFXCountryName?: string
    estimatedFXCurrencyCode?: string
    footerInformation: string
    fxRateQuoteTimestamp?: number
    fxPayoutCompletedOnTimestamp?: number
    fxRateQuote?: FXRateQuote
    hasPdf: boolean
    hoursWorked: number
    id: string
    integrationLog: ClientInvoiceIntegrationError[]
    invoiceAttachmentUrl: string
    invoiceCurrency: string
    invoiceDate: Date
    stringInvoiceDate: string
    invoiceIntegrationComplete: boolean
    invoiceLogoUrl: string
    invoiceMatchesWorkOrder: boolean
    invoiceNumber: string
    invoicerCountryName: string
    invoicerInformation: string
    invoicerName: string
    invoicerOrganizationId?: string
    isApprovable: boolean
    isDraft: boolean
    isExternalClientInvoice?: boolean
    isExternalVendorInvoice?: boolean
    isGuestClientInvoice?: boolean
    lastPaidDateTimestamp: number
    liquidFinancialAccountAssignments?: Array<LiquidFinancialAccountAssignment>
    locked: boolean
    organizationTeamInvitation?: OrganizationTeamInvitation
    orderType: OrderType
    paid: boolean
    pastDue: boolean
    paymentInformation: string
    paymentInstructionsSent?: boolean
    paymentIntegrationComplete: boolean
    paymentTermsDays?: number
    payoutCurrency: string
    payoutInitiated: boolean
    payoutTotal: number
    payoutTotalLocked: boolean
    performanceEndTimestamp?: number
    performanceStartTimestamp?: number
    proofOfReceiptAttachmentUrl?: string
    rateTypes: Array<RateType>
    scheduledPaymentAmount: number
    scheduledPaymentTimestamp: number
    scheduledPaymentTransactionFeeAmount: number
    scheduledPaymentTransactionFeePercent: number
    sentDateTime: Date
    sentDateTimestamp?: number
    status: ClientInvoiceStatus
    taxJurisdiction: string
    taxRate: number
    terms: string | number
    transactionFeeAmount: number
    transactionFeePercent: number
    validationIssues: Array<InvoiceValidationIssue>
    vendorBankAccountNumber: string
    vendorEmail?: string
    vendorRoutingNumber: string
    waitingForProcessing?: boolean
    workOrderName?: string
    workOrderNames: Array<string>
}
