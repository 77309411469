export enum StringType {
    ClientName = 'ClientName',
    ClientRepName = 'ClientRepName',
    ClientRepTitle = 'ClientRepTitle',
    ClientStructure = 'ClientStructure',
    GoverningState = 'GoverningState',
    HiringOrgCompanyName = 'HiringOrgCompanyName',
    HiringOrgSignerName = 'HiringOrgSignerName',
    HiringOrgSignerTitle = 'HiringOrgSignerTitle',
    SignerName = 'SignerName',
    SignerTitle = 'SignerTitle',
    SigningOrgName = 'SigningOrgName',
    String = 'String',
    Text = 'Text',
    VendorName = 'VendorName',
    VendorRepName = 'VendorRepName',
    VendorRepTitle = 'VendorRepTitle',
    VendorStructure = 'VendorStructure',
}
