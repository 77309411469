<div class="container">
    <img src="/assets/img/svg/constructing-site-engineer.svg">
    <ng-container *ngIf="!isMaintenance">
        <h1>Liquid is currently experiencing some downtime.</h1>
        <h2>Please be patient while we get our services online.</h2>
    </ng-container>
    <ng-container *ngIf="isMaintenance">
        <h1>Liquid is undergoing scheduled maintenance.</h1>
        <h2>We'll take you where you were going as soon as maintenance has completed.</h2>
    </ng-container>
    <p>
        <ng-container *ngIf="isMaintenance && maintenanceUntil">Maintenance is scheduled to be completed on or before
            {{maintenanceUntil | date:'M/d/yy @
            h:mm a'}}.<br>
        </ng-container>
        You can view more detailed information on our
        <a
            href="{{liquidStatusUrl}}"
            target="_blank"
        >status</a>
        page.
    </p>
</div>