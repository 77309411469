import { EventEmitter, Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { StatusCodes } from 'http-status-codes'
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { PaymentAccount, PaymentAccountStatus } from '../../core/models'
import { CurrencyService, PaymentAccountService, UserAddressService, UserService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { PaymentAccountHolderInfoAndMicrodepositsDialogComponent } from '../../microdeposits/payment-account-holder-info-and-microdeposits-dialog/payment-account-holder-info-and-microdeposits-dialog.component'
import { PaymentAccountMicrodepositsDialogComponent } from '../../microdeposits/payment-account-microdeposits-dialog/payment-account-microdeposits-dialog.component'
import { PaymentAccountHolderInfoDialogComponent } from '../../payment-account-holder-info/payment-account-holder-info-dialog/payment-account-holder-info-dialog.component'
import { BankAccountDetails, Organization } from '../models'

import { PlaidLinkHandler, PlaidLinkService } from './plaid-link.service'

@Injectable({ providedIn: 'root' })
export class BankAccountWorkflowService {

    private currentBiz: Organization
    private isVendor: boolean = false
    private unsubscribe$: Subject<void>

    acctChange: EventEmitter<PaymentAccount> = new EventEmitter<PaymentAccount>()
    added: EventEmitter<PaymentAccount> = new EventEmitter<PaymentAccount>()
    readonly canConnectStripe$: Observable<boolean> = this.users.teamConfig$
        .pipe(
            map(businessConfig => businessConfig?.myClaims?.canManageClients),
        )
    loaded: boolean = false
    paymentAccount: PaymentAccount
    payoutAccountType: string
    verificationComplete: EventEmitter<PaymentAccount> = new EventEmitter<PaymentAccount>()

    set biz(value: Organization) { this.currentBiz = value }

    constructor(
        private currencyService: CurrencyService,
        private dialogs: DialogService,
        private paymentAccounts: PaymentAccountService,
        private plaidLinkService: PlaidLinkService,
        private userAddresses: UserAddressService,
        private users: UserService,
    ) { }

    addBankAccount(): Observable<PlaidLinkHandler> {

        return this.plaidLinkService.getPlaidLinkHandler({
            onExit: () => this.dialogs.close(),
            onSuccess: (token, metadata) => {
                const submitMessage: string = 'This may take a while so please donʼt refresh your page.'
                this.dialogs.wait(submitMessage)
                this.paymentAccounts.createLinked(
                    metadata.public_token,
                    metadata.account_id,
                    metadata.accounts,
                )
                    .pipe(
                        switchMap(account => this.isVendor ? this.paymentAccounts.setPayoutAccount(account.id).pipe(map(() => account)) : of(account)),
                        switchMap(account => this.openBankInfoForm(account, true)),
                        tap(account => this.added.emit(account)),
                        catchError(error => this.dialogs.error(error)
                            .pipe(
                                switchMap(() => (this.users.refreshTeamConfigRestrictAccessOnError())),
                            ),
                        ),
                    )
                    .subscribe()
            },
        })
            .pipe(
                tap(handler => {
                    this.dialogs.close()
                    handler.open()
                }),
            )
    }

    init(obs$: Subject<void>, isVendor: boolean = false): void {
        this.unsubscribe$ = obs$
        this.isVendor = isVendor
        if (!isVendor) {
            return
        }
        this.users.currentBusiness$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(biz => {
                    this.loaded = false
                    this.currentBiz = biz
                }),
                switchMap(() => this.userAddresses.reset()),
                filter(() => this.isVendor),
                switchMap(() => this.getPayoutAccount()),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    getPayoutAccount(associatedPaymentAccountId?: string): Observable<PaymentAccount> {
        return forkJoin([
            !!associatedPaymentAccountId ? of(associatedPaymentAccountId)
                : this.paymentAccounts.getPayout()
                    .pipe(
                        tap(payoutAccount => this.payoutAccountType = payoutAccount?.payoutAccountType),
                        map(payoutAccount => payoutAccount?.associatedPaymentAccountId),
                    ),
            this.paymentAccounts.get(),
        ])
            .pipe(
                take(1),
                tap(([associatedAccountId, accounts]) => {
                    this.paymentAccount = accounts.find(account => account.id === associatedAccountId)
                    if (!!this.paymentAccount && !this.paymentAccount.currency) {
                        this.paymentAccount.currency = 'USD'
                    }
                    this.acctChange.emit(this.paymentAccount)
                    this.loaded = true
                }),
                map(() => this.paymentAccount),
            )
    }

    verifyAccount(account: PaymentAccount): Observable<void> {
        const success$: Subject<void> = new Subject<void>()

        this.dialogs.wait()
        this.paymentAccounts.getMicrodepositVerificationTokens(account.id)
            .pipe(
                switchMap(response => {
                    return this.plaidLinkService.getPlaidLinkHandler({
                        token: response.token,
                        onExit: () => this.dialogs.close(),
                        onSuccess: () => {
                            this.paymentAccounts.setMicrodepositComplete(account.id)
                                .pipe(
                                    take(1),
                                    // The response is being ignored because almost all attributes are comming as null
                                    tap(() => {
                                        this.verificationComplete.emit({
                                            ...account,
                                            status: PaymentAccountStatus.Verified,
                                        })
                                        success$.next()
                                        success$.complete()
                                    }),
                                    switchMap(() => this.users.initializeUser(true)),
                                    catchError(error => {
                                        success$.error(error)
                                        success$.complete()

                                        return this.dialogs.error(error)
                                    }),
                                )
                                .subscribe()
                        },
                    })
                }),
                tap(handler => {
                    this.dialogs.close()
                    handler.open()
                }),
            )
            .subscribe()

        return success$.asObservable()
    }

    openBankInfoForm(paymentAccount: PaymentAccount, enabled?: boolean, showAccountTypesSelect: boolean = true): Observable<PaymentAccount> {

        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.disableClose = true
        dialogConfig.data = {
            account: paymentAccount,
            enabled,
            isVendor: this.isVendor,
            showAccountTypesSelect,
        }

        return this.dialogs.open(PaymentAccountHolderInfoDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap(accountHolderDetails => {
                    if (!!accountHolderDetails) {
                        this.dialogs.wait()
                    }
                }),
                switchMap((accountHolderDetails: BankAccountDetails) => !!accountHolderDetails ? this.paymentAccounts.setDetails(accountHolderDetails) : of(undefined)),
                tap(updatedAccount => {
                    if (!this.isVendor) {
                        this.dialogs.close()
                    }
                    this.paymentAccount = updatedAccount || paymentAccount
                    this.paymentAccount.currency = this.paymentAccount.currency || 'USD'
                    this.acctChange.emit(this.paymentAccount)
                    this.dialogs.close()
                }),
                switchMap(updatedAccount => this.users.refreshTeamConfigRestrictAccessOnError()
                    .pipe(
                        map(() => updatedAccount),
                    ),
                ),
                catchError(error => this.dialogs.error(error)
                    .pipe(
                        switchMap(() => (this.users.refreshTeamConfigRestrictAccessOnError())),
                    ),
                ),
            )
    }

    openMicrodepositBankInfoForm(paymentAccount: PaymentAccount, allowNewMicrodepositDetails?: boolean): Observable<PaymentAccount> {

        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.disableClose = true
        dialogConfig.data = {
            account: paymentAccount,
            isVendor: this.isVendor,
            allowNewMicrodepositDetails: allowNewMicrodepositDetails ?? false,
        }

        return this.dialogs.open(PaymentAccountHolderInfoAndMicrodepositsDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap(accountHolderDetails => {
                    if (!!accountHolderDetails) {
                        this.dialogs.wait()
                    }
                }),
                switchMap((accountHolderDetails: BankAccountDetails) => !!accountHolderDetails ? this.paymentAccounts.setDetails(accountHolderDetails) : of(undefined)),
                tap(updatedAccount => {
                    if (!this.isVendor) {
                        this.dialogs.close()
                    }
                    this.paymentAccount = updatedAccount || paymentAccount
                    this.paymentAccount.currency = this.paymentAccount.currency || this.currencyService.USD

                    if (!!updatedAccount) {
                        this.acctChange.emit(this.paymentAccount)
                    }
                    this.dialogs.close()
                }),
                catchError(error => this.dialogs.error(error)
                    .pipe(
                        switchMap(() => (this.users.refreshTeamConfigRestrictAccessOnError())),
                    ),
                ),
            )
    }

    openMicrodepositsVerificationForm(paymentAccount: PaymentAccount): Observable<PaymentAccount> {

        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.disableClose = true
        dialogConfig.data = {
            account: paymentAccount,
            isVendor: this.isVendor,
        }

        return this.dialogs.open(PaymentAccountMicrodepositsDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap((updatedAccount: PaymentAccount) => {
                    if (!!updatedAccount?.microdepositVerificationComplete ||
                        updatedAccount.microdepositVerificationRemainingAttempts === 0) {
                        this.acctChange.emit(updatedAccount)
                        this.dialogs.close()
                    }
                    if (<string><unknown>updatedAccount === '') { // on cancel
                        this.acctChange.emit()
                    }
                }),
            )
    }
}
