import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { filter, map, switchMap, take, tap } from 'rxjs/operators'

import { Claim, OrganizationMembershipAbstractMember } from '../../auth/models'
import { SelectOption } from '../models'

import { ClaimsService } from './claims.service'
import { OrganizationService } from './organization.service'
import { ProfileService } from './profile.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessUsersService {

    private userOptionsSubject: BehaviorSubject<Array<SelectOption<OrganizationMembershipAbstractMember>>> = new BehaviorSubject([])
    private usersSubject: BehaviorSubject<Array<OrganizationMembershipAbstractMember>> = new BehaviorSubject([])

    readonly userOptions$: Observable<Array<SelectOption<OrganizationMembershipAbstractMember>>> = this.userOptionsSubject.asObservable()

    get businessUsers(): Array<OrganizationMembershipAbstractMember> { return this.usersSubject.getValue() }
    get currentUser(): OrganizationMembershipAbstractMember { return this.businessUsers.find(mem => mem.isMe) }
    get userOptions(): Array<SelectOption<OrganizationMembershipAbstractMember>> { return this.userOptionsSubject.getValue() }

    constructor(
        private businesses: OrganizationService,
        private claims: ClaimsService,
        private profiles: ProfileService,
        private users: UserService,
    ) {
        this.users.currentBusiness$
            .pipe(
                filter(() => !!this.userOptions?.length),
                tap(() => this.userOptionsSubject.next([])),
                switchMap(() => this.initialize()),
            )
            .subscribe()
    }

    initialize(forceRefresh: boolean = false): Observable<Array<SelectOption<OrganizationMembershipAbstractMember>>> {

        // if we have already set the options, no need to get them again
        if (!forceRefresh && !!this.userOptions.length) {
            return of(this.userOptions)
        }

        return this.users.initializeUser()
            .pipe(
                take(1),
                switchMap(() => this.businesses.getOrganizationMembershipAbstract(this.users.businessSnapshot.id)),
                tap(users => this.usersSubject.next(users.members)),
                map(users => users.members.map(member => this.toOption(member))),
                tap(users => this.userOptionsSubject.next(users)),
            )
    }

    getUserOption(userProfileId: string): SelectOption<OrganizationMembershipAbstractMember> {
        const user: OrganizationMembershipAbstractMember = this.businessUsers.find(profile => userProfileId === profile.profileId) || this.currentUser
        return this.toOption(user)
    }

    hasPermission(userProfileId: string, claim: Claim): boolean {
        const user: OrganizationMembershipAbstractMember = this.userOptions.find(opt => opt.value.profileId === userProfileId)?.value
        return !!user && this.claims.businessUserHasRight(user, claim)
    }

    toOption(user: OrganizationMembershipAbstractMember): SelectOption<OrganizationMembershipAbstractMember> {
        return !user
            ? undefined
            : {
                img: this.profiles.getAvatarUrl(user.avatarUrl),
                label: `${user.firstName} ${user.lastName}`,
                removable: false,
                value: user,
            }
    }
}
