import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of, Subject, throwError } from 'rxjs'
import { catchError, switchMap, take } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { UserService } from '../../core/services'
import { OrganizationTeamConfiguration } from '../models'

// Based on https://github.com/mike-roberts/ngx-plaid-link

export interface PlaidSuccessMetadata {
    link_session_id: string
    institution: PlaidInstitutionObject
    account: Array<PlaidAccountObject>
}

export interface PlaidOnSuccessArgs {
    token: string
    metadata: PlaidSuccessMetadata
}

export interface PlaidInstitutionObject {
    name: string
    institution_id: string
}

export interface PlaidAccountObject {
    id: string
    name: string
    mask: string
    type: string
    subtype: string
}

export interface PlaidErrorObject {
    display_message: string
    error_code: string
    error_message: string
    error_type: string
}

export interface PlaidErrorMetadata {
    link_session_id: string
    institution: PlaidInstitutionObject
    status: string
}

export interface PlaidOnExitArgs {
    error: PlaidErrorObject
    metadata: PlaidErrorMetadata
}

export interface PlaidOnEventArgs {
    eventName: string
    metadata: PlaidEventMetadata
}

export interface PlaidEventMetadata {
    error_code: string
    error_message: string
    error_type: string
    exit_status: string
    institution_id: string
    institution_name: string
    institution_search_query: string
    request_id: string
    link_session_id: string
    mfa_type: string
    view_name: string
    timestamp: string
}

export interface PlaidConfig {
    apiVersion?: string
    clientName?: string
    env?: string
    key?: string
    onLoad?: Function
    onSuccess: Function
    onExit?: Function
    onEvent?: Function
    product?: Array<string>
    token?: string
    webhook?: string
    countryCodes?: string[]
}

declare let Plaid: any

export class PlaidLinkHandler {

    /**
     * Holds the Plaid Link instance.
     */
    private plaidLink: any

    /**
     * Constructor configures the Plaid Link handler with given config options.
     * @param PlaidConfig config
     */
    constructor(config: PlaidConfig) {
        this.plaidLink = Plaid.create(config)
    }

    /**
     * Open the Plaid Link window for this handler.
     * @param string institution The name of the institution to open
     */
    public open(institution?: string): void {
        this.plaidLink.open(institution)
    }

    /**
     * Closes the currently open Plaid Link window if any.
     */
    public exit(): void {
        this.plaidLink.exit()
    }

}

// tslint:disable-next-line: max-classes-per-file
@Injectable({ providedIn: 'root' })
export class PlaidLinkService {

    private loaded: Promise<void>
    // private plaidLinkHandler: PlaidLinkHandler

    constructor(
        private router: Router,
        private userService: UserService) { }

    getPlaidLinkHandler(config: PlaidConfig): Observable<PlaidLinkHandler> {

        // this should error out if they're KYC blocked...
        return this.userService.initializeTeamConfig(true, this.userService.businessSnapshot)
            .pipe(
                take(1),
                catchError(err => {
                    // reload page to blank the UI
                    window.location.reload()
                    return throwError(err)
                }),
                switchMap((result: OrganizationTeamConfiguration) => {
                    const subject: Subject<PlaidLinkHandler> = new Subject()
                    this.createPlaid({
                        apiVersion: 'v2',
                        env: environment.plaidSettings.environment,
                        token: null,
                        product: ['auth'],
                        countryCodes: ['US'],
                        clientName: 'Liquid, Inc',
                        key: environment.plaidSettings.publicKey,
                        webhook: environment.plaidSettings.webhookUrl,
                        onExit: (error, other) => {
                            subject.error('Something went wrong, please try again')
                            subject.complete()
                        },
                        onEvent: () => { },
                        ...config,
                    })
                        .then((handler: PlaidLinkHandler) => {
                            subject.next(handler)
                            subject.complete()
                        })

                    return subject
                }))
    }

    /**
     * Create a Plaid Link instance as soon as Plaid Link has loaded.
     * @param PlaidConfig config
     * @returns Promise<PlaidLinkHandler>
     */
    public createPlaid(config: PlaidConfig): Promise<PlaidLinkHandler> {
        return this.loadPlaid()
            .then(() => {
                return new PlaidLinkHandler(config)
            })
    }

    /**
     * Load or wait for the Plaid Link library to load.
     * @returns Promise<void>
     */
    public loadPlaid(): Promise<void> {
        if (!this.loaded) {
            this.loaded = new Promise<void>((resolve, reject) => {
                const script: any = document.createElement('script')
                script.type = 'text/javascript'
                script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
                script.onerror = (e: any) => reject(e)
                if (script.readyState) {
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null
                            resolve()
                        }
                    }
                } else {
                    script.onload = () => {
                        resolve()
                    }
                }
                document.getElementsByTagName('body')[0].appendChild(script)
            })
        }

        return this.loaded
    }

}
