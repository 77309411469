import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { OrganizationTeamInvitation, UserSetup } from '../../modules/models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class UserStore {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly urls: UrlService,
    ) { }

    getUserConfig(): Observable<UserSetup> {
        return this.httpClient.get<UserSetup>(
            this.urls.api.userSetup(),
        )
    }

    getOrganizationTeamInvitationsForUserEmail(
        email: string,
        includeAnsweredInvites: boolean,
        includeInvoiceInvites: boolean,
    ): Observable<OrganizationTeamInvitation[]> {
        return this.httpClient.get<OrganizationTeamInvitation[]>(
            this.urls.api.organizationTeamInvitations({
                email,
                includeInvoiceInvites,
                includeAnsweredInvites,
            }),
        )
    }
}
