export enum CreateOrderSteps {
    orderName = 'order-name',
    orderNameStep = 'name',
    defineAccountingFields = 'order-accounting-fields',
    defineAccountingFieldsStep = 'accounting-fields',
    selectClient = 'select-client',
    selectClientStep = 'clientOrganizationId',
    selectHiringManager = 'select-hiring-manager',
    selectHiringManagerGroup = 'select-hiring-manager-group',
    selectHiringManagerStep = 'hiringManagerId',
    selectVendor = 'select-vendor',
    selectVendorStep = 'workerOrganizationId',
    send = 'order-send',
    sendStep = 'order-send-step',
    servicesAndDeliverables = 'services-and-deliverables',
    servicesAndDeliverablesStep = 'deliverables',
    vendorDescription = 'vendor-description',
    vendorEmail = 'vendor-email',
    vendorEmailMessage = 'vendor-email-message',
    vendorName = 'vendor-name',
    vendorUsage = 'vendor-usage',
    vendorOwnTracking = 'vendor-own-tracking',
    // onboardingProcess = 'onboarding-process',
    // onboardingProcessMasterContractPeriod = 'onboarding-process-master-contract-period',
    // onboardingProcessUploadMasterContract = 'onboarding-process-upload-master-contract',
}
