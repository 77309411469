<mat-dialog-content>
    <div class="spinner-container">
        <p>
            Please Wait...
        </p>
        <p *ngIf="!!message">
            {{ message }}
        </p>
        <div>
            <mat-spinner></mat-spinner>
        </div>
    </div>
</mat-dialog-content>