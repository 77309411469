<div class="container">

    <div class="step-image">
        <img
            [src]="image"
            [alt]="image"
        />
    </div>

    <div
        class="step-content"
        [formGroup]="form"
    >

        <p class="subtitle1 primary100">
            {{title}}
        </p>

        <p *ngFor="let p of paragraphs">
            {{p}}
        </p>

        <div
            *ngIf="!!inputs"
            class="inputs"
        >
            <ng-container *ngFor="let input of inputs">
                <p *ngIf="!!input.description">
                    {{input.description}}
                </p>

                <ng-container [ngSwitch]="input.type">

                    <ng-container *ngSwitchCase="'bool'">

                        <p *ngIf="!!input.label">{{input.label}}</p>

                        <mat-radio-group
                            [formControlName]="input.name"
                            [required]="input.required"
                        >
                            <mat-radio-button
                                color="primary"
                                [value]="true"
                            >
                                Yes
                            </mat-radio-button>
                            <mat-radio-button
                                color="primary"
                                [value]="false"
                            >
                                No
                            </mat-radio-button>
                        </mat-radio-group>

                    </ng-container>

                    <mat-checkbox
                        *ngSwitchCase="'checkbox'"
                        [formControlName]="input.name"
                        color="primary"
                    >
                        {{input.label}}
                    </mat-checkbox>

                    <mat-form-field
                        *ngSwitchCase="'date'"
                        class="full-width"
                    >
                        <!-- TODO: Replace datepicker mat-form-field with in-situ-edit-text-field -->
                        <mat-label>{{input.label}}</mat-label>
                        <input
                            matInput
                            [formControlName]="input.name"
                            [matDatepicker]="picker"
                            [required]="input.required"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field *ngSwitchCase="'multi-select'">
                        <mat-label>{{input.label}}</mat-label>
                        <mat-select
                            [formControlName]="input.name"
                            multiple
                            [required]="input.required"
                        >
                            <mat-option
                                *ngFor="let option of input.options"
                                [value]="option.value"
                            >
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        *ngSwitchCase="'select'"
                        [required]="input.required"
                    >
                        <mat-label>{{input.label}}</mat-label>
                        <mat-select [formControlName]="input.name">
                            <mat-option
                                *ngFor="let option of input.options"
                                [value]="option.value"
                            >
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        *ngSwitchCase="'textarea'"
                        class="full-width"
                    >
                        <mat-label>{{input.label}}</mat-label>
                        <textarea
                            cdkTextareaAutosize
                            matInput
                            [formControlName]="input.name"
                            [placeholder]="input.placeholder"
                            [required]="!!input.required"
                        ></textarea>
                    </mat-form-field>

                    <app-country-form
                        *ngSwitchCase="'country'"
                        [country]="form.controls[input.name]"
                        [required]="!!input.required"
                    ></app-country-form>

                    <mat-form-field
                        *ngSwitchDefault
                        class="full-width"
                    >
                        <mat-label>{{input.label}}</mat-label>
                        <i-feather
                            *ngIf="input.type === 'email'"
                            matPrefix
                            name="mail"
                        ></i-feather>
                        <input
                            matInput
                            [type]="input.type"
                            [formControlName]="input.name"
                            [number]="input.type === 'number'"
                            [placeholder]="input.placeholder"
                            [required]="!!input.required"
                        />
                    </mat-form-field>

                </ng-container>
            </ng-container>
        </div>
    </div>
</div>