import { Injectable } from '@angular/core'

import { FileChangeResult } from '../models'

@Injectable({
    providedIn: 'root',
})
export class FileFormService {

    constructor() { }

    getFormDataForFileData(inputElement: HTMLInputElement, fileData?: Blob): FormData | undefined {
        if (!inputElement.files?.length
            || !fileData) {
            return undefined
        }

        const formData: FormData = new FormData()
        formData.append('file', fileData)
        return formData
    }

    getValidFile(file: File): File {
        // strip away any special characters from file name that may cause problems for upload
        return new File([file], file?.name.replace(/[\/\\+:*?<>]/g, ''))
    }

    async handleFileChange(event: Event): Promise<FileChangeResult> {
        const fileInput: HTMLInputElement = event.target as HTMLInputElement
        // can use this if we need to preview a file...
        const file: File = fileInput.files[0]
        const originalFilename: string = file.name

        const fileData: Blob = await new Promise<Blob>(resolve => {
            const reader: FileReader = new FileReader()
            reader.onloadend = loadEvent => {
                const arrayBuffer: ArrayBuffer = loadEvent.target.result as ArrayBuffer
                const array: Uint8Array = new Uint8Array(arrayBuffer, 0)
                resolve(new Blob([array]))
            }
            reader.readAsArrayBuffer(file)
        })

        return {
            fileData,
            originalFilename,
        }
    }

}
