import { Component, OnDestroy, OnInit } from '@angular/core'

import { environment } from '../environments/environment'

@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {

    constructor() { }

    ngOnInit(): void {
        const env: { host: string } = environment
        if (!window.location.port && env.host !== window.location.origin) {
            window.location.href = window.location.href.replace(window.location.origin, env.host)
        }
    }

    ngOnDestroy(): void {
    }
}
