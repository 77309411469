import { DeliverableRate } from './deliverable-rate.model'
import { DeliverableStatus } from './deliverable-status.model'

export interface UiRate {
    deliverableId?: string
    rate: number
    description: string
}

// TODO: change this to an interface
export class Deliverable {
    completedByOpenId: string
    completedTimestamp: number
    createdTimestamp: number
    description: string
    displayOrder: number = -1
    dueTimestamp?: number
    estimatedCompletionDate?: Date
    finishMilestone?: string
    id: string
    kickoffTimestamp: number
    name: string
    otherNotes: string
    rate: DeliverableRate
    status: DeliverableStatus
    uiAccepted: boolean = true
    uiEditable: boolean = false
    uiHoursResetString?: string
    uiRecurrenceString?: string
    workOrderId: string

    static getDeliverableRateListForAdd(deliverable: Deliverable): Array<UiRate> {

        if (!deliverable?.rate) {
            return []
        }

        if (!deliverable.rate?.rateTiers?.length) {
            return [
                {
                    deliverableId: deliverable.id,
                    description: `(${deliverable.rate.rateType})`,
                    rate: deliverable.rate.rateType !== 'Custom' ? deliverable.rate.rate : 0,
                },
            ]
        }

        return deliverable.rate.rateTiers
            .map(tier => ({
                deliverableId: deliverable.id,
                description: !!tier.minHours ? `after ${tier.minHours} hours` : `/hr`,
                rate: tier.rate,
            }))
    }
}
