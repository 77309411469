import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

import { LIQUID_VALIDATION_ERROR_CODES } from './constants'
import { ValidatorHelper } from './validator-helper.service'

export class LiquidValidators {
    private static validatorHelper: ValidatorHelper = new ValidatorHelper()

    public static swift(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const inputSwiftCode: string = !!control?.value ? `${control.value}` : undefined
            // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
            // If the value is required, it will be caught by a required validator.
            const hasError: boolean = !!inputSwiftCode && !(inputSwiftCode.length === 8 || inputSwiftCode.length === 11)
            if (hasError) {
                this.validatorHelper.addError(control, LIQUID_VALIDATION_ERROR_CODES.SWIFT)
            } else {
                this.validatorHelper.removeError(control, LIQUID_VALIDATION_ERROR_CODES.SWIFT)
            }
            return hasError ? { [LIQUID_VALIDATION_ERROR_CODES.SWIFT]: true } : undefined
        }
    }
}
