import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, timer } from 'rxjs'
import { tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { LiquidAppComponent } from '../../modules/liquid/liquid-app.component'
import {
    BusinessStructureType,
    HelpFeedback,
    Organization,
    OrganizationTeamConfiguration,
    OrganizationTeamInvitation,
    RateType,
    TeamMemberAbstract,
    TeamMembership,
    UserSetup
} from '../../modules/models'
import { SelectOption } from '../models'

import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class LiquidAppService {

    private _lastWorkOrderSort: any
    private _lastWorkOrderStatusFilter: any
    private _lastWorkOrderWorkerFilter: any
    private _lastWorkOrderClientFilter: any
    private teamConfigSubject: BehaviorSubject<OrganizationTeamConfiguration | undefined> = new BehaviorSubject(undefined)

    private readonly LAST_WORK_ORDER_SORT_KEY: string = 'lastWorkOrderSort'
    private readonly LAST_WORK_ORDER_STATUS_FILTER_KEY: string = 'lastWorkOrderFilterList'
    private readonly LAST_WORKER_STATUS_FILTER_KEY: string = 'lastWorkerFilter'
    private readonly LAST_CLIENT_STATUS_FILTER_KEY: string = 'lastClientFilter'

    readonly businessStructureOptions: Array<SelectOption<BusinessStructureType>> = [
        { value: BusinessStructureType.CCorp, label: BusinessStructureType.CCorp },
        { value: BusinessStructureType.SCorp, label: BusinessStructureType.SCorp },
        { value: BusinessStructureType.LLC_C, label: 'LLC taxed as a C-Corporation' },
        { value: BusinessStructureType.LLC_S, label: 'LLC taxed as an S-Corporation' },
        { value: BusinessStructureType.LLC_P, label: 'LLC taxed as a Partnership' },
        { value: BusinessStructureType.Sole, label: BusinessStructureType.Sole },
        { value: BusinessStructureType.LLC_SM, label: BusinessStructureType.LLC_SM },
        { value: BusinessStructureType.Partnership, label: BusinessStructureType.Partnership },
        { value: BusinessStructureType.Trust, label: BusinessStructureType.Trust },
        { value: BusinessStructureType.Other, label: BusinessStructureType.Other },
    ]

    readonly industries: string[] = [
        'Energy & Minerals',
        'Financial Services',
        'Government & Public Sector',
        'Healthcare, Pharma & Biotech',
        'Industrials & Manufacturing',
        'Innovation & New Ventures',
        'Media & Entertainment',
        'Nonprofits',
        'Private Equity',
        'Real Estate',
        'Retail & Consumer',
        'Technology &  Telecommunications',
        'Other',
    ]

    readonly prohibtedIndustries: Array<string> = [
        'Investment & credit services',
        'Money and legal services',
        'Virtual currency or stored value',
        'Intellectual property or property rights infringement',
        'Counterfeit or unauthorized goods',
        'Gambling',
        'Regulated or illegal products or services (excluding childcare)',
        'Get rich quick schemes',
        'Mugshot publications or pay-to-remove sites',
        'No-value-add services',
        'Aggregation',
        'Drug paraphernalia',
        'High risk businesses',
        'Multi-level marketing',
        'Social media activity',
        'Substances designed to mimic illegal drugs',
        'Video game or virtual world credits',
        'Use of services inconsistent with its intended use or as expressly prohibited in the service agreement',
    ]

    readonly employeeCountChoices: string[] = [
        '0 to 4',
        '5 to 9',
        '10 to 19',
        '20 to 49',
        '50 to 99',
        '100 to 249',
        '250 to 499',
        '500 to 999',
        '1000 or more',
    ]

    readonly contractorCountChoices: string[] = [
        '0 to 4',
        '5 to 9',
        '10 to 19',
        '20 to 49',
        '50 to 99',
        '100 to 249',
        '250 to 499',
        '500 to 999',
        '1000 or more',
    ]

    readonly paymentTermsOptions: Array<SelectOption<number>> = [
        {
            label: 'Due Upon Receipt',
            value: 0,
        },
        {
            label: 'Net 7',
            value: 7,
        },
        {
            label: 'Net 14',
            value: 14,
        },
        {
            label: 'Net 30',
            value: 30,
        },
        {
            label: 'Net 45',
            value: 45,
        },
    ]

    readonly paymentTermsDefaultOption: SelectOption<number> = this.paymentTermsOptions.find(opt => opt.value === 30)

    // credit: https://github.com/arthurvr/image-extensions
    readonly supportedImageExtensions: Array<string> = [
        '3dv',
        'ai',
        'amf',
        'art',
        'art',
        'ase',
        'awg',
        'blp',
        'bmp',
        'bw',
        'bw',
        'cd5',
        'cdr',
        'cgm',
        'cit',
        'cmx',
        'cpt',
        'cr2',
        'cur',
        'cut',
        'dds',
        'dib',
        'djvu',
        'dxf',
        'e2d',
        'ecw',
        'egt',
        'egt',
        'emf',
        'eps',
        'exif',
        'fs',
        'gbr',
        'gif',
        'gpl',
        'grf',
        'hdp',
        'icns',
        'ico',
        'iff',
        'iff',
        'int',
        'int',
        'inta',
        'jfif',
        'jng',
        'jp2',
        'jpeg',
        'jpg',
        'jps',
        'jxr',
        'lbm',
        'lbm',
        'liff',
        'max',
        'miff',
        'mng',
        'msp',
        'nitf',
        'nrrd',
        'odg',
        'ota',
        'pam',
        'pbm',
        'pc1',
        'pc2',
        'pc3',
        'pcf',
        'pct',
        'pcx',
        'pcx',
        'pdd',
        'pdn',
        'pgf',
        'pgm',
        'pi1',
        'pi2',
        'pi3',
        'pict',
        'png',
        'pnm',
        'pns',
        'ppm',
        'psb',
        'psd',
        'psp',
        'px',
        'pxm',
        'pxr',
        'qfx',
        'ras',
        'raw',
        'rgb',
        'rgb',
        'rgba',
        'rle',
        'sct',
        'sgi',
        'sgi',
        'sid',
        'stl',
        'sun',
        'svg',
        'sxd',
        'tga',
        'tga',
        'tif',
        'tiff',
        'v2d',
        'vnd',
        'vrml',
        'vtf',
        'wdp',
        'webp',
        'wmf',
        'x3d',
        'xar',
        'xbm',
        'xcf',
        'xpm',
    ]

    readonly supportedPreviewImageExtensions: Array<string> = [
        'bmp',
        'gif',
        'jng',
        'jpeg',
        'jpg',
        'rgb',
        'svg',
        'png',
        'webp',
        'rgb',
        'rgba',
    ]

    readonly supportedWorkOrderFileExtensions: Array<string> = [
        'doc',
        'gif',
        'jpg',
        'mov',
        'mp3',
        'mp4',
        'mpg',
        'pdf',
        'png',
        'ppt',
        'wav',
        'wmv',
        'xls',
    ]

    readonly invoiceRateUnitOptions: Array<SelectOption<RateType>> = [
        {
            label: 'Hour(s)',
            value: RateType.Hourly,
        },
        {
            label: 'Day(s)',
            value: RateType.Daily,
        },
        {
            label: 'Week(s)',
            value: RateType.Weekly,
        },
        {
            label: '1th and 15th',
            value: RateType.SemiMonthly,
        },
        {
            label: 'Month(s)',
            value: RateType.Monthly,
        },
        {
            label: 'Quarter(s)',
            value: RateType.Quarterly,
        },
        {
            label: RateType.Flat,
            value: RateType.Flat,
        },
    ]

    userSetup?: UserSetup
    confirmedTeamInvitation: OrganizationTeamInvitation
    confirmedOrgMemberInvitation: boolean
    expandSideNav: boolean
    notifyOnNextOrganizationChange: boolean
    teamConfig: Observable<OrganizationTeamConfiguration | undefined> = this.teamConfigSubject.asObservable()

    REGISTERED_LIQUID_APP: LiquidAppComponent

    get selectedOrganization(): Organization | undefined {
        return !!this.userSetup ? this.userSetup.currentOrganization : undefined
    }

    get lastWorkOrderSort(): any {
        return this._lastWorkOrderSort
    }

    set lastWorkOrderSort(sort: any) {
        this._lastWorkOrderSort = sort
        if (sort) {
            localStorage.setItem(this.LAST_WORK_ORDER_SORT_KEY, sort)
        }
    }

    get lastWorkOrderStatusFilter(): any {
        return this._lastWorkOrderStatusFilter
    }

    set lastWorkOrderStatusFilter(filter: any) {
        this._lastWorkOrderStatusFilter = filter
        if (filter) {
            localStorage.setItem(this.LAST_WORK_ORDER_STATUS_FILTER_KEY, filter)
        }
    }

    get lastWorkOrderWorkerFilter(): any {
        return this._lastWorkOrderWorkerFilter
    }

    set lastWorkOrderWorkerFilter(filter: any) {
        this._lastWorkOrderWorkerFilter = filter
        if (filter) {
            localStorage.setItem(this.LAST_WORKER_STATUS_FILTER_KEY, filter)
        }
    }

    get lastWorkOrderClientFilter(): any {
        return this._lastWorkOrderClientFilter
    }

    set lastWorkOrderClientFilter(filter: any) {
        this._lastWorkOrderClientFilter = filter
        if (filter) {
            localStorage.setItem(this.LAST_CLIENT_STATUS_FILTER_KEY, filter)
        }
    }

    constructor(
        private http: HttpClient,
        private users: UserService,
    ) {
        // keep this subscription around for the life of the app
        this.users.user$
            .pipe(
                tap(user => this.userSetup = user),
            )
            .subscribe()
    }

    getClientsTeamSnapshot(): TeamMembership[] {
        return this.users.teamConfigSnapshot?.organizationMembershipConfiguration?.teamMemberships ?? []
    }

    getVendorsTeamSnapshot(): TeamMemberAbstract[] {
        return this.users.teamConfigSnapshot?.teamMembershipAbstract.teams
            .reduce((agg, team) => [
                ...agg,
                ...team?.members,
            ], []) ?? []
    }

    getOneYearFromDate(startValue: Date): Date {
        // null check, default to current date
        startValue = startValue || new Date()
        const oneYearFromDate: Date = new Date(startValue)
        oneYearFromDate.setFullYear(startValue.getFullYear() + 1)
        oneYearFromDate.setDate(startValue.getDate() - 1)
        return oneYearFromDate
    }

    getPaymentTermsOption(terms: number): SelectOption<number> {
        return this.paymentTermsOptions.find(opt => Number.isInteger(terms) && opt.value === terms) || this.paymentTermsDefaultOption
    }

    isCorporation(structure: BusinessStructureType): boolean {
        return [
            BusinessStructureType.CCorp,
            BusinessStructureType.SCorp,
        ]
            .some(type => type === structure)
    }

    isPersonal(structure: BusinessStructureType): boolean {
        return [
            BusinessStructureType.Sole,
            BusinessStructureType.LLC_SM,
        ]
            .some(type => type === structure)
    }

    isLlc(structure: BusinessStructureType): boolean {
        return [
            BusinessStructureType.LLC_C,
            BusinessStructureType.LLC_P,
            BusinessStructureType.LLC_S,
            BusinessStructureType.LLC_SM,
        ]
            .some(type => type === structure)
    }

    isOther(structure: BusinessStructureType): boolean {
        return [
            BusinessStructureType.Other,
        ]
            .some(type => type === structure)
    }

    sendHelpFeedback(rating: number, feedbackFor: string): Observable<HelpFeedback> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/public/sites/feedback`
        const request: HelpFeedback = {
            feedbackFor,
            feedbackType: 'help-sidebar',
            organizationId: this.userSetup?.currentOrganization?.id,
            rating,
            submittedBy: this.userSetup?.profileId,
        }
        return this.http.post<HelpFeedback>(url, request)
    }

    sendEmail(email: string, subject?: string): void {
        const windowRef: Window = window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`)
        // close the window
        timer(20)
            .pipe(
                tap(() => windowRef.close()),
            )
            .subscribe()
    }
}
