import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { UrlService } from '../../core/services'
import { ClientInvoice, InvoiceUpdatePropertiesRequest } from '../models'

@Injectable({
    providedIn: 'root',
})
export class ClientInvoiceStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    getHasInvoices(organizationId: string): Observable<boolean> {
        return this.http.get<boolean>(this.urls.api.hasInvoices(organizationId))
    }

    getHasOutstandingInvoiceApprovals(clientId: string, vendorId: string): Observable<boolean> {
        return this.http.get<boolean>(this.urls.api.vendorHasOutstandingApprovals(clientId, vendorId))
    }

    updateInvoiceProperties(request: InvoiceUpdatePropertiesRequest): Observable<ClientInvoice> {
        return this.http.patch<ClientInvoice>(this.urls.api.invoices(), request)
    }
}
