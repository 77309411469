import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { StatusCodes } from 'http-status-codes'
import { Observable, of } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { LoggingService } from '../../auth/services'
import { Bank, ForeignUSBankAccountRequest, OtherBankAccountProperty, OtherBankAccountRequest } from '../../country-bank-account-config'
import { DialogService } from '../../dialogs'
import {
    BankAccountDetails,
    IntlFeedback,
    MicrodepositVerifcationDetails,
    Payout,
    PayoutAccount,
} from '../../modules/models'
import { PaymentAccount, PaymentAccountSupertype, PaymentAccountType } from '../models'

import { PaymentAccountStore } from './payment-account.store'
import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class PaymentAccountService {

    constructor(
        private dialogs: DialogService,
        private store: PaymentAccountStore,
        private router: Router,
        private urls: UrlService,
        private users: UserService,
    ) { }

    createBankAccount(token: string, accountType: PaymentAccountType): Observable<PaymentAccount> {
        return this.store.createPaymentAccount(this.users.businessSnapshot.id, token, accountType)
            .pipe(
                take(1),
            )
    }

    createCreditCard(uid: string, doNotSetForFees?: boolean): Observable<PaymentAccount> {
        return this.store.createCreditCard(this.users.businessSnapshot.id, uid, doNotSetForFees)
            .pipe(
                take(1),
            )
    }

    createForeignVendorUSABankAccount(request: ForeignUSBankAccountRequest): Observable<PaymentAccount> {
        request.organizationId = this.users.businessSnapshot.id
        return this.store.createForeignVendorUSBankAccount(request)
            .pipe(
                take(1),
            )
    }

    createLinked(publicToken: string, accountId: string, accounts: Array<any>): Observable<PaymentAccount> {
        return this.store.createBankAccountFromLink(this.users.businessSnapshot.id, publicToken, accountId, accounts)
            .pipe(
                take(1),
            )
    }

    createOtherBankAccount(request: OtherBankAccountRequest): Observable<PaymentAccount> {
        return this.store.createOtherBankAccount(request)
            .pipe(
                take(1),
            )
    }

    createPayout(payoutProviderAccountId: string, authorizationCode: string): Observable<PayoutAccount> {
        return this.store.createPayoutAccount(this.users.businessSnapshot.id, payoutProviderAccountId, authorizationCode)
            .pipe(
                take(1),
            )
    }

    delete(accountId: string): Observable<boolean> {
        return this.store.deletePaymentAccount(accountId)
            .pipe(
                take(1),
            )
    }

    get(businessId?: string): Observable<Array<PaymentAccount>> {
        return this.store.getPaymentAccounts(businessId || this.users.businessSnapshot.id)
            .pipe(
                take(1),
            )
    }

    getBank(swiftCode: string): Observable<Bank> {
        return this.store.getBank(swiftCode)
            .pipe(
                take(1),
            )
    }

    getBySuperType(businessId?: string): Observable<Array<Array<PaymentAccount>>> {
        return this.get(businessId)
            .pipe(
                map(accounts => ([
                    accounts.filter(acct => acct.supertype === PaymentAccountSupertype.CC),
                    accounts.filter(acct => acct.supertype === PaymentAccountSupertype.BankAccount),
                ])),
            )
    }

    getHostedPaymentUid(stylesheet?: string): Observable<{ uid: string }> {
        if (!stylesheet) {
            // get the current stylesheet
            const linkCollection: HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName('link')
            const cssMatch: RegExp = /styles\.[0-9a-z]*\.?css$/
            for (let i: number = 0; i < linkCollection.length; i++) {
                const linkEl: HTMLLinkElement = linkCollection[i]
                if (linkEl.rel === 'stylesheet' && linkEl.href.match(cssMatch)) {
                    stylesheet = linkEl.href
                    break
                }
            }
        }

        return this.store.getHostedPaymentId(this.users.businessSnapshot.id, stylesheet)
            .pipe(
                take(1),
            )
    }

    getInternationalFeedback(organizationId: string): Observable<IntlFeedback> {
        return this.store.getInternationalFeedback(organizationId)
            .pipe(
                take(1),
                catchError((error: HttpErrorResponse) => {
                    if (error && error.status === StatusCodes.CONFLICT) {
                        LoggingService.notify(error)
                    }

                    return of(undefined)
                }),
            )
    }

    getMicrodepositVerificationTokens(paymentAccountId: string): Observable<{ token: string }> {
        return this.store.getMicrodepositVerificationTokens(paymentAccountId)
            .pipe(
                take(1),
            )
    }

    getOtherBankAccountProps(paymentAccountId: string): Observable<Array<OtherBankAccountProperty>> {
        return this.store.getOtherBankAccountProps(paymentAccountId)
            .pipe(
                take(1),
            )
    }

    getPayout(): Observable<PayoutAccount> {
        return this.store.getPayoutAccount(this.users.businessSnapshot.id)
            .pipe(
                take(1),
            )
    }

    getPayoutProviderLink(): Observable<{ created: number, url: string; object: string }> {
        return this.store.getPayoutProviderLink(this.users.businessSnapshot.id).pipe(
            take(1),
        )
    }

    getPayouts(): Observable<Payout[]> {
        return this.store.getPayouts(this.users.businessSnapshot.id)
            .pipe(
                take(1),
            )
    }

    hasPayout(businessId: string): Observable<boolean> {
        return !!businessId
            ? this.store.hasPayoutAccount(businessId)
                .pipe(
                    map(ach => !!ach),
                )
            : of(false)
    }

    internationalFeedback(feedback: IntlFeedback): Observable<IntlFeedback> {
        return this.store.internationalFeedback(feedback)
            .pipe(
                take(1),
            )
    }

    promptForPaymentMethod(): Observable<boolean> {
        return this.dialogs.inform('You do not have a verified Payment Method to pay your Liquid Subscription. Please update your Payment Methods.', 'Action Required', 'Go', true)
            .pipe(
                tap(() => this.router.navigateByUrl(this.urls.route.settingsPaymentMethods())),
                map(() => true),
            )
    }

    setPayoutAccount(accountId: string): Observable<PayoutAccount> {
        return this.store.setPayoutAccount(accountId)
            .pipe(
                take(1),
            )
    }

    setDetails(details: BankAccountDetails): Observable<PaymentAccount> {
        return this.store.updatePayoutAccountDetails(details)
            .pipe(
                take(1),
            )
    }

    setVerificationDetails(details: MicrodepositVerifcationDetails): Observable<PaymentAccount> {
        return this.store.updatePayoutAccountVerificationDetails(details)
            .pipe(
                take(1),
            )
    }

    setMicrodepositComplete(accountId: string): Observable<PaymentAccount> {
        return this.store.setMicrodepositComplete(accountId)
            .pipe(
                take(1),
            )
    }

    update(accountId: string, isDefault: boolean, isForSubscription: boolean): Observable<PaymentAccount> {
        return this.store.updatePaymentAccount(accountId, isDefault, isForSubscription)
            .pipe(
                take(1),
            )
    }

    updateOtherBankAccount(request: OtherBankAccountRequest): Observable<PaymentAccount> {
        return this.store.updateOtherBankAccount(request)
            .pipe(
                take(1),
            )
    }

    updatePayout(payoutProviderAccountId: string, authorizationCode: string): Observable<PayoutAccount> {
        return this.store.updatePayoutAccount(this.users.businessSnapshot.id, payoutProviderAccountId, authorizationCode)
            .pipe(
                take(1),
            )
    }

    verify(accountId: string, amounts: Array<number>): Observable<PaymentAccount> {
        return this.store.verifyBankAccount(accountId, amounts)
            .pipe(
                take(1),
            )
    }
}
