import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function UrlValidator(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | undefined => {

        // Checks if it starts with http or https and is followed by ://www. and
        // then any character sequence then . and then any character sequence
        const regex: RegExp = /^((http|https):\/\/(www.)?)+[a-zA-Z0-9_-]+(\.[a-zA-Z]+)/gm

        // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
        // If the value is required, it will be caught by a required validator.
        const url: string = !!control?.value ? `${control.value}` : undefined
        return !!url && !regex.test(url)
            ? { url: true }
            : undefined
    }
}
