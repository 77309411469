<div class="autocomplete-container">
    <mat-form-field>
        <mat-label>Select {{enableMultipleSelection ? 'Countries' : 'Country' }}</mat-label>
        <input
            type="text"
            matInput
            [formControl]="country"
            [matAutocomplete]="auto"
        >
        <!-- TODO: convert this to use the app-autocomplete component -->
        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onSelectOption($event)"
            [displayWith]="displayText"
        >
            <mat-option
                *ngFor="let option of countries"
                [value]="option"
            >
                {{option.label}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<app-chip-list
    *ngIf="!!countriesSelected.length"
    [options]="countriesSelected"
    (removeOption)="removeCountry($event)"
></app-chip-list>