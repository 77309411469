import { InvoiceLineItem } from '.'
import { RateType } from './rate-type.enum'
import { TimeTrackingEntry } from './time-tracking-entry.interface'

export class InvoiceAdjustment implements InvoiceLineItem {

    associatedDeliverableId?: string
    createdTimestamp?: number
    description?: string
    details?: string
    id?: string
    isTaxAdjustment?: boolean
    order: number
    parentInvoiceId?: string
    qty?: number = 1
    rateType?: RateType = RateType.Flat
    readonly isAdjustment?: boolean = true
    taxable?: boolean
    uiAssociatedTimeTrackingEntries?: TimeTrackingEntry[]
    unitPrice?: number
    unitPriceInPayoutCurrency?: number
    uom?: RateType = RateType.Flat
    uomOverride?: string
}
