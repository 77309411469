export enum DefaultLabel {
    AccountHolderName = 'Account Holder Name',
    AccountHolderAddress = 'Account Holder Address',
    BankAccountNumber = 'Bank Account Number',
    BankBranchAddress = 'Bank Branch Address',
    BankInformation = 'Bank Information',
    BankName = 'Bank Name',
    Iban = 'IBAN',
    SwiftCode = 'SWIFT Code',
}
