import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

// NOTE: these must be imported individually b/c this service
// is shared w/the shared work order
import { BusinessServicePlan } from '../../modules/models/business-service-plan.interface'
import { Organization } from '../../modules/models/organization.model'
import { ServicePlan } from '../../modules/models/service-plan.interface'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class ServicePlanStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    cancel(organizationId: string, cancellationReason: string): Observable<Organization> {
        const request: { OrganizationId: string, CancellationReason: string } = {
            OrganizationId: organizationId,
            CancellationReason: cancellationReason,
        }
        return this.http.post<Organization>(this.urls.api.servicePlanCancel(), request)
    }

    get(myOrgId?: string, replaceLowestPlanWithThisPlanId?: string): Observable<Array<ServicePlan>> {
        let planParams: HttpParams = new HttpParams()
        if (!!replaceLowestPlanWithThisPlanId) {
            planParams = planParams.set('plan', replaceLowestPlanWithThisPlanId)
        }
        if (!!myOrgId) {
            planParams = planParams.set('o', myOrgId)
        }

        return this.http.get<Array<ServicePlan>>(this.urls.api.servicePlanGet(), { params: planParams })
    }

    update(businessId: string, liquidServicePlanId: string): Observable<BusinessServicePlan> {
        const request: { organizationId: string, liquidServicePlanId: string } = {
            organizationId: businessId,
            liquidServicePlanId,
        }
        return this.http.post<BusinessServicePlan>(this.urls.api.servicePlanUpdate(), request)
    }
}
