<app-dialog-template-small
    (close)="cancel()"
    title="Enter the deposits you've received"
>
    <div class="dialog-content">
        <app-payment-account-microdeposits-form
            [paymentAccount]="account"
            [isVendor]="isVendor"
            (verificationCompleted)="verificationCompleted($event)"
            (error)="verificationError($event)"
        ></app-payment-account-microdeposits-form>
    </div>
</app-dialog-template-small>