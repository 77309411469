import { CurrencyPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'moneyMaskPipe',
})

export class MoneyMaskPipe extends CurrencyPipe implements PipeTransform {

    public transform(val: string | number, currencyCode: string = 'USD', display: string | boolean = 'symbol', digitsInfo: string = '1.2-2', locale?: string): any {

        // convert the value to a string and remove all non-numbers characters except dots from it
        const value: string = String(val)?.replace(/[^0-9.]/g, '')

        // if the value is null, undefined, or an empty string, return an empty string;
        // otherwise, convert to a number and transform back to money
        return [null, undefined, ''].includes(value) // can't test for falsey b/c that would filter out display zeros
            ? ''
            : super.transform(Number.parseFloat(value), currencyCode, display, digitsInfo, locale)
    }

    public transformCurrency(val: string | number): any {

        // convert the value to a string and remove all non-numbers characters except dots from it
        const value: string = String(val)?.replace(/[^0-9.]/g, '')
        const negative: boolean = Number(val) < 0

        // if the value is null, undefined, or an empty string, return an empty string;
        // otherwise, convert to a number and transform back to money
        return [null, undefined, ''].includes(value) // can't test for falsey b/c that would filter out display zeros
            ? ''
            : (negative ? '-' : '') + Number(parseFloat(value).toFixed(2)).toLocaleString('en', {
                minimumFractionDigits: 2,
            })
    }
}
