import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'

import { UserService } from '../../../../../core/services'
import { UserSetup } from '../../../../models'
import { WorkflowService } from '../services'

import { FormStepDataInput } from './form-step-data-input.interface'

@Component({
    selector: 'app-form-step',
    templateUrl: './form-step.component.html',
    styleUrls: ['./form-step.component.scss'],
})
export class FormStepComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    readonly form: FormGroup = this.workflow.form
    image: string
    inputs: Array<FormStepDataInput>
    next: () => void
    paragraphs: Array<string>
    stepId: string
    title: string

    constructor(
        private users: UserService,
        private workflow: WorkflowService,
    ) { }

    ngOnInit(): void {
        const { data }: any = this.workflow.activeStep

        this.stepId = this.workflow.activeStep.id
        this.image = data.image || '/assets/img/svg/pen.svg'
        this.inputs = (data.inputs || []).map(input => ({
            ...input,
            label: this.formatAttr(input.label),
        }))
        this.paragraphs = data.paragraphs || []
        this.title = this.formatAttr(data.title)

        if (!!data?.buttonLabel) {
            this.workflow.buttonLabel = data.buttonLabel
        }

        this.workflow.setFormGroup()

        if (!!this.workflow.genericStepFunction$) {
            this.workflow.next
                .pipe(
                    takeUntil(this.unsubscribe$),
                    switchMap(() => this.workflow.genericStepFunction$()),
                )
                .subscribe()
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    private formatAttr(attr: string | ((user: UserSetup) => string)): string {
        if (!attr) {
            return undefined
        }
        return typeof attr === 'string' ? attr : attr(this.users.userSnapshot)
    }
}
