import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'
import { take, tap } from 'rxjs/operators'

import { Address, AddressFormComponent, AddressService } from '../../address'
import { PaymentAccount, SelectOption } from '../../core/models'
import { UserAddressService, UserService } from '../../core/services'
import { BankAccountDetails, BankAccountType, CreateOrUpdateLiquidBankAccount } from '../../modules/models'

@Component({
    selector: 'app-payment-account-holder-info',
    templateUrl: './payment-account-holder-info.component.html',
    styleUrls: ['./payment-account-holder-info.component.scss'],
})
export class PaymentAccountHolderInfoComponent implements OnInit {

    private _showAccountTypesSelect: boolean = false
    private get liquidBankAccount(): CreateOrUpdateLiquidBankAccount { return this.liquidBankAccount$.getValue() }

    @Input() account: PaymentAccount
    @Input() form: FormGroup
    @Input() liquidBankAccount$: BehaviorSubject<CreateOrUpdateLiquidBankAccount> = new BehaviorSubject(undefined)
    @Input() set showAccountTypesSelect(value: boolean) {
        this._showAccountTypesSelect = value

        if (value && this.accountType) {
            this.form.addControl('accountType', this.accountType)
        } else {
            this.form.removeControl('accountType')
        }
    }

    @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent

    readonly accountTypes: SelectOption<BankAccountType>[] = [
        {
            label: 'Business Account',
            value: BankAccountType.COMPANY_ACCOUNT,
        },
        {
            label: 'Personal Account',
            value: BankAccountType.INDIVIDUAL_ACCOUNT,
        },
    ]

    accountType: FormControl
    addressForm: FormGroup = new FormGroup({})
    confirmClose: boolean
    holderName: FormControl
    isVendor: boolean
    get showAccountTypesSelect(): boolean {
        return this._showAccountTypesSelect
    }

    readonly address$: BehaviorSubject<Address> = new BehaviorSubject(undefined)

    get accountHolderInfo(): BankAccountDetails { return this.getHolderInfo() }

    constructor(
        private addresses: AddressService,
        private userAddresses: UserAddressService,
        private users: UserService,
    ) { }

    ngOnInit(): void {

        this.accountType = new FormControl(this.account.accountHolderType, Validators.required)
        this.holderName = new FormControl(this.account.accountHolderName || this.users.businessSnapshot?.name, Validators.required)

        if (this._showAccountTypesSelect) {
            this.form.addControl('accountType', this.accountType)
        }

        this.form.addControl('holderName', this.holderName)
        this.form.addControl('addressForm', this.addressForm)

        this.userAddresses.initialize()
            .pipe(
                take(1),
                tap(currentAddress => {
                    const address: Address = this.account.address || currentAddress
                    this.address$.next({
                        ...address,
                        country: address.country === 'US' ? this.addresses.usa : address.country,
                    })
                }),
            )
            .subscribe()
    }

    getHolderInfo(): BankAccountDetails {
        const address: Address = this.addressFormComponent.address
        const isPersonalAccount: boolean = this.showAccountTypesSelect
            ? (this.accountType.value === BankAccountType.INDIVIDUAL_ACCOUNT)
            : (this.account.accountHolderType === BankAccountType.INDIVIDUAL_ACCOUNT)

        const result: BankAccountDetails = {
            accountHolderName: this.holderName.value,
            city: address.city,
            // FIXME AddressFormComponent uses country names instead of country codes
            countryCode2: 'US',
            isPersonalAccount,
            postalCode: address.postalCode,
            paymentAccountId: this.account.id,
            region: address.state,
            street1: address.street1,
            street2: address.street2,
        }

        if (!!this.liquidBankAccount) {
            result.createOrUpdateLiquidAccount = this.liquidBankAccount
        }

        return result
    }
}
