export enum ClientInvoiceStatus {
    DRAFT = 'Draft',
    HOLDING = 'Holding',
    IN_APPROVALS = 'In Approvals',
    IN_APPROVALS_WITH_ME = 'In approvals with me',
    IN_APPROVALS_WITH_OTHERS = 'In approvals with others',
    MANUAL_CHARGE = 'Paid Outside of Liquid',
    NEW = 'New',
    PAYMENT_COMPLETE = 'Payment Complete',
    PAYMENT_FAILURE = 'Payment Failure',
    PAYMENT_INCOMING = 'Payment Incoming',
    PAYMENT_INITIATED = 'Payment Initiated',
    PAYMENT_SCHEDULED = 'Payment Scheduled',
    PENDING_INFORMATION = 'Pending Information',
    PROCESSOR_REVIEW = 'Processor Review',
    READY_TO_PAY = 'Ready to Pay',
    RECEIVED = 'Received',
    REJECTED = 'Rejected',
    REJECTED_BY_VENDOR = 'Rejected by Vendor',
    SENT = 'Sent',
}
