import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { StorageKeyAuth } from '../../auth/models'
import { StorageKey } from '../../common/global'
import { DashboardAction } from '../../dashboard/models'
import { DialogService, RadioInputComponent } from '../../dialogs'
import { ClientInvoice, ExpressClientAction, ServicePlan } from '../../modules/models'
import { ExpressBusinessSetupResult, InvitationClientAccept, PasswordlessLoginRequest, RedirectAction } from '../models'

import { AfterActionRedirectService } from './after-action-redirect.service'
import { OrganizationService } from './organization.service'
import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessExpressService {

    get emailId(): string {
        return localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_EMAIL_ID)
    }

    get invitationId(): string {
        return localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_INVITATION_ID)
    }

    constructor(
        private readonly afterActionRedirect: AfterActionRedirectService,
        private readonly dialogs: DialogService,
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly organizations: OrganizationService,
        private readonly urls: UrlService,
        private readonly users: UserService,
    ) { }

    acceptExpressInvite(invitationClientAccept: InvitationClientAccept): Observable<ClientInvoice> {
        return this.http
            .post<ClientInvoice>(
                this.urls.api.acceptExpressClientInvite(),
                invitationClientAccept,
            )
    }

    getPayAsYouGoPlan(): Observable<ServicePlan> {
        return this.http
            .get<ServicePlan>(
                this.urls.api.servicePlanPayAsYouGo(),
            )
    }

    finishBusinessSetup(setup: ExpressBusinessSetupResult, upsell?: boolean): void {
        if (!!setup.invoiceId && !upsell) {
            this.handlePayNowOption(setup)
            return
        }

        this.clearLocalStorage()
        localStorage.setItem(StorageKey.expressBusinessSetupResult, JSON.stringify(setup))
        this.router.navigateByUrl(this.urls.route.home(DashboardAction.EXPRESS_CLIENT_CHOOSE_ACTION))
    }

    sendMagicLink(req: PasswordlessLoginRequest): Observable<void> {
        return this.http
            .post<void>(
                this.urls.api.sendPasswordlessMagicLink(),
                req,
            )
    }

    sendLoginCode(req: PasswordlessLoginRequest): Observable<void> {
        return this.http
            .post<void>(
                this.urls.api.sendPasswordlessCode(),
                req,
            )
    }

    showChooseActionDialog(): Observable<void> {
        return this.dialogs.open(
            RadioInputComponent,
            {
                data: {
                    description: 'Do you want to just pay this invoice now, or do you want to first onboard this vendor with your contracts, and then pay the invoice after they complete onboarding?',
                    options: [
                        {
                            label: 'Pay invoice now',
                            value: ExpressClientAction.PayNow,
                        },
                        {
                            label: 'Onboard vendor now',
                            value: ExpressClientAction.StartOnboardingProcess,
                        },
                    ],
                    icon: 'assets/img/icons/money-hand.svg',
                    hideCloseButton: true,
                    value: ExpressClientAction.PayNow,
                    okButtonText: 'Next',
                    title: 'Pay Now or Later',
                    titleInline: true,
                },
                disableClose: true,
            },
        )
            .afterClosed()
            .pipe(
                tap(action => {
                    const setup: ExpressBusinessSetupResult = JSON.parse(localStorage.getItem(StorageKey.expressBusinessSetupResult))
                    const invitationId: string = this.invitationId

                    this.clearLocalStorage()

                    switch (action) {
                        case ExpressClientAction.StartOnboardingProcess:
                            this.handleStartOnboardingProcessOption(invitationId, setup)
                            return
                        case ExpressClientAction.PayNow:
                            this.handlePayNowOption(setup)
                            return
                        default:
                            return
                    }
                }),
                map(() => undefined),
            )
    }

    private clearLocalStorage(): void {
        localStorage.removeItem(StorageKey.expressBusinessSetupResult)
        localStorage.removeItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT)
        localStorage.removeItem(StorageKeyAuth.CREATE_ACCOUNT_EMAIL_ID)
        localStorage.removeItem(StorageKeyAuth.CREATE_ACCOUNT_INVITATION_ID)
    }

    private handlePayNowOption(setup: ExpressBusinessSetupResult): void {
        this.router.navigateByUrl(
            this.urls.route.invoiceGuest(
                setup.invoiceId,
                this.users.businessSnapshot.id,
            ),
        )
    }

    private handleStartOnboardingProcessOption(invitationId: string, setup: ExpressBusinessSetupResult): void {
        this.organizations
            .getOrganizationTeamInvitation(invitationId)
            .pipe(
                take(1),
                tap(invitation => {
                    this.afterActionRedirect.addAction(
                        RedirectAction.VendorInvite,
                        RedirectAction.InvoiceDetail,
                        {
                            invoiceId: setup.invoiceId,
                            expressPay: setup.newBusiness,
                        },
                    )

                    this.router.navigateByUrl(
                        this.urls.route.vendors(true, invitation.organizationId),
                    )
                }),
            )
            .subscribe()
    }
}
