import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelect } from '@angular/material/select'
import { MatTooltip } from '@angular/material/tooltip'
import { Subject } from 'rxjs'
import { filter, takeUntil, tap } from 'rxjs/operators'

import { SelectOption } from '../../../../../core/models'
import { Helpers } from '../helpers.class'

@Component({
    selector: 'app-mad-lib-select',
    templateUrl: './mad-lib-select.component.html',
    styleUrls: ['./mad-lib-select.component.scss', '../shared.scss'],
})
export class MadLibSelectComponent<T> implements AfterViewInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @Output() changed: EventEmitter<void> = new EventEmitter()

    @Input() autofocus: boolean = false
    @Input() control: FormControl
    @Input() editable: boolean
    @Input() errorMessage?: string
    @Input() options?: Array<SelectOption<T>> = []
    @Input() saved: boolean = false

    @ViewChild('select') select: MatSelect
    @ViewChild('tooltip') tooltip: MatTooltip
    @ViewChild('trigger') trigger: ElementRef<HTMLSpanElement>

    touched: boolean = false

    get displayText(): string { return this.getDisplayText() }

    ngAfterViewInit(): void {
        this.select?._closedStream
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.touched = true),
                tap(() => this.changed.emit()),
                filter(() => !!this.control.errors && !!this.errorMessage),
                tap(() => this.tooltip?.show()),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    private getDisplayText(): string {
        const selected: SelectOption<T> = this.control.value === undefined ? undefined : this.options.find(o => o.value === this.control.value)
        return selected?.label
    }

    tabAccessibilityForTouchScreen(): void {
        Helpers.simulateTabKeyPress(this.trigger.nativeElement)
    }
}
