export enum LongStringType {
    ClientAddress = 'ClientAddress',
    ConflictingCompaniesList = 'ConflictingCompaniesList',
    HiringOrgAddress = 'HiringOrgAddress',
    LongString = 'LongString',
    MultiLineText = 'MultiLineText',
    PriorInventionsList = 'PriorInventionsList',
    SigningOrgAddress = 'SigningOrgAddress',
    SigningOrgConflictingCompaniesList = 'SigningOrgConflictingCompaniesList',
    SigningOrgPriorInventionsList = 'SigningOrgPriorInventionsList',
    VendorAddress = 'VendorAddress',
}
