import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import {
    BulkImportItem,
    BulkImportNotification,
    ModelValidationResult,
    VendorInvitationsImportSettings
} from '../../models'
import { VendorImportData } from '../../models'
import { BulkImportStatuses } from '../../models/bulk-import-statuses.enum'
import { UrlService } from '../url.service'

@Injectable({
    providedIn: 'root',
})
export class VendorImportStore {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly urlsService: UrlService,
    ) { }

    getVendorInvitationsImportSettings(): Observable<VendorInvitationsImportSettings> {
        return this.httpClient.get<VendorInvitationsImportSettings>(
            this.urlsService.api.vendorImportSettings(),
        )
    }

    getVendorInvitationsImportHistory(businessId: string, statuses: BulkImportStatuses[]): Observable<BulkImportItem[]> {
        const params: HttpParams = new HttpParams().append('statuses', statuses.join(','))

        return this.httpClient.get<BulkImportItem[]>(
            this.urlsService.api.vendorImportHistory(businessId),
            { params },
        )
    }

    getVendorInvitationsImportNotifications(businessId: string): Observable<BulkImportNotification[]> {
        return this.httpClient.get<BulkImportNotification[]>(
            this.urlsService.api.vendorImportNotifications(businessId),
        )
    }

    downloadTemplate(): Observable<Blob> {
        return this.httpClient.get(
            this.urlsService.api.vendorImportTemplate(),
            { responseType: 'blob' },
        )
    }

    removeNotification(notification: string): Observable<void> {
        return this.httpClient.delete<void>(
            this.urlsService.api.dismissNotification(notification),
        )
    }

    uploadVendorInvitationsFileForValidation(file: File): Observable<ModelValidationResult<VendorImportData>[]> {
        const formData: FormData = new FormData()
        formData.append(file.name, file, file.name)

        const headers: HttpHeaders = new HttpHeaders()
        headers.append('Content-Type', 'multipart/form-data')

        return this.httpClient.post<ModelValidationResult<VendorImportData>[]>(
            this.urlsService.api.vendorImportValidation(),
            formData,
            { headers },
        )
    }

    uploadVendorInvitationsFile(businessId: string, vendors: VendorImportData[]): Observable<void> {
        return this.httpClient.post<void>(
            this.urlsService.api.vendorImport(businessId),
            vendors,
        )
    }
}
