<mat-accordion
    displayMode="flat"
    class="deliverable-list-accordion"
>
    <mat-expansion-panel
        *ngFor="let deliverable of deliverables; index as i"
        [disabled]="!!disabled || !!(loading$ | async)"
        [expanded]="selectedIndex === i"
        (opened)="setSelectedIndex(i)"
    >
        <mat-expansion-panel-header>
            <span class="wrap">
                <span class="icon">{{i + 1}}</span>
                <span
                    class="title black100"
                    [ngClass]="{ 'bold-text': !!editable && forms?.state?.dirty, 'error-default': !!editable && forms?.state?.invalid && forms?.maxStep >= forms?.steps?.Estimate, 'low-contrast': !!disabled || !!(loading$ | async) }"
                >{{deliverable?.name}}</span>
                <app-chip-list
                    *ngIf="deliverable?.status === deliverableStatus.ACTIVE || deliverable?.status === deliverableStatus.PROPOSED"
                    [clickable]="false"
                    [options]="[{ label: deliverable?.status, value: deliverable?.status, style: (deliverable?.status | deliverableStatusColor) + ' chip-status-badge' }]"
                >
                </app-chip-list>
            </span>
        </mat-expansion-panel-header>
        <div matExpansionPanelContent>
            <div class="shadow"></div>
            <app-deliverable-form
                *ngIf="!workOrder.type || workOrder.type === orderTypes.WorkOrder"
                #forms
                [disabled]="disabled"
                [editable]="editable"
                [index]="i"
                [isInitiator]="isInitiator"
                [isWorker]="isWorker"
                [loading]="(loading$ | async)"
                [workOrder]="workOrder"
                [state]="statesControl?.value[i]"
                [deliverable]="deliverable"
                (delete)="onDeleteDeliverable($event, i)"
                (nameChange)="onNameChange($event, i)"
                (save)="onSaveDeliverable($event, i)"
                (stateChange)="onStateChange($event)"
            >
            </app-deliverable-form>
            <app-order-item
                *ngIf="workOrder.type === orderTypes.PurchaseOrder"
                #forms
                [disabled]="disabled"
                [editable]="editable"
                [index]="i"
                [isInitiator]="isInitiator"
                [isWorker]="isWorker"
                [loading]="(loading$ | async)"
                [workOrder]="workOrder"
                [state]="statesControl?.value[i]"
                [item]="deliverable"
                (delete)="onDeleteDeliverable($event, i)"
                (nameChange)="onNameChange($event, i)"
                (save)="onSaveDeliverable($event, i)"
                (stateChange)="onStateChange($event)"
            >
            </app-order-item>
            <div class="shadow bottom"></div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<div
    *ngIf="editable"
    class="add-another body1"
    [ngClass]="{ unclickable: addingDeliverable || !canAddAnother || disabled, 'low-contrast': addingDeliverable || !canAddAnother || disabled }"
    (click)="onAdd()"
>
    <span class="icon">
        <i-feather
            name="plus"
            class="black100"
        ></i-feather>
    </span>
    Add another
</div>
