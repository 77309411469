import { Injectable } from '@angular/core'
import { FormGroup, ValidatorFn } from '@angular/forms'

import {
    DocumentSignStepDefinition,
    DocumentSignStepDefinitionTemplateValue,
    OnboardingProcess,
    OnboardingProcessStep,
    OnboardingStepType,
    OrganizationSettings,
    TemplateBaseType,
} from '../../../../models'
import { WorkflowComponent, WorkflowModule, WorkflowService, WorkflowStep, WorkflowStepGroup, WorkflowStepIndices } from '../../workflow'
import { FormStepDataInput } from '../../workflow/form-step/form-step-data-input.interface'
import { FormStepComponent } from '../../workflow/form-step/form-step.component'
import { InviteVendorStepId, VendorInvite } from '../models'

import { InviteService } from './invite-service.interface'

@Injectable({
    providedIn: 'root',
})
export class VendorInviteCommonService {

    private inviteService: InviteService

    get settings(): OrganizationSettings {
        return this.inviteService.getSettings()
    }

    get form(): FormGroup {
        return this.workflow.form
    }

    get invite(): VendorInvite {
        return this.inviteService.invite
    }

    set invite(value: VendorInvite) {
        this.inviteService.invite = value
    }

    get masterContractData(): FormData {
        return this.inviteService.masterContractData
    }

    set masterContractData(value: FormData) {
        this.inviteService.masterContractData = value
    }

    get uploadedFile(): File {
        return this.inviteService.uploadedFile
    }

    set uploadedFile(value: File) {
        this.inviteService.uploadedFile = value
    }

    constructor(
        private readonly workflow: WorkflowService,
    ) { }

    initialize(service: InviteService): void {
        this.inviteService = service
    }

    changeOnboardingProcess(process: OnboardingProcess): void {
        this.inviteService.changeOnboardingProcess(process)
    }

    changeCountersigningEnabled(value: boolean): void {
        this.inviteService.changeCountersigningEnabled(value)
    }

    getOnboardingProcess(): OnboardingProcess {
        return this.inviteService.getOnboardingProcess()
    }

    getCountersigningEnabled(): boolean {
        return this.inviteService.getCountersigningEnabled()
    }

    setFormGroup(inputs: Array<FormStepDataInput> = [], validators?: Array<ValidatorFn>): void {
        this.inviteService.setFormGroup(inputs, validators)
    }

    isPreInviteStep(s: WorkflowStep): boolean {
        return s.id.startsWith(InviteVendorStepId.PreInviteInput)
    }

    shouldStoreValue(stepId: string): boolean {
        // Avoid storing C:\fakepath\... from input type file
        return stepId !== InviteVendorStepId.OnboardingProcessUploadMasterContract
    }

    getPreInviteSteps(process: OnboardingProcess, stepData?: any): WorkflowStep[] {
        // parse the steps and get the pre-invite-inputs
        const preInviteInputs: DocumentSignStepDefinitionTemplateValue[] = this.getPreInviteInputs(process)

        return preInviteInputs
            .map(tv => ({
                component: FormStepComponent,
                id: this.getPreInviteStepId(tv), // assuming all pre-invite step field names are unique
                data: {
                    image: '/assets/img/svg/keyboard.svg',
                    inputs: [
                        {
                            label: tv.name,
                            name: tv.fieldName,
                            required: tv.required,
                            type: this.getInputType(tv.valueType),
                            value: tv.value,
                        },
                    ],
                    title: tv.inputInstruction,
                },
                skipped: false,
                ...stepData,
            }))
    }

    removePreInviteSteps(steps: WorkflowStep[]): void {
        const preInviteStepsToRemove: WorkflowStep[] = steps.filter(s => this.isPreInviteStep(s))
        for (const preInviteStep of preInviteStepsToRemove) {
            steps.splice(steps.findIndex(fi => fi.id === preInviteStep.id), 1)
        }
    }

    handlePreInviteFieldSave(): void {
        // if this isn't a pre-invite input step, no need to continue
        if (!this.isPreInviteStep(this.workflow.activeStep)) {
            return
        }

        // get the current field name
        const currentField: string = this.workflow.activeStep.id.replace(`${InviteVendorStepId.PreInviteInput}-`, '')

        // get the onboarding step that correlates w/this current step
        const step: OnboardingProcessStep = this.invite.onboardingProcess
            .steps
            .find(s => (<DocumentSignStepDefinition>JSON.parse(s.stepDefinition)).templateValues?.some(t => t.fieldName === currentField))

        // parse the current step def
        const stepDef: DocumentSignStepDefinition = JSON.parse(step.stepDefinition)

        // get the template field that needs to be updated
        const tv: DocumentSignStepDefinitionTemplateValue = stepDef.templateValues.find(t => t.fieldName === currentField)

        // if we found the template, set ts value and set that into the step def
        if (!!tv) {
            tv.value = this.workflow.form.value[currentField]
            step.stepDefinition = JSON.stringify(stepDef)
        }
    }

    handleUploadMasterContractSteps(
        process: OnboardingProcess,
        contractInfoGroup: WorkflowStepGroup,
        modules: WorkflowModule[],
        workflowComponent: WorkflowComponent,
    ): boolean {
        const uploadStep: WorkflowStep = contractInfoGroup.steps[this.workflow.stepMap[InviteVendorStepId.OnboardingProcessUploadMasterContract].stepIndex]
        const shouldSkipUpload: boolean = !process?.steps.some(step => step.isMasterContractDocument && step.stepType === OnboardingStepType.masterUpload)

        // if the skipping is already set correctly, no need to continue;
        // otherwise, we'd get in an endless loop
        if (uploadStep.skipped === shouldSkipUpload) {
            return false
        }

        uploadStep.skipped = shouldSkipUpload

        const uploadStepIndex: WorkflowStepIndices = this.workflow.stepMap[InviteVendorStepId.OnboardingProcessUploadMasterContract]
        modules[uploadStepIndex.moduleIndex].groups[uploadStepIndex.groupIndex].steps[uploadStepIndex.stepIndex].skipped = shouldSkipUpload

        workflowComponent.moduleComponents.toArray()[this.workflow.activeModuleIndex].groupComponent.refreshStep()

        return true
    }

    private getPreInviteStepId(tv: DocumentSignStepDefinitionTemplateValue): string {
        return `${InviteVendorStepId.PreInviteInput}-${tv.fieldName}`
    }

    private getPreInviteInputs(process: OnboardingProcess): DocumentSignStepDefinitionTemplateValue[] {
        // parse the steps and get the pre-invite-inputs
        return process.steps
            .map(s => (<DocumentSignStepDefinition>JSON.parse(s.stepDefinition)))
            .filter(s => s.templateValues?.some(t => t.isPreInviteInput))
            .map(current => current.templateValues.filter(tv => tv.isPreInviteInput))
            .reduce((acc, value) => acc.concat(...value), [] as DocumentSignStepDefinitionTemplateValue[])
    }

    private getInputType(type: string): 'text' | 'textarea' | 'date' {
        switch (DocumentSignStepDefinitionTemplateValue.getBaseTypeForValueType(type)) {
            case TemplateBaseType.String:
                return 'text'
            case TemplateBaseType.LongString:
                return 'textarea'
            case TemplateBaseType.Date:
                return 'date'
        }
    }
}
