import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class SiteStatusService {

    get SiteStatusKey(): string { return 'lq-site-status' }
    get SiteStatusBeginKey(): string { return `${this.SiteStatusKey}-begin` }
    get SiteStatusUntilKey(): string { return `${this.SiteStatusKey}-until` }
    get SiteStatus$(): Observable<string> { return this.storageSub.asObservable() }

    private storageSub: Subject<string> = new Subject<string>()

    constructor(

    ) { }

    setMaintenance(status: string, begin: number, until: number): void {
        if (this.siteStatusIsMaintenanceType(status) && !!begin && !!until) {
            localStorage.setItem(this.SiteStatusKey, status)
            localStorage.setItem(this.SiteStatusBeginKey, `${begin * 1000}`)
            localStorage.setItem(this.SiteStatusUntilKey, `${until * 1000}`)
            this.storageSub.next(status)
        } else {
            localStorage.removeItem(this.SiteStatusKey)
            localStorage.removeItem(this.SiteStatusBeginKey)
            localStorage.removeItem(this.SiteStatusUntilKey)
            this.storageSub.next(undefined)
        }
    }

    siteStatusIsMaintenanceType(siteStatus: string): boolean {
        return !!siteStatus && (siteStatus.toLowerCase() === 'maintenance' || siteStatus.toLowerCase() === 'maintenance-scheduled')
    }

    siteStatusIsMaintenance(siteStatus: string): boolean {
        return !!siteStatus && siteStatus.toLowerCase() === 'maintenance'
    }
}
