import { Injectable } from '@angular/core'

import { GenericErrorStrings } from './generic-errors-strings.enum'
import { HandledError } from './handled-error.interface'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingService {

    readonly MAX_RETRIES: number = 10

    handle(error: any): HandledError {
        if (!!error?.template) {
            return error
        }

        const generic: boolean = this.isGeneric(error)
        return {
            generic,
            error: generic ? undefined : this.getErrorString(error),
        }
    }

    public getSupportEmail(): string {
        return `<a href="mailto:${environment.internalEmailAdress.support}">${environment.internalEmailAdress.support}</a>`;
    }

    private getErrorString(error: any): string {
        if (typeof error === 'string') {
            return error
        }

        const errorString: string = error?._body || error?.error || error?.message
        return typeof errorString === 'string' ? errorString : undefined
    }

    private isGeneric(error: any): boolean {
        if (error instanceof Error) {
            return true
        }

        const errorString: string = this.getErrorString(error)
        const genericErrorStrings: Array<string> = Object.values(GenericErrorStrings)

        if (genericErrorStrings.includes(errorString) || (!!error && !errorString)) {
            return true
        }

        return false
    }
}
