import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { UrlService } from '../../core/services'
import { PartialBusiness, SavePartialBusinessRequest, SavePartialBusinessResponse } from '../models'

@Injectable({
    providedIn: 'root',
})
export class PartialBusinessStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(): Observable<PartialBusiness> {
        return this.http.get<PartialBusiness>(this.urls.api.partialBusiness())
            .pipe(
                map((response: SavePartialBusinessResponse) => {
                    const output: PartialBusiness = !!response ? JSON.parse(response.payload) as PartialBusiness : undefined
                    if (!!response?.id && !!output) {
                        output.id = response.id
                    }
                    return output
                }),
            )
    }

    create(business: PartialBusiness): Observable<PartialBusiness> {

        const request: SavePartialBusinessRequest = {
            payload: JSON.stringify(business),
        }

        return this.http.post<SavePartialBusinessResponse>(this.urls.api.partialBusiness(), request)
            .pipe(
                map((response: SavePartialBusinessResponse) => {
                    const output: PartialBusiness = JSON.parse(response.payload) as PartialBusiness
                    output.id = response.id
                    return output
                }),
            )
    }

    update(business: PartialBusiness): Observable<PartialBusiness> {

        const request: SavePartialBusinessRequest = {
            id: business.id,
            payload: JSON.stringify(business),
        }

        return this.http.put<SavePartialBusinessResponse>(this.urls.api.partialBusiness(), request)
            .pipe(
                map((response: SavePartialBusinessResponse) => JSON.parse(response.payload) as PartialBusiness),
            )
    }

    delete(): Observable<void> {
        return this.http.delete<void>(this.urls.api.partialBusiness())
    }
}
