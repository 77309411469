import { ErrorHandler } from '@angular/core'
import Bugsnag, { BrowserConfig } from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

import { environment } from '../../../environments/environment'
import { StorageKeyAuth } from '../models'

export class LoggingService {

    private static client: any

    static initialize(): ErrorHandler {

        const config: BrowserConfig = {
            apiKey: environment.logging.apiKey,
            releaseStage: environment.logging.releaseStage,
            enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
            onError: (event) => {
                // handle chunks missing during deployments:
                /*
                currently, error looks like:
                ChunkLoadError: Loading chunk 59 failed.
                (error: https://web-dev.poweredbyliquid.com/59.11d813babc3954d5a382.js)
                ChunkLoadError: Loading chunk 59 failed.
                (error: https://web-dev.poweredbyliquid.com/59.11d813babc3954d5a382.js)
                    at Function.d.e (bootstrap:126)
                    .
                    .
                    .
                */
                const chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/
                const loginRequiredMessage: RegExp = /Login required/
                const forbiddenOKMessage: RegExp = /403 OK/
                const forbiddenErrorMessage: RegExp = /403 Forbidden/
                const notFoundErrorMessage: RegExp = /404 OK/
                const conflictsErrorMessage: RegExp = /409 OK/

                if (chunkFailedMessage.test(event.errors[0].errorMessage)) {
                    window.location.reload()
                    return false
                }

                if (loginRequiredMessage.test(event.errors[0].errorMessage)) {
                    return false
                }

                if (
                    forbiddenOKMessage.test(event.errors[0].errorMessage)
                    || forbiddenErrorMessage.test(event.errors[0].errorMessage)
                    || notFoundErrorMessage.test(event.errors[0].errorMessage)
                    || conflictsErrorMessage.test(event.errors[0].errorMessage)
                ) {
                    return false
                }

                const openId: string = localStorage.getItem(StorageKeyAuth.OPEN_ID)
                const profileId: string = localStorage.getItem(StorageKeyAuth.PROFILE_ID)

                if (!!openId || !!profileId) {

                    const businessInfo = JSON.parse(localStorage.getItem(StorageKeyAuth.BUSINESS_INFO))

                    const userInfo = {
                        'email': localStorage.getItem(StorageKeyAuth.USER_EMAIL),
                        'name': localStorage.getItem(StorageKeyAuth.USER_NAME),
                        'open id': openId,
                        'profile id': profileId,
                    }

                    event.addMetadata('Business Info', businessInfo)
                    event.addMetadata('User', userInfo)
                    event.groupingHash = event.errors[0].errorClass + event.errors[0].errorMessage
                }

                // Make sure FullStory object exists.
                if ((window as any).FS && (window as any).FS.getCurrentSessionURL) {
                    event.addMetadata('fullstory', {
                        urlAtTime: (window as any).FS.getCurrentSessionURL(true)
                    })
                }
            },
        }

        // if we are enabling console breadcrumbs, add it to the enabled types
        if (!!environment.logging.consoleBreadcrumbsEnabled) {
            config.enabledBreadcrumbTypes.push('log')
        }

        LoggingService.client = Bugsnag.start(config)

        return new BugsnagErrorHandler(LoggingService.client)
    }

    static notify(error: Error): void {
        console.log(error)
        LoggingService.client.notify(error)
    }
}
