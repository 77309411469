import { Pipe, PipeTransform } from '@angular/core'

import { DeliverableStatus } from '../modules/models'

@Pipe({
    name: 'deliverableStatusColor',
})
export class DeliverableStatusColorPipe implements PipeTransform {

    transform(status: DeliverableStatus): string {
        switch (status?.toLowerCase()) {
            case DeliverableStatus.ACTIVE.toLowerCase():
            case DeliverableStatus.COMPLETED.toLowerCase():
                return 'success'
            case DeliverableStatus.DRAFT.toLowerCase():
                return 'warn'
            case DeliverableStatus.PROPOSED.toLowerCase():
            case DeliverableStatus.NOT_SENT.toLowerCase():
                return 'accent'
            default:
                return ''
        }
    }

}
