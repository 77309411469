import { ComponentType } from '@angular/cdk/overlay'
import { Injectable } from '@angular/core'
import { ValidatorFn } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'

import { environment } from '../../environments/environment'
import { LoggingService } from '../auth/services'
import { ErrorHandlingService } from '../error-handling/error-handling.service'
import { HandledError } from '../error-handling/handled-error.interface'

import { AlertComponent } from './alert/alert.component'
import { ConfirmDialogData } from './confirm/confirm-dialog-data.interface'
import { ConfirmComponent } from './confirm/confirm.component'
import { InputDialogConfig } from './input-dialog-config'
import { DialogTemplateType } from './models/dialog-template-type.enum'
import { TextInputComponent } from './text-input/text-input.component'
import { WaitComponent } from './wait/wait.component'

@Injectable({
    providedIn: 'root',
})
export class DialogService {

    private dialogRef: MatDialogRef<WaitComponent | AlertComponent | ConfirmComponent | TextInputComponent>
    private waitDialogRef: MatDialogRef<WaitComponent>

    constructor(
        private dialog: MatDialog,
        private errorHandling: ErrorHandlingService,
    ) { }

    open(ref: ComponentType<any>, config?: MatDialogConfig<any>, templateType?: DialogTemplateType, closeThisBeforeOpen: boolean = true): MatDialogRef<any> {
        templateType = templateType || DialogTemplateType.large

        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            disableClose: false,
            maxHeight: '94vh',
            ...config,
            maxWidth: templateType,
            minWidth: templateType,
            width: templateType,
        }

        if (closeThisBeforeOpen) {
            this.close()
        }

        this.dialogRef = this.dialog.open(ref, dialogConfig)

        return this.dialogRef
    }

    error(error: any, message?: string): Observable<undefined> {

        if (!environment.production) {
            // tslint:disable-next-line: no-console
            console.error(error)
        }

        LoggingService.notify(error)

        this.close()
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        const { error: description }: HandledError = this.errorHandling.handle(error)
        dialogConfig.autoFocus = true
        dialogConfig.maxWidth = DialogTemplateType.medium
        dialogConfig.minWidth = DialogTemplateType.medium
        dialogConfig.data = {
            title: 'Error',
            description: message || description,
            isError: true,
        }
        this.dialogRef = this.dialog.open(AlertComponent, dialogConfig)
        return this.dialogRef.afterClosed()
    }

    wait(message?: string): void {

        const width: string = DialogTemplateType.small

        if (!!this.waitDialogRef) {
            return
        }

        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.autoFocus = true
        dialogConfig.disableClose = true
        dialogConfig.minWidth = width
        dialogConfig.minHeight = width
        dialogConfig.width = width
        dialogConfig.maxWidth = width
        dialogConfig.height = width
        dialogConfig.data = {
            message,
        }
        this.waitDialogRef = this.dialog.open(WaitComponent, dialogConfig)
        this.dialogRef = this.waitDialogRef
    }

    confirm(question: string, yesButtonText: string, title?: string, noButtonText?: string): Observable<boolean> {

        const width: DialogTemplateType = DialogTemplateType.medium

        this.close() // if there was one open, close it

        const data: ConfirmDialogData = {
            description: question,
            title: title || 'Please Confirm',
            yesButtonText,
            noButtonText,
        }
        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            disableClose: true,
            maxWidth: width,
            minWidth: width,
            data,
        }

        this.dialogRef = this.dialog.open(ConfirmComponent, dialogConfig)
        return this.dialogRef.afterClosed()
    }

    inform(description: string, title?: string, actionText?: string, disableClose: boolean = true): Observable<void> {

        const width: DialogTemplateType = DialogTemplateType.medium

        this.close() // if there was one open, close it

        const dialogConfig: MatDialogConfig = {
            autoFocus: true,
            disableClose: disableClose,
            maxWidth: width,
            minWidth: width,
            data: {
                title,
                description,
                actionText,
            },
        }
        this.dialogRef = this.dialog.open(AlertComponent, dialogConfig)
        return this.dialogRef.afterClosed()
    }

    inputFromConfig(config: InputDialogConfig): Observable<string> {
        return this.input(
            config.title,
            config.description,
            config.value,
            config.placeholder,
            config.okButtonText,
            config.cancelButtonText,
            config.isLongString,
            config.autoFocus,
            config.disableClose,
            config.maxWidth,
            config.required,
            config.otherValidators,
            config.errorText,
        )
    }

    input(title: string, description: string, value: string, placeholder: string, okButtonText: string, cancelButtonText: string, isLongString: boolean, autoFocus: boolean, disableClose: boolean, maxWidth: string, required: boolean = false, otherValidators: ValidatorFn[] = [], errorText?: string): Observable<string> {

        const width: DialogTemplateType = DialogTemplateType.medium

        this.close() // if there was one open, close it

        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.autoFocus = autoFocus
        dialogConfig.disableClose = disableClose
        dialogConfig.maxWidth = width
        dialogConfig.minWidth = width
        dialogConfig.data = {
            title,
            description,
            value,
            placeholder,
            okButtonText,
            cancelButtonText,
            isLongString,
            required,
            otherValidators,
            errorText,
        }
        this.dialogRef = this.dialog.open(TextInputComponent, dialogConfig)
        return this.dialogRef.afterClosed()
    }

    close(closeData?: any): void {
        if (!!this.dialogRef) {
            this.dialogRef.close(closeData)
            this.dialogRef = undefined
        }
        if (!!this.waitDialogRef) {
            this.waitDialogRef.close()
            this.waitDialogRef = undefined
        }
    }
}
