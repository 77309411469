import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'
import { ApprovalPolicy } from '../../modules/models'
import { OrganizationApprovals } from '../models'

import { InvoiceApprovalsDataStore } from './invoice-approvals.data-store'

@Injectable({
    providedIn: 'root',
})
export class InvoiceApprovalsService {

    constructor(
        private dialogs: DialogService,
        private store: InvoiceApprovalsDataStore,
    ) { }

    approveInvoices(invoiceIds: string[]): Observable<void> {
        return this.store.approveInvoices(invoiceIds)
    }

    getProcessingSettings(organizationId: string): Observable<OrganizationApprovals | undefined> {
        return this.store.getProcessingSettings(organizationId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    setProcessingSettings(organizationId: string, settings: OrganizationApprovals): Observable<OrganizationApprovals | undefined> {
        return this.store.setProcessingSettings(organizationId, settings)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getApprovalPolicies(organizationId: string): Observable<Array<ApprovalPolicy> | undefined> {
        return this.store.getApprovalPolicies(organizationId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    saveApprovalPolicy(organizationId: string, policy: ApprovalPolicy): Observable<Array<ApprovalPolicy> | undefined> {
        return this.store.saveApprovalPolicy(organizationId, policy)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    deleteApprovalPolicy(policyId: string): Observable<Array<ApprovalPolicy> | undefined> {
        return this.store.deleteApprovalPolicy(policyId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
