import { Injectable, Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'removeMoney',
})
@Injectable({
    providedIn: 'root',
})
export class RemoveMoneyPipe implements PipeTransform {

    transform(value: string | number): number {
        return +String(value).replace('$', '').replace(',', '').trim()
    }
}
