import { EventEmitter, Injectable } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { BehaviorSubject, Observable, of } from 'rxjs'

import { ErrorHandlingService } from '../../../../../error-handling/error-handling.service'
import { HandledError } from '../../../../../error-handling/handled-error.interface'
import {
    WorkflowModule,
    WorkflowStep,
    WorkflowStepGroup,
    WorkflowStepIndices,
    WorkflowStepMap,
} from '../models'

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {

    private readonly activeGroupSubject: BehaviorSubject<WorkflowStepGroup> = new BehaviorSubject(undefined)
    private readonly activeModuleSubject: BehaviorSubject<WorkflowModule> = new BehaviorSubject(undefined)
    private readonly activeStepSubject: BehaviorSubject<WorkflowStep> = new BehaviorSubject(undefined)
    private readonly buttonLabelSubject: BehaviorSubject<string> = new BehaviorSubject('Next')
    private readonly dismissConfirmationSubject: BehaviorSubject<boolean> = new BehaviorSubject(undefined)
    private readonly errorSubject: BehaviorSubject<HandledError> = new BehaviorSubject<HandledError>(undefined)
    private readonly isActiveFirstSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private readonly isActiveLastSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private readonly maxEnabledGroupIndexSubject: BehaviorSubject<number> = new BehaviorSubject(undefined)
    private readonly maxEnabledModuleIndexSubject: BehaviorSubject<number> = new BehaviorSubject(undefined)
    private readonly maxEnabledStepIndexSubject: BehaviorSubject<number> = new BehaviorSubject(undefined)
    private readonly modulesSubject: BehaviorSubject<Array<WorkflowModule>> = new BehaviorSubject([])
    private readonly showDismissMessageSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)

    private get maxEnabledGroup(): WorkflowStepGroup { return this.maxEnabledModule.groups[this.maxEnabledGroupIndex] }
    private get maxEnabledModule(): WorkflowModule { return this.modules[this.maxEnabledModuleIndex] }
    private get maxEnabledStep(): WorkflowStep { return this.maxEnabledGroup.steps[this.maxEnabledStepIndex] }

    private get modules(): Array<WorkflowModule> { return this.modulesSubject.getValue() }
    private set modules(modules: Array<WorkflowModule>) { this.modulesSubject.next(modules || []) }

    readonly activeGroup$: Observable<WorkflowStepGroup> = this.activeGroupSubject.asObservable()
    readonly activeModule$: Observable<WorkflowModule> = this.activeModuleSubject.asObservable()
    readonly activeStep$: Observable<WorkflowStep> = this.activeStepSubject.asObservable()
    readonly buttonLabel$: Observable<string> = this.buttonLabelSubject.asObservable()
    readonly change: EventEmitter<void> = new EventEmitter()
    readonly complete: EventEmitter<void> = new EventEmitter()
    readonly dismissConfirmation$: Observable<boolean> = this.dismissConfirmationSubject.asObservable()
    readonly error$: Observable<HandledError> = this.errorSubject.asObservable()
    readonly formChange: EventEmitter<FormGroup> = new EventEmitter()
    readonly isActiveFirst$: Observable<boolean> = this.isActiveFirstSubject.asObservable()
    readonly isActiveLast$: Observable<boolean> = this.isActiveLastSubject.asObservable()
    readonly isLoading$: Observable<boolean> = this.loadingSubject.asObservable()
    readonly maxEnabledGroupIndex$: Observable<number> = this.maxEnabledGroupIndexSubject.asObservable()
    readonly maxEnabledModuleIndex$: Observable<number> = this.maxEnabledModuleIndexSubject.asObservable()
    readonly maxEnabledStepIndex$: Observable<number> = this.maxEnabledStepIndexSubject.asObservable()
    readonly navigate: EventEmitter<void> = new EventEmitter()
    readonly next: EventEmitter<void> = new EventEmitter()
    readonly previous: EventEmitter<void> = new EventEmitter()
    readonly reset: EventEmitter<void> = new EventEmitter()
    readonly scrollGroup: EventEmitter<void> = new EventEmitter()
    readonly scrollModule: EventEmitter<void> = new EventEmitter()
    readonly showDismissMessage$: Observable<boolean> = this.showDismissMessageSubject.asObservable()

    disableNext: boolean = false
    flowPath: { [key: string]: any } = {}
    form: FormGroup = new FormGroup({})
    genericStepFunction$: () => Observable<any>
    implementationService: object
    stepMap: WorkflowStepMap = {}
    subject: { [key: string]: any, flowPath?: any } = {}

    get activeGroup(): WorkflowStepGroup { return this.activeGroupSubject.getValue() }
    set activeGroup(group: WorkflowStepGroup) { this.activeGroupSubject.next(group) }

    get activeGroupIndex(): number { return !this.activeGroup ? 0 : this.activeModule?.groups.findIndex(group => group.id === this.activeGroup.id) }

    get activeModule(): WorkflowModule { return this.activeModuleSubject.getValue() }
    set activeModule(module: WorkflowModule) { this.activeModuleSubject.next(module) }

    get activeModuleIndex(): number { return !this.activeModule ? 0 : this.modules.findIndex(module => module.id === this.activeModule.id) }

    get activeStep(): WorkflowStep { return this.activeStepSubject.getValue() }
    set activeStep(step: WorkflowStep) { this.activeStepSubject.next(step) }

    get activeStepIndex(): number { return !this.activeStep ? 0 : this.activeGroup?.steps.findIndex(step => step.id === this.activeStep.id) || 0 }
    get isActiveFirst(): boolean { return this.isActiveFirstSubject.getValue() }
    get isActiveLast(): boolean { return this.isActiveLastSubject.getValue() }

    set buttonLabel(label: string) { this.buttonLabelSubject.next(label) }

    set dismissConfirmation(value: boolean) { this.dismissConfirmationSubject.next(value) }

    set error(error: any) {
        if (!error) {
            this.errorSubject.next(undefined)
            return
        }
        const handledError: HandledError = this.errorHandling.handle(error)
        this.errorSubject.next(handledError)
    }

    get isLoading(): boolean { return this.loadingSubject.getValue() }
    set isLoading(loading: boolean) {
        this.loadingSubject.next(loading)
        if (!loading) {
            this.form.enable()
        } else {
            this.form.disable()
        }
        Object.keys(this.form.controls).forEach(key => {
            if (!loading) {
                this.form.controls[key].enable()
            } else {
                this.form.controls[key].disable()
            }
        })
    }

    get maxEnabledGroupIndex(): number { return this.maxEnabledGroupIndexSubject.getValue() }
    set maxEnabledGroupIndex(index: number) { this.maxEnabledGroupIndexSubject.next(index) }

    get maxEnabledModuleIndex(): number { return this.maxEnabledModuleIndexSubject.getValue() }
    set maxEnabledModuleIndex(index: number) { this.maxEnabledModuleIndexSubject.next(index) }

    get maxEnabledStepIndex(): number { return this.maxEnabledStepIndexSubject.getValue() }
    set maxEnabledStepIndex(index: number) { this.maxEnabledStepIndexSubject.next(index) }

    set showDismissMessage(value: boolean) { this.showDismissMessageSubject.next(value) }

    constructor(
        private errorHandling: ErrorHandlingService,
    ) { }

    initialize(modules: Array<WorkflowModule>, stepMap?: { [stepId: string]: WorkflowStepIndices }, stepId?: string, decisions?: { [key: string]: any }): void {
        this.errorSubject.next(undefined)
        this.loadingSubject.next(false)
        this.initializeStepMap(modules, stepMap)
        this.initializeModule(modules, stepId)

        if (!!decisions) {
            this.setDecisions(decisions)
        }
    }

    onChange(): void {
        this.change.emit()
    }

    onNext(): void {
        this.disableNext = true
        this.next.emit()
    }

    onPrevious(): void {
        this.previous.emit()
    }

    onReset(): void {
        this.reset.emit()
    }

    nextModule(): void {

        this.activeModule = this.modules[this.activeModuleIndex + 1]

        // if this is last, then the workflow is complete
        if (this.isActiveLast || !this.activeModule) {
            this.complete.emit()
            return
        }

        this.activeGroup = this.activeModule.groups?.[0]

        let activeStepIndex: number = 0
        while (!this.activeGroup?.steps?.[activeStepIndex]
            || !!this.activeGroup?.steps?.[activeStepIndex]?.skipped
        ) {
            activeStepIndex++
        }
        this.activeStep = this.activeGroup?.steps?.[activeStepIndex]

        // if this is the first time we're seeing this module, reset the max enabled
        if (this.maxEnabledModuleIndex < this.activeModuleIndex) {
            this.maxEnabledModuleIndex = this.activeModuleIndex
            this.maxEnabledGroupIndex = this.activeGroupIndex
            this.maxEnabledStepIndex = this.activeStepIndex
        }

        this.navigateToActiveStep()
        this.setIsActiveFirstAndLast()
        this.scroll()
    }

    nextGroup(): void {

        if (this.activeGroupIndex === this.activeModule.groups.length - 1) {
            this.nextModule()
            return
        }

        this.activeGroup = this.activeModule.groups[this.activeGroupIndex + 1]
        const nextStep: WorkflowStep = this.getNextStepForGroup()

        // if there's no next step b/c they're all skipped, go to the next modules
        if (!nextStep) {
            this.nextModule()
            return
        }

        this.activeStep = nextStep

        if (this.maxEnabledGroupIndex < this.activeGroupIndex) {
            this.maxEnabledGroupIndex = this.activeGroupIndex
            this.maxEnabledStepIndex = this.activeStepIndex
        }
        this.navigateToActiveStep()
        this.setIsActiveFirstAndLast()
        this.scrollGroup.emit()
    }

    nextStep(): void {

        this.disableNext = false
        let nextStepIdx: number = this.activeStepIndex + 1
        if (nextStepIdx === this.activeGroup.steps.length) {
            this.nextGroup()
            return
        }

        while (nextStepIdx < this.activeGroup.steps.length && !!this.activeGroup.steps[nextStepIdx].skipped) {
            nextStepIdx++
        }

        // if we skip to the last step of the group, go to the next group
        if (nextStepIdx >= this.activeGroup.steps.length) {
            this.nextGroup()
            return
        }

        this.activeStep = this.activeGroup.steps[nextStepIdx]
        if (this.maxEnabledStepIndex < this.activeStepIndex) {
            this.maxEnabledStepIndex = this.activeStepIndex
        }
        this.scrollGroup.emit()
        this.navigateToActiveStep()
        this.setIsActiveFirstAndLast()
    }

    previousStep(): void {

        let previousStep: number = this.activeStepIndex - 1
        while (previousStep > 0 && !!this.activeGroup.steps[previousStep].skipped) {
            previousStep--
        }

        this.activeStep = this.activeGroup.steps[previousStep]
        this.setIsActiveFirstAndLast()
        this.navigateToActiveStep()
    }

    setDecisions(decisions: { [key: string]: any }, stepIndex: number = 0, groupIndex: number = 0, moduleIndex: number = 0, modules?: Array<WorkflowModule>): void {

        const decisionNames: string[] = Object.keys(decisions)

        modules = modules || this.modules

        for (; moduleIndex < modules.length; moduleIndex++) {
            const groups: WorkflowStepGroup[] = modules[moduleIndex].groups
            for (; groupIndex < groups.length; groupIndex++) {
                const steps: WorkflowStep[] = groups[groupIndex].steps
                for (; stepIndex < steps.length; stepIndex++) {
                    const step: WorkflowStep = steps[stepIndex]
                    Object.entries(step.showOn || {}).forEach(([name, value]) => {
                        if (decisionNames.includes(name)) {
                            step.skipped = value !== decisions[name]
                        }
                    })
                }
                stepIndex = 0
            }
            groupIndex = 0
        }
    }

    setFormControls(controls: { [key: string]: FormControl | FormGroup }): void {
        this.removeControls()
        Object.keys(controls).forEach(key => this.form.addControl(key, controls[key]))
        this.formChange.emit(this.form)
    }

    setFormGroup(): void {
        this.removeControls()
        const { data }: any = this.activeStep
        if (!data?.inputs) {
            return
        }
        data.inputs?.forEach(input => {
            let value: any
            if (this.subject?.[input.name]) {
                value = this.subject[input.name]
            } else if (input.valueFrom) {
                const parts: string[] = input.valueFrom.split('.')
                value = this.subject
                for (let i: number = 0; i < parts.length; i++) {
                    value.hasOwnProperty(parts[i])
                    value = value[parts[i]]
                }
            } else if (input.value !== null && input.value !== undefined) { // need to specify null and undefined so we can handle false bools
                value = input.value
            }
            const control: FormControl = new FormControl(value, input.validators)
            this.form.addControl(input.name, control)
        })
        this.formChange.emit(this.form)
    }

    goToModule(index: number): void {
        this.activeModule = this.modules[index]
        this.maxEnabledGroupIndex = this.getMaxEnabledGroupIndex(this.activeModule.groups)
        this.goToGroup()
    }

    goToGroup(index: number = 0): void {
        this.buttonLabelSubject.next(undefined)
        this.activeGroup = this.activeModule.groups[index]
        const nextStep: WorkflowStep = this.getNextStepForGroup()
        if (!nextStep) {
            this.goToModule(this.activeModuleIndex + 1)
            return
        }
        this.activeStep = nextStep
        this.navigateToActiveStep()
    }

    goToStepById(stepId: string): void {
        this.initializeModule(this.modules, stepId)
        this.navigateToActiveStep()
    }

    goToMaxEnabled(): void {

        this.activeModule = this.maxEnabledModule
        this.activeGroup = this.maxEnabledGroup
        this.activeStep = this.maxEnabledStep
        if (this.activeStep.skipped) {
            this.setNextValidStep()
        }
        this.navigateToActiveStep()
        this.setIsActiveFirstAndLast()
    }

    isModuleComplete(index: number): boolean {

        if (index === this.maxEnabledModuleIndex) {

            const groups: Array<WorkflowStepGroup> = this.modules[index].groups
            const steps: Array<WorkflowStep> = groups[groups.length - 1].steps

            let lastStepIdx: number = steps.length - 1
            while (lastStepIdx >= 0 && steps[lastStepIdx].skipped) {
                lastStepIdx--
            }

            return !!steps[lastStepIdx]?.value || this.isActiveLast
        }
        return index < this.maxEnabledModuleIndex
    }

    isGroupComplete(index: number, moduleId: string): boolean {

        const steps: Array<WorkflowStep> = this.modules.find(m => m.id === moduleId).groups?.[index]?.steps

        if (!steps || this.isActiveLast) {
            return true
        }

        let lastStepIndex: number = steps.length - 1
        let lastStep: WorkflowStep = steps[lastStepIndex]

        while (lastStep.skipped && lastStepIndex > 0) {
            lastStepIndex--
            lastStep = steps[lastStepIndex]
        }

        return lastStep.value !== null && lastStep.value !== undefined // need to be able to register false as completed
    }

    isStepEnabled(stepId: string): boolean {

        // if there's not step, it can't be enabled
        if (!stepId) {
            return false
        }

        // get the current step info
        const stepIndices: WorkflowStepIndices = this.stepMap[stepId]
        if (!stepIndices) {
            return false
        }

        // if the max enabled module is after than the current module,
        // or the max enabled module is the current module and the max enabled group is after the current group,
        // or the max enabled module is the current module and the max enabled group is the current group
        // and the max enabled step is or is after the current module,
        // then the requested step is enabled
        return this.maxEnabledModuleIndex > stepIndices.moduleIndex
            || (this.maxEnabledModuleIndex === stepIndices.moduleIndex
                && this.maxEnabledGroupIndex > stepIndices.groupIndex)
            || (this.maxEnabledModuleIndex === stepIndices.moduleIndex
                && this.maxEnabledGroupIndex === stepIndices.groupIndex
                && this.maxEnabledStepIndex >= stepIndices.stepIndex)
    }

    handleError(error: any): Observable<undefined> {
        this.error = error
        this.isLoading = false
        this.form.markAsPristine()
        this.disableNext = false
        return of(undefined)
    }

    initializeModule(modules: Array<WorkflowModule>, stepId: string): void {

        this.modules = modules

        if (!modules) {
            return
        }

        this.setMaxEnabled()

        // set the active step
        const safeStepId: string = stepId || this.modules[0].groups[0].steps[0].id
        const stepIndices: WorkflowStepIndices = this.stepMap[safeStepId]

        this.activeModule = this.modules[stepIndices.moduleIndex]
        if (this.activeModule.action || this.activeModule.notExpandable) {
            if (this.modules.length === this.activeModuleIndex - 1) {
                // this is the last step, so just complete
                this.complete.emit()
                return
            }
            this.activeModule = this.modules[this.activeModuleIndex + 1]
        }

        this.activeGroup = this.activeModule.groups[stepIndices.groupIndex]
        this.activeStep = this.activeGroup.steps[stepIndices.stepIndex]

        this.setIsActiveFirstAndLast()
    }

    confirmDismiss(value: boolean): void {
        this.dismissConfirmation = value
        this.showDismissMessage = false
    }

    scroll(): void {
        this.scrollModule.emit()
    }

    public cloneModule(orig: WorkflowModule): WorkflowModule {
        // NOTE: has to be cloned parent-first
        const init: WorkflowModule = { ...orig }
        init.groups = init.groups.map(group => ({ ...group }))
        init.groups.forEach(group => group.steps = group.steps.map(step => ({ ...step })))
        return init
    }

    private initializeStepMap(modules: Array<WorkflowModule>, stepMap: { [stepId: string]: WorkflowStepIndices }): void {

        if (!stepMap) {
            stepMap = {}
            modules
                ?.forEach((module, moduleIndex) => module.groups
                    .forEach((group, groupIndex) => group.steps
                        .forEach((step, stepIndex) => stepMap[step.id] = { stepIndex, groupIndex, moduleIndex })))
        }

        this.stepMap = stepMap
    }

    private getLastValueModuleIndex(): number {

        let lastValueIndex: number = this.getLastValueIndex<WorkflowModule>(this.modules, module => !module.action && !module.notExpandable && this.getLastValueGroupIndex(module.groups) > -1)
        if (lastValueIndex > -1) {
            return lastValueIndex
        }

        lastValueIndex = 0
        while (lastValueIndex < this.modules.length - 1 && (!!this.modules[lastValueIndex].action || !!this.modules[lastValueIndex].notExpandable)) {
            lastValueIndex++
        }
        return lastValueIndex
    }

    private getLastValueGroupIndex(groups: Array<WorkflowStepGroup>): number {
        return this.getLastValueIndex<WorkflowStepGroup>(groups, group => this.getLastValueStepIndex(group.steps) > -1)
    }

    private getLastValueStepIndex(steps: Array<WorkflowStep>): number {
        return this.getLastValueIndex<WorkflowStep>(steps, step => step.value !== undefined)
    }

    private getLastValueIndex<T>(arr: Array<T>, predicate: (value: T, index: number, obj: T[]) => unknown): number {
        const index: number = arr.length - 1 - arr.map(a => a)
            .reverse()
            .findIndex(predicate)
        return index === arr.length ? -1 : index
    }

    private getMaxEnabledModuleIndex(): number {
        const lastValueIndex: number = this.getLastValueModuleIndex()
        return lastValueIndex < 0 ? 0 : lastValueIndex
    }

    private getMaxEnabledGroupIndex(groups?: Array<WorkflowStepGroup>): number {

        const safeGroups: Array<WorkflowStepGroup> = groups || this.maxEnabledModule.groups

        if (!safeGroups.length) {
            return undefined
        }

        const lastValueIndex: number = this.getLastValueGroupIndex(safeGroups)
        return lastValueIndex < 0 ? 0 : lastValueIndex
    }

    private removeControls(): void {
        Object.keys(this.form.controls).forEach(key => this.form.removeControl(key))
        this.form.reset()
    }

    private setMaxEnabled(): void {

        this.maxEnabledModuleIndex = this.getMaxEnabledModuleIndex()
        this.maxEnabledGroupIndex = this.getMaxEnabledGroupIndex()

        let lastValueStepIndex: number = this.getLastValueStepIndex(this.maxEnabledGroup.steps) + 1
        while (lastValueStepIndex < this.maxEnabledGroup.steps.length && this.maxEnabledGroup.steps[lastValueStepIndex].skipped) {
            lastValueStepIndex++
        }

        // increment the max value
        if (lastValueStepIndex < this.maxEnabledGroup.steps.length) {
            this.maxEnabledStepIndex = lastValueStepIndex
            return
        }

        // this is not the last group, so increment it and set the max step to 0
        if (this.maxEnabledGroupIndex < this.maxEnabledModule.groups.length - 1) {
            this.maxEnabledGroupIndex = this.maxEnabledGroupIndex + 1
            lastValueStepIndex = 0

            // get the last step in the group that isn't skipped
            while (lastValueStepIndex < this.maxEnabledGroup.steps.length && this.maxEnabledGroup.steps[lastValueStepIndex].skipped) {
                lastValueStepIndex++
            }

            // if the last step in the group isn't skipped, set the max enabled step and return
            if (!this.maxEnabledGroup.steps[lastValueStepIndex].skipped || lastValueStepIndex !== this.maxEnabledGroup.steps.length - 1) {
                this.maxEnabledStepIndex = lastValueStepIndex
                return
            }

            // TODO: the last step in the group is skipped, so go to the next group!!
        }

        // this is not the last module, so increment it and set the max step and group to 0
        if (this.maxEnabledModuleIndex < this.modules.length - 1) {
            this.maxEnabledModuleIndex = this.maxEnabledModuleIndex + 1
            this.maxEnabledGroupIndex = 0
            this.maxEnabledStepIndex = 0
            return
        }

        // we're at the last step of the last group of the last module,
        // so it is the max enabled
        this.maxEnabledModuleIndex = this.modules.length - 1
        this.maxEnabledGroupIndex = this.maxEnabledModule.groups.length - 1
        this.maxEnabledStepIndex = this.maxEnabledGroup.steps.length - 1
    }

    private setNextValidStep(): void {
        this.activeStep = this.activeGroup.steps.slice(this.maxEnabledStepIndex + 1).filter(val => !val.skipped)[0]
    }

    private setIsActiveFirstAndLast(): void {

        const isActiveLast: boolean = this.activeModuleIndex === this.modules.length - 1
            && this.activeGroupIndex === this.activeModule.groups.length - 1
            && (!this.activeGroup.steps.length || this.activeStepIndex === this.activeGroup.steps.length - 1)

        if (this.activeGroup
            && this.activeGroup.steps
            && this.activeGroup.steps.length > this.activeStepIndex
            && this.activeGroup.steps[this.activeStepIndex]
            && this.activeGroup.steps[this.activeStepIndex]?.data?.buttonLabel)
            this.buttonLabelSubject.next(this.activeGroup.steps[this.activeStepIndex].data.buttonLabel)
        else
            this.buttonLabelSubject.next(undefined)

        this.isActiveLastSubject.next(isActiveLast)

        const isActiveFirst: boolean = this.modules.filter(m => !m.action && !m.notExpandable)?.[0]?.id === this.activeModule.id
            && this.activeGroupIndex === 0
            && this.activeGroup.steps.filter(step => !step.skipped)?.[0]?.id === this.activeStep.id
        this.isActiveFirstSubject.next(isActiveFirst)
    }

    private navigateToActiveStep(): void {
        this.navigate.emit()
    }

    private getNextStepForGroup(): WorkflowStep {
        return this.activeGroup.steps.filter(step => !step.skipped)?.[0]
    }
}
