import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-dialog-template-small',
    templateUrl: './dialog-template-small.component.html',
    styleUrls: ['./dialog-template-small.component.scss'],
})
export class DialogTemplateSmallComponent implements OnInit {

    @Output() close: EventEmitter<MouseEvent> = new EventEmitter()

    @Input() contentClass: string | ReadonlyArray<string>
    @Input() hideButton: boolean
    @Input() hideCloseButton: boolean
    @Input() hideHeader: boolean
    @Input() title: string
    @Input() titleInline: boolean = false

    headerTitle: string
    inlineTitle: string

    ngOnInit(): void {
        if (this.titleInline) {
            this.inlineTitle = this.title
        } else {
            this.headerTitle = this.title
        }
    }

    emitClose(evt: MouseEvent): void {
        this.close.emit(evt)
    }
}
