import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FeatherModule } from 'angular-feather'
import { allIcons } from 'angular-feather/icons'

import { MadLibDateComponent } from './mad-lib-date/mad-lib-date.component'
import { MadLibInputComponent } from './mad-lib-input/mad-lib-input.component'
import { MadLibSelectComponent } from './mad-lib-select/mad-lib-select.component'

@NgModule({
    imports: [
        CommonModule,
        FeatherModule.pick(allIcons),
        FormsModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    declarations: [
        MadLibDateComponent,
        MadLibInputComponent,
        MadLibSelectComponent,
    ],
    exports: [
        MadLibDateComponent,
        MadLibInputComponent,
        MadLibSelectComponent,
    ],
})
export class MadLibsModule { }
