import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { CountryCurrency, Currency } from '../../address'
import { CountryBankAccountInfo } from '../../country-bank-account-config'
import { DialogService } from '../../dialogs'
import { ClientInvoice, IntlFeedback, InvoiceLineItem, RateType } from '../../modules/models'

import { CurrencyStore } from './currency.store'

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {

    private _allCurrencyCodesWithoutDecimals: Array<string> = []

    readonly USD: string = 'USD'

    get allCurrencyCodesWithoutDecimals(): Array<string> {
        return this._allCurrencyCodesWithoutDecimals
    }

    get isZeroDecimalsCurrency(): boolean {
        return this.moneyFormatOptions.minimumFractionDigits === 0
    }
    set isZeroDecimalsCurrency(value: boolean) {
        const [minimumFractionDigits, maximumFractionDigits]: Array<number> = value ? [0, 0] : [2, 2]
        this.moneyFormatOptions = {
            minimumFractionDigits,
            maximumFractionDigits,
        }
    }

    private moneyFormatOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }

    constructor(
        private dialogs: DialogService,
        private store: CurrencyStore,
    ) { }

    get(countryId: string): Observable<CountryBankAccountInfo> {
        return this.store.get(countryId)
            .pipe(
                take(1),
                map(bankInfo => {
                    const foreignCurrencies: Array<CountryCurrency> = bankInfo.country.currencies.filter(c => c.currency.alphaCode !== this.USD)
                    const usdCurrency: CountryCurrency | undefined = bankInfo.country.currencies.find(c => c.currency.alphaCode === this.USD)
                    return {
                        ...bankInfo,
                        // THESE ARE NOW BEING SET IN API
                        // foreignCurrencyConfigured: !!foreignCurrencies.length,
                        // usdConfigured: usdCurrency !== undefined,
                        // hasForeignEnabled: !!foreignCurrencies?.some(c => c.enabled),
                        // hasUSDEnabled: usdCurrency && usdCurrency.enabled,
                    }
                }),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getCurrencyCodesWithoutDecimals(): Observable<Array<string>> {
        return this.store.getAllCurrencyCodesWithoutDecimals()
            .pipe(
                take(1),
                tap(currencies => this._allCurrencyCodesWithoutDecimals = currencies),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPreferredCurrency(preference: IntlFeedback, countryCurrencies: Array<CountryCurrency>): Currency {

        const usd: Currency = countryCurrencies.find(c => c.enabled && c.currency.alphaCode === this.USD)?.currency
        if (!preference?.vendorPrefersLocalCurrency) {
            return usd
        }

        const foreign: Currency = countryCurrencies.find(c => c.enabled && c.currency.alphaCode !== this.USD)?.currency
        return foreign || usd
    }

    getFormattedMoneyAmount(money: number, currency: string): string {
        if (!money) {
            money = 0
        }
        return `${money.toLocaleString('en-US', this.moneyFormatOptions)} ${currency}`
    }

    getPaidStringRatio(invoice: ClientInvoice): number {
        return invoice?.payoutTotalLocked ? invoice?.amountInvoiced / invoice?.payoutTotal : 1
    }

    getLineTotal(invoice: ClientInvoice, lineItem: InvoiceLineItem, useUnitPrice: boolean = false, currency?: string): string {

        const unitPrice: number = !!invoice?.payoutTotalLocked && !useUnitPrice
            ? this.removeMoneyMask(lineItem.unitPriceInPayoutCurrency)
            : this.removeMoneyMask(lineItem.unitPrice)

        const qty: number = this.removeMoneyMask(lineItem.qty)

        currency = !!invoice?.payoutTotalLocked
            ? invoice.payoutCurrency
            : currency || invoice.invoiceCurrency

        return `${this.getFormattedMoneyAmount(qty * unitPrice, currency)}`
    }

    getLineItemsTotalString(invoice: ClientInvoice, useAmountInvoiced: boolean = false, currency?: string): string {
        // need to convert payment amount currency to the payout using a ratio:
        const ratio: number = this.getPaidStringRatio(invoice)

        const amountInvoiced: number = useAmountInvoiced
            ? invoice.amountInvoiced
            : invoice.payoutTotalLocked
                ? invoice?.payoutTotal
                : ratio * invoice.amountInvoiced
        return this.getFormattedMoneyAmount(amountInvoiced, invoice.payoutTotalLocked ? invoice.payoutCurrency : currency || invoice.invoiceCurrency)
    }

    getPeriodicDescription(invoice: ClientInvoice, line: InvoiceLineItem): string {

        const isFlatRateTypeWithoutOverride: boolean = line.uom === RateType.Flat && !line.uomOverride

        if (isFlatRateTypeWithoutOverride && line.qty === 1) {
            return undefined
        }

        let unit: string
        let desc: string
        switch (line.uom) {
            case RateType.Daily:
                unit = 'day'
                desc = 'day'
                break
            case RateType.Flat:
                desc = isFlatRateTypeWithoutOverride ? '' : line.uomOverride
                break
            case RateType.Hourly:
                unit = 'hr.'
                desc = 'hour'
                break
            case RateType.Monthly:
                unit = 'mo.'
                desc = 'month'
                break
            case RateType.Quarterly:
                unit = 'qtr.'
                desc = 'quarter'
                break
            case RateType.Weekly:
                unit = 'wk.'
                desc = 'week'
                break
        }
        const unitCurrency: string = invoice.payoutTotalLocked ? invoice.payoutCurrency : invoice.invoiceCurrency
        return `(${line.qty} ${desc}${line.uom !== RateType.Flat && line.qty > 1 ? 's' : ''} @ ${this.getFormattedMoneyAmount(line.unitPriceInPayoutCurrency, unitCurrency)}${!!unit ? `/${unit}` : ''})`
    }

    getTotalDueString(invoice: ClientInvoice, transactionFee: number = 0): string {
        // need to convert payment amount currency to the payout using a ratio:
        const ratio: number = this.getPaidStringRatio(invoice)
        const amountInvoiced: number = invoice.payoutTotalLocked ? invoice?.payoutTotal : invoice.amountInvoiced / ratio
        let amountPaid: number = (invoice.amountPaid || 0 + invoice.scheduledPaymentAmount || 0) / ratio
        amountPaid = this.isZeroDecimalsCurrency ? Math.round(amountPaid) : amountPaid
        const transactionFeeAmount: number = transactionFee// invoice?.scheduledPaymentTransactionFeeAmount / ratio
        let dueString: string = this.getFormattedMoneyAmount((amountInvoiced - amountPaid) + (transactionFeeAmount && !invoice?.paid && !invoice.payoutTotalLocked ? transactionFeeAmount : 0), invoice.payoutTotalLocked ? invoice.payoutCurrency : invoice.invoiceCurrency)
        if (transactionFeeAmount && !invoice?.paid && invoice.payoutTotalLocked) {
            dueString += ' + ' + this.getFormattedMoneyAmount(transactionFeeAmount, invoice.invoiceCurrency)
        }
        return dueString
    }

    getTotalPaidStringInPayoutCurrency(invoice: ClientInvoice, showNonPayoutTotalLockedInPayoutCurrency: boolean): string {
        if (invoice.payoutTotalLocked) {
            const ratio: number = this.getPaidStringRatio(invoice)
            const amountPaid: number = (invoice.amountPaid || 0 + invoice.scheduledPaymentAmount || 0) / ratio
            const transactionFeeAmount: number = invoice.transactionFeeAmount / ratio

            return this.getFormattedMoneyAmount(((amountPaid || 0) + (transactionFeeAmount || 0)),
                invoice.payoutCurrency)
        } else if (invoice.payoutCurrency === this.USD) {
            return this.getFormattedMoneyAmount(((invoice.amountPaid || 0) + (invoice.transactionFeeAmount || 0)),
                invoice.payoutCurrency)
        } else if (invoice.payoutCurrency !== this.USD && showNonPayoutTotalLockedInPayoutCurrency) { // we will assume that the payout was whole of payout amount, and in the payout currency:
            return this.getFormattedMoneyAmount((invoice.amountPaid || 0 > 0 ? invoice.payoutTotal || 0 : 0), invoice.payoutCurrency)
        } else if (invoice.payoutCurrency !== this.USD && !showNonPayoutTotalLockedInPayoutCurrency) {
            return this.getFormattedMoneyAmount((invoice.amountPaid || 0), invoice.invoiceCurrency)
        } else {
            return this.getFormattedMoneyAmount(((invoice.amountPaid || 0) + (invoice.transactionFeeAmount || 0)),
                invoice.invoiceCurrency)
        }
    }

    getTotalPaidStringInInvoiceCurrency(invoice: ClientInvoice): string {
        const amountPaid: number = invoice.amountPaid
        const transactionFeeAmount: number = invoice.transactionFeeAmount

        return this.getFormattedMoneyAmount(((amountPaid || 0) + (transactionFeeAmount || 0)), invoice.invoiceCurrency)
    }

    getTotalScheduledStringInInvoiceCurrency(invoice: ClientInvoice): string {
        const amountScheduled: number = invoice.scheduledPaymentAmount + (invoice.scheduledPaymentTransactionFeeAmount || 0)
        return this.getFormattedMoneyAmount(amountScheduled || 0, invoice.invoiceCurrency)
    }

    getUsingCurrency(currencyAlpha: string, resultCallback: () => string): string {
        const lastIsZeroDecimalsCurrency: boolean = this.isZeroDecimalsCurrency
        this.isZeroDecimalsCurrency = this._allCurrencyCodesWithoutDecimals.includes(currencyAlpha)
        const result: string = resultCallback()
        this.isZeroDecimalsCurrency = lastIsZeroDecimalsCurrency
        return result
    }

    removeMoneyMask(val: string | number): number {
        if (typeof val === 'string') {
            return Number(val.replace('$', '').replace(',', ''))
        }
        return val
    }
}
