import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { Profile } from '../../modules/models'

import { ProfileService } from './profile.service'

@Injectable({
    providedIn: 'root',
})
export class ProfileResolver implements Resolve<Profile> {

    constructor(
        private profiles: ProfileService,
    ) { }

    resolve(): Observable<Profile> {
        return this.profiles.initialize()
            .pipe(
                take(1),
            )
    }
}
