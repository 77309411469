import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatExpansionPanel } from '@angular/material/expansion'
import { combineLatest, Observable, Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'

import { UserService } from '../../../../../core/services'
import { WorkflowModule, WorkflowStep } from '../models'
import { WorkflowService } from '../services'
import { WorkflowGroupComponent } from '../workflow-group/workflow-group.component'

@Component({
    selector: 'app-workflow-module',
    templateUrl: './workflow-module.component.html',
    styleUrls: ['./workflow-module.component.scss'],
})
export class WorkflowModuleComponent implements AfterViewInit, OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @ViewChild(MatExpansionPanel) panel: MatExpansionPanel
    @ViewChild(WorkflowGroupComponent) groupComponent: WorkflowGroupComponent

    @Input() form: FormGroup
    @Input() noStepImage: boolean
    @Input() isDialog: boolean
    @Input() hideStepIndicators: boolean = false
    @Input() workflowModule: WorkflowModule
    @Input() workflowModuleIndex: number

    @Output() action: EventEmitter<string> = new EventEmitter()
    // can't just use "change" here b/c it's a protected event and will fire on click
    @Output() changed: EventEmitter<void> = new EventEmitter()

    activeModule$: Observable<WorkflowModule>
    hidden: boolean
    maxEnabledModuleIndex$: Observable<number>
    workflowModuleLabel: string

    get disabled$(): Observable<boolean> {
        return combineLatest([
            this.workflow.isLoading$,
            this.maxEnabledModuleIndex$,
        ])
            .pipe(
                map(([loading, max]) => loading || this.workflowModuleIndex > max),
            )
    }
    get hasAction(): boolean { return !!this.workflowModule?.action }
    get isNotExpandable(): boolean { return !!this.workflowModule?.notExpandable }
    get isLoading$(): Observable<boolean> { return this.workflow.isLoading$ }

    constructor(
        private users: UserService,
        public workflow: WorkflowService,
    ) { }

    ngOnInit(): void {
        this.activeModule$ = this.workflow.activeModule$
        this.maxEnabledModuleIndex$ = this.workflow.maxEnabledModuleIndex$
        this.workflowModuleLabel = typeof this.workflowModule.label === 'string' ? this.workflowModule.label : this.workflowModule.label(this.users.userSnapshot)
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    ngAfterViewInit(): void {
        this.workflow.scrollModule
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.scroll()),
            )
            .subscribe()
    }

    afterExpand(): void {
        this.hidden = false
        this.scroll()
    }

    afterCollapse(): void {
        this.hidden = true
    }

    goToModule(): void {

        // if this module has an action, disable the module click
        if (this.workflow.isLoading || !!this.hasAction || !!this.isNotExpandable) {
            return
        }

        if (this.workflowModuleIndex <= this.workflow.maxEnabledModuleIndex && this.workflowModuleIndex !== this.workflow.activeModuleIndex) {
            this.workflow.goToModule(this.workflowModuleIndex)
            this.change()
            this.scroll()
        }
    }

    onAction(event: Event): void {
        event?.stopPropagation()
        this.action.emit(this.workflowModule.action)
    }

    change(): void {
        this.changed.emit()
    }

    completed(): boolean {
        return this.workflow.isModuleComplete(this.workflowModuleIndex)
    }

    scroll(): void {
        if (this.workflowModule.id !== this.workflow.activeModule.id) {
            return
        }

        setTimeout(() => {
            // TODO: pass in the scroll element and offsets so that the workflow module isn't dependent on its UI
            const scrollDiv: Element = document.getElementsByClassName(this.isDialog ? 'wide-dialog' : 'router-outlet')[0]
            const panel: HTMLElement = document.querySelector(`[moduleId="${this.workflow.activeModule.id}"]`)
            const offsetValue: number = this.isDialog ? 120 : 90
            const offset: number = panel?.getBoundingClientRect().top - offsetValue
            scrollDiv?.scrollBy(0, offset)
        }, 375)
    }
}
