import { CurrencyPipe } from '@angular/common'
import { Injectable } from '@angular/core'

import { ClientInvoice } from '../../../modules/models'
import { ClientInvoiceModel } from '../../models'

@Injectable({
    providedIn: 'root',
})
export class InvoiceBulkActionsService {
    getInvoiceTotals(invoices: ClientInvoice[]): { currency: string, total: number }[] {
        const totalsMap: { currency: string, total: number }[] = []

        const invoiceModels: ClientInvoiceModel[] = invoices.map(i => new ClientInvoiceModel(i))
        const currencies: Set<string> = new Set<string>(invoiceModels.map(i => i.payoutCurrency))
        currencies.forEach(currency => {
            const total: number = invoiceModels
                .filter(i => i.payoutCurrency === currency)
                .map(i => i.localCurrencyDueAmount)
                .reduce((prev, current) => prev + current, 0)

            totalsMap.push({ currency, total })
        })

        return totalsMap
    }

    getInvoiceBulkApprovalConfirmationHtml(invoices: ClientInvoice[]): string {
        const currencyPipe: CurrencyPipe = new CurrencyPipe(navigator.language, 'USD')

        const total: number = invoices.reduce<number>((accumulatedTotal, invoice) => invoice.amountInvoiced + accumulatedTotal, 0)
        const invoicesList: string = invoices
            .map(i => `<li><strong>Invoice ${i.invoiceNumber}:</strong> ${currencyPipe.transform(i.amountInvoiced)}</li>`)
            .join('\n')

        const displayWarning: boolean = invoices.some(i => !!i.validationIssues.length)

        return `
Are you sure you want to approve all these invoices?
<ul>
    ${invoicesList}
</ul>
<strong>Total: </strong> ${currencyPipe.transform(total)}
${displayWarning ? '<p class="body2 warn">One or more invoices do not match the work order. Approve anyway?</p>' : ''}
`
    }
}
