import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

// need to import from specific file to avoid circular reference
import { UrlService } from '../../core/services/url.service'
import { Country } from '../models/country.interface'

@Injectable({
    providedIn: 'root',
})
export class AddressStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    getCountries(): Observable<Array<Country>> {
        return this.http.get<Array<Country>>(this.urls.api.countries())
    }
}
