import { DateType, LongStringType, SignatureType, StringType, TemplateBaseType } from './document-sign'

export class DocumentSignStepDefinitionTemplateValue {

    inputInstruction: string
    isInput: boolean
    isPreInviteInput: boolean
    fieldName: string
    name: string
    required: boolean
    value: string
    valueType: string

    static getBaseTypeForValueType(valueType: string): TemplateBaseType {
        if (enumHasValue(StringType, valueType)) { return TemplateBaseType.String }
        if (enumHasValue(LongStringType, valueType)) { return TemplateBaseType.LongString }
        // if (enumHasValue(intType, valueType)) { return 'Int' ; }
        // if (enumHasValue(decimalType, valueType)) { return 'Decimal' ; }
        if (enumHasValue(DateType, valueType)) { return TemplateBaseType.Date }
        if (enumHasValue(SignatureType, valueType)) { return TemplateBaseType.Signature }

        function enumHasValue<T>(enumType: T, value: unknown): boolean {
            return Object.values(enumType).includes(value)
        }
    }
}
