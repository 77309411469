import { AbstractControl } from '@angular/forms'

import { ValidatorHelper } from './validator-helper.service'

export function ZipValidator(control: AbstractControl): { [key: string]: any } | null {

  // If there is no zip to evaluate, do not add the error b/c this is an zip validator, not a required validator.
  // If the zip is required, it will be caught by a required validator.
  const output = control && control.value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(control.value)
    ? { 'invalidZip': true }
    : null

  return output
}

export function formatZip(control: AbstractControl): void {
  new ValidatorHelper().formatNumber(control, [5])
}
