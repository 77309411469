import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'

import { StorageKeyAuth } from '../../auth/models'
import { StorageKey } from '../../common/global'

import { ProfileService } from './profile.service'
import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class ProfileNameGuard implements CanActivate {

    constructor(
        private profiles: ProfileService,
        private router: Router,
        private urls: UrlService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // TODO: extract to method that both auth and name guard uses

        // if the profile has placeholder, display name form
        // if not, but there's an unfinished invite in localstorgae, redirect to a blank name form and call the update profile endpoint with the existing profile along with invite id
        const inviteId = localStorage.getItem(StorageKey.unfinishedTeamInviteId)
        return this.profiles.initialize()
            .pipe(
                take(1),
                switchMap((profile) => {
                    if (this.profiles.hasNamePlaceholder() || (!this.profiles.isAccountVerified() && !this.profiles.profileSnapshot.emailAddress.verificationSent)) {

                        let existingPath = localStorage.getItem(StorageKeyAuth.REDIRECT_ON_AUTH)
                        if (!!existingPath && existingPath === '/liquid/home' && state.url !== '/liquid/home') {
                            existingPath = undefined
                        }

                        if (!existingPath) {
                            localStorage.setItem(StorageKeyAuth.REDIRECT_ON_AUTH, state.url)
                        }
                        this.router.navigate([this.urls.path.setName], { queryParams: { inviteId } })
                        return of(false)
                    }
                    return of(true)
                }),
            )
    }
}
