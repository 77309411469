import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'billingInvoiceStatusColor',
})
export class BillingInvoiceStatusColorPipe implements PipeTransform {

    transform(status: any): string {

        switch (status) {
            case 'Overdue':
            case 'Unpaid':
                return 'error chip-status-badge'
            case 'Paid':
            case 'Pending':
                return 'success chip-status-badge'
            case 'Upcoming':
                return 'warn chip-status-badge'
            case 'Due':
                return 'warn chip-status-badge'
        }
    }
}
