import { DeliverableRateTier } from './deliverable-rate-tier.model'
import { RateType } from './rate-type.enum'
import { RecurrenceParameters } from './recurrence-parameters.model'

// TODO: change to interface
export class DeliverableRate {
    agreedByClientName: string
    agreedByClientOnTimestamp: number
    agreedByClientOrganizationName: string
    agreedByWorkerName: string
    agreedByWorkerOnTimestamp: number
    agreedByWorkerOrganizationName: string
    createdTimestamp: number
    currency: string
    customRateDescription: string
    deliverableId: string
    estimatedTotalFee: number
    hoursLimit: number
    hoursMinimum: number
    id: string
    quantity?: number | undefined
    rate: number
    rateTierResetParameters?: RecurrenceParameters
    rateTiers: Array<DeliverableRateTier> = []
    rateType: RateType
    recurrenceParameters?: RecurrenceParameters
    unitOverride?: string
}
