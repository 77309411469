export enum DefaultTemplateFieldNames {
    clientAddress = 'clientAddress',
    clientName = 'clientName',
    clientRepName = 'clientRepName',
    clientRepSignature = 'clientRepSignature',
    clientRepTitle = 'clientRepTitle',
    clientSignatureDate = 'clientSignatureDate',
    clientStructure = 'clientStructure',
    companyAddress = 'companyAddress',
    companyIncInfo = 'companyIncInfo',
    companyName = 'companyName',
    companyRepName = 'companyRepName',
    companyRepSignature = 'companyRepSignature',
    companyRepTitle = 'companyRepTitle',
    conflictList = 'conflictList',
    conflictsList = 'conflictsList',
    consultantName = 'consultantName',
    currentDate = 'currentDate',
    date = 'date',
    governingState = 'governingState',
    inventionList = 'inventionList',
    inventionsList = 'inventionsList',
    masterContractEndDate = 'mcEndDate',
    masterContractStartDate = 'mcStartDate',
    multiText = 'multiText',
    signerAddress = 'signerAddress',
    signerName = 'signerName',
    signerSignature = 'signerSignature',
    signerSignatureDate = 'signerSignatureDate',
    signerTitle = 'signerTitle',
    text = 'text',
    vendorAddress = 'vendorAddress',
    vendorName = 'vendorName',
    vendorRepName = 'vendorRepName',
    vendorRepSignature = 'vendorRepSignature',
    vendorRepTitle = 'vendorRepTitle',
    vendorSignatureDate = 'vendorSignatureDate',
    vendorStructure = 'vendorStructure',
}
