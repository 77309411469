import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router'

import { SharedModule } from '../shared/shared.module'

import { SiteComponent } from './site.component'

const routes: Routes = [
    {
        path: '',
        component: SiteComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: SiteComponent,
            },
            {
                path: 'generic-contract',
                loadChildren: () => import('./site-generic-contract/site-generic-contract.module').then(m => m.SiteGenericContractModule),
            },
            {
                path: 'plans',
                loadChildren: () => import('../../modules/plans/plans.module').then(m => m.PlansModule),
            },
            {
                path: 'privacy',
                loadChildren: () => import('./site-privacy/site-privacy.module').then(m => m.SitePrivacyModule),
            },
            {
                path: 'terms',
                loadChildren: () => import('./site-terms/site-terms.module').then(m => m.SiteTermsModule),
            },
            {
                path: 'express-landing',
                loadChildren: () => import('../express-setup-landing/express-setup-landing.module').then(m => m.ExpressSetupLandingModule),
            },
        ],
    },
]

@NgModule({
    declarations: [
        SiteComponent,
    ],
    exports: [
        ReactiveFormsModule,
        SiteComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ],
})
export class SiteModule { }
