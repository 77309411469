import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

export function AlphaCharactersValidator(): ValidatorFn {
    const alphaCharactersRegex: RegExp = /^[a-zA-Z]+$/

    return (form: FormGroup): ValidationErrors | undefined => {
        // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
        // If the value is required, it will be caught by a required validator.
        const value: string = !!form?.value ? `${form.value}` : undefined

        return !!value && !alphaCharactersRegex.test(value)
            ? { alpha: true }
            : undefined
    }
}
