import { AbstractControl } from '@angular/forms'
import { isValidNumber, parse } from 'libphonenumber-js'

export function PhoneValidator(control: AbstractControl): {[key: string]: boolean} | null {
    const number = control.value
    if (!number || number.trim().length === 0) {
        return null
    }
    try {
        const parsed = parse(number, 'US')
        const isValid = isValidNumber(parsed)
        if (!isValid) {
            return { 'phone': true }
        } else {
            return null
        }
    } catch {
        return { 'phone': true }
    }
}
