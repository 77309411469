import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { UrlService } from '../../core/services'

import { SystemHealthService } from './system-health.service'

@Injectable({
    providedIn: 'root',
})
export class SystemHealthGuard implements CanActivate {

    constructor(
        private system: SystemHealthService,
        private router: Router,
        private urls: UrlService,
        private route: ActivatedRoute,
    ) {
        this.route.queryParamMap.subscribe(params => {
            params.keys.forEach((k: string) => {
                if (k.startsWith('doIt')) {
                    localStorage.setItem(k, params.get(k))
                }
            })
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.system.getSystemHealth()
            .pipe(
                map(response => !!response),
                tap(isUp => {
                    if (!isUp) {
                        this.router.navigateByUrl(this.urls.route.upDownError())
                    }
                }),
            )
    }

}
