import { Routes } from '@angular/router'

import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { RootComponent } from './modules/root/root.component'
import { SignOutComponent } from './sign-out/sign-out.component'
import { SignUpComponent } from './sign-up/sign-up.component'
import { SystemHealthGuard } from './system-health/services'

export const ROUTES: Routes = [
    {
        path: '',
        component: RootComponent,
        canActivate: [
            SystemHealthGuard,
        ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home',
            },
            {
                path: 'auth-callback',
                component: AuthCallbackComponent,
            },
            {
                path: 'company/:id',
                loadChildren: () => import('./modules/public-organization/public-organization.module').then(m => m.PublicOrganizationModule),
            },
            {
                path: 'error',
                loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
            },
            {
                path: 'join-org/:id',
                loadChildren: () => import('./modules/join-organization/join-organization.module').then(m => m.JoinOrganizationModule),
            },
            {
                path: 'join-team/:id',
                loadChildren: () => import('./modules/join-team/join-team.module').then(m => m.JoinTeamModule),
            },
            {
                path: 'liquid',
                loadChildren: () => import('./modules/liquid/liquid-app.module').then(m => m.LiquidAppModule),
            },
            {
                path: 'profile/:id',
                loadChildren: () => import('./modules/public-organization/public-organization.module').then(m => m.PublicOrganizationModule),
            },
            {
                path: 'set-name',
                loadChildren: () => import('./set-name/set-name.module').then(m => m.SetNameModule),
            },
        ],
    },
    {
        path: '404',
        loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule),
    },
    {
        path: 'code',
        loadChildren: () => import('./login/log-in.module').then(m => m.LoginModule),
    },
    {
        path: 'create-account',
        loadChildren: () => import('./create-account/create-account.module').then(m => m.CreateAccountModule),
    },
    {
        path: 'down',
        loadChildren: () => import('./system-health/system-health.module').then(m => m.SystemHealthModule),
    },
    {
        path: 'sign-up',
        component: SignUpComponent,
    },
    {
        path: 'sign-out',
        component: SignOutComponent,
    },
    {
        path: 'verify',
        loadChildren: () => import('./verify-email/verify-email.module').then(m => m.VerifyEmailModule),
    },
    {
        path: '**',
        redirectTo: '/404',
    },
]
