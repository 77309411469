import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function IbanValidator(countryCode2?: string): ValidatorFn {

    const CODE_LENGTHS: { [key: string]: number } = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
        TL: 23, UA: 29, VA: 22, VG: 24, XK: 20,
    }

    return (control: AbstractControl): ValidationErrors | undefined => {

        const validateIban: (iban: string) => boolean = (iban: string): boolean => {
            let newIban: string = iban.toUpperCase()

            const modulo: (divident: any, divisor: number) => number = (divident: any, divisor: number) => {
                let currentDivident: string = ''
                let currentRest: string = ''

                // tslint:disable-next-line: forin
                for (const i in divident) {
                    const character: string = <string>divident[i]
                    const operator: number = Number(currentRest + '' + currentDivident + '' + character)

                    if (operator < divisor) {
                        currentDivident += '' + character
                    } else {
                        currentRest = (operator % divisor).toString()
                        if (Number(currentRest) === 0) {
                            currentRest = ''
                        }
                        currentDivident = ''
                    }
                }
                currentRest += '' + currentDivident
                if (currentRest === '') {
                    currentRest = '0'
                }
                return Number(currentRest)
            }

            const sanitizedIban: string = String(iban).toUpperCase().replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
            const code: Array<string> = sanitizedIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest

            if (!code || sanitizedIban.length !== CODE_LENGTHS[code[1]] || (!!countryCode2 && countryCode2 !== code[1])) {
                return false
            }

            newIban = newIban.substring(4) + newIban.substring(0, 4)
            newIban = newIban.replace(/[A-Z]/g, (match: string) => (match.charCodeAt(0) - 55).toString())

            return modulo(newIban, 97) === 1
        }

        // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
        // If the value is required, it will be caught by a required validator.
        const inputIban: string = !!control?.value ? `${control.value}` : undefined
        return !!inputIban && !validateIban(inputIban)
            ? { iban: true }
            : undefined
    }
}
