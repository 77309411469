import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FeatherModule } from 'angular-feather'

import { ChipListComponent } from './chip-list/chip-list.component'

@NgModule({
    declarations: [
        ChipListComponent,
    ],
    exports: [
        ChipListComponent,
    ],
    imports: [
        CommonModule,
        FeatherModule,
        MatChipsModule,
        MatIconModule,
        MatTooltipModule,
    ],
})
export class ChipListModule { }
