import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ConfirmDialogData } from './confirm-dialog-data.interface'

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {

    description: string = 'DIALOG DESCRIPTION'
    disableNoButton: boolean = false
    noButtonText: string = undefined
    title: string = 'DIALOG TITLE'
    yesButtonText: string = 'Yes'

    constructor(
        private dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData,
    ) {
        if (!data) {
            return
        }

        if (data.title) {
            this.title = data.title
        }
        if (data.description) {
            this.description = data.description
        }
        if (data.yesButtonText) {
            this.yesButtonText = data.yesButtonText
        }
        if (data.disableNoButton) {
            this.disableNoButton = data.disableNoButton
        }
        if (!!data.noButtonText) {
            this.noButtonText = data.noButtonText
        }
    }

    yesClicked(): void {
        this.dialogRef.close(true)
    }

    noClicked(): void {
        this.dialogRef.close(false)
    }
}
