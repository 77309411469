import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function UrlStartValidator(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | undefined => {

        // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
        // If the value is required, it will be caught by a required validator.

        const url: string = !!control?.value ? `${control.value}` : undefined
        return !!url && !url.startsWith('http://') && !url.startsWith('https://')
            ? { urlStart: true }
            : undefined
    }
}
