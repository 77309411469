import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { WorkOrderApprovalPolicy } from '../../modules/models/work-order-approval-policy.model'
import { WorkOrderApprover } from '../../modules/models/work-order-approver.model'

export interface ApiWorkOrderApprover {
    approverOrganizationMembershipId: string
    order: number
}

@Injectable({
    providedIn: 'root',
})
export class WorkOrderApprovalsService {

    constructor(
        private readonly http: HttpClient,
    ) { }

    getOrganizationWorkOrderApprovalPolicy(orgId: string): Observable<WorkOrderApprovalPolicy> {
        return this.http.get<WorkOrderApprovalPolicy>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}/workorder-approval`)
    }

    getCurrentWorkOrderApprover(orgId: string, workOrderId: string): Observable<WorkOrderApprover> {
        return this.http.get<WorkOrderApprover>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}/workorder-approval/current?workOrderId=${workOrderId}`)
    }

    addWorkOrderApprovalResponse(orgId: string, workOrderId: string, isHiringManager: boolean, approve: boolean, message = ''): Observable<void> {
        const queryString = `workOrderId=${workOrderId}&isHiringManager=${isHiringManager}&approve=${approve}&message=${message}`;
        return this.http.put<void>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}/workorder-approval/response?${queryString}`, undefined)
    }

    setOrganizationWorkOrderApprovalPolicy(orgId: string, status: boolean, isHiringManager: boolean = undefined): Observable<void> {
        let request = `${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}/workorder-approval?`
        if (status !== undefined) {
            request += 'status=' + status
        }
        if (isHiringManager !== undefined) {
            request += '&isHiringManager=' + isHiringManager
        }
        return this.http.put<void>(request, undefined)
    }

    setOrganizationWorkOrderApprovers(orgId: string, approvers: ApiWorkOrderApprover[]): Observable<void> {
        let request = `${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}/workorder-approval/approvers`
        return this.http.put<void>(request, approvers)
    }
}
