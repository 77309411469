import { CdkAccordionModule } from '@angular/cdk/accordion'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatChipsModule } from '@angular/material/chips'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { FeatherModule } from 'angular-feather'
import { allIcons } from 'angular-feather/icons'

import { ChipListModule } from '../chip-list/chip-list.module'
import { TableModule } from '../table/table.module'

import { CtaBannerComponent } from './components/cta-banner/cta-banner.component'
import { FileDropInputComponent } from './components/file-drop-input/file-drop-input.component'
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component'
import { FilterAccordionComponent } from './components/filter-accordion/filter-accordion.component'
import { HelpTooltipComponent } from './components/help-tooltip/help-tooltip.component'
import { SortSelectComponent } from './components/sort-select/sort-select.component'
import { TaxIdTypeFieldComponent } from './components/tax-id-type-field/tax-id-type-field.component'
import { YesNoQuestionComponent } from './components/yes-no-question/yes-no-question.component'
import { AutofocusDirective } from './directives/autofocus.directive'
import { DynamicContainerDirective } from './directives/dynamic-container/dynamic-container.directive'
import { GaTrackerDirective } from './directives/ga-tracker/ga-tracker.directive';
import { AnalyticsDataDirective } from './directives/analytics-data/analytics-data.directive'

@NgModule({
    imports: [
        CdkAccordionModule,
        ChipListModule,
        CommonModule,
        FeatherModule.pick(allIcons),
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        OverlayModule,
        PortalModule,
        ReactiveFormsModule,
        RouterModule,
        TableModule,
        MatDialogModule,
    ],
    providers: [],
    declarations: [
        AnalyticsDataDirective,
        AutofocusDirective,
        CtaBannerComponent,
        DynamicContainerDirective,
        FileDropInputComponent,
        FileUploadDialogComponent,
        FilterAccordionComponent,
        GaTrackerDirective,
        HelpTooltipComponent,
        SortSelectComponent,
        TaxIdTypeFieldComponent,
        YesNoQuestionComponent,
    ],
    exports: [
        AnalyticsDataDirective,
        AutofocusDirective,
        CtaBannerComponent,
        DynamicContainerDirective,
        FeatherModule,
        FilterAccordionComponent,
        GaTrackerDirective,
        HelpTooltipComponent,
        SortSelectComponent,
        TaxIdTypeFieldComponent,
        YesNoQuestionComponent,
        FileDropInputComponent,
    ],
})
export class LiquidCommonModule { }
