import { Pipe, PipeTransform } from '@angular/core'

import { BusinessStructureType } from '../modules/models'

@Pipe(
    {
        name: 'incorporationState',
    },
)
export class IncorporationStatePipe implements PipeTransform {

    transform(structure: BusinessStructureType, incorporationState: string, addressState: string, isLlc: boolean): string {

        // if we don't have a structure, we can't do anything
        if (!structure) {
            return ''
        }

        if (structure === BusinessStructureType.Sole) {
            return 'a consultant'
        }

        const newStructure: string = isLlc
            ? 'LLC'
            : structure

        const safeState: string = incorporationState || addressState || ''

        const aAn: string = 'aio'.includes(safeState[0]?.toLowerCase()) ? 'an' : 'a'

        return `${aAn} ${safeState} ${newStructure}`
    }
}
