import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

import { OrderType } from '../../modules/models'

import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class OrderTypeGuard implements CanActivate {

    constructor(
        private users: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const orderType: OrderType = route.data.orderType

        if (orderType === OrderType.PurchaseOrder) {
            return true
        }

        if (orderType === OrderType.WorkOrder && this.users.isServiceVendor) {
            return true
        }

        return false
    }
}
