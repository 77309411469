import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-root-component',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './root.component.html'
})
export class RootComponent {

}
