import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { StripeCharge } from '../../modules/models'
import { ManualCharge } from '../../modules/models/manual-charge.model'
import { BulkPaymentRequest, PaymentResult } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class PaymentStore {

    constructor(
        private httpClient: HttpClient,
        private readonly urlService: UrlService,
    ) { }

    createManualCharge(charge: ManualCharge): Observable<ManualCharge> {
        return this.httpClient.post<ManualCharge>(environment.liquidApiSettings.apiServicePrefix + `/invoices/payments/manualCharges`,
            charge)
    }

    initiatePayments(bulkPaymentRequest: BulkPaymentRequest): Observable<PaymentResult[]> {
        return this.httpClient.post<PaymentResult[]>(
            this.urlService.api.invoiceBulkPay(),
            {
                invoices: bulkPaymentRequest.invoices.map(i => ({
                    invoiceId: i.invoice.id,
                    fxRateQuoteId: i.fxRateQuote?.id
                })),
                paymentAccountId: bulkPaymentRequest.paymentAccountId,
            },
        )
    }

    payClientInvoice(clientInvoiceId: string, paymentAccountId: string, payOnTimestamp: number, fxRateQuoteId: string, scheduledForLater: boolean): Observable<StripeCharge> {
        return this.httpClient.post<StripeCharge>(environment.liquidApiSettings.apiServicePrefix + `/invoices/payments`,
            { clientInvoiceId, paymentAccountId, payOnTimestamp, fxRateQuoteId, scheduledForLater, })
    }

    payUnknownClientInvoice(clientInvoiceId: string, stripeTokenId: string, idempotencyKey: string): Observable<StripeCharge> {
        return this.httpClient.post<StripeCharge>(environment.liquidApiSettings.apiServicePrefix + `/invoices/unknown-client/payments`,
            { clientInvoiceId, stripeTokenId, idempotencyKey })
    }
}
