export enum UserRoles {
    ADMINISTRATOR = 'Administrator',
    GENERAL_COUNSEL = 'General Counsel',
    CONTRACT_MANAGER = 'Contract Manager',
    CONTRACT_SIGNER = 'Contract Signer',
    WORK_ORDER_PROCESSOR = 'Work Order Processor',
    CONTROLLER = 'Controller',
    ACCOUNTANT = 'Accountant',
    INVOICE_PAYER = 'Invoice Payer',
    INVOICE_PROCESSOR = 'Invoice Processor',
    HIRING_MANAGER = 'Hiring Manager',
}
