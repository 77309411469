export enum GAAction {
    BULK_APPROVE = 'Bulk Approve',
    BULK_INVITE = 'Bulk Invite',
    BULK_INVOICE = 'Bulk Invoice',
    BULK_PAY_COMPLETED = 'Bulk Pay Completed',
    BULK_PAY_STARTED = 'Bulk Pay Started',
    BULK_WORK_ORDER = 'Bulk Work Orders',
    INVOICE_EXISTING_CLIENT = 'Invoice Existing Client',
    INVOICE_EXISTING_VENDOR = 'Invoice Existing Vendor',
    INVOICE_NEW_CLIENT = 'Invoice New Client',
    INVOICE_NEW_VENDOR = 'Invoice New Vendor',
    INVOICE_SENT = 'Invoice Sent',
    PURCHASE_ORDER_NAME = 'Name Purchase Order',
    PURCHASE_ORDER_SEND = 'Send Purchase Order',
}
