import { Pipe, PipeTransform } from '@angular/core'

import { BusinessTeamMemberStates } from '../modules/models'

@Pipe({
    name: 'businessStatusColor',
})
export class BusinessStatusColorPipe implements PipeTransform {

    transform(status: BusinessTeamMemberStates): string {

        switch (status?.toLowerCase()) {
            case BusinessTeamMemberStates.ONBOARDED.toLowerCase():
            case BusinessTeamMemberStates.INVITED.toLowerCase():
                return 'success'
            case BusinessTeamMemberStates.IN_PROCESS.toLowerCase():
            case BusinessTeamMemberStates.UPDATING.toLowerCase():
            case BusinessTeamMemberStates.NOT_SENT.toLowerCase():
                return 'warn'
            case BusinessTeamMemberStates.HIDDEN.toLowerCase():
                return 'error'
            default:
                return ''
        }
    }

}
