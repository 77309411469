import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'

import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class GuestGuard implements CanActivate {
    readonly allowedGuestPaths: Array<string> = [
        '/invoices/guest',
        '/express-setup',
        '/express-landing',
    ]

    constructor(
        private router: Router,
        private urls: UrlService,
        private users: UserService,
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.users.isUserGuest && !this.allowedGuestPaths.some(p => state.url.includes(p))) {
            this.router.navigateByUrl(this.urls.route.acceptExpressClientInvite())
            return false
        }

        return true
    }

}
