<span
    class="wrapper body1"
    [ngClass]="{ 'empty': !isValid, 'error': !!control.errors && (touched || saved) && !control.disabled && editable, 'primary100': !control.errors || !editable, disabled: control.disabled && editable, 'readonly': control.disabled }"
>
    <input
        #trigger
        #tooltip="matTooltip"
        [matDatepicker]="picker"
        [max]="max"
        [min]="min"
        [formControl]="control"
        [matTooltip]="!!errorMessage && !control.disabled && !!control.errors && editable ? errorMessage : undefined"
        [matTooltipClass]="'madlib-error'"
        [matTooltipPosition]="'above'"
        class="native-input label1"
    />
    <i-feather
        name="chevron-down"
        *ngIf="!control.disabled"
        [ngClass]="{ 'error': !!control.errors && (touched || saved) && !control.disabled && editable, 'primary100': !control.errors || !editable }"
        (click)="open()"
    ></i-feather>
</span>

<mat-datepicker #picker></mat-datepicker>