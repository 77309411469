import { Injectable } from '@angular/core'

import { Address } from '../../address'
import { PaymentAccount, PaymentAccountDto, PaymentAccountStatus, PaymentAccountSupertype, PaymentAccountType } from '../../core/models'

@Injectable({
    providedIn: 'root',
})
export class PaymentAccountFactory {

    constructor() { }

    create(dto: PaymentAccountDto): PaymentAccount {

        const account: PaymentAccount = {
            accountHolderName: dto.accountHolderName,
            accountHolderType: dto.accountHolderType,
            address: this.createAddress(dto),
            bankName: dto.bankName,
            bankInformationReviewDecision: dto.bankInformationReviewDecision,
            bankInformationOverride: dto.bankInformationOverride,
            brand: dto.brand,
            country: dto.country,
            created: new Date(dto.createdTimestamp),
            createdBy: dto.createdBy,
            currency: dto.currency,
            deactivatedBy: dto.deactivatedBy,
            expirationMonth: dto.expMonth,
            expirationYear: dto.expYear,
            id: dto.id,
            default: dto.default,
            forSubscription: dto.forSubscription,
            last4: dto.last4,
            microdepositKickedOff: dto.microdepositKickedOff,
            microdepositVerificationComplete: dto.microdepositVerificationComplete,
            microdepositVerificationFailed: dto.microdepositVerificationFailed,
            microdepositVerificationId: dto.microdepositVerificationId,
            microdepositVerificationRemainingAttempts: dto.microdepositVerificationRemainingAttempts,
            organizationId: dto.organizationId,
            routingNumberAbaLast4: dto.routingNumberAbaLast4,
            selectedBankSwiftCode: dto.selectedBankSwiftCode,
            status: (dto.accountHolderInfoRequired ? PaymentAccountStatus.PendingAccountHolderInfo : PaymentAccountStatus[Object.keys(PaymentAccountStatus).find(status => PaymentAccountStatus[status] === dto.status)]),
            supertype: PaymentAccountSupertype[Object.keys(PaymentAccountSupertype).filter(supertype => supertype === dto.paymentAccountSuperType)?.[0]],
            type: PaymentAccountType[Object.keys(PaymentAccountType).filter(type => type === dto.paymentAccountType)?.[0]],
        }

        return account
    }

    private createAddress(response: PaymentAccountDto): Address {

        if (!response.addressCity) {
            return undefined
        }

        return {
            country: response.country,
            street1: response.addressLine1,
            street2: response.addressLine2,
            city: response.addressCity,
            state: response.addressState,
            postalCode: response.addressZip,
        }
    }
}
