export enum InvoiceFrequency {
    CONFIRMATION = 'confirmation',
    DATE = 'date',
    BI_WEEKLY = 'biweekly',
    SEMI_MONTHLY = 'semi-monthly',
    START_MONTHLY = 'start-monthly',
    MONTHLY = 'monthly',
    BI_MONTHLY = 'bimonthly',
    QUARTERLY = 'quarterly',
    CUSTOM = 'custom',
}
