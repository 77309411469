import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, map, switchMap, take } from 'rxjs/operators'

import { StorageKeyAuth } from '../../auth/models'
import { StorageKey } from '../../common/global'
import { DialogService } from '../../dialogs'
// NOTE: these must be imported individually b/c this service
// is shared w/the shared project
import { BusinessServicePlan } from '../../modules/models/business-service-plan.interface'
import { Organization } from '../../modules/models/organization.model'
import { ServicePlan } from '../../modules/models/service-plan.interface'

import { ServicePlanStore } from './service-plan.store'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class ServicePlanService {

    constructor(
        private dialogs: DialogService,
        private store: ServicePlanStore,
        private users: UserService,
    ) { }

    readonly PlanTypes = {
        CUSTOM: 'Custom',
        STARTER: 'Starter',
        GROWTH: 'Growth',
        STANDARD: 'Standard',
        CORPORATE: 'Corporate',
        PAY_AS_YOU_GO: 'Pay As You Go',
        CONCIERGE: 'Concierge',
        ESSENTIALS: 'Essentials',
        PREMIUM: 'Premium',
        BASE: 'Base',
        ESSENTIAL: 'Essential',
        PROFESSIONAL: 'Professional',
    }

    // when the create account request came with a plan parameter
    // that means that the plan was previously selected
    static getPreselectedServicePlanId() {
        const createAccountRedirect = localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT)
        const redirectOnAuth = localStorage.getItem(StorageKeyAuth.REDIRECT_ON_AUTH)
        const validRedirect = createAccountRedirect && createAccountRedirect.toLowerCase().includes('plan=') ? createAccountRedirect : redirectOnAuth
        const url = new URL('https://app.poweredbyliquid.com/' + (validRedirect ?? ''))
        const plan = url.searchParams.get('plan')
        return plan
    }

    static isFreeFxPlan(servicePlanId: string = undefined) {
        servicePlanId = (servicePlanId ?? this.getPreselectedServicePlanId() ?? '').toUpperCase()

        const isFreeFx: boolean = [
            '2D7DFE48-BFBE-47A1-A5D8-572C98B103E8', // DEV
            'BFAD753D-DFA5-44CA-8A3F-9EB444AE2212', // QA
            '271D03C5-C5C2-4BF8-B356-751E882B9566', // PROD
        ].includes(servicePlanId)

        return isFreeFx
    }

    cancel(feedback: string): Observable<Organization> {
        return this.store.cancel(this.users.businessSnapshot.id, feedback)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    get(): Observable<Array<ServicePlan>> {
        const replaceLowestPlanWithThisPlanId: string = localStorage.getItem('planHint')
        return this.users.currentBusiness$.pipe(
            take(1),
            switchMap(org => {
                return this.store.get(org?.id, replaceLowestPlanWithThisPlanId)
                    .pipe(
                        take(1),
                        catchError(error => this.dialogs.error(error)),
                    )
            }),
            catchError(error => this.dialogs.error(error)),
        )
    }

    getBasePlan(): Observable<ServicePlan> {
        return this.get().pipe(
            map(plans => plans.find(p => p.isNoPlanPlan)),
        )
    }

    update(planId: string, businessId: string = undefined): Observable<BusinessServicePlan> {
        return this.store.update(businessId ?? this.users.businessSnapshot.id, planId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
