import { AbstractControl, FormArray, FormGroup } from '@angular/forms'

export function NotToEqualVendorsValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const form: FormGroup | FormArray = control.parent
    // if there's no form, return undefined
    if (!form) {
        return null
    }

    let controlKey: string = ''
    const repeated: { [key: string]: number } = {}

    if (control.value !== undefined) {
        Object.keys(form.controls).forEach((key) => {
            if (!!form.controls[key].value) {
                repeated[form.controls[key].value.id] = repeated[form.controls[key].value.id] ? ++repeated[form.controls[key].value.id] : 1
            }
            if (form.controls[key] === control) {
                controlKey = key
            }
        })
        Object.keys(form.controls).forEach((key) => {
            // Id = 1 is the dummy vendor in vendors matching Not Yet on QuickBooks, should always have error
            // Id = 2 is the dummy vendor in vendors matching Create on QuickBooks, many can have it without errors
            if ((repeated[form.controls[key].value?.id] > 1 || form.controls[key].value.id === '1') && form.controls[key].value.id !== '2') {
                form.controls[key].setErrors({ ...form.controls[key].errors, 'double': true })

            } else {
                if (!!form.controls[key].errors && !!form.controls[key].errors.double) {
                    const errors = form.controls[key].errors
                    delete errors.double
                    form.controls[key].setErrors(Object.keys(errors).length ? errors : null)
                }
            }
        })
    }
    return form.controls[controlKey].errors?.double ? { 'double': true } : null
}
