import { EventEmitter, Injectable, Output } from '@angular/core'
import { forkJoin, Observable, of } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'

import { UserService } from '../../core/services'
import { ClientInvoiceStatus, TimeTrackingDocument, TimeTrackingEntry } from '../models'

import { TimeTrackingEntryItem } from './time-tracking-entry-item.interface'
import { TimeTrackingStore } from './time-tracking.store'

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingService {

    @Output() detectChanges: EventEmitter<void> = new EventEmitter()
    @Output() showFieldErrors: EventEmitter<void> = new EventEmitter()

    documents: Array<TimeTrackingDocument> = []
    invoicedEntries: Array<TimeTrackingEntryItem> = []
    workOrderId: string

    get allEntries(): Array<TimeTrackingEntry> { return this.documents.reduce((agg, doc) => [...agg, ...doc.entries], []) }
    get pendingEntries(): Array<TimeTrackingEntry> { return this.allEntries.filter(e => !e.alreadyInvoiced) }

    constructor(
        private store: TimeTrackingStore,
        private users: UserService,
    ) { }

    initialize(workOrderId: string, sortByEntryDate: boolean = false): Observable<void> {
        this.workOrderId = workOrderId
        return this.store.getTimeTrackingDocumentsForWorkOrder(workOrderId)
            .pipe(
                take(1),
                tap(documents => {
                    this.documents = documents
                    this.invoicedEntries = []
                    this.documents?.forEach((doc, documentIndex) => {
                        doc?.entries?.forEach(entry => {
                            entry.alreadyInvoiced = !!entry.invoiceState && !!entry.invoiceLineItemIds?.length
                            if (entry.alreadyInvoiced) {
                                this.invoicedEntries.push({ documentIndex, entry })
                                return
                            }
                        })
                        doc.entries = [...doc.entries].sort((a, b) => sortByEntryDate ? this.sortByEntryDate(a, b) : this.sortByCreatedDate(a, b))
                    })
                    this.invoicedEntries = [...this.invoicedEntries].sort((a, b) => this.sortByEntryDate(a.entry, b.entry))
                }),
                map(() => undefined),
            )
    }

    cloneEntries(document: TimeTrackingDocument, entries: Array<TimeTrackingEntry>): Observable<Array<TimeTrackingEntry>> {
        entries = entries.map(e => {
            return {
                billableHours: e.billableHours,
                nonBillableHours: undefined,
                deliverableId: e.deliverableId,
                description: e.description,
                entryDateTimestamp: e.entryDateTimestamp,
                timeTrackingDocumentId: e.timeTrackingDocumentId,
            }
        })

        const req$: Array<Observable<TimeTrackingEntry>> = []

        for (let i: number = 0; i < entries.length; i++) {
            if (document) {
                document.entries.push(entries[i])
            }
            req$.push(this.saveEntry(document, entries[i]))
        }

        return !!req$.length ? forkJoin(req$) : of([])
    }

    createBlankDocument(entry?: TimeTrackingEntry): Observable<TimeTrackingEntry> {

        const newDoc: TimeTrackingDocument = new TimeTrackingDocument()
        newDoc.workerOrganizationId = this.users.businessSnapshot.id
        newDoc.workOrderId = this.workOrderId

        return this.store.createTimeTrackingDocument(newDoc)
            .pipe(
                take(1),
                switchMap(doc => {
                    const newEntry: TimeTrackingEntry = entry || this.instantiateEntry()
                    newEntry.timeTrackingDocumentId = doc.id
                    doc.entries.push(newEntry)
                    this.documents.push(doc)
                    return this.saveEntry(doc, newEntry)
                }),
            )
    }

    createBlankEntry(doc: TimeTrackingDocument): Observable<TimeTrackingEntry> {
        const entry: TimeTrackingEntry = this.instantiateEntry(doc)
        if (doc) {
            doc.entries.push(entry)
            return of(entry)
        }
        return this.createBlankDocument(entry)
    }

    currentTrackingHours(deliverableId: string): number {
        return this.documents
            .map(document => document.entries
                .filter(entry => entry.deliverableId === deliverableId && (
                    entry.invoiceState !== ClientInvoiceStatus.DRAFT
                    && entry.invoiceState !== ClientInvoiceStatus.REJECTED
                    && entry.invoiceState !== ClientInvoiceStatus.REJECTED_BY_VENDOR))
                .map(a => a.billableHours)
                .filter(hours => !!hours)
                .reduce((val, sum) => val + sum, 0),
            )
            .reduce((val, sum) => val + sum, 0)
    }

    removeEntry(document: TimeTrackingDocument, entry: TimeTrackingEntry): Observable<boolean> {
        if (!document) {
            return of(true)
        }

        if (!entry.id) {
            this.spliceEntry(document, entry)
            return of(true)
        }

        return this.store.deleteTimeTrackingEntry(entry.id, this.workOrderId)
            .pipe(
                take(1),
                map(success => {
                    if (!success) {
                        return false
                    }
                    this.spliceEntry(document, entry)
                    return true
                }),
            )
    }

    saveEntry(document: TimeTrackingDocument, entry: TimeTrackingEntry): Observable<TimeTrackingEntry> {

        if (!document) {
            return this.createBlankDocument(entry)
        }

        const saveRequest$: Observable<TimeTrackingEntry> = !!entry.id
            ? this.store.updateTimeTrackingEntry(entry)
            : this.store.createTimeTrackingEntry(entry, this.workOrderId)

        return saveRequest$
            .pipe(
                take(1),
                tap(result => {
                    const idx: number = document.entries.indexOf(entry)
                    // only set the ID of the entry;
                    // otherwise, it refreshes the whole form and makes it pristine again
                    document.entries[idx].id = result.id
                }),
            )
    }

    private instantiateEntry(doc?: TimeTrackingDocument): TimeTrackingEntry {
        return {
            billableHours: undefined,
            nonBillableHours: undefined,
            timeTrackingDocumentId: doc?.id,
        }
    }

    private sortByCreatedDate(a: TimeTrackingEntry, b: TimeTrackingEntry): number {
        return a.createdTimestamp - b.createdTimestamp
    }

    private sortByEntryDate(a: TimeTrackingEntry, b: TimeTrackingEntry): number {
        return a.entryDateTimestamp - b.entryDateTimestamp
    }

    private spliceEntry(document: TimeTrackingDocument, entry: TimeTrackingEntry): void {
        document.entries.splice(document.entries.indexOf(entry), 1)
    }
}
