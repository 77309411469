<div
    class="chip-list-title"
    *ngIf="!!listTitle"
>
    <div>
        {{listTitle}}
    </div>
    <mat-icon
        *ngIf="!!options.length && !!tooltip"
        [matTooltip]="tooltip"
        matTooltipPosition="right"
        color="accent"
    >
        info</mat-icon>
</div>

<mat-chip-list>

    <div
        *ngIf="!options.length"
        class="no-options"
    >
        {{noneSelected}}
    </div>

    <mat-chip
        *ngFor="let option of options; let i = index"
        [disableRipple]="!clickable"
        [selectable]="false"
        [removable]="option.removable === undefined || !!option.removable"
        (click)="onClick(option.value)"
        (removed)="onRemoveOption(option.value)"
        [class]="!!option.style ? 'chip-' + option.style : ''"
        [ngClass]="{ 'has-image': !!option.img, clickable: clickable, focused: !!option.selected }"
    >
        <span *ngIf="ordered">
            {{(i + 1) + ')'}}&nbsp;
        </span>

        <img
            *ngIf="!!option.img"
            class="chip-img-prefix"
            [src]="option.img"
            alt=""
        />

        <i-feather
            *ngIf="!option.img && !!option.icon"
            class="chip-icon-prefix"
            [name]="option.icon"
        ></i-feather>

        <span class="chip-value">
            {{option.label}}
        </span>

        <i-feather
            *ngIf="!!option.removable"
            matChipRemove
            name="x-circle"
            [matTooltip]="'Remove ' + option.label"
        >
        </i-feather>

        <i-feather
            *ngIf="!option.removable && !!option.trailingIcon"
            [name]="option.trailingIcon"
            class="chip-icon-suffix"
        ></i-feather>

    </mat-chip>

</mat-chip-list>