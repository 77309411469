import { OrganizationMembershipBasicAbstractMember } from './organization-membership-basic-abstract-member.model'

// TODO: rename this to something people understand, like BusinessUserProfile
// also something that explains why it's in the auth module
export class OrganizationMembershipAbstractMember extends OrganizationMembershipBasicAbstractMember {
    avatarUrl?: string
    joinedOn: Date
    firstName: string
    isMe: boolean
    lastName: string
    profileId: string
}
