import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { from, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { RedirectAction } from '../models'

import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class AfterActionRedirectService {

    private savedActions: Record<RedirectAction, { actionName: RedirectAction, payload: any }> = {
        [RedirectAction.InvoiceDetail]: undefined,
        [RedirectAction.VendorInvite]: undefined,
    }

    constructor(
        private readonly router: Router,
        private readonly urls: UrlService,
        private readonly users: UserService,
    ) { }

    addAction(afterAction: RedirectAction, actionName: RedirectAction, payload: any): void {
        this.savedActions[afterAction] = { actionName, payload }
    }

    handleAction(action: RedirectAction): Observable<any> {
        if (!this.savedActions[action]) {
            return of(undefined)
        }

        const { actionName, payload }: { actionName: RedirectAction, payload: any } = this.savedActions[action]

        this.savedActions[action] = undefined

        switch (actionName) {
            case RedirectAction.InvoiceDetail:
                return this.handleInvoiceDetailAction(payload)
            default:
                return of(undefined)
        }
    }

    private handleInvoiceDetailAction({ invoiceId, expressPay }: { invoiceId: string, expressPay?: boolean }): Observable<void> {
        return from(
            this.router
                .navigateByUrl(
                    this.urls.route.invoiceView(invoiceId, this.users.businessSnapshot.id, expressPay),
                ),
            )
            .pipe(
                map(() => undefined),
            )
    }
}
