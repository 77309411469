export enum CreateBusinessGroupId {
    ClientBranding = 'client-branding',
    ClientBusinessInfo = 'client-business-info',
    ClientIntro = 'client-intro',
    ClientLocation = 'client-location',
    FinalizeFinancePaymentMethod = 'finalize-finance-payment-method',
    FinanceInvoicePayor = 'finance-invoice-payor',
    FinancePaymentMethod = 'finance-payment-method',
    LegalSetupContractTemplates = 'legal-setup-contract-templates',
    LegalSetupContractTemplateType = 'legal-setup-contract-template-type',
    LiquidPlanConfirmation = 'liquid-plan-confirmation',
    LiquidPlanIntro = 'liquid-plan-intro',
    LiquidPlanPromoCode = 'liquid-plan-promo-code',
    LiquidPlanSelection = 'liquid-plan-selection',
    VendorBranding = 'vendor-branding',
    VendorBusinessInfo = 'vendor-business-info',
    VendorIntro = 'vendor-intro',
    VendorLocation = 'vendor-location',
    VendorTaxId = 'vendor-tax-id',
    VendorOutro = 'vendor-outro',
}
