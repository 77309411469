import { OrganizationTeam } from '.'
import { BusinessTeamMemberStates } from './business-team-member-states.enum'
import { LiquidDocument } from './liquid-document.model'
import { OrganizationAbstract } from './organization-abstract.model'
import { OrganizationTeamMembershipVendorType } from './organization-team-membership-vendor-type.enum'

export class TeamMembership {
    activeMasterContracts: LiquidDocument[]
    createdTimestamp: number
    hiddenTimestamp: number
    hiddenByProfileId: string
    hiringManagerAvatarUrl: string
    hiringManagerName: string
    hiringManagerProfileId: string
    id: string
    invitedByAvatarUrl: string
    invitedByName: string
    masterContractActiveFrom?: Date
    membershipIsToOrganization?: OrganizationAbstract
    onboardingComplete: boolean
    onboardingCompleteTimestamp: number
    organizationTeamMemberId: string
    preferredPayoutCurrency?: string
    preferredPayoutCurrencyIsStable: boolean
    status: BusinessTeamMemberStates
    vendorType: OrganizationTeamMembershipVendorType
}
