import { Pipe, PipeTransform } from '@angular/core'

import { Member } from '../auth/models'
import { SelectOption } from '../core/models'

@Pipe({
    name: 'chipOption',
})

export class ChipOptionPipe implements PipeTransform {

    public transform(member: Member, removable?: boolean, classes?: string): SelectOption<Member> {
        return {
            icon: member.invited ? 'mail' : 'user',
            img: member.avatarUrl,
            label: member.fullName,
            removable: removable,
            style: `${member.invited ? 'warn' : undefined} ${classes}`,
            value: member,
        }
    }
}
