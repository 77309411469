export enum RateType {
    Flat = 'Flat',
    Hourly = 'Hourly',
    Daily = 'Daily',
    Weekly = 'Weekly',
    SemiMonthly = 'SemiMonthly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Custom = 'Custom',
}
