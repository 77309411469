import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { CountryBankAccountInfo } from '../../country-bank-account-config/models/country-bank-account-info.interface'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class CurrencyStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(countryId: string): Observable<CountryBankAccountInfo> {
        return this.http.get<CountryBankAccountInfo>(this.urls.api.countryCurrencyConfigurationGet(countryId))
    }

    getAllCurrencyCodesWithoutDecimals(): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.urls.api.currenciesWithoutDecimals())
    }
}
