import { OrganizationPermission } from '../../auth/models'

import { OrganizationMemberInvitationSource } from './organization-member-invitation-source.enum'

export class OrganizationMembershipInvitation {
    adminCreated: boolean
    emailAddress: string
    from: string
    id?: string
    infoParameters: string[] = []
    invitationSource: OrganizationMemberInvitationSource
    inviteeDescription: string
    inviteeEmailAddress?: string
    inviteeRoles: string[]
    message: string
    organizationAvatarUrl: string
    organizationName: string
    organizationShortName: string
    permissions: OrganizationPermission[] = []
}
