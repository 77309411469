import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { UpdownDebouncerService } from '../services'

@Injectable({
    providedIn: 'root',
})
export class SystemHealthInterceptor implements HttpInterceptor {

    constructor(
        private updownDebouncer: UpdownDebouncerService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
        return next.handle(request)
            .pipe(
                catchError(error => {
                    this.updownDebouncer.isDown.next(error.status === 0)
                    return throwError(error)
                }),
            )
    }
}
