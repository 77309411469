import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatAccordion } from '@angular/material/expansion'
import { Observable, Subject } from 'rxjs'

import { FormState } from '../../liquid/modules/create-work-order/services/form-state.interface'
import { Deliverable, DeliverableRate, DeliverableStatus, OrderType, WorkOrder } from '../../models'
import { DeliverableFormComponent } from '../deliverable-form/deliverable-form.component'
import { OrderItemComponent } from '../order-item/order-item.component'

@Component({
    selector: 'app-deliverable-list',
    templateUrl: './deliverable-list.component.html',
    styleUrls: ['./deliverable-list.component.scss'],
})
export class DeliverableListComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    readonly cdInstance: ChangeDetectorRef = this.cd
    readonly deliverableStatus: typeof DeliverableStatus = DeliverableStatus
    readonly orderTypes: typeof OrderType = OrderType

    @ViewChild('accordion') accordion: MatAccordion
    @ViewChildren('forms') forms: QueryList<DeliverableFormComponent | OrderItemComponent>

    @Output() addingChange: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() delete: EventEmitter<number> = new EventEmitter<number>()
    @Output() stateChange: EventEmitter<FormState> = new EventEmitter<FormState>()
    @Output() save: EventEmitter<{ deliverable: Deliverable, index: number }> = new EventEmitter<{ deliverable: Deliverable, index: number }>()

    @Input() autoOpen: boolean
    @Input() control: FormControl
    @Input() disabled: boolean
    @Input() loading$: Observable<boolean>
    @Input() editable: boolean
    @Input() isWorker: boolean
    @Input() isInitiator: boolean
    @Input() workOrder: WorkOrder
    @Input() statesControl: FormControl

    addingDeliverable: boolean = false
    selectedIndex: number

    get deliverables(): Array<Deliverable> { return this.control?.value || [] }
    set deliverables(value: Array<Deliverable>) { this.control?.setValue(value) }

    get canAddAnother(): boolean {
        return !this.forms?.length || this.forms.reduce<boolean>((agg, form) => agg && (!!form?.form?.valid && !form?.form?.dirty), true)
    }

    constructor(
        private cd: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.selectedIndex = this.autoOpen ? 0 : undefined

        if (!this.editable) {
            return

        }

        if (!this.deliverables.length) {
            this.onAdd(true)
        } else if (!this.statesControl?.value?.length) {
            this.statesControl?.setValue(
                this.deliverables.map((t, index) => ({ index })),
            )
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    clearIndex(): void {
        this.selectedIndex = undefined
    }

    onAdd(copyFromWorkOrder: boolean = false): void {
        if (this.addingDeliverable || (!!this.forms?.length && !this.canAddAnother)) {
            return
        }
        this.addingDeliverable = true

        const deliverable: Deliverable = new Deliverable()
        deliverable.status = DeliverableStatus.DRAFT
        deliverable.rate = new DeliverableRate()
        deliverable.displayOrder = this.deliverables.length

        if (copyFromWorkOrder) {
            deliverable.name = this.workOrder.name
        }

        this.deliverables = [...this.deliverables, deliverable]
        this.selectedIndex = this.deliverables.length - 1
        this.statesControl.setValue([...this.statesControl.value, { index: this.selectedIndex }], { emitEvent: false })
        this.cd.detectChanges()
    }

    onDeleteDeliverable(index: number): void {
        this.addingDeliverable = false
        const forms: Array<FormGroup> = [...this.statesControl.value]
        forms.splice(index, 1)
        this.statesControl.setValue(forms)
        this.delete.emit(index)
        this.clearIndex()
    }

    setSelectedIndex(index: number): void {
        this.selectedIndex = index
    }

    onStateChange(state: FormState): void {
        this.stateChange.emit(state)
    }

    onNameChange(name: string, index: number): void {
        this.deliverables[index].name = name
    }

    onSaveDeliverable(deliverable: Deliverable, index: number): void {
        this.save.emit({ deliverable, index })
    }
}
