import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'

import { OrganizationMembershipAbstractMember } from '../../auth/models'
import { SelectOption } from '../models'

import { BusinessUsersService } from './business-users.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessUsersResolver implements Resolve<Array<SelectOption<OrganizationMembershipAbstractMember>>> {

    constructor(
        private users: BusinessUsersService,
    ) { }

    resolve(): Observable<Array<SelectOption<OrganizationMembershipAbstractMember>>> {
        return this.users.initialize()
    }
}
