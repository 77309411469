import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import * as FileSaver from 'file-saver'
import { Observable, throwError } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { UpdatePropertiesRequestScope } from '../../core/models'
import { UserService } from '../../core/services/user.service'
import { DialogService } from '../../dialogs'
import { FileItem, UploadFileResponse } from '../liquid/modules/file-uploads/models'
import {
    BillingField,
    Deliverable,
    DeliverableRate,
    OrderType,
    WorkOrder,
    WorkOrderAbstract,
    WorkOrderCounts,
    WorkOrderFinalizeRequest,
    WorkOrderFinancialDetails,
    WorkOrderInfo,
    WorkOrderMessage,
    WorkOrderPageInfo,
    WorkOrderProjectInfo,
    WorkOrderSetAutoCreateInvoiceDraftsRequest,
    WorkOrderStatus,
    WorkOrderSummary,
    WorkOrderUpdatePropertiesRequest
} from '../models'
import { OrderAbstract } from '../models/order-abstract.interface'

import { WorkOrderStore } from './work-order.store'

@Injectable({
    providedIn: 'root',
})
export class WorkOrderService {

    private readonly readonlyStatuses: Array<WorkOrderStatus> = [
        WorkOrderStatus.COMPLETED,
        WorkOrderStatus.CANCELLED,
    ]

    constructor(
        private dialogs: DialogService,
        private users: UserService,
        private workOrderStore: WorkOrderStore,
    ) { }

    uploadFileForWorkOrder(workOrderId: string, file: FormData, fileName: string): Observable<UploadFileResponse> {

        return this.workOrderStore.uploadFileForWorkOrder(workOrderId, file, fileName)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createFileForWorkOrder(file: FileItem): Observable<FileItem> {
        return this.workOrderStore.createFileForWorkOrder(file)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    deleteFileForWorkOrder(workOrderId: string, orgId: string): Observable<boolean> {
        return this.workOrderStore.deleteFileForWorkOrder(workOrderId, orgId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getFilesForWorkOrder(workOrderId: string, orgId: string): Observable<FileItem[]> {
        return this.workOrderStore.getFilesForWorkOrder(workOrderId, orgId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    downloadFileForWorkOrder(workOrderId: string, fileId: string, fileName: string): Observable<boolean> {
        return this.workOrderStore.downloadFile(workOrderId, fileId, fileName)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    updateFileForWorkOrder(file: FileItem): Observable<boolean> {
        return this.workOrderStore.updateFileForWorkOrder(file)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    isReadonly(workOrder: WorkOrderAbstract): boolean {
        return this.readonlyStatuses.includes(workOrder.status)
    }

    sortDeliverables(p: WorkOrder): void {

        if (!p?.deliverables?.length) {
            return
        }

        p.deliverables.sort((a, b) => a.displayOrder - b.displayOrder)
    }

    getHasOrders(organizationId: string, orderType: OrderType): Observable<boolean> {
        return this.workOrderStore.getHasOrders(organizationId, orderType)
    }

    getOrdersCanBeInvoiced(clientOrganizationId: string): Observable<OrderAbstract[]> {
        return this.workOrderStore.getOrdersCanBeInvoiced(clientOrganizationId)
    }

    getPagedWorkOrdersForOrganization(organizationId: string, orderType: OrderType, page: Partial<PageEvent>, sort: Sort, filters?: { [key: string]: string }): Observable<WorkOrderPageInfo> {
        let params: HttpParams = new HttpParams()
        params = params.appendAll({
            page: String(page.pageIndex + 1),
            pageSize: String(page.pageSize),
            sortString: `${sort.active} ${sort.direction}`,
        })

        if (!!filters) {
            Object.keys(filters).map(filterKey => {
                params = params.append(filterKey, filters[filterKey])
            })
        }

        return this.workOrderStore.getPagedWorkOrdersForOrganization(organizationId, orderType, params)
            .pipe(
                take(1),
            )
    }

    getWorkOrder(workOrderId: string): Observable<WorkOrder> {
        return this.workOrderStore.getWorkOrder(workOrderId)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    getWorkOrdersCountsForOrganization(organizationId: string, orderType: OrderType): Observable<WorkOrderCounts> {
        return this.workOrderStore.getWorkOrderCountsForOrganization(organizationId, orderType)
            .pipe(
                take(1),
            )
    }

    getWorkOrdersForOrganization(organizationId: string, orderType: OrderType): Observable<WorkOrderAbstract[]> {
        return this.workOrderStore.getWorkOrdersForOrganization(organizationId, orderType)
            .pipe(
                take(1),
            )
    }

    getWorkOrdersForProject(organizationId: string): Observable<WorkOrderProjectInfo[]> {
        return this.workOrderStore.getWorkOrdersForProject(organizationId)
            .pipe(
                take(1),
            )
    }

    getWorkOrderInfoForOrganization(organizationId: string): Observable<WorkOrderInfo[]> {
        return this.workOrderStore.getWorkOrderInfoForOrganization(organizationId)
            .pipe(
                take(1),
            )
    }

    // Grabs Active or Completed WorkOrder Summaries
    getWorkOrderSummariesForOrganization(businessId: string, excludeCreatedFromClientInvoice: boolean = false): Observable<Array<WorkOrderSummary>> {
        return this.workOrderStore.getWorkOrderSummariesForOrganization(businessId, excludeCreatedFromClientInvoice)
            .pipe(
                take(1),
            )
    }

    createWorkOrder(newWorkOrder: WorkOrder): Observable<WorkOrder> {
        return this.workOrderStore.createWorkOrder(newWorkOrder)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    createWorkOrderWithDeliverables(newWorkOrder: WorkOrder): Observable<WorkOrder> {
        return this.workOrderStore.createWorkOrderWithDeliverables(newWorkOrder)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    createWorkOrderDeliverable(newWorkOrderDeliverable: Deliverable): Observable<Deliverable> {
        return this.workOrderStore.createDeliverable(newWorkOrderDeliverable)
            .pipe(
                take(1),
            )
    }

    updateWorkOrderDeliverable(updatedDeliverable: Deliverable): Observable<Deliverable> {
        return this.workOrderStore.updateDeliverable(updatedDeliverable)
            .pipe(
                take(1),
            )
    }

    deleteWorkOrderDeliverable(deliverableId: string): Observable<boolean> {
        return this.workOrderStore.deleteWorkOrderDeliverable(deliverableId)
            .pipe(
                take(1),
            )
    }

    markWorkOrderDeliverableComplete(deliverableId: string): Observable<boolean> {
        return this.workOrderStore.markWorkOrderDeliverableComplete(deliverableId)
            .pipe(
                take(1),
            )
    }

    sendWorkOrderDeliverableDraft(deliverableId: string): Observable<boolean> {
        return this.workOrderStore.sendWorkOrderDeliverableDraft(deliverableId)
            .pipe(
                take(1),
            )
    }

    updateBillingField(billingFieldId: string, workOrderId: string, scope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.All): Observable<WorkOrder> {
        if (!workOrderId) {
            return throwError('Work Order Id is required')
        }

        const request: WorkOrderUpdatePropertiesRequest = {
            workOrderId: workOrderId,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                BillingFieldId: billingFieldId,
            },
            updateScope: scope,
        }

        return this.workOrderStore.updateWorkOrderProperties(request)
            .pipe(
                take(1),
            )
    }

    associateWorkOrderBillingField(workOrderId: string, billingFieldId: string): Observable<boolean> {
        return this.workOrderStore.associateWorkOrderBillingField(workOrderId, billingFieldId)
            .pipe(
                take(1),
            )
    }

    disassociateWorkOrderBillingField(workOrderId: string, billingFieldId: string): Observable<boolean> {
        return this.workOrderStore.disassociateWorkOrderBillingField(workOrderId, billingFieldId)
            .pipe(
                take(1),
            )
    }

    getWorkOrderBillingFieldsAssociations(workOrderId: string): Observable<BillingField[]> {
        return this.workOrderStore.getWorkOrderBillingFieldsAssociations(workOrderId)
    }

    updateWorkOrder(updatedWorkOrder: WorkOrder): Observable<WorkOrder> {
        return this.workOrderStore.updateWorkOrder(updatedWorkOrder)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    updateWorkOrderFinancialDetails(financialDetails: WorkOrderFinancialDetails): Observable<WorkOrder> {
        return this.workOrderStore.updateWorkOrderFinancialDetails(financialDetails)
            .pipe(
                take(1),
            )
    }

    setAutoCreateInvoiceDrafts(autoCreateInvoiceDrafts: boolean, workOrderId: string): Observable<boolean> {
        if (!workOrderId) {
            return throwError('Work Order Id is required')
        }

        const request: WorkOrderSetAutoCreateInvoiceDraftsRequest = {
            workOrderId: workOrderId,
            autoCreateInvoiceDrafts: autoCreateInvoiceDrafts,
        }
        return this.workOrderStore.setAutoCreateInvoiceDrafts(request)
            .pipe(
                take(1),
            )
    }

    updateWorkOrderHiringManager(hiringManagerProfileId: string, workOrderId: string, scope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.This): Observable<WorkOrder> {

        if (!workOrderId) {
            return throwError('Work Order Id is required')
        }

        const request: WorkOrderUpdatePropertiesRequest = {
            workOrderId: workOrderId,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                HiringManagerLiquidProfileId: hiringManagerProfileId,
            },
            updateScope: scope,
        }

        this.dialogs.wait()
        return this.workOrderStore.updateWorkOrderProperties(request)
            .pipe(
                take(1),
                tap(() => this.dialogs.close()),
                catchError((err) => this.dialogs.error(err)),
            )
    }

    updateTotalEstimatedFee(totalEstimatedFee: number, workOrderId: string, scope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.This): Observable<WorkOrder> {
        if (!workOrderId) {
            return throwError('Work Order Id is required')
        }

        const request: WorkOrderUpdatePropertiesRequest = {
            workOrderId: workOrderId,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                TotalEstimatedFee: totalEstimatedFee,
            },
            updateScope: scope,
        }

        return this.workOrderStore.updateWorkOrderProperties(request)
            .pipe(
                take(1),
            )
    }

    updateEstimatedEndDate(estimatedEndDate: string, workOrderId: string, scope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.This): Observable<WorkOrder> {
        if (!workOrderId) {
            return throwError('Work Order Id is required')
        }

        const request: WorkOrderUpdatePropertiesRequest = {
            workOrderId: workOrderId,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                EstimatedEndDate: estimatedEndDate,
            },
            updateScope: scope,
        }

        return this.workOrderStore.updateWorkOrderProperties(request)
            .pipe(
                take(1),
            )
    }

    acceptWorkOrderDeliverableWorkerRates(deliverableRateIds: string[]): Observable<DeliverableRate[]> {
        return this.workOrderStore.acceptDeliverableWorkerRates(deliverableRateIds)
            .pipe(
                take(1),
            )
    }

    acceptWorkOrderDeliverableClientRates(deliverableRateIds: string[], clientOrganizationIdOverride: string | null): Observable<DeliverableRate[]> {
        return this.workOrderStore.acceptDeliverableClientRates(deliverableRateIds, clientOrganizationIdOverride)
            .pipe(
                take(1),
            )
    }

    finalizeDraftWorkOrder(request: WorkOrderFinalizeRequest): Observable<WorkOrder> {
        return this.workOrderStore.finalizeDraftWorkOrder(request)
            .pipe(
                take(1),
            )
    }

    getOrderMessages(workOrderId: string): Observable<Array<WorkOrderMessage>> {
        return this.workOrderStore.getOrderMessages(workOrderId)
            .pipe(
                take(1),
            )
    }

    getNoClientOrganizationWorkOrder(workOrderId: string): Observable<WorkOrder> {
        return this.workOrderStore.getNoClientOrganizationWorkOrder(workOrderId)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    acceptNoClientOrganizationWorkOrderDeliverableClientRate(deliverableRateIds: string[], agreedByClientName: string, agreedByClientSignature: string, agreedByClientOrganizationName: string): Observable<DeliverableRate[]> {
        return this.workOrderStore.acceptNoClientOrganizationDeliverableClientRate(deliverableRateIds, agreedByClientName, agreedByClientSignature, agreedByClientOrganizationName)
            .pipe(
                take(1),
            )
    }

    sendNoClientOrganizationWorkOrderMessage(workOrderId: string, message: string): Observable<boolean> {
        return this.workOrderStore.sendNoClientOrganizationWorkOrderMessage(workOrderId, message)
            .pipe(
                take(1),
            )
    }

    sendLoggedInWorkOrderMessage(workOrderId: string, message: string, toOrganizationId: string): Observable<boolean> {
        return this.workOrderStore.sendLoggedInWorkOrderMessage(workOrderId, message, toOrganizationId)
            .pipe(
                take(1),
            )
    }

    resendWorkOrderAcceptEmail(workOrderId: string): Observable<boolean> {
        return this.workOrderStore.resendWorkOrderAcceptEmail(workOrderId)
            .pipe(
                take(1),
            )
    }

    updateWorkOrderStatus(workOrderId: string, newStatus: string, requestedByBizId?: string): Observable<WorkOrder> {
        return this.workOrderStore.updateWorkOrderStatus(workOrderId, newStatus, requestedByBizId)
            .pipe(
                take(1),
                map(workOrder => {
                    this.sortDeliverables(workOrder)
                    return workOrder
                }),
            )
    }

    getWorkOrderSOW(workOrderId: string, WorkOrderName: string): Observable<boolean> {
        return this.workOrderStore.getWorkOrderSOW(workOrderId, WorkOrderName)
            .pipe(
                take(1),
                map(response => {
                    FileSaver.saveAs(response, `WorkOrder-${WorkOrderName}.pdf`)
                    return true
                }),
            )
    }

    getUnknownWorkOrderSOW(workOrderId: string, WorkOrderName: string): Observable<boolean> {
        return this.workOrderStore.getUnknownWorkOrderSOW(workOrderId, WorkOrderName)
            .pipe(
                take(1),
                map(response => {
                    FileSaver.saveAs(response, `WorkOrder-${WorkOrderName}.pdf`)
                    return true
                }),
            )
    }

    discardDraft(workOrder: WorkOrder): Observable<WorkOrder> {
        return this.updateWorkOrderStatus(workOrder.id, WorkOrderStatus.REMOVED, this.users.businessSnapshot.id)
    }
}
