import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { UrlService } from '../../core/services'

@Injectable({
    providedIn: 'root',
})
export class SystemHealthStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    getSystemHealth(): Observable<void> {
        // just testing for a response from the endpoint
        return this.http.get<void>(this.urls.api.youUp())
    }
}
