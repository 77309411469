export class RecurrenceParameters {
    public startTimestamp: number
    public untilTimestamp?: number
    public frequency?: string
    public interval: number
    public daysOfMonth: number[]
    public daysOfWeek: string[]
    public endOccurrences: number
    public rfc5545Calendar: string

    public static getRecurrenceString(timezone: string, prefix: string, rp: RecurrenceParameters, rd: string, moment) {
        if (rp.frequency === 'week') {
            rd = `${prefix} starting ${this.getFormattedTimezoneDate(rp.startTimestamp, timezone, moment)}`
            if (rp.interval === 1) {
                rd += `, then every week on `
            } else if (rp.interval > 1) {
                rd += `, then every ${rp.interval} weeks on `
            }
            rp.daysOfWeek.forEach(day => {
                rd += `${day.charAt(0).toUpperCase() + day.slice(1)}s, `
            })
            rd = rd.substring(0, rd.length - 2)
            if (rp.endOccurrences > 0) {
                rd += `, ending after ${rp.endOccurrences} occurrences.`
            } else if (rp.untilTimestamp) {
                rd += `, until before ${this.getFormattedTimezoneDate(rp.untilTimestamp, timezone, moment)}.`
            } else {
                rd += '.'
            }
        } else if (rp.frequency === 'month') {
            if (rp.interval === 1) {
                rd = `${prefix} every month`
            } else if (rp.interval > 1) {
                rd = `${prefix} every ${rp.interval} months`
            }
            rd += `, starting ${this.getFormattedTimezoneDate(rp.startTimestamp, timezone, moment)},`
            if (rp.endOccurrences < 0 && !rp.untilTimestamp) {
                rd += ' then on the '
            } else if (rp.endOccurrences > 0) {
                rd += ` ending after ${rp.endOccurrences} occurrences, on the `
            } else if (rp.untilTimestamp) {
                rd += ` until before ${this.getFormattedTimezoneDate(rp.untilTimestamp, timezone, moment)}, on the `
            }
            rp.daysOfMonth.forEach(day => {
                rd += `${this.getOrdinalSuffix(day)}, `
            })
            rd = rd.substring(0, rd.length - 2) + ' of the month.'
        } else if (rp.frequency === 'year') {
            if (rp.interval === 1) {
                rd = `${prefix} every year`
            } else if (rp.interval > 1) {
                rd = `${prefix} every ${rp.interval} years`
            }
            rd += `, starting ${this.getFormattedTimezoneDate(rp.startTimestamp, timezone, moment)},`
            if (rp.endOccurrences < 0) {
            } else if (rp.endOccurrences > 0) {
                rd += ` ending after ${rp.endOccurrences} occurrences.`
            } else if (rp.untilTimestamp) {
                rd += `, until before ${this.getFormattedTimezoneDate(rp.untilTimestamp, timezone, moment)}`
            }
            rd += '.'
        } else if (rp.frequency === 'day') {
            if (rp.interval === 1) {
                rd = `${prefix} every day`
            } else if (rp.interval > 1) {
                rd = `${prefix} every ${rp.interval} days`
            } rd += `, starting ${this.getFormattedTimezoneDate(rp.startTimestamp, timezone, moment)},`
            if (rp.endOccurrences < 0) {
            } else if (rp.endOccurrences > 0) {
                rd += ` ending after ${rp.endOccurrences} occurrences.`
            } else if (rp.untilTimestamp) {
                rd += `, until before ${this.getFormattedTimezoneDate(rp.untilTimestamp, timezone, moment)}`
            }
            rd += '.'
        }
        return rd
    }

    private static getFormattedTimezoneDate(utcTimestamp: number, timezone: string, moment: any): string {
        const ret: string = moment(new Date(utcTimestamp * 1000)).tz(timezone).format('MMMM Do YYYY')
        return ret
    }

    private static getOrdinalSuffix(i: number) {
        if (i === -1) {
            return 'last day'
        }
        const j = i % 10,
            k = i % 100
        if (j === 1 && k !== 11) {
            return i + 'st'
        }
        if (j === 2 && k !== 12) {
            return i + 'nd'
        }
        if (j === 3 && k !== 13) {
            return i + 'rd'
        }
        return i + 'th'
    }
}
