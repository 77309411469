<mat-accordion displayMode="flat">
    <app-workflow-module
        *ngFor="let workflowModule of workflowModules; let i = index"
        [form]="form"
        [isDialog]="isDialog"
        [hideStepIndicators]="hideStepIndicators"
        [noStepImage]="noStepImage"
        [workflowModule]="workflowModule"
        [workflowModuleIndex]="i"
        (action)="onAction($event)"
        (changed)="change()"
    ></app-workflow-module>
</mat-accordion>