import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { forkJoin, Observable, of } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { UrlService } from '../../core/services/url.service'
import { FileItem, UploadFileResponse } from '../liquid/modules/file-uploads/models'
import {
    BillingField,
    Deliverable,
    DeliverableRate,
    OrderType,
    WorkOrder,
    WorkOrderAbstract,
    WorkOrderCounts,
    WorkOrderFinalizeRequest,
    WorkOrderFinancialDetails,
    WorkOrderInfo,
    WorkOrderMessage,
    WorkOrderPageInfo,
    WorkOrderProjectInfo,
    WorkOrderSetAutoCreateInvoiceDraftsRequest,
    WorkOrderSummary,
    WorkOrderUpdatePropertiesRequest,
} from '../models'
import { OrderAbstract } from '../models/order-abstract.interface'

import { WorkOrderFactory } from './work-order.factory'

@Injectable({
    providedIn: 'root',
})
export class WorkOrderStore {

    constructor(
        private workOrderFactory: WorkOrderFactory,
        private http: HttpClient,
        private urls: UrlService,

    ) { }

    uploadFileForWorkOrder(workOrderId: string, file: FormData, fileName: string): Observable<UploadFileResponse> {
        return this.http.post<UploadFileResponse>(this.urls.api.workOrderFileUpload(workOrderId, fileName), file)
    }

    createFileForWorkOrder(file: FileItem): Observable<FileItem> {
        return this.http.post<FileItem>(this.urls.api.workOrderFileCreate(), file)
    }

    deleteFileForWorkOrder(workOrderId: string, orgId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.urls.api.workOrderFileDelete(workOrderId, orgId))
    }

    getFilesForWorkOrder(workOrderId: string, orgId: string): Observable<FileItem[]> {
        return this.http.get<FileItem[]>(this.urls.api.workOrderGetFiles(workOrderId, orgId))
    }

    updateFileForWorkOrder(file: FileItem): Observable<boolean> {
        return this.http.put<boolean>(this.urls.api.workOrderFileUpdate(), file)
    }

    downloadFile(workOrderId: string, fileId: string, fileName: string): Observable<boolean> {
        return this.http.get(this.urls.api.workOrderFileDownload(workOrderId, fileId), { responseType: 'blob' })
            .pipe(
                tap(response => FileSaver.saveAs(response, fileName)),
                map(() => true),
            )
    }

    getHasOrders(organizationId: string, orderType: OrderType): Observable<boolean> {
        return this.http.get<boolean>(this.urls.api.hasOrders(organizationId, orderType))
    }

    getOrdersCanBeInvoiced(clientOrganizationId: string): Observable<OrderAbstract[]> {
        return this.http.get<OrderAbstract[]>(
            this.urls.api.ordersCanBeInvoiced(clientOrganizationId),
        )
    }

    getPagedWorkOrdersForOrganization(organizationId: string, orderType: OrderType, params?: HttpParams): Observable<WorkOrderPageInfo> {
        return this.http.get<WorkOrderPageInfo>(this.urls.api.workOrdersPaged(organizationId, orderType), { params })
            .pipe(
                map(result => {
                    return {
                        ...result,
                        page: {
                            ...result.page,
                            results: result.page.results.map(workOrder => this.workOrderFactory.create<WorkOrderAbstract>(workOrder)),
                        },
                    }
                }),
            )
    }

    getWorkOrder(workOrderId: string): Observable<WorkOrder> {
        return this.http.get<WorkOrder>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/${workOrderId}`)
            .pipe(
                map(response => {
                    this.workOrderFactory.create<WorkOrder>(response)
                    return response
                }),
            )
    }

    getWorkOrderCountsForOrganization(organizationId: string, orderType: OrderType): Observable<WorkOrderCounts> {
        return this.http.get<WorkOrderCounts>(this.urls.api.workOrderCounts(organizationId, orderType))
    }

    getWorkOrdersForOrganization(organizationId: string, orderType: OrderType): Observable<WorkOrderAbstract[]> {
        return this.http.get<WorkOrderAbstract[]>(environment.liquidApiSettings.apiServicePrefix + `/organizations/${organizationId}/work-orders?orderType=${orderType}`)
            .pipe(
                map(workOrders => workOrders.map(workOrder => this.workOrderFactory.create<WorkOrderAbstract>(workOrder))),
            )
    }

    getWorkOrdersForProject(organizationId: string): Observable<WorkOrderProjectInfo[]> {
        return this.http.get<WorkOrderProjectInfo[]>(this.urls.api.workOrdersForProject(organizationId))
            .pipe(
                map(workOrders => workOrders.map(workOrder => this.workOrderFactory.createProjectInfo<WorkOrderProjectInfo>(workOrder))),
            )
    }

    getWorkOrderInfoForOrganization(organizationId: string): Observable<WorkOrderInfo[]> {
        return this.http.get<WorkOrderInfo[]>(this.urls.api.workOrderInfos(organizationId))
    }

    // Grabs Active or Completed WorkOrder Summaries
    getWorkOrderSummariesForOrganization(businessId: string, excludeCreatedFromClientInvoice: boolean = false): Observable<Array<WorkOrderSummary>> {
        return this.http.get<Array<WorkOrderSummary>>(this.urls.api.workOrderSummaries(businessId, excludeCreatedFromClientInvoice))
    }

    createWorkOrder(newWorkOrder: WorkOrder): Observable<WorkOrder> {
        return this.http.post<WorkOrder>(this.urls.api.workOrders(), newWorkOrder)
            .pipe(
                map(response => {
                    this.workOrderFactory.create(response)
                    return response
                }),
            )
    }

    createWorkOrderWithDeliverables(newWorkOrder: WorkOrder): Observable<WorkOrder> {
        let finalWorkOrder: WorkOrder
        return this.createWorkOrder(newWorkOrder)
            .pipe(
                take(1),
                switchMap(createdWorkOrder => {
                    finalWorkOrder = createdWorkOrder
                    return this.createDeliverables(newWorkOrder.deliverables, createdWorkOrder)
                }),
                switchMap(deliverables => {
                    finalWorkOrder.deliverables = deliverables
                    this.workOrderFactory.create(finalWorkOrder)
                    return of(finalWorkOrder)
                }),
            )
    }

    createDeliverable(newDeliverable: Deliverable): Observable<Deliverable> {
        newDeliverable.uiRecurrenceString = undefined
        newDeliverable.uiHoursResetString = undefined
        return this.http.post<Deliverable>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/deliverables`,
            newDeliverable)
    }

    createDeliverables(newDeliverables: Deliverable[], createdWorkOrder: WorkOrder): Observable<Deliverable[]> {
        const deliverableObservables: Observable<Deliverable>[] = []

        newDeliverables.forEach((deliverable) => {
            deliverable.workOrderId = createdWorkOrder.id
            deliverableObservables.push(this.createDeliverable(deliverable))
        })
        return forkJoin(deliverableObservables)
    }

    updateDeliverable(updatedDeliverable: Deliverable): Observable<Deliverable> {
        updatedDeliverable.uiRecurrenceString = undefined
        updatedDeliverable.uiHoursResetString = undefined
        return this.http.put<Deliverable>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/deliverables`, updatedDeliverable)
    }

    deleteWorkOrderDeliverable(deliverablesId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/deliverables/${deliverablesId}`)
    }

    markWorkOrderDeliverableComplete(deliverablesId: string): Observable<boolean> {
        return this.http.get<boolean>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/deliverables/${deliverablesId}/completes`)
    }

    sendWorkOrderDeliverableDraft(deliverablesId: string): Observable<boolean> {
        return this.http.get<boolean>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/deliverables/${deliverablesId}/draftSends`)
    }

    updateWorkOrder(updatedWorkOrder: WorkOrder): Observable<WorkOrder> {
        updatedWorkOrder.deliverables.forEach(t => {
            t.uiRecurrenceString = undefined
            t.uiHoursResetString = undefined
        })
        return this.http.put<WorkOrder>(
            environment.liquidApiSettings.apiServicePrefix + '/work-orders',
            updatedWorkOrder,
        ).pipe(
            map(response => {
                this.workOrderFactory.create(response)
                return response
            }),
        )
    }

    updateWorkOrderFinancialDetails(financialDetails: WorkOrderFinancialDetails): Observable<WorkOrder> {
        return this.http.put<WorkOrder>(
            this.urls.api.workOrderFinancialDetails(),
            financialDetails,
        ).pipe(
            map(response => {
                this.workOrderFactory.create(response)
                return response
            }),
        )
    }

    updateWorkOrderProperties(request: WorkOrderUpdatePropertiesRequest): Observable<WorkOrder> {
        return this.http.patch<WorkOrder>(this.urls.api.workOrders(), request)
    }

    setAutoCreateInvoiceDrafts(request: WorkOrderSetAutoCreateInvoiceDraftsRequest): Observable<boolean> {
        return this.http.put<boolean>(this.urls.api.autoCreateInvoiceDrafts(), request)
    }

    acceptDeliverableWorkerRates(deliverableRateIds: string[]): Observable<DeliverableRate[]> {
        return this.http.put<DeliverableRate[]>(
            environment.liquidApiSettings.apiServicePrefix + '/work-orders/deliverables/rates/workers/accepts',
            { deliverableRateIds: deliverableRateIds })
    }

    acceptDeliverableClientRates(deliverableRateIds: string[], clientOrganizationIdOverride: string | null): Observable<DeliverableRate[]> {
        return this.http.put<DeliverableRate[]>(
            environment.liquidApiSettings.apiServicePrefix + '/work-orders/deliverables/rates/clients/accepts',
            { deliverableRateIds: deliverableRateIds, clientOrganizationIdOverride })
    }

    finalizeDraftWorkOrder(request: WorkOrderFinalizeRequest): Observable<WorkOrder> {
        return this.http.post<WorkOrder>(
            environment.liquidApiSettings.apiServicePrefix + '/work-orders/finalizeDrafts',
            request,
        )
    }

    getOrderMessages(workOrderId: string): Observable<Array<WorkOrderMessage>> {
        return this.http.get<Array<WorkOrderMessage>>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/${workOrderId}/messages`)
    }

    getNoClientOrganizationWorkOrder(workOrderId: string): Observable<WorkOrder> {
        return this.http.get<WorkOrder>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/unknown-client/${workOrderId}`)
    }

    acceptNoClientOrganizationDeliverableClientRate(deliverableRateIds: string[], agreedByClientName: string,
        agreedByClientSignature: string, agreedByClientOrganizationName: string): Observable<DeliverableRate[]> {
        return this.http.put<DeliverableRate[]>(environment.liquidApiSettings.apiServicePrefix + '/work-orders/unknown-client/deliverables/rates/accepts',
            { deliverableRateIds: deliverableRateIds, agreedByClientOrganizationName, agreedByClientName, agreedByClientSignature })
    }

    sendNoClientOrganizationWorkOrderMessage(workOrderId: string, message: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/work-orders/unknown-client/messages',
            { workOrderId, message })
    }

    sendLoggedInWorkOrderMessage(workOrderId: string, message: string, toOrganizationId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/work-orders/messages',
            { workOrderId, message, toOrganizationId })
    }

    resendWorkOrderAcceptEmail(workOrderId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/work-orders/accepts/emails/resends',
            { workOrderId })
    }

    updateWorkOrderStatus(workOrderId: string, newStatus: string, requestedByBizId?: string): Observable<WorkOrder> {
        return this.http.post<WorkOrder>(environment.liquidApiSettings.apiServicePrefix + '/work-orders/statuses',
            {
                workOrderId,
                newStatus,
                requestedByOrganizationId: requestedByBizId || null,
            })
    }

    getWorkOrderSOW(workOrderId: string, workOrderName: string): Observable<Blob> {
        return this.http.get(environment.liquidApiSettings.apiServicePrefix + `/work-orders/sows/${workOrderId}/pdfs`, { responseType: 'blob' })
    }

    getUnknownWorkOrderSOW(workOrderId: string, workOrderName: string): Observable<Blob> {
        return this.http.get(environment.liquidApiSettings.apiServicePrefix + `/work-orders/unknown-client/sows/${workOrderId}/pdfs`, { responseType: 'blob' })
    }

    getWorkOrderBillingFieldsAssociations(workOrderId: string): Observable<BillingField[]> {
        return this.http.get<BillingField[]>(this.urls.api.workOrderBillingFieldAssociations(workOrderId))
    }

    associateWorkOrderBillingField(workOrderId: string, billingFieldId: string): Observable<boolean> {
        return this.http.put<boolean>(this.urls.api.workOrderBillingFieldAssociation(workOrderId, billingFieldId), null)
    }

    disassociateWorkOrderBillingField(workOrderId: string, billingFieldId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.urls.api.workOrderBillingFieldAssociation(workOrderId, billingFieldId))
    }
}
