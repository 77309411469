import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'

import {
    BulkImportItem,
    BulkImportNotification,
    ModelValidationResult,
    VendorImportData,
    VendorInvitationsImportSettings
} from '../../models'
import { BulkImportStatuses } from '../../models/bulk-import-statuses.enum'
import { BulkImportService } from '../import/bulk-import-service.interface'
import { UserService } from '../user.service'

import { VendorImportStore } from './vendor-import.store'

@Injectable({
    providedIn: 'root',
})
export class VendorImportService {

    constructor(
        private readonly usersService: UserService,
        private readonly vendorStore: VendorImportStore,
    ) {
    }

    getImportItems(statuses: BulkImportStatuses[] = []): Observable<BulkImportItem[]> {
        return this.vendorStore.getVendorInvitationsImportHistory(
            this.usersService.businessSnapshot.id,
            statuses,
        ).pipe(
            take(1),
        )
    }

    getVendorInvitationsImportSettings(): Observable<VendorInvitationsImportSettings> {
        return this.vendorStore.getVendorInvitationsImportSettings()
    }

    getImportNotifications(): Observable<BulkImportNotification[]> {
        return this.vendorStore.getVendorInvitationsImportNotifications(
            this.usersService.businessSnapshot.id,
        )
    }

    downloadTemplate(): Observable<void | Blob> {
        return this.vendorStore.downloadTemplate()
            .pipe(
                tap(templateFile => FileSaver.saveAs(templateFile, 'Vendor Invitations Import Template.csv')),
            )
    }

    dismissNotification(notificationId: string): Observable<void> {
        return this.vendorStore.removeNotification(notificationId)
    }

    uploadVendors(vendors: VendorImportData[]): Observable<void> {
        return this.vendorStore.uploadVendorInvitationsFile(
            this.usersService.businessSnapshot.id,
            vendors,
        )
    }

    validateImportFile(file: File): Observable<ModelValidationResult<VendorImportData>[]> {
        return this.vendorStore.uploadVendorInvitationsFileForValidation(file)
    }
}
