export class Payout {
    clientInvoiceId: string
    clientName?: string
    created: number
    id: string
    invoiceAmount: number
    invoiceCurrency: string
    invoiced: number
    invoiceNumber?: string
    invoiceStatus?: string
    maskedPayoutAccountNumber?: string
    payoutAmount: number
    payoutCurrency?: string
    payoutMethod?: string
    transferProvider?: string
}
