import { TimeTrackingEntry } from "./time-tracking-entry.interface"

export class TimeTrackingDocument {

    createdTimestamp: number
    description: string
    entries: TimeTrackingEntry[]
    id: string
    name: string
    periodBeginningTimestamp: number
    periodEndingTimestamp: number
    workOrderId: string
    workerOrganizationId: string

    static sortEntriesByEntryDate(doc: TimeTrackingDocument): void {
        doc.entries = doc.entries.sort((a, b) => a.entryDateTimestamp - b.entryDateTimestamp)
    }
}
