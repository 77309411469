import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, Input, Type, ViewContainerRef } from '@angular/core'

@Directive({
    selector: '[appDynamicContainer]',
})
export class DynamicContainerDirective {

    @Input() groupId: string

    constructor(
        private resolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef,
    ) { }

    loadComponent(component: Type<any>): ComponentRef<any> {

        // create the specified component and load it into the container
        const componentFactory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component)
        this.viewContainerRef.clear()
        return this.viewContainerRef.createComponent(componentFactory)
    }
}
