export enum InvoiceValidationIssue {
    NONE = 'NONE',
    BILLING_DATE_DUPLICATE = 'BILLING_DATE_DUPLICATE',
    BILLING_DATE_MISMATCH = 'BILLING_DATE_MISMATCH',
    END_DATE_BEYOND_AGREED = 'END_DATE_BEYOND_AGREED',
    HOURS_EXCEEDED = 'HOURS_EXCEEDED',
    LINE_ITEM_DESC_MISMATCH = 'LINE_ITEM_DESC_MISMATCH',
    LINE_ITEM_NOT_FOUND = 'LINE_ITEM_NOT_FOUND',
    LINE_ITEM_QTY_INVALID = 'LINE_ITEM_QTY_INVALID',
    LINE_ITEM_RATE_MISMATCH = 'LINE_ITEM_RATE_MISMATCH',
    LINE_ITEM_UOM_MISMATCH = 'LINE_ITEM_UOM_MISMATCH',
    PERFORMANCE_PERIOD_OVERLAP = 'PERFORMANCE_PERIOD_OVERLAP',
    TIME_PERIOD_MISMATCH = 'TIME_PERIOD_MISMATCH',
    WORK_ORDER_NOT_FOUND = 'WORK_ORDER_NOT_FOUND',
}
