<mat-expansion-panel
    [disabled]="(disabled$ | async) || hasAction || isNotExpandable"
    [expanded]="workflowModule.id === (activeModule$ | async)?.id"
    [hideToggle]="!!workflowModule.action"
    class="workflow-module-panel"
    (opened)="afterExpand()"
    (afterCollapse)="afterCollapse()"
>

    <mat-expansion-panel-header
        collapsedHeight="120px"
        expandedHeight="120px"
        class="workflow-module-header"
        (click)="goToModule()"
        [attr.moduleId]="workflowModule.id"
    >

        <div class="header-container">

            <img [src]="workflowModule.image">

            <div
                [ngClass]="{ 'low-contrast': (isLoading$ | async) || ((disabled$ | async) && !hasAction && !isNotExpandable), 'primary100': ((disabled$ | async) === false) || hasAction || isNotExpandable }"
                class="subtitle1"
            >
                {{workflowModuleLabel}}
            </div>

            <div
                *ngIf="completed() && !(isLoading$ | async)"
                class="group-complete"
            >
                <i-feather
                    name="check-circle"
                    class="success"
                ></i-feather>
            </div>

            <div
                *ngIf="(isLoading$ | async)"
                class="group-loading"
            >
                <i-feather
                    name="refresh-cw"
                    class="primary80"
                ></i-feather>
            </div>

            <div
                *ngIf="!!workflowModule.action"
                class="action-container"
            >
                <button
                    mat-stroked-button
                    [disabled]="(isLoading$ | async)"
                    (click)="onAction($event)"
                >
                    {{workflowModule.action}}
                </button>
            </div>

        </div>

    </mat-expansion-panel-header>

    <app-workflow-group
        [form]="form"
        [hideStepIndicators]="hideStepIndicators"
        [isDialog]="isDialog"
        [noStepImage]="noStepImage"
        [workflowModule]="workflowModule"
        (changed)="change()"
        [ngClass]="{ hidden: (hidden || workflowModule.id !== (activeModule$ | async)?.id) }"
    ></app-workflow-group>

</mat-expansion-panel>
