import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatChipsModule } from '@angular/material/chips'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'

import { ChipListModule } from '../chip-list/chip-list.module'

import { AddressDisplayComponent } from './address-display/address-display.component'
import { AddressFormComponent } from './address-form/address-form.component'
import { CountryAutocompleteFormComponent } from './country-autocomplete-form/country-autocomplete-form.component'
import { CountryFormComponent } from './country-form/country-form.component'
import { AddressService } from './services/address.service'
import { StateFormComponent } from './state-form/state-form.component'

@NgModule({
    declarations: [
        AddressDisplayComponent,
        AddressFormComponent,
        CountryAutocompleteFormComponent,
        CountryFormComponent,
        StateFormComponent,
    ],
    exports: [
        AddressDisplayComponent,
        AddressFormComponent,
        CountryAutocompleteFormComponent,
        CountryFormComponent,
        StateFormComponent,
    ],
    imports: [
        ChipListModule,
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
})
export class AddressModule {

    static forRoot(): ModuleWithProviders<AddressModule> {
        return {
            ngModule: AddressModule,
            providers: [
                AddressService,
            ],
        }
    }
}
