import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatCardModule } from '@angular/material/card'

import { GenericContractComponent } from './generic-contract.component'
import { PrivacyComponent } from './privacy.component'
import { TermsComponent } from './terms.component'

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
    ],
    providers: [
        // services go here
    ],
    declarations: [
        PrivacyComponent,
        TermsComponent,
        GenericContractComponent,
    ],
    exports: [
        PrivacyComponent,
        TermsComponent,
        GenericContractComponent,
    ],
})
export class SharedModule { }
