import { DialogModule } from './../../dialogs/dialog.module';
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatOptionModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSliderModule } from '@angular/material/slider'
import { FeatherModule } from 'angular-feather'
import { ImageCropperModule } from 'ngx-image-cropper'

import { OrganizationService } from '../../core/services'

import { AvatarManagementDialogComponent } from './avatar-management-dialog/avatar-management-dialog.component'
import { EINDialogComponent } from './EIN-dialog/EIN-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    ReactiveFormsModule,
    FeatherModule,
    MatSliderModule,
    DialogModule
  ],
  providers: [
    OrganizationService,
  ],
  declarations: [
    AvatarManagementDialogComponent,
    EINDialogComponent,
  ],
  exports: [
    ReactiveFormsModule,
    AvatarManagementDialogComponent,
    EINDialogComponent,
  ],
})
export class OrganizationDialogsModule { }
