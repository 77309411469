import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { TimeTrackingDocument, TimeTrackingEntry } from '../models'

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingStore {

    constructor(
        private http: HttpClient,
    ) { }

    getTimeTrackingDocumentsForWorkOrder(workOrderId: string): Observable<Array<TimeTrackingDocument>> {
        return this.http.get<Array<TimeTrackingDocument>>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/${workOrderId}/timeTracking`)
    }

    createTimeTrackingDocument(newTimeTrackingDocument: TimeTrackingDocument): Observable<TimeTrackingDocument> {
        return this.http.post<TimeTrackingDocument>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/timeTracking/documents`,
            newTimeTrackingDocument)
    }

    createTimeTrackingEntry(newTimeTrackingEntry: TimeTrackingEntry, workOrderId: string): Observable<TimeTrackingEntry> {
        return this.http.post<TimeTrackingEntry>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/${workOrderId}/timeTracking/entries`,
            newTimeTrackingEntry)
    }

    updateTimeTrackingEntry(updatedEntry: TimeTrackingEntry): Observable<TimeTrackingEntry> {
        return this.http.put<TimeTrackingEntry>(environment.liquidApiSettings.apiServicePrefix + `/work-orders/timeTracking/entries`,
            updatedEntry)
    }

    deleteTimeTrackingEntry(timeTrackingEntryId: string, workOrderId: string): Observable<boolean> {
        return this.http.delete<boolean>(
            environment.liquidApiSettings.apiServicePrefix + `/work-orders/${workOrderId}/timeTracking/entries/${timeTrackingEntryId}`)
    }

}
