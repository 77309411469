export enum CreateBusinessStepId {
    Address = 'address',
    BusinessName = 'name',
    Intro = 'intro',
    Confirm = 'confirm',
    Country = 'country',
    Email = 'email',
    FinanceCard = 'card',
    FinanceBankAccount = 'bank-account-store',
    FinanceBankAccountAdd = 'bank-account',
    FinanceBankAccountAddress = 'bank-account-address',
    FinanceBankAccountHolderName = 'bank-account-holder-name',
    FinanceBankAccountInfo = 'bank-account-info',
    FinanceBankAccountInformation = 'bank-information',
    FinanceBankAccountConnect = 'bank-account-connect',
    FinanceBankAccountNumber = 'bank-account-number',
    FinanceBankAccountType = 'bank-account-type',
    FinanceBankAddressSame = 'finance-bank-address-same',
    FinanceBillingAddress = 'billing-address',
    FinanceConfirmation = 'confirmation',
    FinanceEmailSent = 'email-sent',
    FinanceIntlCountryCurrency = 'intl-country-currency',
    FinanceIntlCountryCurrencyEnable = 'intl-country-currency-enable',
    FinanceIntlFeedback = 'intl-feedback',
    FinanceIntlVendors = 'intl-vendors',
    FinanceInviteEmail = 'invite-email',
    FinanceInvoicePayorComplete = 'invoice-payor-complete',
    FinanceLiquidFeesPlaid = 'liquid-fees-plaid',
    FinanceMicrodepositBankAccountInformation = 'microdeposit-bank-information',
    FinancePayLiquidFees = 'pay-liquid-fees',
    FinanceLiquidFeesCard = 'liquid-fees-card',
    FinancePayThroughLiquidPlaid = 'pay-through-liquid-plaid',
    FinancePlaidAccountInformation = 'plaid-account-information',
    FinancePlaidConfirmation = 'plaid-confirmation',
    FinanceSetupPaymentMethod = 'setup-payment-method',
    FinanceTempAddCard = 'temp-add-card',
    FinanceTempBillingAddress = 'temp-billing-address',
    FinanceVendorType = 'vendor-type',
    FinanceWhoPaysInvoices = 'who-pays-invoices',
    FinanceWhoPaysConfirmation = 'who-pays-confirmation',
    IncorporationInformation = 'incorporation-information',
    Industry = 'industry',
    IntlRequirements = 'intl-requirements',
    IntlRequirementsWithCurrency = 'intl-requirements-currency',
    LegalSetupBusinessName = 'business-name',
    LegalSetupCompanyIncorporation = 'company-incorporation',
    LegalSetupConfirmation = 'confirmation',
    LegalSetupContractTemplatesDocuments = 'contract-templates-documents',
    LegalSetupContractTemplateType = 'contract-template-type',
    LegalSetupDisclaimer = 'disclaimer',
    LegalSetupDocuments = 'documents',
    LegalSetupResolution = 'resolution',
    LegalSetupSendTemplates = 'send-templates',
    LiquidPlanConfirm = 'subscription/plan-confirm',
    LiquidPlanEnterPromoCode = 'subscription/plan-promo-code',
    LiquidPlanFreeTrial = 'subscription/free-trial',
    LiquidPlanIntro = 'subscription/plan-intro',
    LiquidPlanOptions = 'subscription/plan-options',
    LiquidPlanPromoCodeDecision = 'subscription/plan-promo-code-decision',
    Logo = 'logo',
    ProfileId = 'profile-id',
    ProfileInfo = 'profile-info',
    Structure = 'structure',
    StructureOther = 'structure-other',
    TaxId = 'tax-id',
    TaxIdType = 'tax-id-type',
    Type = 'create/type',
}
