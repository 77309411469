export enum StorageKey {
    businessToken = 'business-token',
    clientFilters = 'selected-client-filters',
    contactFilters = 'contact-filters',
    contactInvite = 'contact-invite',
    deliverables = 'deliverables',
    dialogStore = 'dialog-store',
    expressBusinessSetupResult = 'express-business-setup-result',
    freeFXActions = 'free-fx-actions',
    inviteNewBusinessId = 'invite-new-business-id',
    inviteeQuickBooksVendorId = 'invitee-quickbooks-vendorid',
    invoiceCarouselInvoiceIds = 'invoice-carousel-invoice-ids',
    invoiceCarouselOpenInfo = 'invoice-carousel-open-config',
    invoiceSentToQuickBooks = 'invoice-sent-to-quickbooks',
    invoiceStatusFiltersClient = 'invoice-status-filters-client',
    invoiceVendorFiltersClient = 'invoice-vendor-filters-client',
    legalSetupRedirect = 'legal-setup-redirect',
    liquidInvoicesSentToQuickBooks = 'liquid-invoices-sent-to-quickbooks',
    onboardingStore = 'onboarding-store',
    purchaseOrder = 'purchase-order-partial',
    purchaseOrderFiltersClient = 'purchase-orders-filters-client',
    purchaseOrderFiltersVendor = 'purchase-orders-filters-vendor',
    purchaseOrderListModeClient = 'po-list-mode-client',
    purchaseOrderListModeVendor = 'po-list-mode-vendor',
    purchaseOrderSortClient = 'purchase-orders-sort-client',
    purchaseOrderSortVendor = 'purchase-orders-sort-vendor',
    tablePageSize = 'table-page-size',
    tableSortActive = 'table-sort-active',
    tableSortDirection = 'table-sort-direction',
    teamListMode = 'selected-list-mode',
    teamMembershipId = 'team-membership-id',
    unfinishedTeamInvite = 'unfinished-team-invitation',
    unfinishedTeamInviteId = 'unfinished-team-invitation-id',
    unfinishedTeamInviteClientInvoiceId = 'unfinished-team-invitation-client-invoice-id',
    vendorFilters = 'selected-vendor-filters',
    vendorName = 'vendor-name',
    verifyAccount = 'account-code',
    verifyBusiness = 'business-code',
    w8 = 'w8',
    workOrder = 'work-order-partial',
    workOrderFiltersClient = 'work-orders-filters-client',
    workOrderFiltersVendor = 'work-orders-filters-vendor',
    workOrderListModeClient = 'wo-list-mode-client',
    workOrderListModeVendor = 'wo-list-mode-vendor',
    workOrderSortClient = 'work-orders-sort-client',
    workOrderSortVendor = 'work-orders-sort-vendor',
}
