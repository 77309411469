import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService as Auth0AuthService } from '@auth0/auth0-angular'
import { interval, Observable, of, Subject } from 'rxjs'
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { StorageKeyAuth } from '../auth/models'
import { AuthService, LoggingService } from '../auth/services'
import { DialogService } from '../dialogs'

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnDestroy, OnInit {

    private readonly unsubscribe$: Subject<void> = new Subject()
    private readonly xi: string = 'xi'

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        const url: string = this.router.routerState.snapshot.url
        const expressUrl: string = localStorage.getItem(StorageKeyAuth.EXPRESS_REDIRECT)

        this.handleMagicLinkErrorRoutes()
            .pipe(
                map(() => {
                    if (!!environment.internalSettings.enableFullStory && url.includes(this.xi)) {
                        localStorage.setItem(StorageKeyAuth.FULL_STORY, 'true')
                    }

                    if (url.includes('error=login_required') && !!expressUrl) {
                        this.router.navigateByUrl(expressUrl)
                        return of(undefined)
                    }
                    if (url.includes('error=access_denied')) {
                        // did not provide consent to login. redirect to home and zap all local storage:
                        const redirectUrl: string = localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT)
                        const invitationId: string = localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_INVITATION_ID)
                        const emailId: string = localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_EMAIL_ID)
                        localStorage.clear()
                        localStorage.setItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT, redirectUrl)
                        localStorage.setItem(StorageKeyAuth.CREATE_ACCOUNT_INVITATION_ID, invitationId)
                        localStorage.setItem(StorageKeyAuth.CREATE_ACCOUNT_EMAIL_ID, emailId)

                        if (!!expressUrl) {
                            this.router.navigateByUrl(expressUrl)
                            return of(undefined)
                        }
                        this.router.navigateByUrl('/home')
                    }

                    return of(true)
                }),
                switchMap(() => {
                    return this.authService.completeAuthentication()
                })).subscribe()

        // notify bugsnag if we are in callback loop for 30 seconds
        interval(30000)
            .pipe(
                takeUntil(this.unsubscribe$),
                take(1),
                tap(() => LoggingService.notify(new Error('Authentication infinite loop detected.'))),
            )
            .subscribe()
    }

    private handleMagicLinkErrorRoutes(): Observable<any> {
        return this.route.fragment
            .pipe(
                take(1),
                switchMap((fragment: string) => {
                    const errTag: string = 'error='
                    if (!!fragment && fragment.startsWith(errTag)) {
                        const ampIdx: number = fragment.indexOf('&')
                        let errCode: string
                        if (ampIdx > 0) {
                            errCode = fragment.substring(errTag.length, ampIdx)
                            if (errCode.toLowerCase() === 'unauthorized') {
                                const descTag: string = '&error_description='
                                const descTagIdx: number = fragment.indexOf(descTag)
                                if (descTagIdx > 0) {
                                    const errDesc: string = fragment.substring(descTagIdx + descTag.length, fragment.length)
                                    const exceeded: string = `You've reached the maximum number of attempts. Please try to login again.`
                                    const expired: string = `The verification code has expired. Please try to login again.`
                                    const wrong: string = `Wrong email or verification code.`
                                    if (errDesc === exceeded || errDesc === expired || errDesc === wrong) {
                                        // check to see if local storage has the variables to continue:
                                        if (!!localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT) &&
                                            !!localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_INVITATION_ID) &&
                                            !!localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_EMAIL_ID) &&
                                            !!localStorage.getItem(StorageKeyAuth.EXPRESS_REDIRECT)) {
                                            // if not, throw up generic error
                                            return this.dialogService.error(`Your login link is either expired, invalid, or has already been used to log in. Please send yourself a new login link.`)
                                                .pipe(
                                                    take(1),
                                                    tap(() => {
                                                        this.router.navigateByUrl(localStorage.getItem(StorageKeyAuth.EXPRESS_REDIRECT))
                                                    }),
                                                )
                                        } else {
                                            // if not, throw up generic error
                                            return this.dialogService.error(`Your login link is either expired, or has already been used to log in. Please try accessing your invoice again by clicking on the invoice link in your email.`)
                                                .pipe(
                                                    take(1),
                                                    tap(() => {
                                                        window.location.href = 'https://www.goliquid.io'
                                                    }),
                                                )
                                        }
                                    } else {
                                        // deal with any other error in this format
                                        return this.dialogService.error(`${errCode.toUpperCase()}: ${errDesc}`)
                                            .pipe(
                                                take(1),
                                                tap(() => {
                                                    window.location.href = 'https://www.goliquid.io'
                                                }),
                                            )
                                    }
                                }
                            }
                        }
                        return of(undefined)
                    } else {
                        return of(undefined)
                    }
                }),
            )
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }
}
