import { Component, OnInit } from '@angular/core'

import { environment } from '../../../environments/environment'
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-google-tag-manager',
    templateUrl: './google-tag-manager.component.html',
    styleUrls: ['./google-tag-manager.component.scss'],
})
export class GoogleTagManagerComponent implements OnInit {

    private readonly enabledGoogleTagManager: Array<string> = environment.internalSettings.enabledGoogleAnalytics

    constructor(private gtmService: GoogleTagManagerService) {}

    ngOnInit(): void {
        if (environment.googleTagManager.enabled) {
            this.initTagManager()
        }
    }

    private initTagManager(): void {
        this.gtmService.addGtmToDom();
    }
}
