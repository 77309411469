import { ClientInvoice } from './client-invoice.model'
import { InvoiceLineItem } from './invoice-line-item.model'
import { LiquidFinancialAccountAssignment } from './liquid-financial-account-assignment.model'

export class ClientInvoiceCreationRequest {
    associatedWorkOrderIds: Array<string>
    createdByOrganizationId: string
    createdForBillingReminderId?: string
    contactId?: string
    invoice: ClientInvoice
    lineItems: Array<InvoiceLineItem>
    liquidFinancialAccountAssignments: Array<LiquidFinancialAccountAssignment>
    vendorBankAccountNumber?: string
    vendorRoutingNumber?: string
}
