<app-dialog-template-small [title]="title">

    <div
        class="dialog-content"
        [innerHTML]="description | safeHtml"
    ></div>

    <button
        mat-stroked-button
        *ngIf="!!noButtonText"
        (click)="noClicked()"
        class="dialog-button"
    >
        {{noButtonText}}
    </button>
    <button
        mat-flat-button
        color="primary"
        (click)="yesClicked()"
        class="dialog-button"
    >
        {{yesButtonText}}
    </button>
</app-dialog-template-small>
