<app-dialog-template-small
    (close)="cancel()"
    [title]="dialogTitle"
>
    <div class="dialog-content">
        <ng-container *ngIf="allowNewMicrodepositDetails">
            <p class="subtitle1 primary100">
                Enter your account details, and you'll receive two micro-deposits within two business days. You'll then
                need to come back to Liquid to enter the amounts to verify your bank account.
            </p>
            <p class="subtitle3 inline-warning">By Providing your account and routing numbers as well as your Account
                holder information and then clicking 'Next', you authoirze Liquid to initiate these micro-deposits and
                offsetting debit(s).</p>
        </ng-container>

        <app-verify-with-deposits-details
            [accountNumberValue]="accountNumber"
            [bankNameValue]="bankName"
            [disableInputs]="!allowNewMicrodepositDetails"
            [form]="form"
            [routingNumberAbaValue]="routingNumberAba"
            [liquidBankAccount$]="liquidBankAccount$"
        >
        </app-verify-with-deposits-details>

        <app-payment-account-holder-info
            [account]="account"
            [form]="form"
            [liquidBankAccount$]="liquidBankAccount$"
        ></app-payment-account-holder-info>
    </div>
    <div
        *ngIf="confirmClose"
        class="inline-error error-default cancel-alert dialog-content"
    >
        Click 'x' again if you would like to exit without saving your changes.
    </div>
    <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid || !form.dirty"
        (click)="setInfo()"
        class="dialog-button"
    >
        {{allowNewMicrodepositDetails ? 'Next' : 'Save'}}
    </button>
</app-dialog-template-small>