import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ContractSignerInvitation, OnboardingProcess, OrganizationTeamConfiguration } from '../../modules/models'

import { LegalSetupStore } from './legal-setup.store'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class LegalSetupService {

    readonly currentOrganizationNeedsLegalSetup$: Observable<boolean> = this.userService.teamConfig$
        .pipe(
            map(teamConfig => this.organizationNeedsLegalSetup(teamConfig)),
        )

    get currentOrganizationNeedsLegalSetupSnapshot(): boolean {
        return this.organizationNeedsLegalSetup(this.userService.teamConfigSnapshot)
    }

    constructor(
        private legalSetupStore: LegalSetupStore,
        private readonly userService: UserService,
    ) { }

    getLatestContractSingerInvitation(): Observable<ContractSignerInvitation[]> {
        return this.legalSetupStore.getContractSingerInvitations()
    }

    legalSetupNotStarted(processes: ReadonlyArray<OnboardingProcess>): boolean {
        return !processes.some(p => p.configured)
    }

    legalSetupIncomplete(invitations: ContractSignerInvitation[], processes: ReadonlyArray<OnboardingProcess>): boolean {

        // if there are no processes that aren't verified,
        // then we don't need to worry about legal setup
        if (!processes.some(p => !p.configured)) {
            return false
        }

        const hasAcceptedInvitation: boolean = invitations.some(i => i.accepted)
        const hasPendingInvitation: boolean = invitations.some(i => !i.accepted)
        return !hasAcceptedInvitation && hasPendingInvitation
    }

    organizationNeedsLegalSetup(teamConfig: OrganizationTeamConfiguration | undefined): boolean {
        if (!teamConfig) {
            return false
        }

        return this.legalSetupNotStarted(teamConfig.onboardingProcesses)
    }
}
