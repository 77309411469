import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators'

import { DynamicContainerDirective } from '../../../../../common/directives/dynamic-container/dynamic-container.directive'
import { SelectOption } from '../../../../../core/models'
import { DialogService } from '../../../../../dialogs'
import { HandledError } from '../../../../../error-handling/handled-error.interface'
import { WorkflowModule, WorkflowStep, WorkflowStepGroup } from '../models'
import { WorkflowService } from '../services'

@Component({
    selector: 'app-workflow-group',
    templateUrl: './workflow-group.component.html',
    styleUrls: ['./workflow-group.component.scss'],
})
export class WorkflowGroupComponent implements OnInit, AfterViewInit, OnDestroy {

    private activeStepComponentRef: ComponentRef<any>
    private unsubscribe$: Subject<void> = new Subject()

    private get groupContainer(): DynamicContainerDirective { return this.stepContainers.find(container => container.groupId === this.workflow.activeGroup.id) }
    private get numberOfSkipped(): number { return this.workflow.activeGroup.steps.filter((val, index) => val.skipped && index < this.workflow.activeStepIndex).length }
    public get currentStepData(): number { return this.workflow.activeStep?.data }

    @ViewChildren(DynamicContainerDirective) private stepContainers: QueryList<DynamicContainerDirective>

    @Output() changed: EventEmitter<void> = new EventEmitter()

    @Input() form: FormGroup
    @Input() hideStepIndicators: boolean = false
    @Input() isDialog: boolean
    @Input() noStepImage: boolean
    @Input() workflowModule: WorkflowModule

    readonly activeGroup$: Observable<WorkflowStepGroup> = this.workflow.activeGroup$
    readonly activeStep$: Observable<WorkflowStep> = this.workflow.activeStep$
    error: HandledError
    readonly isActiveLast$: Observable<boolean> = this.workflow.isActiveLast$
    readonly isActiveFirst$: Observable<boolean> = this.workflow.isActiveFirst$
    readonly maxEnabledGroupIndex$: Observable<number> = this.workflow.maxEnabledGroupIndex$
    readonly maxEnabledStepIndex$: Observable<number> = this.workflow.maxEnabledStepIndex$
    readonly nextButtonText$: Observable<string> = combineLatest([
        this.workflow.buttonLabel$,
        this.workflow.isActiveLast$,
    ])
        .pipe(
            map(([label, last]) => {
                return !!label
                    ? label
                    : last ? 'Start' : 'Next'
            }),
        )
    readonly showDismissMessage$: Observable<boolean> = this.workflow.showDismissMessage$
    readonly stepIndicatorOptions$: Observable<Array<SelectOption<string>>> = combineLatest([
        this.activeGroup$,
        this.activeStep$,
    ])
        .pipe(
            map(([group]) => `${this.workflow.activeStepIndex + 1 - this.numberOfSkipped} of ${group.steps.filter(s => !s.skipped).length}`),
            map(label => ([{
                label: label,
                value: label,
                removable: false,
            }])),
        )

    get disableBack(): boolean { return this.workflow.activeStep.disableBack }
    get hideBack(): boolean { return !!this.workflow.activeStep.hideBack || this.workflow.activeStepIndex - this.numberOfSkipped < 1 }
    get isLoading$(): Observable<boolean> { return this.workflow.isLoading$ }
    get shouldDisable(): boolean { return this.workflow.isLoading || this.form.invalid || this.workflow.disableNext }

    constructor(
        private cd: ChangeDetectorRef,
        private dialogs: DialogService,
        private workflow: WorkflowService,
    ) { }

    ngOnInit(): void {
        this.workflow.change
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.changed.emit()),
            )
            .subscribe()

        this.workflow.error$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(error => this.error = error),
                filter(error => !!error && !this.isDialog),
                tap(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    ngAfterViewInit(): void {

        // have to wrap this in a timer
        // so that the ng lifecycle can complete
        of(undefined)
            .pipe(
                delay(0),
                tap(() => this.refreshStep()),
            )
            .subscribe()

        this.workflow.scrollGroup
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.scroll()),
            )
            .subscribe()

        setTimeout(() => {
            const panel: HTMLElement = document.querySelector(`[groupId="${this.workflow.activeGroup.id}"]`)
            const scrollDiv: Element = document.getElementsByClassName('router-outlet')[0]
            const offset: number = panel?.getBoundingClientRect().top - 90
            scrollDiv?.scrollBy(0, offset)
        }, 400)
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    refreshStep(): void {

        // destroy any components that have been loaded
        this.activeStepComponentRef?.destroy()
        this.activeStepComponentRef = undefined

        // after the workflow is complete, there might not be an active module,
        // so handle for undefined
        if (this.workflowModule.id === this.workflow.activeModule?.id) {
            this.activeStepComponentRef = this.groupContainer.loadComponent(this.workflow.activeStep.component)
        }
        this.cd.detectChanges()
    }

    disabled(index: number): boolean {
        return this.workflow.isLoading
            || (!this.workflow.isModuleComplete(this.workflow.activeModuleIndex) && index > this.workflow.maxEnabledGroupIndex)
    }

    expanded(index: number): boolean {
        return index === this.workflow.activeGroupIndex
    }

    completed(index: number): boolean {
        // this can get called after the workflow is totally completed and the active module not longer exists,
        // so this is the only place to handle for an undefined active module
        return this.workflow.isModuleComplete(this.workflow.activeModuleIndex)
            || (this.workflowModule.id === this.workflow.activeModule?.id && this.workflow.isGroupComplete(index, this.workflowModule.id))
    }

    active(index: number): boolean {
        return this.completed(index) || index <= this.workflow.activeGroupIndex
    }

    goToGroup(groupIndex: number): void {

        if (this.workflow.isLoading || groupIndex > this.workflow.maxEnabledGroupIndex || groupIndex === this.workflow.activeGroupIndex) {
            return
        }

        // we got here by skipping a next button and going straight to a group,
        // so remove any fields that are invalid
        Object.keys(this.form.controls)
            .filter(key => this.form.controls.hasOwnProperty(key) && this.form.controls[key].invalid)
            .forEach(key => this.form.removeControl(key))

        this.workflow.goToGroup(groupIndex)
        this.changed.emit()
    }

    submit(): void {
        this.workflow.onReset()
        if (this.form.valid) {
            this.workflow.onNext()
            document.getElementById('primary-' + this.workflow.activeGroup.id).focus()
        }
    }

    onPrevious(): void {
        this.workflow.onPrevious()
    }

    scroll(): void {
        if (this.workflowModule.id !== this.workflow.activeModule.id) {
            return
        }
        setTimeout(() => {
            const panel: HTMLElement = document.querySelector(`[groupId="${this.workflow.activeGroup.id}"]`)
            const scrollDiv: Element = document.getElementsByClassName('router-outlet')[0]
            const offset: number = panel?.getBoundingClientRect().top - 90
            scrollDiv?.scrollBy(0, offset)
        }, 350)
    }

    confirmDismiss(value: boolean, evt?: MouseEvent): void {
        evt?.preventDefault()
        evt?.stopPropagation()
        this.workflow.confirmDismiss(value)
    }
}
