import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import { Observable, throwError } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { LoggingService } from '../../auth/services'
import { ConstGlobal } from '../../common/global'
import { InvoiceFactory } from '../../core/factories'
import { UpdatePropertiesRequestScope } from '../../core/models'
import { BusinessUsersService, UrlService, UserService } from '../../core/services'
import { PageRequest } from '../../table/models'
import { Invoice } from '../liquid/modules/invoices/models'
import {
    BillingReminder,
    ClientInvoice,
    ClientInvoiceCreationRequest,
    ClientInvoicePreview,
    ClientInvoicesResult,
    ClientInvoiceStatus,
    InvoiceApprover,
    InvoiceAttachmentType,
    InvoiceLineItem,
    InvoiceListFilters,
    InvoicePageRequest,
    InvoiceUpdatePropertiesRequest,
    InvoiceValidationIssue,
    LiquidFinancialAccountAssignment,
    PrecalculatedInvoice,
    ProcessingHistoryItem,
    TimeTrackingEntry,
    WorkOrderInvoicingAbstract,
} from '../models'

import { ClientInvoiceStore } from './client-invoice.store'

@Injectable({
    providedIn: 'root',
})
export class ClientInvoiceService {

    private readonly uploadValidationIssues: ReadonlyArray<InvoiceValidationIssue> = [
        InvoiceValidationIssue.END_DATE_BEYOND_AGREED,
        InvoiceValidationIssue.PERFORMANCE_PERIOD_OVERLAP,
    ]

    constructor(
        private businessUsers: BusinessUsersService,
        private http: HttpClient,
        private invoiceFactory: InvoiceFactory,
        private clientInvoiceStore: ClientInvoiceStore,
        private urls: UrlService,
        private users: UserService,
    ) { }

    getHasInvoices(organizationId: string): Observable<boolean> {
        return this.clientInvoiceStore.getHasInvoices(organizationId)
            .pipe(
                take(1),
            )
    }

    getHasOutstandingInvoiceApprovals(clientId: string, vendorId: string): Observable<boolean> {
        return this.clientInvoiceStore.getHasOutstandingInvoiceApprovals(clientId, vendorId)
            .pipe(
                take(1),
            )
    }

    getInvoiceHistory(clientId: string, vendorId: string, page: PageEvent, sort: Sort, defaultSort: string, workOrderId?: string, isWorker: boolean = false): Observable<ClientInvoicesResult> {

        sort.active = ['invoiceNumberColumn'].includes(sort.active) ? sort.active.replace('Column', '') : sort.active
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }
        const filters: InvoiceListFilters = {
            statuses: Object.values(ClientInvoiceStatus).filter(status => status !== ClientInvoiceStatus.DRAFT).join(','),
            clientIds: isWorker ? [clientId] : undefined,
            vendorIds: isWorker ? undefined : [vendorId],
            workOrderIds: !!workOrderId ? [workOrderId] : undefined,
        }
        return isWorker
            ? this.getInvoicesForInvoicerOrganization(vendorId, pageRequest, defaultSort, filters)
            : this.getInvoicesForClientOrganization(clientId, pageRequest, defaultSort, filters)
    }

    getInvoiceIdsForClientOrganization(businessId: string, sortBy: string, defaultSort: string, filters?: InvoiceListFilters): Observable<Array<string>> {
        return this.getInvoiceIdsForOrganization(businessId, 'clients', sortBy, filters)
            .pipe(
                catchError(err => {
                    if (err.error.includes(ConstGlobal.INVALID_SORT_PARAMETER)) {
                        sortBy = defaultSort
                        LoggingService.notify(new Error(err.error))
                    }
                    return this.getInvoiceIdsForOrganization(businessId, 'clients', sortBy, filters)
                }),
            )
    }

    getInvoiceIdsForInvoicerOrganization(businessId: string, sortBy: string, defaultSort: string, filters?: InvoiceListFilters): Observable<Array<string>> {
        return this.getInvoiceIdsForOrganization(businessId, 'invoicers', sortBy, filters)
            .pipe(
                catchError(err => {
                    if (err.error.includes(ConstGlobal.INVALID_SORT_PARAMETER)) {
                        sortBy = defaultSort
                        LoggingService.notify(new Error(err.error))
                    }
                    return this.getInvoiceIdsForOrganization(businessId, 'invoicers', sortBy, filters)
                }),
            )
    }

    getInvoicesForClientOrganization(organizationId: string, page: PageRequest, defaultSort: string, filters?: InvoiceListFilters): Observable<ClientInvoicesResult> {
        return this.getInvoicesForOrganization(organizationId, 'clients', page, filters)
            .pipe(
                catchError(err => {
                    if (err.error.includes(ConstGlobal.INVALID_SORT_PARAMETER)) {
                        page.sortBy = defaultSort
                        LoggingService.notify(new Error(err.error))
                    }
                    return this.getInvoicesForOrganization(organizationId, 'clients', page, filters)
                }),
            )
    }

    getInvoicesForInvoicerOrganization(organizationId: string, page: PageRequest, defaultSort: string, filters?: InvoiceListFilters): Observable<ClientInvoicesResult> {
        return this.getInvoicesForOrganization(organizationId, 'invoicers', page, filters)
            .pipe(
                catchError(err => {
                    if (err.error.includes(ConstGlobal.INVALID_SORT_PARAMETER)) {
                        page.sortBy = defaultSort
                        LoggingService.notify(new Error(err.error))
                    }
                    return this.getInvoicesForOrganization(organizationId, 'invoicers', page, filters)
                }),
            )
    }

    getInvoiceFromInvitation(inviteId: string): Observable<ClientInvoicePreview> {
        return this.http.get<ClientInvoicePreview>(this.urls.api.invoiceForInvite(inviteId))
            .pipe(
                map(response => this.invoiceFactory.createPreview(response)),
            )
    }

    getInvoiceAttachmentBlob(invoice: ClientInvoice, attachmentType: InvoiceAttachmentType): Observable<Blob> {
        return this.http.get(`${environment.liquidApiSettings.apiServicePrefix}/invoices/${invoice.id}/attachments/downloads?attachmentType=${attachmentType}`, { responseType: 'blob' })
    }

    getInvoiceAttachmentUrl(invoice: ClientInvoice): string {
        // TODO: https://poweredbyliquid.atlassian.net/browse/LQD-6732
        return `${environment.liquidApiSettings.apiServicePrefix}/${invoice.id}`
    }

    getInvoiceById(invoiceId: string, businessId: string): Observable<ClientInvoice> {

        if (!invoiceId) {
            return this.logUndefinedInvoiceError('getInvoiceById')
        }

        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/${businessId}/${invoiceId}`
        return this.http.get<ClientInvoice>(url)
            .pipe(
                map(response => this.invoiceFactory.create(response)),
            )
    }

    getNewInvoiceNumber(): Observable<string> {
        return this.http.get<any>(this.urls.api.invoiceNewInvoiceNumber())
            .pipe(
                map(obj => obj.invoiceNumber),
            )
    }

    getUnknownClientInvoiceById(invoiceId: string): Observable<ClientInvoice> {
        return this.http.get<ClientInvoice>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/unknown-client/${invoiceId}`)
    }

    getLineItemsForClientInvoice(invoiceId: string, businessId: string, from: string = ''): Observable<InvoiceLineItem[]> {
        if (!invoiceId) {
            return this.logUndefinedInvoiceError(from)
        }

        return this.http.get<InvoiceLineItem[]>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/${businessId}/${invoiceId}/lineItems`)
    }

    getLineItemsForUnknownClientInvoice(invoiceId: string): Observable<InvoiceLineItem[]> {
        return this.http.get<InvoiceLineItem[]>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/unknown-client/${invoiceId}/lineItems`)
    }

    getPayoutCurrenciesForOrganizationTeamMember(organizationTeamMemberId: string): Observable<string[]> {
        return this.http.get<string[]>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/teamMembers/${organizationTeamMemberId}/availablePayoutCurrencies`)
    }

    getPotentialTimeTrackingEntriesForInvoice(invoiceId: string): Observable<TimeTrackingEntry[]> {
        return this.http.get<TimeTrackingEntry[]>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/${invoiceId}/potentialTimeTrackingEntries`)
    }

    generatePreviewInvoice(invoice: ClientInvoice,
        lineItems: InvoiceLineItem[], associatedWorkOrderIds: string[]): Observable<boolean> {
        return this.http.post(environment.liquidApiSettings.apiServicePrefix + `/invoices/previews`,
            { invoice, lineItems, associatedWorkOrderIds }, { responseType: 'blob' })
            .pipe(
                map(response => {
                    FileSaver.saveAs(response, `invoice-${invoice.invoiceNumber}_preview.pdf`)
                    return true
                }),
            )
    }

    sendInvoiceToQB(clientInvoiceId: string): Observable<boolean> {
        return this.http.post(this.urls.api.invoiceIntegrations(), { clientInvoiceId })
            .pipe(
                map(() => true),
            )
    }

    createInvoice(invoiceCreationRequest: ClientInvoiceCreationRequest): Observable<{ invoice: ClientInvoice, lineItems: InvoiceLineItem[] }> {
        if (invoiceCreationRequest?.invoice?.invoiceDate) {
            invoiceCreationRequest.invoice.invoiceDate = this.enforceInvoiceDateOnly(invoiceCreationRequest.invoice.invoiceDate)
            invoiceCreationRequest.invoice.stringInvoiceDate = this.getClientSideInvoiceDateAsString(invoiceCreationRequest.invoice.invoiceDate);
        }

        return this.http.post<{ invoice: ClientInvoice, lineItems: InvoiceLineItem[] }>(environment.liquidApiSettings.apiServicePrefix + `/invoices`,
            invoiceCreationRequest)
    }

    private enforceInvoiceDateOnly(invoiceDate: any) {
        const parsedInvoiceDate = new Date(invoiceDate)
        return new Date(parsedInvoiceDate.setHours(0, 0, 0, 0))
    }

    private getClientSideInvoiceDateAsString(invoiceDate: Date): string {
        // yyyy-mm-dd
        return moment(invoiceDate).format('YYYY-MM-DD')
    }

    updateInvoice(
        createdByOrganizationId: string,
        invoice: ClientInvoice,
        lineItems: InvoiceLineItem[],
        associatedWorkOrderIds: string[],
        liquidFinancialAccountAssignments: Array<LiquidFinancialAccountAssignment> = null,
        vendorBankAccountNumber?: string,
        vendorRoutingNumber?: string,
    ): Observable<{ invoice: ClientInvoice, lineItems: InvoiceLineItem[] }> {
        if (invoice.invoiceDate) {
            invoice.invoiceDate = this.enforceInvoiceDateOnly(invoice.invoiceDate)
            invoice.stringInvoiceDate = this.getClientSideInvoiceDateAsString(invoice.invoiceDate)
        }

        const request: ClientInvoiceCreationRequest = {
            invoice,
            lineItems,
            createdByOrganizationId,
            associatedWorkOrderIds,
            liquidFinancialAccountAssignments,
            vendorBankAccountNumber,
            vendorRoutingNumber,
        }
        return this.http.put<{ invoice: ClientInvoice, lineItems: InvoiceLineItem[] }>(environment.liquidApiSettings.apiServicePrefix + `/invoices`,
            request)
    }

    deleteDraftInvoice(invoice: ClientInvoice): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix + `/invoices/${invoice.id}`)
    }

    downloadClientInvoice(invoice: ClientInvoice): Observable<boolean> {
        return this.http.get(environment.liquidApiSettings.apiServicePrefix + `/invoices/${invoice.id}/pdf`, { responseType: 'blob' })
            .pipe(
                map(response => {
                    FileSaver.saveAs(response, `invoice-${invoice.invoiceNumber}.pdf`)
                    return true
                }),
            )
    }

    downloadClientInvoiceAttachment(invoice: ClientInvoice): Observable<boolean> {
        return this.getInvoiceAttachmentBlob(invoice, InvoiceAttachmentType.Invoice)
            .pipe(
                map(response => {
                    const split: string[] = invoice.invoiceAttachmentUrl.split('.')
                    let ext: string = ''
                    if (split.length > 1) {
                        ext = split[split.length - 1]
                        if (ext && ext.length > 0) {
                            ext = `.${ext}`
                        }
                    }
                    FileSaver.saveAs(response, `invoice-${invoice.invoiceNumber}${ext}`)
                    return true
                }),
            )
    }

    downloadUnknownClientInvoice(invoice: ClientInvoice): Observable<boolean> {
        return this.http.get(environment.liquidApiSettings.apiServicePrefix + `/invoices/unknown-client/${invoice.id}/pdf`, { responseType: 'blob' })
            .pipe(
                map(response => {
                    FileSaver.saveAs(response, `invoice-${invoice.invoiceNumber}.pdf`)
                    return true
                }),
            )
    }

    resendClientInvoice(clientInvoiceId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + `/invoices/notifications`, { clientInvoiceId })
    }

    getInvoiceAbstractForWorkOrder(organizationId: string, workOrderId: string): Observable<WorkOrderInvoicingAbstract> {
        return this.http.get<WorkOrderInvoicingAbstract>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/workerOrganizations/${organizationId}/invoicingAbstracts/${workOrderId}`)
    }

    getBillingRemindersForWorkerOrganizatiopn(organizationId: string): Observable<BillingReminder[]> {
        return this.http.get<BillingReminder[]>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/workerOrganizations/${organizationId}/billingReminders`)
    }

    getPrecalculatedInvoiceForBillingReminder(billingReminderId: string): Observable<PrecalculatedInvoice> {
        return this.http.get<PrecalculatedInvoice>(environment.liquidApiSettings.apiServicePrefix +
            `/invoices/pre-calcs/billingReminders/${billingReminderId}`)
    }

    getPrecalculatedInvoiceForWorkOrder(workOrderId: string): Observable<PrecalculatedInvoice> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/pre-calcs/work-orders/${workOrderId}`
        return this.http.get<PrecalculatedInvoice>(url)
    }

    removeBillingReminder(billingReminderId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/billingReminders/${billingReminderId}`)
    }

    attachInvoiceDocumentToInvoice(invoiceId: string, filename: string, attachmentType: InvoiceAttachmentType, data: FormData, createdByOrganizationId: string): Observable<boolean> {
        const url: string = this.urls.api.invoiceUploadAttachment(invoiceId, filename, createdByOrganizationId, attachmentType)
        return this.http.post<boolean>(url, data)
    }

    sendNoClientOrganizationInvoiceMessage(invoiceId: string, message: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/invoices/unknown-client/messages',
            { invoiceId, message })
    }

    associateInvoiceWithOrganization(clientInvoiceId: string, organizationId: string):
        Observable<ClientInvoice> {
        return this.http.post<ClientInvoice>(environment.liquidApiSettings.apiServicePrefix + `/invoices/orgAssociations`,
            { clientInvoiceId, organizationId })
    }

    reject(clientInvoiceId: string, reason: string): Observable<boolean> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/processingDecisions`
        const request: any = {
            clientInvoiceId: clientInvoiceId,
            accepted: false,
            noteToInvoicer: reason,
        }
        return this.http.post<boolean>(url, request)
    }

    accept(clientInvoiceId: string): Observable<boolean> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/processingDecisions`
        const request: any = {
            clientInvoiceId: clientInvoiceId,
            accepted: true,
        }
        return this.http.post<boolean>(url, request)
    }

    assign(approvers: Array<InvoiceApprover>, invoiceId: string): Observable<Array<InvoiceApprover>> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/${invoiceId}/approvalProcesses`
        return this.http.post<Array<InvoiceApprover>>(url, approvers)
            .pipe(
                map(apps => this.invoiceFactory.normalizeApprovers(apps)),
            )
    }

    approve(approverId: string, response: boolean, note?: string): Observable<InvoiceApprover> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/approvals`
        const body: any = {
            clientInvoiceApproverId: approverId,
            approvalResponse: response,
            approvalResponseExplanation: note,
        }
        return this.http.post<InvoiceApprover>(url, body)
    }

    associateChartOfAccounts(clientInvoiceId: string, liquidFinancialAccountAssignments: Array<LiquidFinancialAccountAssignment>): Observable<Invoice> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/chartOfAccountsAssociations`
        const request: any = {
            clientInvoiceId,
            liquidFinancialAccountAssignments,
        }
        return this.http.post<Invoice>(url, request)
    }

    getProcessingHistory(invoiceId: string): Observable<Array<ProcessingHistoryItem>> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/${invoiceId}/invoiceProcessingHistory`
        return this.http.get<Array<ProcessingHistoryItem>>(url)
    }

    sendInvoiceMessage(invoiceId: string, message: string): Observable<boolean> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/invoices/messages`
        const body: { [property: string]: string } = {
            invoiceId,
            message,
        }
        return this.http.post<boolean>(url, body)
    }

    filterUploadIssues(validationIssues: ReadonlyArray<InvoiceValidationIssue>): InvoiceValidationIssue[] {
        return validationIssues.filter(validationIssue => this.uploadValidationIssues.includes(validationIssue))
    }

    someUploadIssue(validationIssues: ReadonlyArray<InvoiceValidationIssue>): boolean {
        return validationIssues.some(validationIssue => this.uploadValidationIssues.includes(validationIssue))
    }

    updateClientInvoiceBillingField(billingFieldId: string, clientInvoiceId: string, updateScope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.All): Observable<ClientInvoice> {
        if (!clientInvoiceId) {
            return throwError('Invoice ID is required')
        }

        const request: InvoiceUpdatePropertiesRequest = {
            clientInvoiceId,
            requestedByOrgId: this.users.businessSnapshot.id,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                BillingFieldId: billingFieldId,
            },
            updateScope,
        }

        return this.clientInvoiceStore.updateInvoiceProperties(request)
            .pipe(
                take(1),
            )
    }

    private getInvoiceIdsForOrganization(organizationId: string, type: 'clients' | 'invoicers', sortBy: string, filters?: InvoiceListFilters): Observable<Array<string>> {
        const pageRequest: InvoicePageRequest = this.getInvoicePageRequest({ sortBy, filters })
        return this.http.post<Array<string>>(this.urls.api.invoiceIds(organizationId, type), pageRequest)
    }

    private getInvoicesForOrganization(organizationId: string, type: 'clients' | 'invoicers', page: PageRequest, filters?: InvoiceListFilters): Observable<ClientInvoicesResult> {
        const pageRequest: InvoicePageRequest = this.getInvoicePageRequest({ ...page, filters })
        return this.http.post<ClientInvoicesResult>(this.urls.api.invoiceList(organizationId, type), pageRequest)
            .pipe(
                tap((result: ClientInvoicesResult) => result.page.results = result.page.results.map(invoice => this.invoiceFactory.create(invoice))),
            )
    }

    private getInvoicePageRequest(input: { page?: number, pageSize?: number, sortBy?: string, filters?: InvoiceListFilters }): InvoicePageRequest {
        const { page, pageSize, sortBy, filters }: typeof input = input
        return {
            clientIds: filters?.clientIds?.join(','),
            page,
            pageSize,
            sortBy,
            statuses: filters?.statuses,
            vendorIds: filters?.vendorIds?.join(','),
            workOrderIds: filters?.workOrderIds?.join(','),
        }
    }

    private logUndefinedInvoiceError(fromMethod: string): Observable<any> {
        return this.businessUsers.initialize()
            .pipe(
                tap(() => {
                    const err: Error = new Error(`${ConstGlobal.UNDEFINED_INVOICE_ID} from: ${fromMethod} for Liquid Profile Id: ${this.businessUsers.currentUser.profileId}`)
                    LoggingService.notify(err)
                    throw err
                }),
                map(() => undefined),
            )
    }
}
