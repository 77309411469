import { CdkTreeModule } from '@angular/cdk/tree'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { RouterModule, Routes } from '@angular/router'

import { LiquidCommonModule } from '../../../../common/liquid-common.module'
import { DialogModule } from '../../../../dialogs'
import { IncorporationStatePipe } from '../../../../pipes'
import { OrganizationDialogsModule } from '../../../organization-dialogs/organization-dialogs.module'
import { EditSignatureModule } from '../edit-signature/edit-signature.module'
import { EditOrganizationMemberDialogComponent } from '../user-admin/edit-organization-member-dialog.component'

import { AvatarPlaceholderComponent } from './avatar-placeholder/avatar-placeholder.component'
import { OnboardingUploadWizardComponent } from './onboarding-upload-wizard.component'
import { OnboardingVerificationComponent } from './onboarding-verification.component'
import { ProfileIdFormComponent } from './profile-id-form/profile-id-form.component'
import { WizardControllerComponent } from './wizard-controller.component'

const routes: Routes = []

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        EditSignatureModule,
        FormsModule,
        LiquidCommonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatTooltipModule,
        MatTreeModule,
        OrganizationDialogsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ],
    providers: [
        IncorporationStatePipe,
    ],
    declarations: [
        AvatarPlaceholderComponent,
        EditOrganizationMemberDialogComponent,
        OnboardingUploadWizardComponent,
        OnboardingVerificationComponent,
        ProfileIdFormComponent,
        WizardControllerComponent,
    ],
    exports: [
        AvatarPlaceholderComponent,
        CdkTreeModule,
        MatTreeModule,
        OnboardingUploadWizardComponent,
        OnboardingVerificationComponent,
        ProfileIdFormComponent,
        ReactiveFormsModule,
        WizardControllerComponent,
    ],
})
export class AppCommonModule { }
