export enum StripeChargeStatus {
    PENDING = 'pending'
}

export class StripeCharge {
    public createdTimestamp: Number;
    public organizationId: string;
    public clientInvoiceId: string;
    public stripeCardId: string;
    public stripeBankAccountId: string;
    public amount: Number;
    public amountRefunded: Number;
    public currency: string;
    public description: string;
    public paid = false;
    public refunded = false;
    public status: StripeChargeStatus;
}
