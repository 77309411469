import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'

import { ClaimsService } from './claims.service'
import { ProfileService } from './profile.service'
import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class ClaimsGuard implements CanActivate {

    constructor(
        private claims: ClaimsService,
        private router: Router,
        private urls: UrlService,
        private users: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        // if we didn't get a team config, the only thing
        // we can do is create an org or an accept an invite
        if (!this.users.teamConfigSnapshot) {

            const anonLinks: ReadonlyArray<string> = ['team-accept', 'organization-accept', 'express-setup']

            if (state.url.startsWith('/liquid/organization') || anonLinks.some(al => state.url.includes(al))) {
                return true
            }

            this.router.navigateByUrl(this.urls.route.createBusiness())
            return false
        }

        return this.claims.verifyRightsForBusiness(this.users.businessSnapshot, route, state.url)
    }
}
