import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { BusinessTokenInterceptor } from './interceptors/business-token.interceptor'
import { AuthGuard, AuthService } from './services'

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        AuthGuard,
        AuthService,
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthGuard,
                BusinessTokenInterceptor,
                AuthService,
            ],
        }
    }
}
