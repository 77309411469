import { LiquidFinancialAccountUiType } from './liquid-financial-account-ui-type.enum'
import { QuickBooksAccount } from './quickbooks-account.model'

export class LiquidFinancialAccount {
    accountName: string
    description: string
    id: string
    isForPaymentMethod: boolean = false
    isSystemAccount: boolean = false
    isDefaultCostOfGoodsFeesAccount: boolean = false
    isDefaultProfessionalServiceFeesAccount: boolean = false
    isDeletedPaymentMethodAccount: boolean = false
    isLiquidPlatformFeesAccount: boolean = false
    isLiquidTransactionFeesAccount: boolean = false
    isAccountsPayableAccount: boolean = false
    liquidFinancialAccountDetailTypeId: string
    organizationId: string
    uiAccountType?: LiquidFinancialAccountUiType
    uiQuickBooksAccount?: QuickBooksAccount
}
