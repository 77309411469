import {Directive, ElementRef, Input, HostBinding, Renderer2, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[analyticsData]'
})
export class AnalyticsDataDirective implements OnChanges{
    @Input('analyticsData')
    options: any = {}

    @HostBinding('dataId')
    dataId: string

    constructor(
        private el: ElementRef,
        private _renderer: Renderer2,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.options.id) {
            this.dataId = this.options.id;
            this._renderer.setAttribute(this.el.nativeElement, 'data-id', this.dataId);
        } else {
            this._renderer.removeAttribute(this.el.nativeElement, 'data-id');
        }
    }
}
