import { Component, Inject, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs'

import { PaymentAccount, PaymentAccountStatus } from '../../core/models'
import { DialogService } from '../../dialogs'
import { CreateOrUpdateLiquidBankAccount } from '../../modules/models'
import { PaymentAccountHolderInfoComponent } from '../../payment-account-holder-info/payment-account-holder-info/payment-account-holder-info.component'

@Component({
    selector: 'app-payment-account-holder-info-and-microdeposits-dialog',
    templateUrl: './payment-account-holder-info-and-microdeposits-dialog.component.html',
    styleUrls: ['./payment-account-holder-info-and-microdeposits-dialog.component.scss'],
})
export class PaymentAccountHolderInfoAndMicrodepositsDialogComponent {

    readonly account: PaymentAccount
    readonly accountNumber: string
    readonly allowNewMicrodepositDetails: boolean
    readonly bankName: string
    confirmClose: boolean
    readonly dialogTitle: string
    readonly form: FormGroup = new FormGroup({})
    readonly isVendor: boolean
    readonly liquidBankAccount$: BehaviorSubject<CreateOrUpdateLiquidBankAccount> = new BehaviorSubject(undefined)
    readonly routingNumberAba: string

    @ViewChild(PaymentAccountHolderInfoComponent) accountHolderFormComponent: PaymentAccountHolderInfoComponent

    constructor(
        private dialogs: DialogService,
        @Inject(MAT_DIALOG_DATA) data: {
            account: PaymentAccount,
            isVendor: boolean,
            allowNewMicrodepositDetails: boolean
        },
    ) {
        this.account = data.account
        this.accountNumber = `XXXXXXXX${data.account.last4}`
        this.allowNewMicrodepositDetails = data.allowNewMicrodepositDetails
        this.bankName = data.account.bankName
        this.dialogTitle = this.allowNewMicrodepositDetails ? 'Verify your bank with micro-deposits' : undefined
        this.isVendor = data.isVendor
        this.routingNumberAba = `XXXXX${data.account.routingNumberAbaLast4}`
    }

    cancel(): void {
        if (this.account.status === PaymentAccountStatus.PendingAccountHolderInfo && !this.confirmClose) {
            this.confirmClose = true
            return
        }
        this.dialogs.close()
    }

    setInfo(): void {
        this.dialogs.close(this.accountHolderFormComponent.accountHolderInfo)
    }
}
