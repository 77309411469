<header
    *ngIf="!titleInline || !hideCloseButton"
    class="header"
>
    <h5 *ngIf="!hideHeader">{{headerTitle}}</h5>
    <button
        *ngIf="!hideCloseButton"
        type="button"
        mat-icon-button
        mat-dialog-close
        class="dialog-close"
        (click)="emitClose($event)"
    >
        <i-feather
            id="close"
            name="x"
        ></i-feather>
    </button>
</header>

<mat-dialog-content [ngClass]="contentClass">
    <ng-content select=".dialog-content"></ng-content>
    <div>
        <h5 *ngIf="titleInline">
            {{inlineTitle}}
        </h5>

        <ng-content select=".dialog-input"></ng-content>
    </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!hideButton">
    <ng-content select=".dialog-button"></ng-content>
</mat-dialog-actions>
