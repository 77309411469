import { Injectable } from '@angular/core'
import { combineLatest, Observable, of, Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'

import { OrganizationMembershipAbstractMember, OrganizationRoleMembers } from '../../auth/models'
import { UserAdminRoles } from '../../modules/liquid/modules/user-admin/models/user-admin-roles.interface'
import { UserAdminRolesIndent } from '../../modules/liquid/modules/user-admin/user-admin-roles-indent.enum'
import { Organization, OrganizationMembershipAbstract, OrganizationMembershipAbstractOutstandingInvitation } from '../../modules/models'
import { RoleMembersTree } from '../../modules/models/role-members-tree.model'
import { Claim, OrganizationRole } from '../models'

import { OrganizationService } from './organization.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class LiquidRolesService {

    private currentOrg: Organization
    private rolesMemberTree: RoleMembersTree
    private unsubscribe$: Subject<void> = new Subject()

    constructor(
        private orgs: OrganizationService,
        private users: UserService,
    ) {
        this.initialize()
    }

    initialize(): void {
        this.users.currentBusiness$
            .pipe(
                takeUntil(this.unsubscribe$),
                map(currOrg => this.currentOrg = currOrg),
            )
            .subscribe()

        combineLatest([
            this.orgs.getRoles(this.currentOrg.isWorker, this.currentOrg.isHirer),
            this.orgs.getOrganizationMembershipAbstract(this.currentOrg?.id),
        ]).pipe(
            takeUntil(this.unsubscribe$),
            tap(([roles, membershipAbstract]) => {
                this.transformToTreeStructure(roles, membershipAbstract)
            }),
        )
            .subscribe()
    }

    findUser(claim: Claim): Observable<Array<OrganizationMembershipAbstractMember>> {
        const users: OrganizationMembershipAbstractMember[] = this.matchUserToPermission(this.rolesMemberTree, claim)
        return of(this.getUserWithLowerRole(users))
    }

    formatUserRoles(roles: OrganizationRole[], orgAbstract: OrganizationMembershipAbstract): { [key: string]: OrganizationRoleMembers } {
        const subRoles: { [key: string]: string } = {}
        let path: number = 0
        let diffUsers: Array<string> = []

        return roles.reduce((acc, role) => {
            const rolePath: string = subRoles.hasOwnProperty(role.name) ? subRoles[role.name] : (path++).toString()
            const indent: number = rolePath.split('_').length - 1
            const roleDiffUsers: Array<string> = this.hasDiffUserInChildren(indent, role, orgAbstract)
            diffUsers = diffUsers.concat(roleDiffUsers)
            const hidden: boolean = (indent >= 2) && !diffUsers.includes(role.name)

            const _role: OrganizationRoleMembers = {
                ...role,
                collapsed: indent !== 0 && roleDiffUsers.length === 0,
                hidden: hidden,
                indent: indent,
                members: [],
                path: rolePath,
            }

            if (!!_role.includesRoles.length) {
                let subPath: number = 0
                role.includesRoles.forEach(subRoleName => {
                    subRoles[subRoleName] = `${_role.path}_${subPath}`
                    subPath++
                })
            }
            acc[_role.name] = _role
            return acc
        }, {})
    }

    getHighestRoleAssigned(memberRoles: Array<String>, orgRoles: { [key: string]: UserAdminRoles }): Array<String> {
        const admin: String = memberRoles.find(role => this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.FirstLevel))
        if (!!admin) {
            return [admin]
        }

        const res: Array<String> = []
        const childrenNotAdded: Array<string> = []

        memberRoles.forEach(role => {
            if (this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.SecondLevel)) {
                res.push(role)
                orgRoles[<string>role].includesRoles.forEach(r => {
                    childrenNotAdded.push(r)
                })
            }
        })

        memberRoles.forEach(role => {
            if (this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.ThirdLevel) && !childrenNotAdded.includes(<string>role)) {
                res.push(role)
            }
        })

        return res
    }

    structureRoles(roles: OrganizationRole[]): { [key: string]: OrganizationRoleMembers } {
        const subRoles: { [key: string]: string } = {}
        let path: number = 0

        return roles.reduce((acc, role) => {
            const rolePath: string = subRoles.hasOwnProperty(role.name) ? subRoles[role.name] : (path++).toString()
            const _role: OrganizationRoleMembers = {
                ...role,
                collapsed: false,
                hidden: false,
                indent: rolePath.split('_').length - 1,
                members: [],
                path: rolePath,
            }

            if (!!_role.includesRoles.length) {
                let subPath: number = 0
                role.includesRoles.forEach(subRoleName => {
                    subRoles[subRoleName] = `${_role.path}_${subPath}`
                    subPath++
                })
            }
            acc[_role.name] = _role
            return acc
        }, {})
    }

    hasDiffUserInChildren(indent: number, role: OrganizationRole, orgAbstract: OrganizationMembershipAbstract): Array<string> {
        let hasDiffUser: boolean = false

        if (indent === 1) {

            const members: Array<string> = this.getRoleMembersId(orgAbstract.members, role.name)
            const invites: Array<string> = this.getRoleInvitationsId(orgAbstract.outstandingInvitations, role.name)

            role.includesRoles.forEach((childRole) => {

                const childRoleMembers: Array<string> = this.getRoleMembersId(orgAbstract.members, childRole)
                const childRoleInvitations: Array<string> = this.getRoleInvitationsId(orgAbstract.outstandingInvitations, childRole)

                if (!!childRoleMembers.length && !hasDiffUser) {
                    hasDiffUser = !!childRoleMembers.filter(i => !members.includes(i)).length
                }

                if (!!childRoleInvitations.length && !hasDiffUser) {
                    hasDiffUser = !!childRoleInvitations.filter(i => !invites.includes(i)).length
                }
            })
        }

        return hasDiffUser ? role.includesRoles : []
    }

    private checkRoleIndentLevel(role: string, orgRoles: { [key: string]: UserAdminRoles }, indent: number): boolean {
        return role === orgRoles[role].name && orgRoles[role].indent === indent
    }

    private getRoleMembersId(members: Array<OrganizationMembershipAbstractMember>, roleName: string): Array<string> {
        return members
            .filter(mem => mem.roles.find(r => r.includes(roleName)))
            .map(mem => mem.membershipId)
    }

    private getRoleInvitationsId(invitations: Array<OrganizationMembershipAbstractOutstandingInvitation>, roleName: string): Array<string> {
        return invitations
            .filter(inv => inv.membership.roles.find(r => r.includes(roleName)))
            .map(inv => inv.membership.membershipId)
    }

    private getUserWithLowerRole(users: Array<OrganizationMembershipAbstractMember>): Array<OrganizationMembershipAbstractMember> {
        return users.length > 1 ? [users.reduce((a, b) => a.roles.length <= b.roles.length ? a : b)] : []
    }

    private transformToTreeStructure(orgRoles: Array<OrganizationRole>, orgMembership: OrganizationMembershipAbstract): void {
        const roles: Array<RoleMembersTree> = []
        const admin: OrganizationRole = orgRoles.find(role => role.isFunctionalAdminRole)
        const root: RoleMembersTree = {
            description: admin.description,
            includesRoles: [],
            name: admin.name,
            members: orgMembership.members.filter(member => member.roles.includes(admin.name)),
            permissions: admin.permissions,
        }
        roles.push(root)

        orgRoles.filter(role => !role.isFunctionalAdminRole)
            .forEach((role: OrganizationRole) => {
                if (roles[roles.length - 1].includesRoles.some(r => r.name === role.name)) {
                    return
                }

                const newRole: RoleMembersTree = {
                    description: role.description,
                    includesRoles: [],
                    members: orgMembership.members.filter(member => member.roles.includes(role.name)),
                    name: role.name,
                    permissions: role.permissions,
                }

                const children: Array<RoleMembersTree> = []
                role.includesRoles.forEach(innerRole => {
                    const child: RoleMembersTree = {
                        description: orgRoles.find(orgRole => orgRole.name === innerRole).description,
                        includesRoles: [],
                        members: orgMembership.members.filter(member => member.roles.includes(innerRole)),
                        name: innerRole,
                        permissions: orgRoles.find(orgRole => orgRole.name === innerRole).permissions,
                    }
                    children.push(child)
                })

                newRole.includesRoles = children
                roles.push(newRole)
            })

        this.rolesMemberTree = {
            description: admin.description,
            includesRoles: roles.filter(role => role.name !== admin.name),
            name: root.name,
            members: root.members,
            permissions: root.permissions,
        }
    }

    private matchUserToPermission(role: RoleMembersTree, claim: Claim): Array<OrganizationMembershipAbstractMember> {
        if (role.includesRoles.length === 0) {
            return role.permissions.some(r => r.name === claim) && !!role.members.length ? role.members : []
        }

        for (let i: number = 0; i < role.includesRoles.length; i++) {
            const r: RoleMembersTree = role.includesRoles[i]
            const res: Array<OrganizationMembershipAbstractMember> = this.matchUserToPermission(r, claim)

            if (!!res.length) {
                return res
            }

            if (r.permissions.some(permission => permission.name === claim) && !!r.members.length) {
                return r.members
            }
        }

        return []
    }
}
