import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { PaletteSelection } from '../../modules/liquid/modules/team-onboarding-processes/models'
import {
    DocumentSignStepDefinition,
    DocumentSignStepDefinitionTemplateValue,
    DocumentUploadStepDefinition,
    LiquidDocument,
    LiquidDocumentUpdateRequest,
    MasterContractUploadStepDefinition,
    OnboardingProcess,
    OnboardingProcessStep,
    OnboardingStepType,
    OnboardingTemplateViewType,
    OrganizationTeamInvitation,
    OrganizationTeamOnboardingProcessInstanceStep,
    ProvideDocumentStepDefinition,
    QuestionnaireAnswerSet,
    QuestionnaireStepDefinition,
    W8Request,
    W9,
} from '../../modules/models'
import { OnboardingFactory, StepDefinitionFactory } from '../factories'

import { UrlService } from './url.service'

// TODO: change this to a store w/a service so the service can handle the factory
@Injectable()
export class OnboardingService {

    readonly paletteSelections: Array<PaletteSelection> = [
        {
            type: 'option',
            value: '',
            desc: 'Select a step to add to your process',
            template: undefined,
        },
        {
            type: 'option',
            value: OnboardingStepType.provide,
            desc: 'Provide a document',
            template: {
                id: undefined,
                isMasterContractDocument: false,
                order: 0,
                required: false,
                stepType: OnboardingStepType.provide,
                name: 'Provide a document',
                stepDefinition: JSON.stringify(new ProvideDocumentStepDefinition()),
            },
        },
        {
            type: 'option',
            value: OnboardingStepType.sign,
            desc: 'Sign a document',
            template: {
                id: undefined,
                isMasterContractDocument: false,
                order: 0,
                required: false,
                stepType: OnboardingStepType.sign,
                name: 'Sign a document',
                stepDefinition: JSON.stringify(new DocumentSignStepDefinition()),
            },
        },
        {
            type: 'option',
            value: OnboardingStepType.questionnaire,
            desc: 'Complete a questionnaire',
            template: {
                id: undefined,
                isMasterContractDocument: false,
                order: 0,
                required: false,
                stepType: OnboardingStepType.questionnaire,
                name: 'Complete a questionnaire',
                stepDefinition: JSON.stringify(new QuestionnaireStepDefinition()),
            },
        },
        {
            type: 'option',
            value: OnboardingStepType.upload,
            desc: 'Upload a document',
            template: {
                id: undefined,
                isMasterContractDocument: false,
                order: 0,
                required: false,
                stepType: OnboardingStepType.upload,
                name: 'Upload a document',
                stepDefinition: JSON.stringify(new DocumentUploadStepDefinition()),
            },
        },
        {
            type: 'option',
            value: OnboardingStepType.masterUpload,
            desc: 'Upload a Master Contract Executed Offline',
            template: {
                id: undefined,
                isMasterContractDocument: true,
                order: 0,
                required: true,
                stepType: OnboardingStepType.masterUpload,
                name: 'Upload a Master Contract Executed Offline',
                stepDefinition: JSON.stringify(new MasterContractUploadStepDefinition()),
            },
        },
        {
            type: 'option',
            value: OnboardingStepType.w8orw9,
            desc: 'Tax Form (W-9 or W-8BEN)',
            template: {
                id: undefined,
                isMasterContractDocument: false,
                order: 0,
                stepType: OnboardingStepType.w8orw9,
                name: 'Tax Form (W-9 or W-8BEN)',
                required: true,
                stepDefinition: JSON.stringify({}),
            },
        },
        {
            type: 'group',
            desc: 'Liquid Templates',
            options: [
                {
                    type: 'option',
                    value: OnboardingStepType.genericMasterContract,
                    desc: 'Generic Master Contract',
                    template: {
                        id: undefined,
                        isMasterContractDocument: true,
                        order: 0,
                        required: true,
                        stepType: OnboardingStepType.genericMasterContract,
                        name: 'Generic Master Contract',
                        stepDefinition: JSON.stringify(this.stepDefinitionFactory.createGenericMasterContractStepDefinition()),
                    },
                },
                {
                    type: 'option',
                    value: OnboardingStepType.ciia,
                    desc: 'Confidential Information and Invention Assignment Agreement',
                    template: {
                        id: undefined,
                        isMasterContractDocument: false,
                        order: 0,
                        required: false,
                        stepType: OnboardingStepType.ciia,
                        name: 'Confidential Information and Invention Assignment Agreement',
                        stepDefinition: JSON.stringify(this.stepDefinitionFactory.createCiiaStepDefinition()),
                    },
                },
                {
                    type: 'option',
                    value: OnboardingStepType.consultingAgreement,
                    desc: 'Consulting Agreement',
                    template: {
                        id: undefined,
                        isMasterContractDocument: true,
                        order: 0,
                        required: true,
                        stepType: OnboardingStepType.consultingAgreement,
                        name: 'Consulting Agreement',
                        stepDefinition: JSON.stringify(this.stepDefinitionFactory.createConsultingAgreementStepDefinition()),
                    },
                },
            ],
        },
    ]

    constructor(
        private http: HttpClient,
        private onboardingFactory: OnboardingFactory,
        private stepDefinitionFactory: StepDefinitionFactory,
        private urls: UrlService,
    ) { }

    assignOrganizationMembershipInvitationToOnboardingProcessInstance(organizationId: string, onboardingInstanceId: string, invitationId: string): Observable<boolean> {
        return this.http.put<boolean>(this.urls.api.assignOrganizationMembershipInvitationToOnboardingProcessInstance(organizationId, onboardingInstanceId, invitationId), undefined)
    }

    getCustomContractEmailSubject(name: string): string {
        return `Custom Contract Templates for ${name}`
    }

    getOnboardingProcessesForOrganization(organizationId: string): Observable<ReadonlyArray<OnboardingProcess>> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + `/onboarding/organizations/${organizationId}/processes`
        return this.http.get<Array<OnboardingProcess>>(url)
            .pipe(
                map(results => this.onboardingFactory.createProcesses(results)),
            )
    }

    updateOnboardingProcess(process: OnboardingProcess): Observable<OnboardingProcess> {
        return this.http.put<OnboardingProcess>(
            `${environment.liquidApiSettings.apiServicePrefix}/onboarding/organizations/processes`,
            process,
        )
    }

    createNewOnboardingProcess(process: OnboardingProcess): Observable<OnboardingProcess> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/onboarding/organizations/processes`
        return this.http.post<OnboardingProcess>(url, process)
    }

    uploadOnboardingProcessStepFile(
        onboardingProcessId: string,
        onboardingProcessStepId: string,
        filename: string,
        data: FormData,
    ): Observable<OnboardingProcessStep> {
        const url: string = environment.liquidApiSettings.apiServicePrefix +
            `/onboarding/organizations/processes/${onboardingProcessId}/steps/${onboardingProcessStepId}/fileUploads/${filename}`
        return this.http.post<OnboardingProcessStep>(url, data)
    }

    updateOnboardingStepDocument(stepInstanceId: string, documentId: string, data: LiquidDocumentUpdateRequest): Observable<LiquidDocument> {
        return this.http.patch<LiquidDocument>(
            environment.liquidApiSettings.apiServicePrefix + `/onboarding/steps/${stepInstanceId}/documentUploads/${documentId}`,
            data,
        )
    }

    deleteOnboardingProcess(processId: string): Observable<boolean> {
        return this.http.delete<boolean>(
            `${environment.liquidApiSettings.apiServicePrefix}/onboarding/organizations/processes/${processId}`,
        )
    }

    submitW9(w9: W9, onboardingStepId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/submissions/steps/${onboardingStepId}/w9s`,
            w9,
        )
    }

    submitDocumentUpload(
        onboardingProcessStepInstanceId: string,
        originalFilename: string,
        data: FormData,
    ): Observable<LiquidDocument> {
        const url: string = environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/submissions/steps/${onboardingProcessStepInstanceId}/documentUploads/${originalFilename}`
        return this.http.post<LiquidDocument>(url, data)
    }

    submitDocumentReceiptAcknowledgement(onboardingStepId: string): Observable<boolean> {
        return this.http.post<boolean>(
            environment.liquidApiSettings.apiServicePrefix + `/onboarding/submissions/steps/${onboardingStepId}/acknowledgeDocumentReceipt`,
            {},
        )
    }

    downloadDocumentForStep(step: OrganizationTeamOnboardingProcessInstanceStep): Observable<boolean> {

        const stepDef: any = JSON.parse(step.stepDefinition)

        return this.getDocumentForStep(step.id)
            .pipe(
                map(response => {
                    FileSaver.saveAs(response, stepDef.originalFilename || stepDef.documentName)
                    return true
                }),
            )
    }

    getDocumentForStep(stepId: string): Observable<Blob> {
        return this.http.get(
            environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/submissions/steps/${stepId}/files/any`,
            { responseType: 'blob' },
        )
    }

    submitQuestionnaireAnswers(answerSet: QuestionnaireAnswerSet, onboardingStepId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/submissions/steps/${onboardingStepId}/questionnaireAnswers`,
            answerSet,
        )
    }

    viewEmptyDocumentForStep(step: OnboardingProcessStep, type: OnboardingTemplateViewType): Observable<Blob> {
        const stepDef: DocumentSignStepDefinition = JSON.parse(step.stepDefinition)
        // replace all the template values w/their names as placeholders
        const placeholderValues: Array<DocumentSignStepDefinitionTemplateValue> = stepDef.templateValues
            .map(orig => {
                // if the original value is related to signers, prepend the view type
                const placeholder: string = orig.name.toLocaleLowerCase().startsWith(`signer's`) ? `${type} ${orig.name}` : orig.name
                return {
                    ...orig,
                    value: `[[${placeholder.toLocaleUpperCase()}]]`,
                }
            })
        return this.postPreviewDocumentForStep(step.id, step.name, type, placeholderValues)
    }

    previewDocumentForStep(step: OnboardingProcessStep, type: OnboardingTemplateViewType): Observable<Blob> {
        const stepDef: DocumentSignStepDefinition = JSON.parse(step.stepDefinition)
        return this.postPreviewDocumentForStep(step.id, step.name, type, stepDef.templateValues)
    }

    signDocumentForStep(step: OrganizationTeamOnboardingProcessInstanceStep, contractSendCopy: boolean = false): Observable<boolean> {
        const stepDef: DocumentSignStepDefinition = JSON.parse(step.stepDefinition)
        const request: { completedSteps: DocumentSignStepDefinitionTemplateValue[]; sendCopyMasterContract: boolean } = {
            completedSteps: stepDef.templateValues,
            sendCopyMasterContract: !!contractSendCopy,
        }
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/submissions/steps/${step.id}/documentSign`,
            request,
        )
            .pipe(
                map(() => true),
            )
    }

    previewDocumentSignStepTemplate(processId: string, stepId: string, originalFilename: string): Observable<boolean> {
        return this.http.get(
            environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/processes/${processId}/steps/${stepId}/documentPreview`,
            { responseType: 'blob' },
        )
            .pipe(
                map(response => {
                    FileSaver.saveAs(response, originalFilename + '_preview.pdf')
                    return true
                }),
            )
    }

    downloadDocumentSignStepTemplate(process: OnboardingProcess, step: OnboardingProcessStep): Observable<boolean> {
        return this.http.get(
            environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/processes/${process.id}/steps/${step.id}/templates`,
            { responseType: 'blob' },
        )
            .pipe(
                map(response => {
                    const stepDef: DocumentSignStepDefinition = JSON.parse(step.stepDefinition)
                    FileSaver.saveAs(response, stepDef.originalFilename)
                    return true
                }),
            )
    }

    /**
     * Warning: Even the fact that this is a GET request, it creates a new resource on every call.
     */
    getNewDefaultOnboardingProcess(organizationId: string, templateType: string): Observable<OnboardingProcess> {
        return this.http.get<OnboardingProcess>(
            environment.liquidApiSettings.apiServicePrefix
            + `/onboarding/organizations/${organizationId}/newDefaultProcesses/${templateType}`,
        )
    }

    getOrganizationTeamInvitationByProcessInstanceId(businessId: string, instanceId: string): Observable<OrganizationTeamInvitation> {
        return this.http.get<OrganizationTeamInvitation>(this.urls.api.onboardingInvitation(businessId, instanceId))
    }

    previewW8(onboardingStepId: string, w8Request: W8Request): Observable<boolean> {
        return this.http.post(this.urls.api.w8Preview(onboardingStepId), w8Request, { responseType: 'blob' })
            .pipe(
                tap(response => FileSaver.saveAs(response, `w8_preview_${w8Request.signedDate}.pdf`)),
                map(() => true),
            )
    }

    previewW9(w9Request: W9, onboardingStepId: string): Observable<boolean> {
        return this.http.post(this.urls.api.w9Preview(onboardingStepId), w9Request, { responseType: 'blob' })
            .pipe(
                tap(response => FileSaver.saveAs(response, `w9_preview_${w9Request.signedDate}.pdf`)),
                map(() => true),
            )
    }

    submitW8(w8Request: W8Request, onboardingStepId: string): Observable<boolean> {
        return this.http.post(this.urls.api.w8Submit(onboardingStepId), w8Request)
            .pipe(
                map(() => true),
            )
    }

    isDocumentSign(stepType: OnboardingStepType): boolean {
        return [
            OnboardingStepType.sign,
            OnboardingStepType.genericMasterContract,
            OnboardingStepType.ciia,
            OnboardingStepType.consultingAgreement,
        ].includes(stepType)
    }

    isMasterUpload(stepType: OnboardingStepType): boolean {
        return [OnboardingStepType.masterUpload].includes(stepType)
    }

    isTaxForm(stepType: OnboardingStepType): boolean {
        return [OnboardingStepType.w8, OnboardingStepType.w9, OnboardingStepType.w8orw9].includes(stepType)
    }

    isUpload(stepType: OnboardingStepType): boolean {
        return [OnboardingStepType.upload, OnboardingStepType.masterUpload].includes(stepType)
    }

    private postPreviewDocumentForStep(stepId: string, stepName: string, type: OnboardingTemplateViewType = 'vendor', values: Array<DocumentSignStepDefinitionTemplateValue>): Observable<Blob> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + `/onboarding/${type}/steps/${stepId}/documentPreview`
        return this.http.post(url, values, { responseType: 'blob' }).pipe(
            tap(blob => FileSaver.saveAs(blob, `liquid-${stepName.replace(/ /g, '-')}-template.pdf`.toLocaleLowerCase())),
        )
    }
}
