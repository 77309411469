export enum W8EntityType {
    Bank = 'Central Bank of Issue',
    Complex = 'Complex Trust',
    Corp = 'Corporation',
    Disregarded = 'Disregarded Entity',
    Estate = 'Estate',
    Exempt = 'Tax Exempt Organization',
    Foudation = 'Private Foundation',
    Gov = 'Government',
    Grantor = 'Grantor Trust',
    Intl = 'International Organization',
    Partner = 'Partnership',
    Simple = 'Simple Trust',
}
