import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, debounceTime, switchMap, take } from 'rxjs/operators'

/**
 * From a given remove validation fn, it returns the AsyncValidatorFn
 * @param remoteValidation: The remote validation fn that returns an observable of <ValidationErrors | null>
 * @param debounceMs: The debounce time
 */
export function debouncedAsyncValidator<TValue>(
    remoteValidation: (v: TValue) => Observable<ValidationErrors | null>,
    remoteError: ValidationErrors = { remote: 'Unhandled error occurred.' },
    debounceMs: number = 300,
): AsyncValidatorFn {
    const values: BehaviorSubject<TValue> = new BehaviorSubject<TValue>(null)
    const validity$: Observable<ValidationErrors> = values
        .pipe(
            debounceTime(debounceMs),
            switchMap(remoteValidation),
            catchError(() => of(remoteError)),
            take(1),
        )

    return (control: AbstractControl) => {
        if (!control.value) {
            return of(null)
        }
        values.next(control.value)
        return validity$
    }
}
