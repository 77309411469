import { TextFieldModule } from '@angular/cdk/text-field'
import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { FeatherModule } from 'angular-feather'

import { PipesModule } from '../pipes'

import { AlertComponent } from './alert/alert.component'
import { ConfirmComponent } from './confirm/confirm.component'
import { DialogTemplateImageComponent } from './dialog-template-image/dialog-template-image.component'
import { DialogTemplatePlainComponent } from './dialog-template-plain/dialog-template-plain.component'
import { DialogTemplateSmallComponent } from './dialog-template-small/dialog-template-small.component'
import { DialogService } from './dialog.service'
import { RadioInputComponent } from './radio-input/radio-input.component'
import { TextInputComponent } from './text-input/text-input.component'
import { WaitComponent } from './wait/wait.component'
@NgModule({
    declarations: [
        AlertComponent,
        ConfirmComponent,
        DialogTemplateImageComponent,
        DialogTemplatePlainComponent,
        DialogTemplateSmallComponent,
        TextInputComponent,
        WaitComponent,
        RadioInputComponent,
    ],
    exports: [
        AlertComponent,
        ConfirmComponent,
        DialogTemplateImageComponent,
        DialogTemplatePlainComponent,
        DialogTemplateSmallComponent,
        RadioInputComponent,
        TextInputComponent,
        WaitComponent,
    ],
    imports: [
        CommonModule,
        FeatherModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        PipesModule,
        ReactiveFormsModule,
        TextFieldModule,
    ],
})
export class DialogModule {

    static forRoot(): ModuleWithProviders<DialogModule> {
        return {
            ngModule: DialogModule,
            providers: [
                DialogService
            ],
        }
    }
}
