import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'

import { AuthService } from '../../../../auth/services'
import { OrganizationService, UserService } from '../../../../core/services'
import { AlertComponent, DialogService, DialogTemplateType } from '../../../../dialogs'
import { Organization, TeamMemberAbstract, TeamMembership } from '../../../models'
import { WorkOrderService } from '../../../services'

@Component({
    selector: 'app-send-message',
    templateUrl: './send-message.component.html',
    styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @Output() closeMessageEditor: EventEmitter<void> = new EventEmitter()
    @Output() messageSent: EventEmitter<void> = new EventEmitter()

    @Input() headerText?: string
    @Input() isForClientOrganization?: boolean = false
    @Input() membership?: TeamMembership
    @Input() messageIsForWorkOrderId?: string
    @Input() recipientBusinessId?: string
    @Input() teamMember?: TeamMemberAbstract
    @Input() sendMessageAction?: (message: string) => Observable<boolean>

    buttonText: string = 'Send'
    readonly currentBusiness: Organization = this.users.businessSnapshot
    disableConfirmAfterSent?: boolean = false
    header: string
    label: string
    messageEditorMessage: string
    ready: boolean
    title: string
    to: string
    toOrgId: string

    constructor(
        private authService: AuthService,
        // this is required to use the mat-dialog-close attribute
        private dialogs: DialogService,
        private orgs: OrganizationService,
        private workOrders: WorkOrderService,
        private users: UserService,
    ) { }

    ngOnInit(): void {
        this.header = this.headerText
        this.to = this.isForClientOrganization ? this.membership.membershipIsToOrganization.name : this.teamMember.teamMemberOrganization.name
        this.title = `Email ${this.to}`
        this.toOrgId = this.isForClientOrganization ? this.membership.id : this.teamMember.organizationTeamMemberId
        if (!this.label) {
            this.label = `Your Message to ${this.to}`
        }
        this.ready = true
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    sendMessage(): void {

        let $obs: Observable<boolean>
        if (!!this.sendMessageAction) {
            $obs = this.sendMessageAction(this.messageEditorMessage)
        } else if (!!this.messageIsForWorkOrderId && !!this.recipientBusinessId) {
            $obs = this.workOrders.sendLoggedInWorkOrderMessage(this.messageIsForWorkOrderId, this.messageEditorMessage, this.recipientBusinessId)
        } else {
            $obs = this.orgs.sendOrganizationTeamMemberMessage(this.toOrgId, this.messageEditorMessage, !!this.isForClientOrganization)
        }

        $obs
            .pipe(
                take(1),
                filter(result => !!result),
                map(() => {
                    this.messageSent.emit()
                    return {
                        autoFocus: true,
                        data: {
                            title: `Sent`,
                            description: `Your message to ${this.to} was sent via email.`,
                        },
                    }
                }),
                filter(() => !this.disableConfirmAfterSent),
                switchMap(config => this.dialogs.open(AlertComponent, config, DialogTemplateType.medium).afterClosed()),
                tap(() => this.closeMessageEditor.emit()),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    clearBody(): void {
        this.messageEditorMessage = ''
    }

    emitClose(): void {
        this.closeMessageEditor.emit()
    }
}
