import { Component, OnInit } from '@angular/core'

import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-sleek-plan',
    templateUrl: './sleek-plan.component.html',
    styleUrls: ['./sleek-plan.component.scss'],
})
export class SleekPlanComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        if (!environment.internalSettings.enableSleekPlan) {
            return
        }

        // Sleekplan Embed Code initialization
        window['$sleek'] = []
        window['SLEEK_PRODUCT_ID'] = environment.sleekPlanSettings.productId
        const e: HTMLScriptElement = document.createElement('script')
        e.id = 'sleek-plan-loader'
        e.async = !0
        e.src = 'https://client.sleekplan.com/sdk/e.js'
        document.head.appendChild(e)

    }

}
