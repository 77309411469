import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { WorkflowModule } from '../models'
import { WorkflowService } from '../services'
import { WorkflowModuleComponent } from '../workflow-module/workflow-module.component'

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss'],
})
export class WorkflowComponent {

    @ViewChildren(WorkflowModuleComponent) moduleComponents: QueryList<WorkflowModuleComponent>

    @Input() isDialog: boolean = false
    @Input() noStepImage: boolean
    @Input() hideStepIndicators: boolean = false
    @Input() workflowModule: WorkflowModule
    @Input() workflowModules: Array<WorkflowModule>

    @Output() action: EventEmitter<string> = new EventEmitter()
    // can't just use "change" here b/c it's a protected event and will fire on click
    @Output() changed: EventEmitter<void> = new EventEmitter()

    constructor(
        private workflow: WorkflowService,
    ) { }

    get form(): FormGroup { return this.workflow.form }

    change(): void {
        this.changed.emit()
    }

    onAction(event: string): void {
        this.action.emit(event)
    }
}
