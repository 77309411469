import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { BillingInvoiceStatusColorPipe } from './billing-invoice-status-color.pipe'
import { BusinessStatusColorPipe } from './business-status-color.pipe'
import { BusinessStatusLabelPipe } from './business-status-label.pipe'
import { ChipOptionPipe } from './chip-option.pipe'
import { DeliverableStatusColorPipe } from './deliverable-status-color.pipe'
import { EntityTypePipe } from './entity-type.pipe'
import { HighlightMatchingTextPipe } from './highlight-matching-text.pipe'
import { IncorporationStatePipe } from './incorporation-state.pipe'
import { InviteStatusColorPipe } from './invite-status-color.pipe'
import { InvoiceStatusColorPipe } from './invoice-status-color.pipe'
import { InvoiceStatusTooltipPipe } from './invoice-status-tooltip-pipe'
import { InvoiceValidationIssuePipe } from './invoice-validation-issue.pipe'
import { MoneyMaskPipe } from './money-mask.pipe'
import { ObjToArrayPipe } from './obj-to-array.pipe'
import { WorkOrderStatusColorPipe } from './work-order-status-color.pipe'
import { RemoveMoneyPipe } from './remove-money.pipe'
import { SafeHtmlPipe } from './safe-html.pipe'
import { UserReassignItemPipe } from './user-reassign-item.pipe'

@NgModule({
    declarations: [
        BillingInvoiceStatusColorPipe,
        BusinessStatusColorPipe,
        BusinessStatusLabelPipe,
        ChipOptionPipe,
        DeliverableStatusColorPipe,
        EntityTypePipe,
        HighlightMatchingTextPipe,
        IncorporationStatePipe,
        InviteStatusColorPipe,
        InvoiceStatusColorPipe,
        InvoiceStatusTooltipPipe,
        InvoiceValidationIssuePipe,
        MoneyMaskPipe,
        ObjToArrayPipe,
        WorkOrderStatusColorPipe,
        RemoveMoneyPipe,
        SafeHtmlPipe,
        UserReassignItemPipe,
    ],
    exports: [
        BillingInvoiceStatusColorPipe,
        BusinessStatusColorPipe,
        BusinessStatusLabelPipe,
        ChipOptionPipe,
        DeliverableStatusColorPipe,
        EntityTypePipe,
        HighlightMatchingTextPipe,
        IncorporationStatePipe,
        InviteStatusColorPipe,
        InvoiceStatusColorPipe,
        InvoiceStatusTooltipPipe,
        InvoiceValidationIssuePipe,
        MoneyMaskPipe,
        ObjToArrayPipe,
        WorkOrderStatusColorPipe,
        RemoveMoneyPipe,
        SafeHtmlPipe,
        UserReassignItemPipe,
    ],
    imports: [
        CommonModule,
    ],
})
export class PipesModule { }
