import { ClientInvoice } from '../../modules/models'

export class ClientInvoiceModel {

    // Note, it does not include fee amount!
    get localCurrencyDueAmount(): number {
        const {
            amountInvoiced,
            amountPaid,
            payoutTotalLocked,
            payoutTotal,
            scheduledPaymentAmount,
        }: ClientInvoice = this._invoice
        const paidRation: number = payoutTotalLocked
            ? amountInvoiced / payoutTotal
            : 1
        const invoiced: number = payoutTotalLocked
            ? payoutTotal
            : amountInvoiced / paidRation
        const paid: number = ((amountPaid ?? 0) + (scheduledPaymentAmount ?? 0)) / paidRation

        return invoiced - paid
    }

    get payoutCurrency(): string {
        return this._invoice.payoutTotalLocked ? this._invoice.payoutCurrency : this._invoice.invoiceCurrency
    }

    get invoice(): ClientInvoice {
        return {...this._invoice}
    }

    constructor(private readonly _invoice: ClientInvoice) { }
}
