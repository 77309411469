import { PagedSet } from '../../table/models'

import { ClientInvoice } from './client-invoice.model'

export enum ClientInvoiceReportType {
    NONE = 0,
    FOR_INVOICER = 1,
    FOR_CLIENT = 2,
    // FOR_BOTH = 3
}

export class ClientInvoiceReport extends PagedSet<ClientInvoice> {
    public reportType: ClientInvoiceReportType
    public stats: Array<{
        clientOrganizationId: string,
        amountPaid: number,
        amountDue: number,
        invoiceCount: number,
        amountOverdue: number
    }> = []
}
