import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'

import { AddressModule } from '../../../../address'
import { AngularSignaturePadModule } from '../../angular-signature-pad/angular-signature-pad.module'

import { EditSignatureComponent } from './edit-signature.component'

@NgModule({
    imports: [
        AddressModule,
        AngularSignaturePadModule.forRoot(),
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    declarations: [
        EditSignatureComponent,
    ],
    exports: [
        EditSignatureComponent,
    ],
})
export class EditSignatureModule { }
