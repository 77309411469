import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-wait-dialog',
    templateUrl: './wait.component.html',
    styleUrls: ['./wait.component.scss'],
})
export class WaitComponent {
    message: string
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.message = data.message
    }
}
