import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {

    actionText: string
    readonly chatEnabled: boolean = !!environment.internalSettings?.enableHubspot
    description: string
    isError: boolean
    title: string
    supportEmail: string
    chat: any

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { title: string, description: string, actionText?: string, isError?: boolean },
        private readonly dialogRef: MatDialogRef<AlertComponent>,
    ) {
        this.actionText = data.actionText || 'OK'
        this.description = data.description
        this.title = data.title
        this.isError = !!data.isError
        this.supportEmail = environment.internalEmailAdress.support
        this.chat = window['HubSpotConversations']
    }

    openChat(): void {
        this.chat?.widget.load()
    }
}
