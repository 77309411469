import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { Bank, ForeignUSBankAccountRequest, OtherBankAccountProperty, OtherBankAccountRequest } from '../../country-bank-account-config'
import {
    BankAccountDetails,
    IntlFeedback,
    MicrodepositVerifcationDetails,
    Payout,
    PayoutAccount,
} from '../../modules/models'
import { PaymentAccountFactory } from '../factories'
import { PaymentAccount, PaymentAccountDto, PaymentAccountType } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class PaymentAccountStore {

    constructor(
        private factory: PaymentAccountFactory,
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    createBankAccountFromLink(businessId: string, publicToken: string, accountId: string, accounts: Array<any>): Observable<PaymentAccount> {
        const request: any = {
            organizationId: businessId,
            publicToken,
            accountId,
            accounts,
        }
        return this.http.post<PaymentAccountDto>(this.urls.api.paymentAccountCreateFromPlaid(), request)
            .pipe(
                map(dto => this.factory.create(dto)),
            )
    }

    createCreditCard(businessId: string, uid: string, doNotSetForFees?: boolean): Observable<PaymentAccount> {
        const request: any = {
            doNotSetForFees,
            organizationId: businessId,
            uid,
        }
        return this.http.post<PaymentAccount>(this.urls.api.paymentAccountCreateCreditCard(), request)
    }

    createOtherBankAccount(request: OtherBankAccountRequest): Observable<PaymentAccount> {
        return this.http.post<PaymentAccount>(this.urls.api.otherBankAccount(), request)
    }

    createForeignVendorUSBankAccount(request: ForeignUSBankAccountRequest): Observable<PaymentAccount> {
        return this.http.post<PaymentAccountDto>(this.urls.api.foreignUsBankAccount(), request)
        .pipe(
            map(dto => this.factory.create(dto))
        )
    }

    createPaymentAccount(businessId: string, token: string, accountType: PaymentAccountType): Observable<PaymentAccount> {
        const request: any = {
            organizationId: businessId,
            token,
            accountType,
        }
        return this.http.post<PaymentAccount>(this.urls.api.paymentAccount(), request)
    }

    createPayoutAccount(businessId: string, payoutAccountProviderId: string, passedBusinessType: string): Observable<PayoutAccount> {
        const request: any = {
            organizationId: businessId,
            liquidStripeClientId: payoutAccountProviderId,
            passedBusinessType,
        }
        return this.http.post<PayoutAccount>(this.urls.api.payoutAccount(), request)
    }

    deletePaymentAccount(accountId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.urls.api.paymentAccountDelete(accountId))
    }

    getBank(swiftCode: string): Observable<Bank> {
        return this.http.get<Bank>(this.urls.api.bank(swiftCode))
    }

    getHostedPaymentId(businessId: string, stylesheet: string): Observable<{ uid: string }> {
        const request: any = {
            organizationId: businessId,
            callbackUrl: this.urls.route.hostedPaymentCallback(),
            cssUrl: stylesheet,
            // TODO: let the api handle session id
            sessionId: new Date().toISOString(),
        }
        return this.http.post<{ uid: string }>(this.urls.api.hostedPaymentUid(), request)
    }

    getInternationalFeedback(organizationId: string): Observable<IntlFeedback> {
        return this.http.get<IntlFeedback>(this.urls.api.paymentInternationalFeedbackGet(organizationId))
    }

    getMicrodepositVerificationTokens(paymentAccountId: string): Observable<{ token: string }> {
        return this.http.get<{ token: string }>(this.urls.api.microdepositVerificationTokens(paymentAccountId))
    }

    getOtherBankAccountProps(paymentAccountId: string): Observable<Array<OtherBankAccountProperty>> {
        return this.http.get<Array<OtherBankAccountProperty>>(this.urls.api.otherBankAccountProperties(paymentAccountId))
    }

    getPaymentAccounts(businessId: string): Observable<Array<PaymentAccount>> {
        return this.http.get<Array<PaymentAccountDto>>(this.urls.api.paymentAccounts(businessId))
            .pipe(
                map(dtos => dtos.map(dto => this.factory.create(dto))),
            )
    }

    getPayoutAccount(businessId: string): Observable<PayoutAccount> {
        return this.http.get<PayoutAccount>(this.urls.api.payoutAccounts(businessId))
    }

    getPayoutProviderLink(businessId: string): Observable<{ created: number, url: string; object: string }> {
        const req: any = {
            organizationId: businessId,
            redirectUrl: environment.stripeSettings.stripe_dash_logout_redirect,
        }
        return this.http.post<{ created: number, url: string; object: string }>(this.urls.api.paymentAccountGetProviderLink(), req)
    }

    getPayouts(businessId: string): Observable<Payout[]> {
        return this.http.get<Payout[]>(this.urls.api.payouts(businessId))
    }

    hasPayoutAccount(businessId: string): Observable<boolean> {
        return this.http.get<{ hasPayoutAccount: boolean }>(this.urls.api.hasPayoutAccount(businessId))
            .pipe(
                map(response => response.hasPayoutAccount),
            )
    }

    internationalFeedback(feedback: IntlFeedback): Observable<IntlFeedback> {
        return this.http.post<IntlFeedback>(this.urls.api.paymentInternationalFeedback(), feedback)
    }

    setMicrodepositComplete(paymentAccountId: string): Observable<PaymentAccount> {
        return this.http.post<PaymentAccountDto>(this.urls.api.paymentAccountMicrodeposits(), { paymentAccountId })
            .pipe(
                map(dto => this.factory.create(dto)),
            )
    }

    setPayoutAccount(paymentAccountId: string): Observable<PayoutAccount> {
        return this.http.post<PayoutAccount>(this.urls.api.setPaymentAccounts(), { paymentAccountId })
    }

    updateOtherBankAccount(request: OtherBankAccountRequest): Observable<PaymentAccount> {
        return this.http.put<PaymentAccount>(this.urls.api.otherBankAccount(), request)
    }

    updatePaymentAccount(accountId: string, isDefault: boolean, isForSubscription: boolean): Observable<PaymentAccount> {
        const request: any = {
            paymentAccountId: accountId,
            default: isDefault,
            forSubscription: isForSubscription,
        }
        return this.http.patch<PaymentAccount>(this.urls.api.paymentAccount(), request)
    }

    updatePayoutAccount(businessId: string, payoutProviderAccountId: string, authorizationCode: string): Observable<PayoutAccount> {
        const request: any = {
            organizationId: businessId,
            stripeConnectAccountId: payoutProviderAccountId,
            authorizationCode,
        }
        return this.http.put<PayoutAccount>(this.urls.api.payoutAccount(), request)
    }

    updatePayoutAccountDetails(details: BankAccountDetails): Observable<PaymentAccount> {
        return this.http.post<PaymentAccountDto>(this.urls.api.paymentAccountDetails(), details)
            .pipe(
                map(dto => this.factory.create(dto)),
            )
    }

    updatePayoutAccountVerificationDetails(details: MicrodepositVerifcationDetails): Observable<PaymentAccount> {
        return this.http.post<PaymentAccountDto>(this.urls.api.paymentAccountVerificationDetails(), details)
            .pipe(
                map(dto => this.factory.create(dto)),
            )
    }

    verifyBankAccount(paymentAccountId: string, amounts: number[]): Observable<PaymentAccount> {
        return this.http.post<PaymentAccountDto>(this.urls.api.paymentAccountVerify(), { paymentAccountId, amounts })
            .pipe(
                map(dto => this.factory.create(dto)),
            )
    }
}
