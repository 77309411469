import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { Address } from '../../address'

import { UserAddressService } from './user-address.service'

@Injectable({
    providedIn: 'root',
})
export class UserAddressResolver implements Resolve<Address> {

    constructor(
        private userAddresses: UserAddressService,
    ) { }

    resolve(): Observable<Address> {
        return this.userAddresses.initialize()
            .pipe(
                take(1),
            )
    }
}
