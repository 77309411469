import { Injectable } from '@angular/core'

import { OrganizationTeamConfiguration } from '../../modules/models'
import { Claim, Claims } from '../models'

@Injectable({
    providedIn: 'root',
})
export class ClaimsFactory {

    create(teamConfig: OrganizationTeamConfiguration): Claims {
        return {
            canAcceptVendorSow: this.hasRight(teamConfig, Claim.AcceptVendorSow),
            canBrowseVendors: this.hasRight(teamConfig, Claim.BrowseVendors),
            canManageAccounting: this.hasRight(teamConfig, Claim.ManageAccounting),
            canManageBusiness: this.hasRight(teamConfig, Claim.ManageBusiness),
            canManageClients: this.hasRight(teamConfig, Claim.ManageClients),
            canManageContracts: this.hasRight(teamConfig, Claim.ManageContracts),
            canManageInvoicePolicies: this.hasRight(teamConfig, Claim.ManageInvoicePolicies),
            canManageIncomingInvoices: this.hasRight(teamConfig, Claim.ManageIncomingInvoices),
            canManageWorkOrders: this.hasRight(teamConfig, Claim.ManageWorkOrders),
            canManageSowPolicies: this.hasRight(teamConfig, Claim.ManageSowPolicies),
            canManageVendors: this.hasRight(teamConfig, Claim.ManageVendors),
            canPayInvoices: this.hasRight(teamConfig, Claim.PayInvoices),
            canProcessInvoices: this.hasRight(teamConfig, Claim.ProcessInvoices),
            canProcessSows: this.hasRight(teamConfig, Claim.ProcessSows),
            canSignContracts: this.hasRight(teamConfig, Claim.SignContracts),
            canViewIncomingInvoices: this.hasRight(teamConfig, Claim.ViewIncomingInvoices),
            hasClaims: !!teamConfig?.myPermissions?.length,
        }
    }

    private hasRight(teamConfig: OrganizationTeamConfiguration, claim: Claim): boolean {
        return teamConfig?.myPermissions?.some(permission => permission.name === claim?.toString())
    }
}
