export class ManualCharge {
    amount: number
    createdTimestamp: number
    clientInvoiceId: string
    currency: string
    description: string
    fxRateQuoteId: string
    forOrganizationId?: string
    status: string
}
