import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router'
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'

import { OrganizationMembershipAbstractMember, StorageKeyAuth } from '../../auth/models'
import { Organization, OrganizationTeamConfiguration, Profile } from '../../modules/models'
import { BusinessCompare, Claim, TeamCompare } from '../models'

import { ProfileService } from './profile.service'
import { UrlService } from './url.service'
import { UserService } from './user.service'
@Injectable({
    providedIn: 'root',
})
export class ClaimsService {
    private teamConfig: OrganizationTeamConfiguration
    constructor(
        private profiles: ProfileService,
        private route: ActivatedRoute,
        private router: Router,
        private urls: UrlService,
        private users: UserService,
    ) {
        // create an observable that constantly watches
        // the current user for the life of the app
        this.users.teamConfig$
            .pipe(
                filter(teamConfig => !!teamConfig),
                tap(teamConfig => this.teamConfig = teamConfig),
            )
            .subscribe()
        // create an observable that constantly watched
        // the current business and manages permissions
        this.users.currentBusiness$
            .pipe(
                distinctUntilChanged(),
                tap(() => this.verifyRightsForBusiness(this.users.businessSnapshot, this.getRouteData(), this.route['_routerState'].snapshot.url.toLowerCase())),
            )
            .subscribe()
    }
    businessUserHasRight(businessUser: OrganizationMembershipAbstractMember, claim: Claim): boolean {
        return businessUser.permissions.some(perm => perm.name === claim)
    }
    compareBusinesses(currentBiz: Organization, incomingBiz: Organization): BusinessCompare {
        const isDifferent: boolean = currentBiz?.id !== incomingBiz?.id
        return { isDifferent, incomingBiz }
    }
    compareTeams(currentTeam: OrganizationTeamConfiguration, incomingTeam: OrganizationTeamConfiguration): TeamCompare {
        const isDifferent: boolean = currentTeam !== incomingTeam
        return { isDifferent, incomingTeam }
    }
    hasRight(claim: Claim): boolean {
        return this.isValidRequest(claim) && this.claimsInclude(claim)
    }
    hasOneOfRights(claims: Array<Claim>): boolean {
        return this.isValidRequest(claims) && claims.some(claim => this.claimsInclude(claim))
    }
    verifyRightsForBusiness(biz: Organization, route: ActivatedRouteSnapshot, requestedUrl: string): boolean {
        // if we're going to the home url, there are no restrictions, so return true
        if (this.urls.route.isHomePage(requestedUrl) || !requestedUrl) {
            return true
        }

        // if we're going to the create biz workflow or the auth callback or team/org accept, it's permitted
        if (this.urls.route.isAuthCallback(requestedUrl)
            || this.urls.route.isCreateBusiness(requestedUrl)
            || this.urls.route.isTeamAccept(requestedUrl)
            || this.urls.route.isOrgAccept(requestedUrl)
            || this.urls.route.isJoinOrgLanding(requestedUrl)) {
            return true
        }
        const rights: {
            claims: Array<Claim>
            fallbackUrl: string
            permitRequirePaymentUpdate: boolean,
            permitBasePlan: boolean
        } = route.data?.rights
        // ensure we have rights before loading
        const isNoPlanPlan: boolean = biz?.isHirer && (this.users.teamConfigSnapshot.servicePlan.plan.isNoPlanPlan || biz.planCancellationPending)
        const claims: Array<Claim> = rights?.claims || []
        const hasRight: boolean = (!claims.length || this.hasOneOfRights(claims))
            // if we have the claims and we have a plan and are not restricting access, we are good
            && (
                (!isNoPlanPlan && !biz?.restrictAccess)
                || (
                    // if i don't have a plan, i am allowed to go to a page that permits no plans
                    (isNoPlanPlan && !!rights.permitBasePlan)
                    // or if i'm restricted access, i'm allowed to go to pages that permit payment updates, but only if i require a payment upddate
                    || (!!biz?.restrictAccess && !!rights.permitRequirePaymentUpdate && !!biz.requirePaymentUpdate)
                )
            )
            && this.profiles.isAccountVerified()
        // if we have the right, return true
        if (hasRight && !!this.users.isBizEmailVerified()) {
            return true
        }
        const redirect: string = rights?.fallbackUrl
        // if the page we are redirecting to is the same one that we just determined we don't have the rights to see,
        // we have a problem
        if (redirect?.toLowerCase() === requestedUrl) {
            this.router.navigateByUrl(this.urls.route.error())
            throw new Error(`User has insufficient permissions to view ${redirect}`)
        }
        if (hasRight && !this.users.isBizEmailVerified()) {
            if (this.urls.route.isBusinessSettings(requestedUrl)) {
                return true
            }
            this.router.navigateByUrl(this.urls.route.businessSettings())
            return false
        }
        this.router.navigateByUrl(redirect || this.urls.route.home())
        return false
    }
    private claimsInclude(claim: Claim): boolean {
        // no need to check for undefined values b/c we should never call this for an invalid request
        return this.teamConfig.myPermissions?.some(permission => permission.name === claim.toString())
    }
    private getRouteData(): ActivatedRouteSnapshot {
        let output: any = this.route['_routerState'].snapshot['_root']
        while (!!output.children.length) {
            output = output.children[0]
        }
        return output.value
    }
    private isValidRequest(compare: Claim | Array<Claim>): boolean {
        return !!compare && !!this.teamConfig?.myPermissions
    }
}
