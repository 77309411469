import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { InvoiceFactory } from '../../core/factories/invoice.factory'
import { UrlService } from '../../core/services'
import { ClientInvoiceReport, ReportRequest, WorkOrderReport } from '../models'

@Injectable()
export class ReportingService {

    constructor(
        private http: HttpClient,
        private invoiceFactory: InvoiceFactory,
        private urls: UrlService,
    ) { }

    getAccountsPayableReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.accountsPayableReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidAccountsPayableReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getCashForecastReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.cashForecastReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidCashForecastReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getExpenseAccrualReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.expenseAccrualReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidExpenseAccrualReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getInvoiceReport(businessId: string, request: ReportRequest): Observable<ClientInvoiceReport> {

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get<ClientInvoiceReport>(this.urls.api.invoiceReport(businessId), { params })
            .pipe(
                tap(report => report.results = report.results.map(invoice => this.invoiceFactory.create(invoice))),
            )
    }

    getInvoiceDetailsReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        let type: 'csv' | 'xlsx'
        if (!request?.fileType) {
            type = 'csv'
        } else {
            type = request.fileType
            delete request.fileType
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.invoiceDetailsReportFile(businessId, type), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidInvoiceDetailsReport.${type}`)
                    return true
                }),
            )
    }

    getInvoiceReportFile(businessId: string, request: ReportRequest): Observable<boolean> {

        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.invoiceReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidInvoiceReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getJournalEntryReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.journalEntryReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidJournalEntryReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getWorkOrderReport(businessId: string, request: ReportRequest): Observable<WorkOrderReport> {
        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get<WorkOrderReport>(this.urls.api.workOrderReport(businessId), { params })
    }

    getWorkOrderReportFile(businessId: string, isClient: boolean, request: ReportRequest): Observable<boolean> {

        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()
        const fileName: string = isClient ? `LiquidWorkOrderReport.${request.fileType}` : `LiquidWorkOrderReport.${request.fileType}`

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        // const options = new RequestOptions({headers: header, responseType: ResponseContentType.Blob});
        return this.http.get(this.urls.api.workOrderReportDownload(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    FileSaver.saveAs(response.body, fileName)
                    return true
                }),
            )
    }

    getQBTransactionReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.quickbooksTransactionReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidQuickBooksTransactionReport.${request.fileType}`)
                    return true
                }),
            )
    }

    getVendorDetailsReportFile(businessId: string, request: ReportRequest): Observable<boolean> {
        // if we don't have a file type, we have a problem
        if (!request?.fileType) {
            request.fileType = 'csv'
        }

        let params: HttpParams = new HttpParams()

        Object.keys(request)
            .filter(key => request.hasOwnProperty(key) && request[key] !== undefined && request[key] !== null)
            .forEach(key => params = params.set(key, request[key]))

        return this.http.get(this.urls.api.vendorDetailsReportFile(businessId, request.fileType), { params, observe: 'response', responseType: 'blob' })
            .pipe(
                map(response => {
                    const blob: Blob = response.body
                    FileSaver.saveAs(blob, `LiquidVendorDetailsReport.${request.fileType}`)
                    return true
                }),
            )
    }

    get1099Report(organizationId: string, password: string, year: number, fileName: string): Observable<boolean> {
        const request: any = {
            organizationId: organizationId,
            filePassword: password,
            format: 'xlsx',
            year: year,
        }
        return this.http.post(this.urls.api.irs1099Report(), request, { responseType: 'arraybuffer' })
            .pipe(
                map(response => {
                    const blob: Blob = new Blob([response], { type: 'application/ms-excel' })
                    FileSaver.saveAs(blob, fileName)
                    return true
                }),
            )
    }
}
