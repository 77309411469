import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'
import moment from 'moment'

import { ValidatorHelper } from './validator-helper.service'

export function DateIsAfterValidator(controlName: string, compare: string | Date | moment.Moment, checkCurrentDate: boolean = false): ValidatorFn {

    const helper: ValidatorHelper = new ValidatorHelper()
    const errorCode: string = 'dateIsAfter'

    return (form: FormGroup): ValidationErrors => {
        if (!form) {
            return
        }
        const control: AbstractControl = form.get(controlName)
        const controlDate: moment.Moment = !!control.value ? moment(control.value) : undefined
        let compareDate: moment.Moment
        if (typeof compare === 'string') {
            compareDate = !!form.get(compare).value ? moment(form.get(compare).value) : undefined
        } else {
            compareDate = moment(compare)
        }
        if (!controlDate || !compareDate) {
            return
        }
        let hasError: boolean = !controlDate.startOf('day').isSameOrAfter(compareDate.startOf('day'), 'date')
        if (checkCurrentDate && controlDate.startOf('day').isBefore(moment().startOf('day'), 'date')) {
            hasError = true
        }

        if (hasError) {
            helper.addError(control, errorCode, compareDate.toLocaleString())
        } else {
            helper.removeError(control, errorCode)
        }

        return hasError ? { [errorCode]: true } : undefined
    }
}
