import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Injectable({
    providedIn: 'root',
})
export class ValidatorHelper {

    removeError(control: AbstractControl, remove: string): void {
        // if the control had the removed error, remove it
        if (control.hasError(remove) && control.errors) {
            delete control.errors[remove]
            // if the removed error was the only error, delete the error object
            if (!Object.keys(control.errors).length) {
                control.setErrors(null)
            }
        }
    }

    addError(control: AbstractControl, add: string, errorMessage?: string): void {
        if (control.invalid && control.errors) {
            control.errors[add] = errorMessage || true
        } else {
            control.setErrors({ [add]: errorMessage || true })
        }
    }

    stripNonNumbers(control: AbstractControl): string {
        return !!control.value && typeof control.value === 'string'
            ? control.value.replace(/\D/g, '')
            : control.value
    }

    formatNumber(control: AbstractControl, hyphenIndexes?: Array<number>): void {
        const cleanedValue: string = this.stripNonNumbers(control)
        if (!hyphenIndexes || !cleanedValue) {
            control.setValue(cleanedValue)
            return
        }
        let formattedValue: string = cleanedValue
        hyphenIndexes
            .filter(index => cleanedValue.length > index)
            .forEach(index => formattedValue = `${formattedValue.substr(0, index)}-${formattedValue.substr(index)}`)
        control.setValue(formattedValue)
    }
}
