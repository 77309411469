import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'

import { AddressService } from './address.service'

@Injectable({
    providedIn: 'root',
})
export class AddressResolver implements Resolve<Observable<void>>  {

    constructor(
        private addresses: AddressService,
    ) { }

    resolve(): Observable<void> {
        return this.addresses.initialize()
    }
}
