import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { BehaviorSubject, Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'

import { ConstGlobal } from '../../common/global'
import { PromoCode, ServicePlan } from '../../modules/models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class PromoCodeService {

    private readonly freeTrialSubject: BehaviorSubject<PromoCode> = new BehaviorSubject(undefined)

    readonly freeTrial$: Observable<PromoCode> = this.freeTrialSubject.asObservable()

    get freeTrial(): PromoCode { return this.freeTrialSubject.getValue() }

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(promoCode: string): Observable<PromoCode> {
        return this.http.get<PromoCode>(this.urls.api.promoCode(promoCode))
    }

    getCurrentPrice(plan: ServicePlan, promoCode: PromoCode): number {

        const planPrice: number = plan.monthlyPrice

        if (!!promoCode?.discountPercent) {
            return planPrice - (promoCode.discountPercent * .01 * planPrice)
        }

        if (!!promoCode?.discountAmount) {
            return planPrice - promoCode.discountAmount
        }

        return planPrice
    }

    isInvalidFreeTrial(error: HttpErrorResponse, promoCodes: Array<PromoCode>): boolean {
        return error.status === StatusCodes.CONFLICT
            && error.error === ConstGlobal.INVALID_PROMO_CODE
            // this is a bad free trial if it auto applies but doesn't have an estimated completion date
            && !!promoCodes?.some(p => p.autoApply && !p.estimatedCompletion)
    }

    initFreeTrial(force?: boolean, orgId?: string): Observable<PromoCode | undefined> {

        if (!force && !!this.freeTrial) {
            return this.freeTrial$
                .pipe(
                    take(1),
                )
        }

        let params: HttpParams = new HttpParams()
        if (!!orgId) {
            params = params.append('organizationId', orgId)
        }

        return this.http.get<PromoCode>(this.urls.api.promoCodeFreeTrial(), { params })
            .pipe(
                tap(freeTrial => this.freeTrialSubject.next(freeTrial)),
            )
    }
}
