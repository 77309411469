import { Pipe, PipeTransform } from '@angular/core'
import { SafeHtml } from '@angular/platform-browser'

@Pipe({
    name: 'highlightMatching',
})
export class HighlightMatchingTextPipe implements PipeTransform {

    public transform(value: string, match: string): SafeHtml {

        if (!match || !value) {
            return value
        }

        const output = value
            // replace matches w/highlighted spans
            .replace(new RegExp(this.escapeParentheses(match), 'gi'), `<span class="highlight">$&</span>`)
            // if spans are adjacenct to a space, explicitly set the space
            .replace(new RegExp('[ ]<span', 'gi'), '&nbsp;<span')
            .replace(new RegExp('highlight">[ ]', 'gi'), 'highlight">&nbsp;')
            .replace(new RegExp('[ ]</span', 'gi'), '&nbsp;</span')
            .replace(new RegExp('span>[ ]', 'gi'), 'span>&nbsp;')

        return output
    }

    private escapeParentheses(value: string): string {
        return value.replace(new RegExp('[\(\)]', 'gi'), `\\$&`)
    }
}
