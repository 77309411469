import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

import { ValidatorHelper } from './validator-helper.service'

export function RequiredIfOtherNotEqualValidator(requiredField: string, otherField: string, otherValue: string): ValidatorFn {

    const helper: ValidatorHelper = new ValidatorHelper()
    const error = 'required'

    return (form: FormGroup): ValidationErrors | null => {

        // if there's no form, return undefined
        if (!form) {
            return null
        }

        // if we don't have the control that might be required, there's nothing to validate
        const requiredControl: AbstractControl | null = form.get(requiredField)
        if (!requiredControl) {
            return null
        }

        // if the control has a value there's no need to check any further
        const value = requiredControl.value
        if (value !== undefined && value !== null && (typeof value !== 'string' || value.trim() !== '')) {
            helper.removeError(requiredControl, error)
            return null
        }

        // get the other field
        const other = form.get(otherField)
        if (!other) {
            return null
        }

        // if the other field doesn't match the specified value, the control is required
        if (!!other.value
            && ((other.value.hasOwnProperty('name') && other.value.name !== otherValue)
                || (!other.value.hasOwnProperty('name') && other.value !== otherValue))
        ) {
            helper.addError(requiredControl, error)
        } else {
            helper.removeError(requiredControl, error)
        }

        return null
    }
}
