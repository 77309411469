export enum CreateBusinessModuleId {
    AddAccountFinanceSetup = 'add-account-finance-setup',
    ClientIntro = 'client-intro',
    ClientProfile = 'client-profile',
    FinanceSetup = 'finance-setup',
    LegalSetup = 'legal-setup',
    LiquidPlanSetup = 'liquid-plan-setup',
    SwitchBizFunc = 'switch-business-function',
    VendorFinanceSetup = 'vendor-finance-setup',
    VendorIntro = 'vendor-intro',
    VendorProfile = 'vendor-profile',
    VendorOutro = 'vendor-outro',
}
