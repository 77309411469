<mat-form-field class='state-input'>

    <mat-label>{{stateFieldName}}</mat-label>

    <mat-select
        *ngIf="!isInternational"
        [formControl]="state"
        [errorStateMatcher]="errorMatcher"
        [required]="required"
    >
        <mat-option
            *ngFor="let option of stateOptions"
            [value]="option.value"
        >
            {{option.label}}
        </mat-option>
    </mat-select>

    <input
        *ngIf="isInternational"
        matInput
        [formControl]="state"
        [errorStateMatcher]="errorMatcher"
        [required]="required"
    >

    <mat-error *ngIf="state?.errors?.required">
        {{stateFieldName}} is required
    </mat-error>

</mat-form-field>
