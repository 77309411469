import { PagedSet } from '../../table/models'

import { OrganizationAbstract } from './organization-abstract.model'
import { WorkOrderAbstract } from './work-order-abstract.interface'
export enum WorkOrderReportType {
    NONE = 0,
    FOR_WORKER = 1,
    FOR_CLIENT = 2,
    // FOR_BOTH = 3
}
export class WorkOrderReport extends PagedSet<WorkOrderAbstract> {
    reportType: WorkOrderReportType
    stats: {
        activeWorkOrders: number,
        clientOrganization: OrganizationAbstract,
        clientOrganizationId: string,
        completedWorkOrders: number,
        waitingForClientApprovalWorkOrders: number,
        waitingForWorkerApprovalWorkOrders: number
        workerOrganization: OrganizationAbstract,
        workerOrganizationId: string,
    }[] = []
}
