import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

import { ValidatorHelper } from './validator-helper.service'

export function RequiredIfOtherEqualsValidator(requiredField: string, otherField: string, otherValues: Array<string | boolean | number>): ValidatorFn {

    const helper: ValidatorHelper = new ValidatorHelper()
    const error: string = 'required'

    return (form: FormGroup): ValidationErrors | null => {

        // if there's no form, return undefined
        if (!form) {
            return undefined
        }

        // if we don't have the control that might be required, there's nothing to validate
        const requiredControl: AbstractControl | null = form.get(requiredField)
        if (!requiredControl) {
            return undefined
        }

        // if the control has a value there's no need to check any further
        const value: any = requiredControl.value
        if (value !== undefined && value !== null
            && (typeof value === 'string' && value.trim() !== ''
                || (Array.isArray(value) && !!value.length)
                || (typeof value !== 'string' && !Array.isArray(value)))) {

            helper.removeError(requiredControl, error)
            return undefined
        }

        // get the other field
        const other: AbstractControl = form.get(otherField)
        if (!other) {
            return undefined
        }

        // if the other field matches any of the specified values, the control is required
        if ((otherValues.includes(other.value) || (!!other.value?.hasOwnProperty('name') && otherValues.includes(other.value?.name)))) {
            helper.addError(requiredControl, error)

        } else {
            helper.removeError(requiredControl, error)
        }

        return undefined
    }
}
