import { Component } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../auth/services'

@Component({
    selector: 'app-sign-out',
    template: '',
})
export class SignOutComponent {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
        this.authService.logOut()
        this.router.navigate(['/'])
    }
}
