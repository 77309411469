export enum StorageKeyAuth {
    AUTH_BROWSER_REFRESH_COUNT = 'auth-browser-refresh-count',
    CREATE_ACCOUNT_EMAIL_ID = 'create-account-emailId',
    CREATE_ACCOUNT_INVITATION_ID = 'create-account-invitationId',
    CREATE_ACCOUNT_REDIRECT = 'create-account-url',
    EXPRESS_REDIRECT = 'express-redirect',
    FULL_STORY = 'full-story',
    POP_PAY_AS_GUEST = 'pop-pay-as-guest',
    REDIRECT_ON_AUTH = 'redirect-on-auth',

    // exceptions tracking info
    BUSINESS_INFO = 'zbi',
    OPEN_ID = 'xi',
    PROFILE_ID = 'xpi',
    USER_EMAIL = 'xe',
    USER_NAME = 'xn',
}
