import { Injectable } from '@angular/core'

import { Address, AddressRequest } from '../models'

@Injectable({
    providedIn: 'root',
})
export class AddressFactory {

    private readonly appCountry: string = 'country'
    private readonly businessId: string = 'organizationId'
    private readonly requestCountry: string = 'countryName'
    private readonly type: string = 'addressType'

    create(address: Address): Address {

        if (!address) {
            return undefined
        }

        address = {
            ...address,
            [this.appCountry]: address[this.requestCountry],
        }
        delete address[this.requestCountry]
        delete address[this.businessId]
        delete address[this.type]
        return address
    }

    createRequest(address: Address, businessId: string, addressType: string): AddressRequest {
        const requestAddress: AddressRequest = {
            ...address,
            countryName: address[this.appCountry],
            [this.businessId]: businessId,
            addressType,
        }
        delete requestAddress[this.appCountry]
        return requestAddress
    }
}
