export class EmailAddress {
    public id: string
    public address: string
    public createdTimestamp: number
    public primary: boolean
    public verifiedTimestamp: number
    public verificationSent: boolean
    public organizationMembershipId: string
    public notificationAddressForOrganizationId: string
}
