<div class="form-group">
    <mat-form-field class="full-width">
        <mat-label>Account Holder Name</mat-label>
        <input
            matInput
            type="text"
            [formControl]="holderName"
            required
        />
        <mat-error *ngIf="!!holderName.errors?.required">
            Account Holder Name is required
        </mat-error>
    </mat-form-field>
</div>

<div
    *ngIf="showAccountTypesSelect"
    class="form-group"
>
    <mat-radio-group
        [formControl]="accountType"
        required
    >
        <mat-radio-button
            *ngFor="let type of accountTypes"
            [value]="type.value"
            color="primary"
        >
            {{ type.label }}
        </mat-radio-button>
    </mat-radio-group>
</div>

<div class="form-group">
    <app-address-form
        [addressForm]="addressForm"
        [hideCountry]="true"
        [narrow]="true"
        [addressInput$]="address$"
        [stateAbbrv]="true"
    ></app-address-form>
</div>
