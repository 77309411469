import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function NoChangePatternValidator(originalValue: string, validationPattern: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | undefined => {
        const regex: RegExp = new RegExp(validationPattern)
        const val: string = !!control?.value ? `${control.value}` : undefined

        // if the value has not changed, its valid
        if (!!val && val === originalValue) {
            return undefined
        }

        return !!val && !regex.test(val)
            ? { maskedValue: true }
            : undefined
    }
}
