import { Injectable } from '@angular/core'

import { environment } from '../../../../../environments/environment'
import { GATracking } from '../models'

@Injectable({
    providedIn: 'root',
})
export class GaTrackingService {
    constructor() { }

    sendEvent(payload: GATracking): void {
        if (!!environment.internalSettings.enabledGoogleAnalytics?.length) {
            const tracker: any = window['ga'].getAll()
            if (!!tracker?.length) {
                tracker[0]?.send('event', payload.category, payload.action, payload.label)
            }
        }
    }
}
