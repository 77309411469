import { Component, Inject, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { PaymentAccount, PaymentAccountStatus } from '../../core/models'
import { DialogService } from '../../dialogs'
import { PaymentAccountHolderInfoComponent } from '../payment-account-holder-info/payment-account-holder-info.component'

@Component({
    selector: 'app-payment-account-holder-info-dialog',
    templateUrl: './payment-account-holder-info-dialog.component.html',
    styleUrls: ['./payment-account-holder-info-dialog.component.scss'],
})
export class PaymentAccountHolderInfoDialogComponent {

    account: PaymentAccount
    confirmClose: boolean
    enabledByDefault: boolean
    form: FormGroup = new FormGroup({})
    isVendor: boolean
    showAccountTypesSelect: boolean

    @ViewChild(PaymentAccountHolderInfoComponent) accountHolderFormComponent: PaymentAccountHolderInfoComponent

    constructor(
        private dialogs: DialogService,
        @Inject(MAT_DIALOG_DATA) data: {
            account: PaymentAccount,
            enabled: boolean
            isVendor: boolean,
            showAccountTypesSelect: boolean
        },
    ) {
        this.account = data.account
        this.isVendor = data.isVendor
        this.showAccountTypesSelect = data.showAccountTypesSelect
        this.enabledByDefault = data.enabled
    }

    cancel(): void {
        if (this.account.status === PaymentAccountStatus.PendingAccountHolderInfo && !this.confirmClose) {
            this.confirmClose = true
            return
        }
        this.dialogs.close()
    }

    setInfo(): void {
        this.dialogs.close(this.accountHolderFormComponent.accountHolderInfo)
    }
}
