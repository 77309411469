import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { AppState, AuthService as Auth0AuthService, User } from '@auth0/auth0-angular'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'

import { Auth0Environment, environment } from '../../../environments/environment'
import { StorageKeyAuth } from '../models'

import { LoggingService } from './logging.service'
import { UserRole } from './user-role.enum'

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private guestFeatureEnabled: boolean = !!environment.internalSettings?.enableGuestClient
    private jwtHelperService: JwtHelperService = new JwtHelperService()
    private readonly maxFailsToSkipForBrowserRefresh: number = 2
    private userSource: BehaviorSubject<User> = new BehaviorSubject<User>(undefined)

    userLoggedIn$: Observable<User> = this.userSource.asObservable()

    get user(): User { return this.userSource.getValue() }
    set user(user: User) { this.userSource.next(user) }

    constructor(
        private auth: Auth0AuthService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    redirectTo(uri: string): void {
        this.router.navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(uri))
    }

    isLoggedIn(): boolean {
        return !!this.user // return this.user !== undefined && !this.user.expired && !!this.getAuthorizationHeaderValue()
    }

    getAuthorizationHeaderValue(): string | undefined {
        if (!this.user) {
            return undefined
        }
        return `${this.user.token_type} ${this.user.access_token}`
    }

    getUserRoles(user: User): Observable<UserRole[]> {
        if (!user) {
            return of([])
        }

        return this.auth.getAccessTokenSilently()
            .pipe(
                take(1),
                map((token: string) => {
                    const decodedToken: any = this.jwtHelperService.decodeToken(token)
                    const roleArray: UserRole[] = decodedToken['https://api.poweredbyliquid.com/roles'] as UserRole[] | []
                    return roleArray
                }),
            )
    }

    refreshAuthentication(redirect: string): Promise<void> {
        return new Promise((resolve) => {
            resolve()
        })
        // return this.manager.signinRedirect({
        //     acr_values: [`refreshUrl:${redirect}`],
        //     prompt: 'none',
        // })
        //     .then(() => localStorage.setItem(StorageKeyAuth.REDIRECT_ON_AUTH, redirect))
    }

    silentLogin(username: string, password: string): Promise<void> {
        return new Promise((resolve) => {
            resolve()
        })
        // return this.manager.signinRedirect({
        //     acr_values: [`prop1:${username} prop2:${password}`],
        // })
        //     // CANT USE URL SERVICE DUE TO NASTY CIRCULAR DEPENDENCY
        //     .then(() => localStorage.setItem(StorageKeyAuth.REDIRECT_ON_AUTH, 'liquid/express-setup/client'))
    }

    startAuthentication(): Observable<void> {
        // check to see if there are any doIts:
        const redirectParams: any = {}
        Object.keys(localStorage).forEach((k: string) => {
            if (k.startsWith('doIt')) {
                redirectParams[k] = localStorage.getItem(k)
            }
            if (k === StorageKeyAuth.REDIRECT_ON_AUTH) {
                if (this.shouldRedirectToSignUp(k)) {
                    redirectParams['screen_hint'] = 'signup'
                }
            }
        })
        return this.auth.loginWithRedirect(redirectParams)
    }

    completeAuthentication(): Observable<any> {
        return this.auth.user$
            .pipe(
                take(1),
                tap((user) => {
                    // for passwordless, if user is null, that's fine... just don't set any of these. It'll get back after redirection
                    // ?s prevent crashing. Don't remove.
                    this.user = user
                    localStorage.setItem(StorageKeyAuth.OPEN_ID, user?.sub)
                    localStorage.setItem(StorageKeyAuth.USER_NAME, user?.name)
                    localStorage.setItem(StorageKeyAuth.USER_EMAIL, user?.email)

                    this.handleRedirects()

                    // this.router.navigateByUrl(environment.host)
                    localStorage.removeItem(StorageKeyAuth.AUTH_BROWSER_REFRESH_COUNT)
                },
                    (err => {
                        // tslint:disable-next-line: no-console

                        let authBrowserRefreshCount: number = parseInt(localStorage.getItem(StorageKeyAuth.AUTH_BROWSER_REFRESH_COUNT), 10)
                        if (Number.isNaN(authBrowserRefreshCount)) {
                            localStorage.setItem(StorageKeyAuth.AUTH_BROWSER_REFRESH_COUNT, '1')
                            this.router.navigateByUrl('/liquid/home')
                            return
                        } else if (this.maxFailsToSkipForBrowserRefresh > authBrowserRefreshCount) {
                            localStorage.setItem(StorageKeyAuth.AUTH_BROWSER_REFRESH_COUNT, (++authBrowserRefreshCount).toString())
                            this.router.navigateByUrl('/liquid/home')
                            return
                        }
                        LoggingService.notify(err)
                        this.router.navigateByUrl('/')
                    })),
            )
    }

    handleRedirects(): void {
        const expressUrl: string = localStorage.getItem(StorageKeyAuth.EXPRESS_REDIRECT)
        if (!!expressUrl) {
            this.router.navigateByUrl(expressUrl)
            localStorage.removeItem(StorageKeyAuth.EXPRESS_REDIRECT)

            if (this.guestFeatureEnabled) {
                localStorage.setItem(StorageKeyAuth.POP_PAY_AS_GUEST, 'true')
            }
            return
        }

        const storedUrl: string = localStorage.getItem(StorageKeyAuth.CREATE_ACCOUNT_REDIRECT)
        if (!!storedUrl) {
            this.router.navigateByUrl(storedUrl)
            return
        }

        const url: string = localStorage.getItem(StorageKeyAuth.REDIRECT_ON_AUTH)
        if (!!url) {
            this.router.navigateByUrl(url)
            localStorage.removeItem(StorageKeyAuth.REDIRECT_ON_AUTH)
            return
        }
    }

    logOut(): void {
        // make sure there's no cruft hanging about after logout!
        this.auth.logout({ returnTo: environment.host })
        const expressUrl: string = localStorage.getItem(StorageKeyAuth.EXPRESS_REDIRECT)
        if (!!expressUrl && this.guestFeatureEnabled) {
            localStorage.removeItem(StorageKeyAuth.EXPRESS_REDIRECT)
            this.router.navigateByUrl(expressUrl)
        }

        localStorage.clear()
    }

    private shouldRedirectToSignUp(k: string): boolean {
        return localStorage.getItem(k).indexOf('liquid/create-business') >= 0
            || localStorage.getItem(k).indexOf('liquid/organization-accept') >= 0
    }
}
