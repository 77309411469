import { Injectable } from '@angular/core'

import { BusinessStructureType, Organization } from '../../modules/models'

@Injectable({
    providedIn: 'root',
})
export class BusinessFactory {

    create(business: Organization): Organization {

        business.businessFunction = business.isHirer && business.isWorker ? 'both' : (business.isHirer ? 'client' : 'vendor')

        // if this is a new biz structure, don't worry about updating
        const newBizStructure: BusinessStructureType = BusinessStructureType[business.organizationStructure]

        if (!!newBizStructure) {
            return business
        }

        // map old structures onto new
        switch (<string>business.organizationStructure) {
            case 'C-Corp':
                business.organizationStructure = BusinessStructureType.CCorp
                break
            case 'S-Corp':
                business.organizationStructure = BusinessStructureType.SCorp
                break
            case 'LLC':
                business.organizationStructure = BusinessStructureType.LLC_C
                break
            case 'Sole Proprietorship (I work as myself)':
            case 'Sole Proprietorship':
                business.organizationStructure = BusinessStructureType.Sole
                break
        }

        return business
    }
}
