import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatTooltip } from '@angular/material/tooltip'
import { Subject } from 'rxjs'
import { filter, takeUntil, tap } from 'rxjs/operators'

import { CurrencyService } from '../../../../../core/services'
import { MoneyMaskPipe } from '../../../../../pipes'
import { Helpers } from '../helpers.class'

@Component({
    selector: 'app-mad-lib-input',
    templateUrl: './mad-lib-input.component.html',
    styleUrls: ['./mad-lib-input.component.scss', '../shared.scss'],
})
export class MadLibInputComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject()

    @ViewChild('input', { static: true }) input: ElementRef<HTMLSpanElement>
    @ViewChild('tooltip') tooltip: MatTooltip

    @Output() blur: EventEmitter<void> = new EventEmitter()
    @Output() change: EventEmitter<string> = new EventEmitter<string>()
    @Output() focused: EventEmitter<void> = new EventEmitter()

    @Input() autofocus: boolean = false
    @Input() control: FormControl
    @Input() currencyForMoney: string = this.currencyService.USD
    @Input() editable: boolean
    @Input() emitChangEvent: boolean = false
    @Input() errorMessage?: string
    @Input() forceFocus: boolean = false
    @Input() forceTooltip: boolean = false
    @Input() isMoney: boolean = false
    @Input() isNumber: boolean = false
    @Input() isZeroDecimalCurrency: boolean
    @Input() multiline: boolean = false
    @Input() saved: boolean = false

    isFocused: boolean = false
    forcedTooltipShown: boolean = false
    hasValue: boolean = false
    initialTextSet: boolean = false
    touched: boolean = false

    get text(): string { return this.input?.nativeElement?.innerText }
    set text(value: string) {
        if (!value) {
            return
        }
        if (this.multiline) {
            this.input.nativeElement.innerText = value
        } else {
            this.input.nativeElement.textContent = value
        }
    }
    get isEmpty(): boolean { return !!this.control && !this.control.value || (typeof this.control.value === 'string' && !this.control.value.length) }

    constructor(
        private cd: ChangeDetectorRef,
        private readonly currencyService: CurrencyService,
        private moneyPipe: MoneyMaskPipe,
    ) { }

    ngAfterViewInit(): void {

        if (!!this.control.value) {
            this.text = this.applyMasks(this.control.value)
            this.touched = !!this.control.errors || this.saved
        }

        this.control.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(text => text !== undefined && text !== null && text !== this.text),
                tap(text => this.text = this.applyMasks(text)),
            )
            .subscribe()

        this.input?.nativeElement.addEventListener('keyup', evt => {
            if (evt.key === 'Enter' && !this.multiline) {
                this.input.nativeElement?.blur()
                return
            }
            this.change.emit(this.text)
            const markDirty: boolean = this.text !== this.control.value
            this.control.setValue(this.text)
            if (markDirty) {
                this.control.markAsDirty()
            }
        })
    }

    ngAfterViewChecked(): void {
        if (this.autofocus && !this.control.disabled && (this.forceFocus || (!this.isFocused && !this.touched))) {
            this.focus()
        }
        if (this.forceTooltip && !this.forcedTooltipShown && !this.saved) {
            this.tooltip?.show()
            this.cd.detectChanges()
            this.forcedTooltipShown = true
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    focus(): void {
        this.isFocused = true
        this.focused.emit()
        this.input.nativeElement.focus()
    }

    hideTooltip(): void {
        this.tooltip?.hide()
    }

    onBlur(): void {
        this.touched = true
        this.isFocused = false
        this.text = this.applyMasks(this.text)
        if (!!this.control.errors && !this.control.disabled && !!this.errorMessage) {
            this.tooltip?.show()
        } else {
            this.tooltip?.hide()
        }
        this.blur.emit()
    }

    private applyMasks(value: string): string {
        if (!value) {
            return
        }
        const text: string = String(value)
        if (this.isMoney) {
            return this.formatMoney(text)
        }
        if (this.isNumber) {
            return text.replace(/\D/g, '')
        }
        return this.isZeroDecimalCurrency ? Math.round(parseFloat(text)).toString() : text
    }

    private formatMoney(text: string): string {
        if (!text) {
            return
        }
        let digitsInfo: string = '1.2-2'
        if (this.isZeroDecimalCurrency || text.indexOf('.') === -1) {
            digitsInfo = '1.0-0'
        }
        return this.moneyPipe.transform(text, this.currencyForMoney, '', digitsInfo)
    }

    tabAccessibilityForTouchScreen(): void {
        Helpers.simulateTabKeyPress(this.input.nativeElement)
    }

}
