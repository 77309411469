/**
 * Specify behavior for for LocalStorage service
 * @param {number} Local Item will be saved to local storage only
 * @param {number} InMemory Item will be saved to im-memory stream only
 * @param {number} LocalAndInMemory Item will be saved to local storage and in-memory streams both
 */
export enum StorageUsage {
    Local,
    InMemory,
    LocalAndInMemory,
}
