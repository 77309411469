import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { MatChipList } from '@angular/material/chips'

import { SelectOption } from '../../core/models'

@Component({
    selector: 'app-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent<T> {

    @ViewChild(MatChipList) list: MatChipList

    @Input() clickable: boolean
    @Input() listTitle: string
    @Input() noneSelected: string
    @Input() options: Array<SelectOption<T>>
    @Input() ordered: boolean
    @Input() tooltip: string

    @Output() removeOption: EventEmitter<T> = new EventEmitter()
    @Output() clicked: EventEmitter<T> = new EventEmitter()

    onRemoveOption(option: T): void {
        this.removeOption.emit(option)
    }

    onClick(option: T): void {
        this.clicked.emit(option)
    }
}
