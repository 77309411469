<mat-accordion displayMode="flat">

    <div class="workflow-module-top-shadow"></div>

    <mat-expansion-panel
        *ngFor="let group of workflowModule.groups; let i = index"
        [expanded]="expanded(i)"
        [disabled]="disabled(i)"
        [ngClass]="workflowModule.id"
        (opened)="scroll()"
        class="workflow-group-panel"
    >

        <mat-expansion-panel-header
            *ngIf="workflowModule.groups.length > 1"
            collapsedHeight="60px"
            expandedHeight="60px"
            (click)="goToGroup(i)"
        >
            <div class="header-container">
                <div class="group-badge-container">
                    <div
                        [ngClass]="{ active: active(i), 'low-contrast': (isLoading$ | async) }"
                        class="group-badge"
                        [attr.group-badge]="i + 1"
                    ></div>
                </div>
                <div
                    [ngClass]="{ 'low-contrast': disabled(i) }"
                    class="subtitle3 group-label"
                >
                    {{group.label}}
                </div>
                <div
                    *ngIf="completed(i) && (isLoading$ | async) === false"
                    class="group-complete"
                >
                    <i-feather
                        name="check-circle"
                        class="success"
                    ></i-feather>
                </div>
                <div
                    *ngIf="(isLoading$ | async)"
                    class="group-loading"
                >
                    <i-feather
                        name="refresh-cw"
                        class="primary80"
                    ></i-feather>
                </div>
            </div>
        </mat-expansion-panel-header>

        <div
            [ngClass]="{ 'single-step': workflowModule.groups.length === 1 }"
            class="workflow-group-content"
        >

            <div
                *ngIf="!!(showDismissMessage$ | async)"
                class="label2 inline-confirm"
            >
                <h5>You have unsaved changes</h5>
                <p>To save your changes, click the Return button and then click the Next button.</p>
                <p>Closing will discard changes.</p>

                <div class="actions">
                    <button
                        mat-stroked-button
                        color="primary"
                        (click)="confirmDismiss(true, $event)"
                    >
                        Continue Close
                    </button>
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="confirmDismiss(false, $event)"
                    >
                        Return
                    </button>
                </div>
            </div>

            <div
                *ngIf="isDialog && !!error"
                class="inline-error"
            >
                <p
                    class="white-default"
                    *ngIf="!error.generic && !error.template"
                >{{error.error}}</p>

                <p
                    class="white-default"
                    *ngIf="!error.generic && !!error.template"
                >
                    <ng-container *ngTemplateOutlet="error.template"></ng-container>
                </p>

                <p
                    class="white-default"
                    *ngIf="!!error.generic"
                >
                    An error occurred and has been reported. <br>
                    Please contact <a href="mailto:support@poweredbyliquid.com">support@poweredbyliquid.com</a>
                    for help.
                </p>

            </div>

            <form
                [ngClass]="{ 'showForm': !(showDismissMessage$ | async)}"
                [formGroup]="form"
                (submit)="submit()"
            >

                <div
                    appDynamicContainer
                    [groupId]="group.id"
                ></div>

                <div class="step-footer">

                    <div
                        class="step-indicator"
                        [ngClass]="{ 'no-step-image': noStepImage }"
                    >
                        <app-chip-list
                            *ngIf="!hideStepIndicators"
                            [options]="stepIndicatorOptions$ | async"
                        ></app-chip-list>
                    </div>

                    <div class="button-container">
                        <!-- NOTE: these are reversed in order to make the next button the default
                            so that it triggers the click for next instead of previous -->
                        <button
                            type="submit"
                            data-id
                            [analyticsData]="{id: currentStepData?.analytics?.id}"
                            [id]="'primary-' + group.id"
                            mat-flat-button
                            cdkFocusRegionstart
                            color="primary"
                            [autofocus]="true"
                            [disabled]="shouldDisable"
                        >
                            {{nextButtonText$ | async}}
                        </button>
                        <button
                            type="button"
                            *ngIf="!hideBack"
                            mat-stroked-button
                            type="button"
                            [autofocus]="false"
                            [disabled]="disableBack || (isLoading$ | async)"
                            (click)="onPrevious()"
                        >
                            Back
                        </button>
                    </div>
                </div>

                <div
                    #scrollIntoView
                    [attr.groupId]="group.id"
                ></div>

            </form>

        </div>

    </mat-expansion-panel>

    <div class="workflow-module-bottom-shadow"></div>

</mat-accordion>
