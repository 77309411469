import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { PaymentAccount } from '../../core/models'
import { DialogService } from '../../dialogs'

@Component({
    selector: 'app-payment-account-microdeposits-dialog',
    templateUrl: './payment-account-microdeposits-dialog.component.html',
    styleUrls: ['./payment-account-microdeposits-dialog.component.scss'],
})
export class PaymentAccountMicrodepositsDialogComponent {

    account: PaymentAccount
    readonly isVendor: boolean

    constructor(
        private dialogs: DialogService,
        @Inject(MAT_DIALOG_DATA) data: {
            account: PaymentAccount,
            isVendor: boolean,
        }
    ) {
        this.account = data.account
        this.isVendor = data.isVendor
    }

    cancel(): void {
        this.dialogs.close()
    }

    verificationCompleted(account: PaymentAccount) {
        this.dialogs.close(account)
    }

    verificationError(error: any) {
        this.dialogs.error(error)
    }
}
