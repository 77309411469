import { Pipe, PipeTransform } from '@angular/core'

import { LoggingService } from '../auth/services'
import { ClientInvoiceStatus } from '../modules/models'

@Pipe({
    name: 'invoiceStatusColor',
})
export class InvoiceStatusColorPipe implements PipeTransform {

    transform(status: ClientInvoiceStatus, isChip?: boolean): string {

        switch (status?.toLocaleLowerCase()) {

            case ClientInvoiceStatus.DRAFT.toLocaleLowerCase():
            case ClientInvoiceStatus.REJECTED.toLocaleLowerCase():
            case ClientInvoiceStatus.REJECTED_BY_VENDOR.toLocaleLowerCase():
            case ClientInvoiceStatus.PAYMENT_FAILURE.toLocaleLowerCase():
                return !isChip ? 'error-default' : 'error'

            case ClientInvoiceStatus.HOLDING.toLocaleLowerCase():
            case ClientInvoiceStatus.PROCESSOR_REVIEW.toLocaleLowerCase():
            case ClientInvoiceStatus.IN_APPROVALS.toLocaleLowerCase():
            case ClientInvoiceStatus.IN_APPROVALS_WITH_ME.toLocaleLowerCase():
            case ClientInvoiceStatus.IN_APPROVALS_WITH_OTHERS.toLocaleLowerCase():
                return !isChip ? 'warning-dark' : 'warn'

            case ClientInvoiceStatus.MANUAL_CHARGE.toLocaleLowerCase():
            case ClientInvoiceStatus.SENT.toLocaleLowerCase():
                return !isChip ? 'success-default' : 'success'

            case ClientInvoiceStatus.PAYMENT_INCOMING.toLocaleLowerCase():
            case ClientInvoiceStatus.PAYMENT_INITIATED.toLocaleLowerCase():
            case ClientInvoiceStatus.PAYMENT_COMPLETE.toLocaleLowerCase():
            case ClientInvoiceStatus.PAYMENT_SCHEDULED.toLocaleLowerCase():
                return !isChip ? 'success-dark' : 'success'

            case ClientInvoiceStatus.NEW:
            case ClientInvoiceStatus.PENDING_INFORMATION.toLocaleLowerCase():
            case ClientInvoiceStatus.READY_TO_PAY.toLocaleLowerCase():
                return !isChip ? 'accent-default' : 'accent'

            default:
                LoggingService.notify(new Error('Unknown Client Invoice Status: ' + status))
                return !isChip ? 'accent-default' : 'accent'
        }
    }
}
