<mat-form-field
    class='form-input'
    *ngIf="!!country"
>

    <mat-label>{{formLabel}}</mat-label>

    <mat-select
        [formControl]="country"
        [required]="required"
    >

        <mat-option
            *ngFor="let thisCountry of countries"
            [value]="thisCountry.value"
        >
            {{thisCountry.label}}
        </mat-option>

    </mat-select>

    <mat-error *ngIf="!!country?.errors?.required">
        Country is required
    </mat-error>

    <mat-error *ngIf="!!country?.errors?.w8CountryNotPermitted">
        {{country.errors.w8CountryNotPermitted}}
    </mat-error>

</mat-form-field>