export enum OnboardingTemplateType {
    CustomOnboarding = 'Custom',
    GenericMasterContract = 'GenericContract',
    OfflineContracts = 'OfflineContracts',
    OfflineContractsUS = 'OfflineContractsUS',
    OfflineContractsNonUS = 'OfflineContractsNonUS',
    StandardOnboarding = 'StandardOnboarding',
    StandardOnboardingUS = 'StandardOnboardingUS',
    StandardOnboardingNonUS = 'StandardOnboardingNonUS',
}
