import { Injectable } from '@angular/core'

import {
    DateType,
    DefaultTemplateFieldNames,
    DocumentSignDataType,
    DocumentSignStepDefinitionTemplateValue,
    LongStringType,
    SignatureType,
    StringType,
    TemplateValueType,
    WhoFillsOut,
} from '../models'

@Injectable({
    providedIn: 'root',
})
export class DocumentSignTemplateValueService {

    readonly dataTypesDefaults: ReadonlyArray<DocumentSignDataType> = [
        {
            valueType: DateType.MasterContractStartDate, oldValueTypes: [],
            name: 'Master Contract Start Date', oldNames: [], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.masterContractStartDate, oldPlaceholders: [], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Enter your master contract start date.', isInstructionsEditable: false,
            isLegalSetupRequired: false,
        },
        {
            valueType: DateType.MasterContractEndDate, oldValueTypes: [],
            name: 'Master Contract End Date', oldNames: [], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.masterContractEndDate, oldPlaceholders: [], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Enter your master contract end date.', isInstructionsEditable: false,
            isLegalSetupRequired: false,
        },
        {
            valueType: StringType.ClientName, oldValueTypes: [StringType.HiringOrgCompanyName],
            name: 'Client Business Name', oldNames: ['Client Name'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientName, oldPlaceholders: [DefaultTemplateFieldNames.companyName], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
            isLegalSetupRequired: false,
        },
        {
            valueType: StringType.ClientStructure, oldValueTypes: [],
            name: 'Client Business Structure', oldNames: ['Company Incorporation Info'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientStructure, oldPlaceholders: [DefaultTemplateFieldNames.companyIncInfo], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
            isLegalSetupRequired: false,
        },
        {
            valueType: StringType.GoverningState, oldValueTypes: [],
            name: 'Governing State', oldNames: ['Governing State'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.governingState, oldPlaceholders: [DefaultTemplateFieldNames.governingState], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Client, isWhoFillsOutEditable: false, // TODO: handle Client first time; save for use later
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
            isLegalSetupRequired: false,
        },
        {
            valueType: StringType.ClientRepName, oldValueTypes: [StringType.HiringOrgSignerName],
            name: 'Client Rep Name', oldNames: ['Client Rep Name'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientRepName, oldPlaceholders: [DefaultTemplateFieldNames.companyRepName], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
            isLegalSetupRequired: true,
        },
        {
            valueType: StringType.ClientRepTitle, oldValueTypes: [StringType.HiringOrgSignerTitle],
            name: 'Client Rep Title', oldNames: ['Client Rep Title'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientRepTitle, oldPlaceholders: [DefaultTemplateFieldNames.companyRepTitle], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Enter your title for use in contracts.', isInstructionsEditable: false,
            isLegalSetupRequired: true,
        },
        {
            valueType: SignatureType.ClientRepSignature, oldValueTypes: [SignatureType.CompanyRepSignature],
            name: 'Client Rep Signature', oldNames: ['Client Rep Signature'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientRepSignature, oldPlaceholders: [DefaultTemplateFieldNames.companyRepSignature], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Input your signature.', isInstructionsEditable: false,
            isLegalSetupRequired: true,
        },
        {
            valueType: DateType.ClientSignatureDate, oldValueTypes: [],
            name: 'Client Signature Date', oldNames: ['Client Signing Date'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientSignatureDate, oldPlaceholders: [DefaultTemplateFieldNames.companyRepSignature], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: LongStringType.ClientAddress, oldValueTypes: [LongStringType.HiringOrgAddress],
            name: 'Client Business Address', oldNames: ['Client Address'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.clientAddress, oldPlaceholders: [DefaultTemplateFieldNames.companyAddress], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: StringType.VendorName, oldValueTypes: [StringType.SigningOrgName],
            name: 'Vendor Business Name', oldNames: ['Vendor Name'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorName, oldPlaceholders: [DefaultTemplateFieldNames.consultantName], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: StringType.VendorStructure, oldValueTypes: [],
            name: 'Vendor Business Structure', oldNames: [], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorStructure, oldPlaceholders: [], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: StringType.VendorRepName, oldValueTypes: [StringType.SignerName],
            name: 'Vendor Rep Name', oldNames: ['Vendor Rep Name'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorRepName, oldPlaceholders: [DefaultTemplateFieldNames.signerName], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: StringType.VendorRepTitle, oldValueTypes: [StringType.SignerTitle],
            name: 'Vendor Rep Title', oldNames: ['Vendor Rep Title'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorRepTitle, oldPlaceholders: [DefaultTemplateFieldNames.signerTitle], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Enter your title for use in contracts.', isInstructionsEditable: false,
        },
        {
            valueType: SignatureType.VendorRepSignature, oldValueTypes: [SignatureType.SignerSignature],
            name: 'Vendor Rep Signature', oldNames: ['Vendor Rep Signature'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorRepSignature, oldPlaceholders: [DefaultTemplateFieldNames.signerSignature], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: 'Input your signature.', isInstructionsEditable: false,
        },
        {
            valueType: DateType.VendorSignatureDate, oldValueTypes: [],
            name: 'Vendor Signature Date', oldNames: ['Vendor Rep Signing Date'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorSignatureDate, oldPlaceholders: [DefaultTemplateFieldNames.signerSignatureDate], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: LongStringType.VendorAddress, oldValueTypes: [LongStringType.SigningOrgAddress],
            name: 'Vendor Address', oldNames: ['Vendor Address'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.vendorAddress, oldPlaceholders: [DefaultTemplateFieldNames.signerAddress], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: false,
            isRequired: true, isRequiredEditable: false,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: LongStringType.PriorInventionsList, oldValueTypes: [LongStringType.SigningOrgPriorInventionsList],
            name: 'Prior Inventions List', oldNames: ['Prior Inventions List'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.inventionsList, oldPlaceholders: [DefaultTemplateFieldNames.inventionList], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: false,
            isRequired: false, isRequiredEditable: true,
            instructions: 'Enter a list of your inventions. Include details such as title, date, and identifying number or description.', isInstructionsEditable: false,
        },
        {
            valueType: LongStringType.ConflictingCompaniesList, oldValueTypes: [LongStringType.SigningOrgConflictingCompaniesList],
            name: 'Conflicting Companies List', oldNames: ['Conflicting Companies List'], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.conflictsList, oldPlaceholders: [DefaultTemplateFieldNames.conflictList], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: false,
            isRequired: false, isRequiredEditable: true,
            instructions: 'Enter a list of companies with the name of agreements and dates where you may have conflicts with performing your Services.', isInstructionsEditable: false,
        },
        {
            valueType: DateType.CurrentDate, oldValueTypes: [],
            name: 'Current Date', oldNames: [''], isNameEditable: false,
            placeholder: DefaultTemplateFieldNames.currentDate, oldPlaceholders: [], isPlaceholderEditable: false,
            whoFillsOut: WhoFillsOut.Automatic, isWhoFillsOutEditable: false,
            isRequired: false, isRequiredEditable: true,
            instructions: '', isInstructionsEditable: false,
        },
        {
            valueType: StringType.Text, oldValueTypes: [StringType.String],
            name: 'Text', oldNames: [], isNameEditable: true,
            placeholder: DefaultTemplateFieldNames.text, oldPlaceholders: [], isPlaceholderEditable: true,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: true,
            isRequired: false, isRequiredEditable: true,
            instructions: 'Enter text.', isInstructionsEditable: true,
        },
        {
            valueType: LongStringType.MultiLineText, oldValueTypes: [LongStringType.LongString],
            name: 'Multi-line Text', oldNames: [], isNameEditable: true,
            placeholder: DefaultTemplateFieldNames.multiText, oldPlaceholders: [], isPlaceholderEditable: true,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: true,
            isRequired: false, isRequiredEditable: true,
            instructions: 'Enter text.', isInstructionsEditable: true,
        },
        {
            valueType: DateType.Date, oldValueTypes: [],
            name: 'Date', oldNames: [''], isNameEditable: true,
            placeholder: DefaultTemplateFieldNames.date, oldPlaceholders: [], isPlaceholderEditable: true,
            whoFillsOut: WhoFillsOut.Vendor, isWhoFillsOutEditable: true,
            isRequired: false, isRequiredEditable: true,
            instructions: 'Enter date.', isInstructionsEditable: true,
        },
    ]

    get dataTypesDefaultsByValueType(): Record<DateType, DocumentSignDataType> {
        return this.dataTypesDefaults
            .reduce<Record<DateType, DocumentSignDataType>>((byValueType, value) => {
                byValueType[value.valueType] = value
                return byValueType
            }, {} as Record<DateType, DocumentSignDataType>)
    }

    constructor() { }

    findDocumentSignDataTypeForTemplateValue(templateValue: DocumentSignStepDefinitionTemplateValue): DocumentSignDataType | undefined {
        return this.dataTypesDefaults.find(dataType => {
            return [dataType.valueType, ...dataType.oldValueTypes].includes(templateValue.valueType as TemplateValueType)
                || [dataType.placeholder, ...dataType.oldPlaceholders].includes(templateValue.fieldName as DefaultTemplateFieldNames)
                || [dataType.name, ...dataType.oldNames].includes(templateValue.name)
        })
    }

    matchTemplateValueByValueType<T>(templateValue: DocumentSignStepDefinitionTemplateValue, matchMap: MatchTemplateValueByValueTypeMap<T>): T {
        type Matcher = () => T
        const dataTypeDefault: DocumentSignDataType | undefined = this.findDocumentSignDataTypeForTemplateValue(templateValue)
        if (!dataTypeDefault) {
            return matchMap.__?.()
        }
        const matchingEntry: [TemplateValueType, Matcher] | undefined = Object.entries(matchMap).find(([valueType]) => {
            return [dataTypeDefault.valueType, ...dataTypeDefault.oldValueTypes].includes(valueType as TemplateValueType)
        }) as [TemplateValueType, Matcher] | undefined
        if (!matchingEntry) {
            return matchMap.__?.()
        }
        const matcher: Matcher = matchingEntry[1]
        return matcher()
    }

    templateValueMatchFieldName(templateValue: DocumentSignStepDefinitionTemplateValue, fieldName: DefaultTemplateFieldNames): boolean {
        const dataTypeDefault: DocumentSignDataType | undefined = this.findDocumentSignDataTypeForTemplateValue(templateValue)
        if (!dataTypeDefault) {
            return false
        }
        return [dataTypeDefault.placeholder, ...dataTypeDefault.oldPlaceholders].includes(fieldName)
    }

    templateValueMatchValueType(templateValue: DocumentSignStepDefinitionTemplateValue, valueType: TemplateValueType): boolean {
        const dataTypeDefault: DocumentSignDataType | undefined = this.findDocumentSignDataTypeForTemplateValue(templateValue)
        if (!dataTypeDefault) {
            return false
        }
        return [dataTypeDefault.valueType, ...dataTypeDefault.oldValueTypes].includes(valueType)
    }
}

type MatchTemplateValueByValueTypeMap<T> =
    & { [Key in TemplateValueType]?: () => T; }
    & { __?: () => T } // optional default case
