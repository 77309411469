import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { mapTo, shareReplay, take, tap } from 'rxjs/operators'

import { ProfileSetting } from '../../modules/models'

import { ProfileSettingsStore } from './profile-settings.store'

@Injectable({
    providedIn: 'root',
})
export class ProfileSettingsService {

    private profileSettings: ProfileSetting[]
    private readonly profileSettingSubject: BehaviorSubject<ProfileSetting[]> = new BehaviorSubject(undefined)
    private profileSettingsInitializationObs: Observable<ProfileSetting[]>

    readonly profileSetting$: Observable<ProfileSetting[]> = this.profileSettingSubject.asObservable()

    get profileSettingsShapshot(): ProfileSetting[] { return this.profileSettingSubject.getValue() }

    constructor(
        private profileSettingsStore: ProfileSettingsStore,
    ) { }

    initialize(): Observable<ProfileSetting[]> {

        if (!!this.profileSettingsShapshot) {
            return this.profileSetting$
        }

        if (!!this.profileSettingsInitializationObs) {
            return this.profileSettingsInitializationObs
        }

        this.profileSettingsInitializationObs = this.profileSettingsStore.get()
            .pipe(
                tap(settings => {
                    this.profileSettings = settings
                    this.profileSettingSubject.next(settings)
                }),
                shareReplay(1),
            )

        return this.profileSettingsInitializationObs
    }

    upsertProfileSetting(profileSetting: ProfileSetting): Observable<void> {
        return this.profileSettingsStore.update(profileSetting)
            .pipe(
                take(1),
                tap(setting => {
                    if (!this.profileSettings) {
                        return
                    }

                    const profileSettingIndex: number = this.profileSettings.findIndex(st => st.group === setting.group && st.name === setting.name)
                    const isProfileSettingExist: boolean = profileSettingIndex > -1

                    if (isProfileSettingExist) {
                        this.profileSettings[profileSettingIndex] = setting
                    } else {
                        this.profileSettings.push(setting)
                    }
                }),
                mapTo(undefined),
            )

    }
}
