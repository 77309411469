export enum BusinessTeamMemberStates {
    HIDDEN = 'Hidden',
    COUNTERSIGNING_IN_PROCESS = 'CountersigningInProcess',
    INVITED = 'Invited',
    IN_PROCESS = 'InProcess',
    NOT_SENT = 'NotSent',
    ONBOARDED = 'Onboarded',
    REMOVED = 'Removed',
    UPDATING = 'Updating',
}
