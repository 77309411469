import { AbstractControl, ValidatorFn } from '@angular/forms'

import { ConstValidation } from './const.validation'
import { ValidatorHelper } from './validator-helper.service'

export function DecimalPrecisionValidator(precision: number = 4): ValidatorFn {

    return (control: AbstractControl): { [key: string]: string } | undefined => {

        // WARNING! THIS ONLY WORKS FOR US-STYLE NUMBER SEPARATORS
        // WITH DOTS FOR DECIMALS AND COMMAS FOR THOUSANDS

        const validationHelper: ValidatorHelper = new ValidatorHelper()

        // get the value of the control as a string
        // so we can use string parsing for the dot and remove commas
        const value: string = control?.value?.toString().replace(',', '')

        // if we don't have a value, there's nothing to verify b/c this is a decimal precision validator,
        // not a required validator
        // can't use falsy here, b/c zero is valid
        // also, if the value is not a number, we can't get decimal places
        if (value === null || value === undefined || isNaN(+value)) {
            validationHelper.removeError(control, ConstValidation.DECIMAL_PRECISION_ERROR_KEY)
            return undefined
        }

        // get the number of decimals
        const countDecimals: number = value.split('.')?.[1]?.length || 0

        // if we have the correct number of digits, we're good
        if (countDecimals <= precision) {
            validationHelper.removeError(control, ConstValidation.DECIMAL_PRECISION_ERROR_KEY)
            return undefined
        }

        // we have too many digits, so add the error
        const errorMessage: string = `Max ${precision} decimal places`
        validationHelper.addError(control, ConstValidation.DECIMAL_PRECISION_ERROR_KEY, errorMessage)

        return { [ConstValidation.DECIMAL_PRECISION_ERROR_KEY]: errorMessage }
    }
}
