import { OrganizationMembershipAbstractMember } from './organization-membership-abstract-member.model'

export class Member extends OrganizationMembershipAbstractMember {

    avatarUrl?: string
    createdOn: Date
    fullName: string
    id: string
    inviteeEmailAddress?: string
    invited: boolean
    organizationMembershipInvitationId?: string

    constructor(member: any) {
        super()
        if (!!member.membership) {
            this.createdOn = member.sentOn
            this.firstName = member.inviteeEmailAddress
            this.lastName = ''
            this.membershipId = member.membership.membershipId
            this.description = member.membership.description
            this.permissions = member.membership.permissions || []
            this.roles = member.membership.roles || []
            this.invited = true
            this.organizationMembershipInvitationId = member.organizationMembershipInvitationId
            this.inviteeEmailAddress = member.inviteeEmailAddress
        } else {
            this.avatarUrl = member.avatarUrl
            this.createdOn = member.joinedOn
            this.firstName = member.firstName
            this.lastName = member.lastName
            this.description = member.description
            this.membershipId = member.membershipId
            this.permissions = member.permissions || []
            this.roles = member.roles || []
            this.invited = false
        }
        this.isMe = member.isMe
        this.fullName = [this.firstName, this.lastName].filter(n => !!n).join(' ')
        this.avatarUrl = member.avatarUrl
    }
}
