import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'

import { BusinessFunction } from '../models'

import { UrlService } from './url.service'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {

    constructor(
        private router: Router,
        private urls: UrlService,
        private users: UserService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const roleConfig: { required: BusinessFunction, redirect: string, redirectProvider?: (route: ActivatedRouteSnapshot) => string } = route.data.role
        if (!roleConfig) {
            return true
        }

        const permitted: boolean = roleConfig.required === 'client' ? this.users.businessSnapshot?.isHirer : this.users.businessSnapshot?.isWorker

        if (!permitted) {
            this.router.navigateByUrl(roleConfig.redirect || roleConfig.redirectProvider?.call(undefined, route) || this.urls.route.home())
        }

        return permitted
    }
}
