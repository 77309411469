import { HttpClient, } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { UrlService } from '../../core/services'
import { AccountAssociation } from '../liquid/modules/chart-of-accounts/models'
import { BillingField, Organization, QBFieldAssociation, QBFieldCreate, QuickBooksAccount, QuickBooksField, QuickBooksIntegrationResponse, QuickBooksVendorRequest } from '../models'

@Injectable({
    providedIn: 'root',
})
export class IntegratorStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    associateQBField(assoc: QBFieldAssociation): Observable<QuickBooksField> {
        return this.http.post<QuickBooksField>(this.urls.api.quickBooksFieldsAssociation(), assoc)
    }

    createQBField(cr: QBFieldCreate): Observable<QuickBooksField> {
        return this.http.post<QuickBooksField>(this.urls.api.createQBField(), cr)
    }

    createQuickBooksLinkRequest(forOrganizationId: string): Observable<QuickBooksIntegrationResponse> {
        return this.http.get<QuickBooksIntegrationResponse>(this.urls.api.linkRequest(false, forOrganizationId))
    }

    createBillingField(billingField: BillingField): Observable<BillingField> {
        return this.http.post<BillingField>(this.urls.api.createBillingField(), billingField)
    }

    deleteBillingField(billingFieldId: string, orgId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.urls.api.deleteBillingField(billingFieldId))
    }

    dissociateQBField(qbFieldId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.urls.api.quickbooksFieldsDisassociation(qbFieldId))
    }

    editBillingField(billingField: BillingField): Observable<BillingField> {
        return this.http.put<BillingField>(this.urls.api.updateBillingField(billingField.id), billingField)
    }

    reconnectQuickBooksLinkRequest(forOrganizationId: string): Observable<QuickBooksIntegrationResponse> {
        return this.http.get<QuickBooksIntegrationResponse>(this.urls.api.linkRequest(true, forOrganizationId),
        )
    }

    disconnectQuickBooks(organizationId: string): Observable<Organization> {
        return this.http.get<Organization>(this.urls.api.disconnectQuickbooks(organizationId))
    }

    syncQuickBooksAccounts(organizationId: string): Observable<boolean> {
        return this.http.patch<boolean>(this.urls.api.syncQuickBooksAccounts(organizationId), {})
    }

    syncQuickBooksFields(organizationId: string): Observable<boolean> {
        return this.http.patch<boolean>(this.urls.api.syncQuickBooksFields(organizationId), {})
    }

    syncQuickBooksVendors(organizationId: string): Observable<boolean> {
        return this.http.patch<boolean>(this.urls.api.syncQuickBooksVendors(organizationId), {})
    }

    getQuickBooksVendorListForOrganization(organizationId: string): Observable<QuickBooksVendorRequest[]> {
        return this.http.get<QuickBooksVendorRequest[]>(this.urls.api.quickbooksVendors(organizationId))
    }

    getQuickBooksAccountListForOrganization(organizationId: string): Observable<QuickBooksAccount[]> {
        return this.http.get<QuickBooksAccount[]>(this.urls.api.quickbooksAccounts(organizationId))
    }

    getQuickBooksFieldListForOrganization(organizationId: string): Observable<QuickBooksField[]> {
        return this.http.get<QuickBooksField[]>(this.urls.api.quickbooksFields(organizationId))
    }

    getBillingFieldsForOrganization(organizationId: string): Observable<BillingField[]> {
        return this.http.get<BillingField[]>(this.urls.api.getBillingFields(organizationId))
    }

    associateQuickBooksVendorToOrgForOrg(orgId: string, qbVendorId: string, vendorOrgId: string): Observable<QuickBooksVendorRequest> {
        return this.http.post<QuickBooksVendorRequest>(
            this.urls.api.quickBooksVendorsAssociation(),
            {
                organizationId: orgId,
                quickBooksVendorId: qbVendorId,
                vendorOrganizationId: vendorOrgId,
            },
        )
    }

    dissociateQuickBooksVendorToOrganizationForOrganization(quickBooksVendorId: string): Observable<QuickBooksVendorRequest> {
        return this.http.delete<QuickBooksVendorRequest>(this.urls.api.quickBooksVendorsDisassociation(quickBooksVendorId))
    }

    setQuickBooksVendorAsLiquidInvoiceVendor(quickBooksVendorId: string): Observable<QuickBooksVendorRequest> {
        return this.http.post<QuickBooksVendorRequest>(this.urls.api.liquidInvoiceVendorAssociation(),
            {
                quickBooksVendorId,
            },
        )
    }

    batchAssociateQuickBooksAccountToLiquidFinancialAccount(toAssociate: AccountAssociation[], toDisassociate: AccountAssociation[]): Observable<any> {
        return this.http.post<any>(this.urls.api.quickBooksBatchAssociation(),
            { toAssociate, toDisassociate },
        )
    }

    associateQuickBooksAccountToLiquidFinancialAccount(quickBooksAccountId: string, liquidFinancialAccountId: string): Observable<QuickBooksAccount> {
        return this.http.post<QuickBooksAccount>(this.urls.api.quickBooksAccountsAssociation(),
            { quickBooksAccountId, liquidFinancialAccountId },
        )
    }

    dissociateQuickBooksAccountFromLiquidFinancialAccount(quickBooksAccountId: string, liquidFinancialAccountId: string): Observable<QuickBooksAccount> {
        return this.http.delete<QuickBooksAccount>(this.urls.api.quickBooksAccountsDisassociation(quickBooksAccountId, liquidFinancialAccountId),
        )
    }

    sendBackfillDate(organizationId: string, backfillFromTimestamp: number): Observable<boolean> {
        return this.http.post<boolean>(this.urls.api.quickBooksBackfills(),
            { organizationId, backfillFromTimestamp },
        )
    }
}
