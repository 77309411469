import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { UserService } from '../../core/services'
import { AccountAssociation } from '../../modules/liquid/modules/chart-of-accounts/models'
import { QuickBooksVendorFactory } from '../../modules/liquid/modules/vendor-associations/factories'
import {
    BillingField,
    Organization,
    QBFieldAssociation,
    QBFieldCreate,
    QuickBooksAccount,
    QuickBooksField,
    QuickBooksVendor,
} from '../../modules/models'
import { QuickBooksIntegrationResponse } from '../models'

import { IntegratorStore } from './integrator.store'

@Injectable({
    providedIn: 'root',
})
export class IntegratorService {

    constructor(
        private integratorStore: IntegratorStore,
        private qbVendorFactory: QuickBooksVendorFactory,
        private users: UserService,
    ) { }

    associateQBField(assoc: QBFieldAssociation): Observable<QuickBooksField> {
        return this.integratorStore.associateQBField(assoc)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    createQBField(cr: QBFieldCreate): Observable<QuickBooksField> {
        return this.integratorStore.createQBField(cr)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    createQuickBooksLinkRequest(businessId: string): Observable<QuickBooksIntegrationResponse> {
        return this.integratorStore.createQuickBooksLinkRequest(businessId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    createBillingField(billingField: BillingField): Observable<BillingField> {
        return this.integratorStore.createBillingField(billingField)
    }

    deleteBillingField(billingFieldId: string, orgId: string): Observable<boolean> {
        return this.integratorStore.deleteBillingField(billingFieldId, orgId)
    }

    dissociateQBField(qbFieldId: string): Observable<boolean> {
        return this.integratorStore.dissociateQBField(qbFieldId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    editBillingField(billingField: BillingField): Observable<BillingField> {
        return this.integratorStore.editBillingField(billingField)
    }

    reconnectQuickBooks(businessId: string): Observable<QuickBooksIntegrationResponse> {
        return this.integratorStore.reconnectQuickBooksLinkRequest(businessId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    disconnectQuickBooks(organizationId: string): Observable<Organization> {
        return this.integratorStore.disconnectQuickBooks(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    syncQuickBooksAccounts(organizationId: string): Observable<boolean> {
        return this.integratorStore.syncQuickBooksAccounts(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    syncQuickBooksFields(organizationId: string): Observable<boolean> {
        return this.integratorStore.syncQuickBooksFields(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    syncQuickBooksVendors(organizationId: string): Observable<boolean> {
        return this.integratorStore.syncQuickBooksVendors(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    getBillingFieldsForOrganization(organizationId: string): Observable<BillingField[]> {
        return this.integratorStore.getBillingFieldsForOrganization(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    getQuickBooksVendorListForOrganization(organizationId: string): Observable<QuickBooksVendor[]> {
        return this.integratorStore.getQuickBooksVendorListForOrganization(organizationId)
            .pipe(
                map(qbVendorsRequest => qbVendorsRequest.map(v => this.qbVendorFactory.create(v))),
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    getQuickBooksAccountListForOrganization(organizationId: string): Observable<QuickBooksAccount[]> {
        return this.integratorStore.getQuickBooksAccountListForOrganization(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    getQuickBooksFieldsForOrganization(organizationId: string): Observable<QuickBooksField[]> {
        return this.integratorStore.getQuickBooksFieldListForOrganization(organizationId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    associateQuickBooksVendorToOrgForOrg(orgId: string, qbVendorId: string, vendorOrgId: string): Observable<QuickBooksVendor> {
        return this.integratorStore.associateQuickBooksVendorToOrgForOrg(orgId, qbVendorId, vendorOrgId)
            .pipe(
                map(qbVendorsRequest => this.qbVendorFactory.create(qbVendorsRequest)),
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    dissociateQuickBooksVendorToOrganizationForOrganization(quickBooksVendorId: string): Observable<QuickBooksVendor> {
        return this.integratorStore.dissociateQuickBooksVendorToOrganizationForOrganization(quickBooksVendorId)
            .pipe(
                map(qbVendorsRequest => this.qbVendorFactory.create(qbVendorsRequest)),
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    setQuickBooksVendorAsLiquidInvoiceVendor(quickBooksVendorId: string): Observable<QuickBooksVendor> {
        return this.integratorStore.setQuickBooksVendorAsLiquidInvoiceVendor(quickBooksVendorId)
            .pipe(
                map(qbVendorsRequest => this.qbVendorFactory.create(qbVendorsRequest)),
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    batchAssociateQuickBooksAccountToLiquidFinancialAccount(toAssociate: AccountAssociation[], toDisassociate: AccountAssociation[]): Observable<boolean> {
        return this.integratorStore.batchAssociateQuickBooksAccountToLiquidFinancialAccount(toAssociate, toDisassociate)
            .pipe(
                map(() => true),
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    associateQuickBooksAccountToLiquidFinancialAccount(quickBooksAccountId: string, liquidFinancialAccountId: string): Observable<QuickBooksAccount> {
        return this.integratorStore.associateQuickBooksAccountToLiquidFinancialAccount(quickBooksAccountId, liquidFinancialAccountId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    dissociateQuickBooksAccountFromLiquidFinancialAccount(quickBooksAccountId: string, liquidFinancialAccountId: string): Observable<QuickBooksAccount> {
        return this.integratorStore.dissociateQuickBooksAccountFromLiquidFinancialAccount(quickBooksAccountId, liquidFinancialAccountId)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    sendBackfillDate(organizationId: string, backfillFromTimestamp: number): Observable<boolean> {
        return this.integratorStore.sendBackfillDate(organizationId, backfillFromTimestamp)
            .pipe(
                catchError(error => this.handleDisconnectionResponse(error)),
            )
    }

    private handleDisconnectionResponse(error: HttpErrorResponse): Observable<never> {
        if (error.status === 405) {
            return this.users.initializeUser(true)
                .pipe(
                    switchMap(() => throwError(error)),
                )
        }
        return throwError(error)
    }
}
