import { enableProdMode, NgModuleRef } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { LoggingService } from './app/auth/services'
import { environment } from './environments/environment'
import { hmrBootstrap, NodeHotModule } from './hmr'

if (environment.production) {
    enableProdMode()
    if (window) {
        window.console.log = function (): void { }
        window.console.error = function (): void { }
    }
}

const bootstrap: () => Promise<NgModuleRef<AppModule>> = () => platformBrowserDynamic().bootstrapModule(AppModule)
const bootstrapWithLogging: () => Promise<void | NgModuleRef<AppModule>> = () => platformBrowserDynamic().bootstrapModule(AppModule).catch(error => LoggingService.notify(error))

if (environment.hmr && !environment.production) {
    if (module['hot']) {
        // hmrBootstrap handles LoggingService.notify call
        hmrBootstrap<AppModule>(module as NodeHotModule, bootstrap)
    } else {
        // HMR should only be used in development
        bootstrapWithLogging()
        // tslint:disable-next-line: no-console
        console.error('HMR is not enabled')
    }
} else {
    bootstrapWithLogging()
}
