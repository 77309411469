export enum OnboardingStepType {
    ciia = 'CiiaAgreement',
    consultingAgreement = 'ConsultingAgreement',
    genericMasterContract = 'GenericMasterContract',
    masterUpload = 'MasterContractUpload',
    provide = 'ProvideDocument',
    questionnaire = 'Questionnaire',
    sign = 'DocumentSign',
    upload = 'DocumentUpload',
    w8 = 'W8',
    w9 = 'W9',
    w8orw9 = 'W8orW9',
}
