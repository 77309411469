import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'

import { StorageKeyAuth } from '../models'

import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
    ) {
        // check to see if the plan selection was passed in:
        this.activatedRoute.queryParams.subscribe(params => {
            if (!!params.psp) {
                localStorage.setItem('planHint', params.psp)
            }
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (this.authService.isLoggedIn()) {
            return true
        }

        let existingPath: string = localStorage.getItem(StorageKeyAuth.REDIRECT_ON_AUTH)
        if (!!existingPath && existingPath === '/liquid/home' && state.url !== '/liquid/home') {
            existingPath = undefined
        } else if (!!existingPath && existingPath.indexOf('liquid/create-business/') >= 0 && state.url.indexOf('liquid/create-business/') < 0) {
            existingPath = undefined
        }

        if (!existingPath) {
            localStorage.setItem(StorageKeyAuth.REDIRECT_ON_AUTH, state.url)
        }

        this.authService.startAuthentication()

        return false
    }
}
