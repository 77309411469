import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, switchMapTo, take } from 'rxjs/operators'

import { DialogService } from '../../../dialogs'
import { FileItem, UploadFileResponse } from '../../../modules/liquid/modules/file-uploads/models'
import { PaymentScheduleUpdateRequest } from '../../../modules/liquid/modules/invoice-detail/models'
import { InvoiceApproveData } from '../../../modules/liquid/modules/invoice-detail/models/invoice-approve-data'
import { Invoice } from '../../../modules/liquid/modules/invoices/models'
import {
    BillingField,
    ClientInvoice,
    ClientInvoiceStatus,
    FXRateQuote,
    InvoiceApprover,
    InvoiceAttachmentType,
    InvoiceLineItem, InvoiceUpdatePropertiesRequest,
    InvoiceValidationIssue,
    LiquidFinancialAccountAssignment,
    ManualCharge,
    ServicePlan,
    StripeCharge,
    VendorInvoicePaymentCount,
    WorkOrder
} from '../../../modules/models'
// Note! These imports can't be shortened because of occurring circular dependency.
// e.g. index -> InvoiceDetailsService -> index
import { WorkOrderService } from '../../../modules/services/work-order.service'
import { InvoiceFactory } from '../../factories/invoice.factory'
import { PaymentAccount, PaymentAccountSupertype, UpdatePropertiesRequestScope } from '../../models'
import { CurrencyService } from '../currency.service'
import { PaymentStore } from '../payment.store'
import { UrlService } from '../url.service'
import { UserService } from '../user.service'

import { InvoiceDetailsStore } from './invoice-details.store'
import { InvoicePaymentInformation } from './invoice-payment-information'
import { UPLOAD_VALIDATION_ISSUES } from './upload-validation-issues'

@Injectable({ providedIn: 'any' })
export class InvoiceDetailsService {

    constructor(
        private readonly dialogs: DialogService,
        private readonly currencyService: CurrencyService,
        private readonly invoiceFactory: InvoiceFactory,
        private readonly invoiceStore: InvoiceDetailsStore,
        private readonly paymentStore: PaymentStore,
        private readonly urls: UrlService,
        private readonly usersService: UserService,
        private readonly workOrderService: WorkOrderService,
    ) { }

    approveInvoice(approvalData: InvoiceApproveData): Observable<boolean> {
        return this.invoiceStore.updateInvoiceApprovalStatus(approvalData.approverId, true)
            .pipe(
                switchMapTo(this.invoiceStore.getInvoice(approvalData.invoiceId, approvalData.businessId)),
                switchMap((invoice) => {
                    if (invoice.approvers.every(a => !a.approvalResponse && !invoice.waitingForProcessing)) {
                        const accountAssigment: LiquidFinancialAccountAssignment[]
                            = this.getInvoiceFinancialAssignments(invoice, approvalData.coaId)

                        return forkJoin([
                            this.invoiceStore.updateInvoiceProcessingDecision(invoice.id, true),
                            this.invoiceStore.updateChartOfAccountAssociations(invoice.id, accountAssigment),
                        ])
                    }

                    return forkJoin([
                        of(true),
                        of(invoice),
                    ])
                }),
                map(([approved,]) => approved),
            )
    }

    assignInvoice(invoiceId: string, approvers: InvoiceApprover[]): Observable<InvoiceApprover[]> {
        return this.invoiceStore.updateInvoiceApprovers(invoiceId, approvers)
            .pipe(
                map(a => this.invoiceFactory.normalizeApprovers(a)),
            )
    }

    associateAccountAssignmentsWithChartOfAccounts(
        invoice: ClientInvoice,
        assignments: LiquidFinancialAccountAssignment[],
    ): Observable<Invoice> {
        return this.invoiceStore.updateChartOfAccountAssociations(
            invoice.id,
            assignments,
        )
    }

    cancelInvoice(invoiceId: string, reason: string): Observable<boolean> {
        return this.invoiceStore.cancelInvoice(invoiceId, reason)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createFileForInvoice(file: FileItem): Observable<FileItem> {
        return this.invoiceStore.createFileForInvoice(file)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    downloadFileForInvoice(invoiceId: string, fileId: string, fileName: string): Observable<boolean> {
        return this.invoiceStore.downloadFile(invoiceId, fileId, fileName)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    downloadInvoiceAttachment(invoice: ClientInvoice, attachmentType: InvoiceAttachmentType): Observable<boolean> {
        return this.invoiceStore.getInvoiceAttachment(invoice.id, attachmentType)
            .pipe(
                map(response => {
                    const url: string = attachmentType === InvoiceAttachmentType.Invoice ? invoice.invoiceAttachmentUrl : invoice.proofOfReceiptAttachmentUrl
                    const split: string[] = url.split('.')
                    let ext: string = ''
                    if (split.length > 1) {
                        ext = split[split.length - 1]
                        if (ext && ext.length > 0) {
                            ext = `.${ext}`
                        }
                    }

                    const fileName: string = attachmentType === InvoiceAttachmentType.Invoice
                        ? `invoice-${invoice.invoiceNumber}${ext}`
                        : `proof-of-receipt-invoice-${invoice.invoiceNumber}${ext}`

                    FileSaver.saveAs(response, fileName)
                    return true
                }),
            )
    }

    getCurrentApprover(invoice: ClientInvoice): InvoiceApprover | undefined {
        const orderedApprovers: InvoiceApprover[] = invoice.approvers.filter(a => !a.approvalResponse)
            .sort((a, b) => a.order - b.order)

        return orderedApprovers.length ? orderedApprovers[0] : undefined
    }

    getFilesForInvoice(invoiceId: string, bizId: string): Observable<Array<FileItem>> {
        return this.invoiceStore.getFilesForInvoice(invoiceId, bizId)
    }

    getFirstWorkOrderAssociatedToInvoice(invoice: ClientInvoice): Observable<WorkOrder | undefined> {
        const firstWorkOrderId: string | undefined = invoice.associatedWorkOrderIds.length
            ? invoice.associatedWorkOrderIds[0]
            : undefined

        return firstWorkOrderId ? this.workOrderService.getWorkOrder(firstWorkOrderId) : of(undefined)
    }

    getInvoiceUploadValidationIssues(invoice: ClientInvoice): InvoiceValidationIssue[] {
        return invoice.validationIssues.filter(vi => UPLOAD_VALIDATION_ISSUES.includes(vi))
    }

    getInvoiceLogoUrl(invoice: ClientInvoice): string {
        return invoice.invoiceLogoUrl && !invoice.invoiceLogoUrl.startsWith('http')
            ? this.urls.api.avatarUrl(invoice.invoiceLogoUrl)
            : invoice.invoiceLogoUrl
    }

    getInvoice(invoiceId: string, businessId: string): Observable<any> {
        return this.invoiceStore.getInvoice(invoiceId, businessId)
    }

    getInvoiceFinancialAssignments(invoice: ClientInvoice, coaId: string): LiquidFinancialAccountAssignment[] {
        if (this.shouldUpdateCoa(invoice, coaId)) {
            const accountAssigment: LiquidFinancialAccountAssignment = {
                clientInvoiceId: invoice.id,
                amount: invoice.amountInvoiced,
                liquidFinancialAccountId: coaId,
            }
            return [accountAssigment]
        }

        return invoice.liquidFinancialAccountAssignments
    }

    getInvoiceLineItems(invoiceId: string, businessId: string): Observable<InvoiceLineItem[]> {
        return this.invoiceStore.getInvoiceLineItems(invoiceId, businessId)
    }

    getInvoiceFormattedDueAmount(invoice: ClientInvoice, feeAmount: number): string {
        const {
            amountInvoiced,
            amountPaid,
            fxRateQuote,
            invoiceCurrency,
            payoutTotalLocked,
        }: ClientInvoice = invoice

        const chargeAmount: string = this.currencyService
            .getFormattedMoneyAmount(amountInvoiced - amountPaid, invoiceCurrency)

        if (!invoice.fxRateQuote) {
            return chargeAmount + this.getTaxFeeLabel(feeAmount, invoiceCurrency)
        }

        const formattedAmountInQuotedCurrency: string = this.currencyService
            .getFormattedMoneyAmount(fxRateQuote.quotedCurrencyAmount, fxRateQuote.quotedCurrencyCode)

        const moneyFormatCallback: () => string = () => this.currencyService.getFormattedMoneyAmount(fxRateQuote.sourceCurrencyAmount, fxRateQuote.sourceCurrencyCode)
        const moneyFormatCallbackEstimated: () => string = () => this.currencyService.getFormattedMoneyAmount(
            fxRateQuote.sourceCurrencyAmount === fxRateQuote.quotedCurrencyAmount ? fxRateQuote.sourceCurrencyAmount : fxRateQuote.quotedCurrencyAmount,
            fxRateQuote.sourceCurrencyAmount === fxRateQuote.quotedCurrencyAmount ? fxRateQuote.sourceCurrencyCode : fxRateQuote.quotedCurrencyCode)

        return payoutTotalLocked
            ? `${formattedAmountInQuotedCurrency} (${this.currencyService.getUsingCurrency(fxRateQuote.sourceCurrencyCode, moneyFormatCallback)})`
            + this.getTxFeeMessage(feeAmount, fxRateQuote.sourceCurrencyCode)
            : `${this.currencyService.getUsingCurrency(fxRateQuote.sourceCurrencyCode, moneyFormatCallback)} `
            + `(estimated ${this.currencyService.getUsingCurrency(fxRateQuote.quotedCurrencyCode, moneyFormatCallbackEstimated)})`
            + this.getTxFeeMessage(feeAmount, fxRateQuote.sourceCurrencyCode)
    }

    getInvoicesFXRateQuotes(invoiceIds: string[], businessId: string): Observable<FXRateQuote[]> {
        return this.invoiceStore.getInvoicesFXRateQuotes(invoiceIds, businessId)
    }

    getPaymentQuestionHtmlForMultipleInvoices(invoices: { invoice: ClientInvoice, transactionFee: number }[]): string {
        const dueAmountHtml: string = invoices
            .map(i => {
                const formattedAmount: string = this.getInvoiceFormattedDueAmount(i.invoice, i.transactionFee)
                return `<li>Invoice ${i.invoice.invoiceNumber}: ${formattedAmount}</li>`
            })
            .join('')

        return `
I authorize Liquid to electronically debit my account for the following invoices:
<ul>
${dueAmountHtml}
</ul>
I further authorize Liquid to electronically credit my account to correct erroneous debits, if necessary.
If you need to contact us for any reason, please send us an email to payments@poweredbyliquid.com.
`
    }

    getLiquidInvoicePaymentAgreementQuestion(invoice: ClientInvoice, feeAmount: number, payOnDate: Date): string {

        const { invoiceNumber }: ClientInvoice = invoice
        const payOnDateLabel: string = payOnDate ? this.getPayOnLabel(payOnDate) : ''
        const formattedDueAmount: string = this.getInvoiceFormattedDueAmount(invoice, feeAmount)

        return `
I authorize Liquid to electronically debit my account ${payOnDateLabel} for Invoice ${invoiceNumber} for ${formattedDueAmount}
I further authorize Liquid to electronically credit my account to correct erroneous debits, if necessary.
If you need to contact us for any reason, please send us an email to payments@poweredbyliquid.com.
`
    }

    getNonLiquidInvoicePaymentAgreementQuestion(invoice: ClientInvoice, bankAccount: PaymentAccount, payOnDate?: Date): string {
        const chargeAmount: string = this.currencyService
            .getFormattedMoneyAmount(invoice.amountInvoiced - invoice.amountPaid, invoice.invoiceCurrency)
        const payOnDateLabel: string = payOnDate ? this.getPayOnLabel(payOnDate) : ''
        const bankLabel: string = bankAccount.brand ? bankAccount.brand : bankAccount.bankName

        return `Charge ${chargeAmount} to ${bankLabel} ending with ${bankAccount.last4} ${payOnDateLabel}`
    }

    getPaymentAmountCtaLabel(invoice: ClientInvoice): string {
        const {
            amountInvoiced,
            amountPaid,
            fxRateQuote,
            invoiceCurrency,
            paid,
            payoutTotalLocked,
            scheduledPaymentTransactionFeeAmount,
        }: ClientInvoice = invoice

        if (!fxRateQuote) {
            const totalInBaseCurrency: number = (amountInvoiced - amountPaid)
                + (scheduledPaymentTransactionFeeAmount && !paid ? scheduledPaymentTransactionFeeAmount : 0)
            return `Pay Invoice (Total ${this.currencyService.getFormattedMoneyAmount(totalInBaseCurrency, invoiceCurrency)})`
        }

        const { sourceCurrencyAmount, sourceCurrencyCode, quotedCurrencyAmount, quotedCurrencyCode }: FXRateQuote = fxRateQuote

        const formattedAmountInSourceCurrency: string = this.currencyService
            .getUsingCurrency(sourceCurrencyCode, () => this.currencyService.getFormattedMoneyAmount(sourceCurrencyAmount, sourceCurrencyCode))
        const formattedAmountInQuotedCurrency: string = this.currencyService
            .getUsingCurrency(quotedCurrencyCode, () => this.currencyService.getFormattedMoneyAmount(quotedCurrencyAmount, quotedCurrencyCode))

        const equivalentLabel: string = payoutTotalLocked ? 'Equivalent' : 'Estimated'
        return `Pay Invoice (Total ${formattedAmountInSourceCurrency} / ${equivalentLabel} ${formattedAmountInQuotedCurrency})`
    }

    getTransactionFee = (
        invoice: ClientInvoice,
        plan: ServicePlan,
        bankAccountType: PaymentAccountSupertype,
        internationalVendor: boolean,
    ): number => {
        let fee: number = 0
        if (bankAccountType === PaymentAccountSupertype.BankAccount && plan.achFeeIsChargedMonthly) {
            return 0
        } else if (bankAccountType === PaymentAccountSupertype.BankAccount && !plan.achFeeIsChargedMonthly) {
            fee = internationalVendor ? plan.achFeeFlatIntlAmount : plan.achFeeFlatAmount
            return fee + (invoice.amountInvoiced * plan.achFeePercentage / 100)
        } else if (bankAccountType === PaymentAccountSupertype.CC && plan.ccFeeIsChargedMonthly) {
            return 0
        } else if (bankAccountType === PaymentAccountSupertype.CC && !plan.ccFeeIsChargedMonthly) {
            fee = internationalVendor ? plan.ccFeeFlatAmount : plan.achFeeFlatAmount
            return fee + (invoice.amountInvoiced * plan.ccFeePercentage / 100)
        }
    }

    getVendorInvoicePaymentCount(businessId: string, invoiceId: string): Observable<VendorInvoicePaymentCount> {
        return this.invoiceStore.getVendorInvoicePaymentCount(businessId, invoiceId)
            .pipe(
                take(1),
                catchError(err => this.dialogs.error(err)),
            )
    }

    invoiceCanBePaid(invoice: ClientInvoice): boolean {
        return invoice
            && invoice.status !== ClientInvoiceStatus.HOLDING
            && invoice.status !== ClientInvoiceStatus.IN_APPROVALS
            && invoice.status !== ClientInvoiceStatus.PENDING_INFORMATION
            && !invoice.paid
            && !invoice.scheduledPaymentAmount
            && !invoice.isDraft
    }

    invoiceCanBeResend(invoice: ClientInvoice): boolean {
        // in order to resend, this has to be the vendor
        // and the invoice can't be:
        // - already paid
        // - scheduled to be paid,
        // - a draft
        // - rejected
        return this.usersService.businessSnapshot.id === invoice.invoicerOrganizationId
            && invoice.status !== ClientInvoiceStatus.REJECTED
            && !invoice.paid
            && !invoice.scheduledPaymentAmount
            && !invoice.isDraft
    }

    manuallyChargeInvoice(invoice: ClientInvoice, description: string): Observable<ManualCharge> {
        const charge: ManualCharge = new ManualCharge()
        charge.amount = invoice.amountInvoiced
        charge.clientInvoiceId = invoice.id
        charge.currency = this.currencyService.USD
        charge.description = description
        charge.forOrganizationId = this.usersService.businessSnapshot.id
        charge.fxRateQuoteId = invoice.fxRateQuote?.id

        return this.paymentStore.createManualCharge(charge)
    }

    payInvoice(paymentInformation: InvoicePaymentInformation): Observable<StripeCharge> {
        return this.paymentStore.payClientInvoice(
            paymentInformation.invoiceId,
            paymentInformation.paymentMethodId,
            paymentInformation.payOnTimestamp,
            paymentInformation.fxRateQuoteId,
            paymentInformation.scheduledForLater,
        )
    }

    rejectInvoice(invoiceId: string, reason: string, approverId?: string): Observable<boolean> {
        const updateInvoiceApprovalStatus$: Observable<InvoiceApprover> = !!approverId
            ? this.invoiceStore.updateInvoiceApprovalStatus(approverId, false, reason)
            : of(undefined)

        return updateInvoiceApprovalStatus$
            .pipe(
                mergeMap(() => this.invoiceStore.updateInvoiceProcessingDecision(invoiceId, false, reason)),
                map((approved) => approved),
            )
    }

    resendInvoice(invoiceId: string): Observable<boolean> {
        return this.invoiceStore.createInvoiceNotification(invoiceId)
    }

    sendMessage(invoiceId: string, message: string): Observable<boolean> {
        return this.invoiceStore.createInvoiceMessage(invoiceId, message)
    }

    shouldUpdateCoa(invoice: ClientInvoice, accountId: string): boolean {
        return invoice.liquidFinancialAccountAssignments.length > 1
            ? false
            : !!accountId && (!invoice.liquidFinancialAccountAssignments.length || invoice.liquidFinancialAccountAssignments[0]?.liquidFinancialAccountId !== accountId)
    }

    updateInvoiceBillingField(invoiceId: string, billingFieldId: string, updateScope: UpdatePropertiesRequestScope = UpdatePropertiesRequestScope.All): Observable<ClientInvoice> {
        const currentUserOrgId: string = this.usersService.businessSnapshot.id

        const requestData: InvoiceUpdatePropertiesRequest = {
            clientInvoiceId: invoiceId,
            requestedByOrgId: currentUserOrgId,
            organizationId: currentUserOrgId,
            properties: {
                BillingFieldId: billingFieldId,
            },
            updateScope,
        }

        return this.invoiceStore.updateInvoiceProperties(requestData)
    }

    associateInvoiceBillingField(invoiceId: string, billingFieldId: string): Observable<boolean> {
        return this.invoiceStore.associateInvoiceBillingField(invoiceId, billingFieldId)
            .pipe(
                take(1),
            )
    }

    disassociateInvoiceBillingField(invoiceId: string, billingFieldId: string): Observable<boolean> {
        return this.invoiceStore.disassociateInvoiceBillingField(invoiceId, billingFieldId)
            .pipe(
                take(1),
            )
    }

    getInvoiceBillingFieldsAssociations(invoiceId: string): Observable<BillingField[]> {
        return this.invoiceStore.getInvoiceBillingFieldsAssociations(invoiceId)
    }

    updateSchedulePayment(invoiceId: string, request: PaymentScheduleUpdateRequest): Observable<ClientInvoice> {
        return this.invoiceStore.updateSchedulePayment(invoiceId, request)
            .pipe(
                take(1),
                map(invoice => this.invoiceFactory.create(invoice)),
            )
    }

    uploadFileForInvoice(invoiceId: string, file: FormData, fileName: string): Observable<UploadFileResponse> {
        return this.invoiceStore.uploadFileForInvoice(invoiceId, file, fileName)
    }

    private getPayOnLabel(payOnDate: Date): string {
        const formattedPayOnDate: string = new Intl.DateTimeFormat('en-US').format(payOnDate)
        return `on ${formattedPayOnDate}`
    }

    private getTaxFeeLabel(feeAmount: number, currencyCode: string): string {
        return !!feeAmount
            ? ` plus ${this.currencyService.getFormattedMoneyAmount(feeAmount, currencyCode)} transaction fee.`
            : '. '
    }

    private getTxFeeMessage(txFeeAmount: number, currencyCode: string): string {
        return !!txFeeAmount
            ? ` plus ${this.currencyService.getUsingCurrency(currencyCode, () => this.currencyService.getFormattedMoneyAmount(txFeeAmount, currencyCode))} transaction fee. `
            : '. '
    }
}
