export class W9 {
    public name: string
    public businessName: string
    public classification: string
    public llcType: string
    public otherType: string
    public exemptPayeeCode: string
    public exemptFATCACode: string
    public address: string
    public address2: string
    public ssn: string
    public ein: string
    public signedDate: string
    public signatureImage: string
    public signatureDocumentId: string
}
