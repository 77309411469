import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, switchMap, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { StorageKeyAuth } from '../../auth/models'
import { LoggingService } from '../../auth/services'
import { EmailVerificationRequest, GuestRequest, GuestResponse, GuestUpsellRequest, Profile, ProfileRequest, UserSetup } from '../../modules/models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    private readonly FIRST_PLACEHOLDER: string = 'FIRST'
    private readonly LAST_PLACEHOLDER: string = 'LAST'
    private readonly SMOKE_TEST_NAME: string = 'BROS-SMOKE-TEST'
    private readonly profileSubject: BehaviorSubject<Profile> = new BehaviorSubject(undefined)
    private _userSetup: UserSetup

    readonly profile$: Observable<Profile> = this.profileSubject.asObservable()

    get fullName(): string { return `${this.profileSnapshot.firstName} ${this.profileSnapshot.lastName}` }
    get profileSnapshot(): Profile { return this.profileSubject.getValue() }

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    initialize(refresh: boolean = false): Observable<Profile> {

        if (!!this.profileSnapshot && !refresh) {
            return this.profile$
                .pipe(
                    take(1),
                )
        }

        return this.getProfile()
            .pipe(
                take(1),
                tap(profile => {
                    this.profileSubject.next(profile)
                    this.initializeFullStory(profile)
                }),
                switchMap(profile => this.initializeHubspot(profile)),
                switchMap(profile => this.initializeSleekPlan(profile)),
            )
    }

    isAccountVerified(): boolean {
        return !!this.profileSnapshot?.emailAddress.verifiedTimestamp
    }

    isSmokeTestAccount(): boolean {
        return this.profileSnapshot?.lastName.toLowerCase() === this.SMOKE_TEST_NAME.toLowerCase()
    }
    hasNamePlaceholder(): boolean {
        return this.profileSnapshot?.firstName === this.FIRST_PLACEHOLDER && this.profileSnapshot?.lastName === this.LAST_PLACEHOLDER
    }

    createGuestAccount(request: GuestRequest): Observable<GuestResponse> {
        return this.http.post<GuestResponse>(this.urls.api.createGuestAccount(), request)
    }

    // TODO: remove all uses of this method and use the resolver instead
    getProfile(): Observable<Profile> {
        return this.http.get<Profile>(environment.liquidApiSettings.apiServicePrefix + '/profiles')
    }

    resendVerificationEmail(): Observable<void> {
        return this.http.post<void>(this.urls.api.profileVerificationSend(), {})
    }

    userSetup(refreshIfExists: boolean): Observable<UserSetup> {
        if (refreshIfExists || !this._userSetup) {
            return this.http.get<UserSetup>(environment.liquidApiSettings.apiServicePrefix + '/userSetup')
        } else {
            return of(this._userSetup)
        }
    }

    updateProfile(profile: ProfileRequest): Observable<Profile> {
        return this.http.put<Profile>(environment.liquidApiSettings.apiServicePrefix + '/profiles', profile)
            .pipe(
                tap(result => this.profileSubject.next(result)),
            )
    }

    upsellGuestAccount(req: GuestUpsellRequest): Observable<Profile> {
        return this.http.post<Profile>(this.urls.api.upsellGuest(), req)
            .pipe(
                tap(result => this.profileSubject.next(result)),
            )
    }

    updateAppDefaults(defaultOrganizationId: string, defaultOrganizationIdSpecified: boolean): Observable<{ defaultOrganizationId: string }> {
        const request: any = {
            defaultOrganizationId,
            defaultOrganizationIdSpecified,
        }
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/profiles/apps/defaults'
        return this.http.post<{ defaultOrganizationId: string }>(url, request)
    }

    uploadAvatar(profileId: string, data: FormData): Observable<Profile> {
        return this.http.post<Profile>(environment.liquidApiSettings.apiServicePrefix + `/profiles/${profileId}/avatars`, data)
            .pipe(
                tap(result => this.profileSubject.next(result)),
            )
    }

    getAvatarUrl(profileAvatarUrl: string): string | undefined {
        // not locally stored:
        if (profileAvatarUrl?.toLowerCase().startsWith('http')) {
            return profileAvatarUrl
        }

        if (profileAvatarUrl && profileAvatarUrl.trim().length > 4) {
            return environment.liquidApiSettings.userAvatarPrefix + '/' + profileAvatarUrl
        }
    }

    updatePassword(): Observable<Profile> {
        return this.http.post<Profile>(`${environment.liquidApiSettings.apiServicePrefix}/profiles/passwords`, {})
            .pipe(
                tap(result => this.profileSubject.next(result)),
            )
    }

    verifyEmail(verifyRequest: EmailVerificationRequest): Observable<void> {
        return this.http.post<void>(this.urls.api.emailVerify(), verifyRequest)
    }

    verifyExpressAccount(invitationId: string): Observable<void> {
        return this.http.post<void>(this.urls.api.expressVerify(invitationId), {})
    }

    private initializeFullStory(profile: Profile): void {
        if (!!environment.internalSettings.enableFullStory) {
            const name: string = !!localStorage.getItem(StorageKeyAuth.FULL_STORY) ? `Liquid as ${profile.firstName} ${profile.lastName}` : `${profile.firstName} ${profile.lastName}`
            localStorage.removeItem(StorageKeyAuth.FULL_STORY)
            window['FS'].identify(profile.id, {
                displayName: name,
                email: profile.username,
            })
        }
    }

    private initializeHubspot(profile: Profile): Observable<Profile> {
        if (!!environment.internalSettings.enableHubspot) {
            return this.http.get<any>(this.urls.api.hubspotJWT())
                .pipe(
                    tap((res) => {
                        window['hsConversationsSettings'] = {
                            identificationEmail: profile.emailAddress.address,
                            identificationToken: res.token,
                        }

                        window['HubSpotConversations']?.widget.load()
                        console.log('Token received, Hubspot widget loaded.')
                    }),
                    switchMap(() => of(profile)),
                    catchError(err => {

                        window['HubSpotConversations']?.widget.load()

                        console.log('Token failed, Hubspot widget loaded without identification.')
                        LoggingService.notify(err)
                        return of(profile)
                    }),
                )
        }
        return of(profile)
    }

    private initializeSleekPlan(profile: Profile): Observable<Profile> {
        if (!!environment.internalSettings.enableSleekPlan) {
            return this.http.get<any>(this.urls.api.sleekPlanJWT())
                .pipe(
                    tap(res => {
                        window['$sleek'].resetUser()
                        window['$sleek'].setUser({ token: res.token })
                        console.log('Token retrieved, Sleekplan widget loaded.')
                    }),
                    switchMap(() => of(profile)),
                    catchError(err => {

                        console.log('Token failed, Sleekplan widget loaded without identification.')
                        LoggingService.notify(err)
                        return of(profile)
                    }),
                )
        }
        return of(profile)
    }
}
