import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function NotInListValidator(list: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // If there is no value to evaluate, do not add the error
        const output = !!control && !!control.value && list.find(elem => elem === control.value)
            ? { 'inList': true }
            : null

        return output
    }
}
