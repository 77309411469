import { Pipe, PipeTransform } from '@angular/core'
import { W8EntityType } from '../modules/models';

@Pipe(
    {
        name: 'entityType',
    },
)
export class EntityTypePipe implements PipeTransform {

    transform(entityType: W8EntityType): string {
        switch (entityType) {
            case W8EntityType.Bank:
                return `CentralBank`
            case W8EntityType.Complex:
                return `ComplexTrust`
            case W8EntityType.Corp:
                return `Corporation`
            case W8EntityType.Disregarded:
                return `DisregardedEntity`
            case W8EntityType.Estate:
                return `Estate`
            case W8EntityType.Exempt:
                return `TaxExempt`
            case W8EntityType.Foudation:
                return `PrivateFoundation`
            case W8EntityType.Gov:
                return `Government`
            case W8EntityType.Grantor:
                return `GrantorTrust`
            case W8EntityType.Intl:
                return `InternationalOrg`
            case W8EntityType.Partner:
                return `Partnership`
            case W8EntityType.Simple:
                return `SimpleTrust`
        }

    }
}