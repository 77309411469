import { Address } from '../../address'
import { BusinessFunction } from '../../core/models'

import { BusinessStructureType } from './business-structure-type.enum'
import { OrganizationBase } from './organization-base.model'
import { PromoCode } from './promo-code.interface'

// TODO: Move to core and convert to interface
export class Organization extends OrganizationBase {
    available?: boolean
    businessFunction?: BusinessFunction
    canPayClientInvoiceWithCC?: boolean
    clientList: string
    contractorCount?: string
    createdTimestamp?: number
    customContractsEnabled?: boolean
    einIsSsn?: boolean
    employeeCount?: string
    financialIntegrationDisconnectedOn?: Date
    hasQuickBooksIntegration?: boolean
    financialIntegrationStartedOn?: Date
    id: string
    incorporationInformation: string
    industry?: string
    isGuest?: boolean
    isHirer: boolean
    isFullBucket?: boolean
    isPersonal: boolean
    isWorker: boolean
    kycStatus?: string
    lastUpdatedTimestamp?: number
    maskedEin?: string
    organizationAddress?: Address
    organizationStructure: BusinessStructureType
    otherOperatingLocations?: string
    paymentStatus?: string
    paymentTermsDays?: number
    planCancellationPending?: boolean
    preferVendorLocalCurrency?: boolean
    profilePrivateFlags: string
    promoCodes: Array<PromoCode>
    quickBooksIntegrationEnabled?: boolean
    quickBooksCanSyncTags?: boolean
    quickBooksPlan?: string
    requirePaymentUpdate?: boolean
    restrictAccess?: boolean
    restrictPayments?: boolean
    tags?: string

    // TODO: Mock field for now
    guest?: string
}
