import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function MinLengthValidator(minLength: number): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    // If there is no value to evaluate, do not add the error b/c this is an value validator, not a required validator.
    // If the value is required, it will be caught by a required validator.
    const output = !!control && !!control.value && control.value.length < minLength
      ? { 'minLength': minLength }
      : null

    return output
  }
}
