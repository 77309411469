import { Pipe, PipeTransform } from '@angular/core'

import { WorkOrderStatus } from '../modules/models'

@Pipe({
    name: 'workOrderStatusColor',
})
export class WorkOrderStatusColorPipe implements PipeTransform {

    transform(status: WorkOrderStatus): string {

        switch (status?.toLowerCase()) {

            case WorkOrderStatus.COMPLETED.toLowerCase():
            case WorkOrderStatus.ACTIVE.toLowerCase():
                return 'success'

            case WorkOrderStatus.DRAFT.toLowerCase():
            case WorkOrderStatus.HOLDING.toLowerCase():
                return 'warn'

            case WorkOrderStatus.SENT.toLowerCase():
            case WorkOrderStatus.NOT_SENT.toLowerCase():
            case WorkOrderStatus.PROPOSED.toLowerCase():
            case WorkOrderStatus.IN_APPROVALS.toLowerCase():
                return 'accent'

            case WorkOrderStatus.CANCELLED.toLowerCase():
            case WorkOrderStatus.REJECTED.toLowerCase():
                return 'error'

            default:
                return ''
        }
    }
}
