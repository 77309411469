export enum DeliverableFormSteps {
    Name = 0,
    Description = 1,
    Kickoff = 2,
    Due = 3,
    DueEstimate = 4,
    RateType = 5,
    RateAmount = 6,
    HourCap = 7,
    HourCapAmount = 8,
    HourCapStrategy = 9,
    HourCapRateAdjust = 10,
    InvoiceTrigger = 11,
    InvoiceDetail = 12,
    Estimate = 13,
    Other = 14,
    Finish = 15,
}
