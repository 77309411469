import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { debounce, debounceTime, filter, tap } from 'rxjs/operators'

import { LoggingService } from '../../auth/services'
import { UrlService } from '../../core/services'

@Injectable({
    providedIn: 'root',
})
export class UpdownDebouncerService {

    isDown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    constructor(
        private router: Router,
        private urls: UrlService,
    ) {
        this.isDown.asObservable()
            .pipe(
                debounceTime(150),
                filter(down => !!down),
                tap(() => {
                    this.router.navigateByUrl(this.urls.route.upDownError())
                    LoggingService.notify(new Error('Down status returned from System Health.'))
                }),
            )
            .subscribe()
    }
}
