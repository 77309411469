import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'

import { AddressModule } from '../../../../address'
import { ChipListModule } from '../../../../chip-list/chip-list.module'
import { LiquidCommonModule } from '../../../../common/liquid-common.module'
import { PipesModule } from '../../../../pipes'

import { DecisionStepComponent } from './decision-step/decision-step.component'
import { FormStepComponent } from './form-step/form-step.component'
import { WorkflowService } from './services/workflow.service'
import { WorkflowGroupComponent } from './workflow-group/workflow-group.component'
import { WorkflowModuleComponent } from './workflow-module/workflow-module.component'
import { WorkflowComponent } from './workflow/workflow.component'

@NgModule({
    declarations: [
        DecisionStepComponent,
        FormStepComponent,
        WorkflowComponent,
        WorkflowGroupComponent,
        WorkflowModuleComponent,
    ],
    imports: [
        AddressModule,
        ChipListModule,
        CommonModule,
        FormsModule,
        LiquidCommonModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        PipesModule,
        ReactiveFormsModule,
    ],
    exports: [
        FormStepComponent,
        WorkflowComponent,
        WorkflowGroupComponent,
        WorkflowModuleComponent,
    ],
})
export class LiquidWorkflowModule {

    static forRoot(): ModuleWithProviders<LiquidWorkflowModule> {
        return {
            ngModule: LiquidWorkflowModule,
            providers: [
                WorkflowService,
            ],
        }
    }
}
