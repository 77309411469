import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'

import { LoggingService } from '../../auth/services'

import { SystemHealthStore } from './system-health.store'

@Injectable({
    providedIn: 'root',
})
export class SystemHealthService {

    constructor(
        private store: SystemHealthStore,
    ) { }

    getSystemHealth(): Observable<boolean> {
        return this.store.getSystemHealth()
            .pipe(
                take(1),
                // when youUp returns empty OK, API is still online
                map(res => true),
                catchError(error => {
                    // Purposely swallowing the error here
                    LoggingService.notify(error)
                    return of(undefined)
                }),
            )
    }
}
