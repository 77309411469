import { Injectable } from '@angular/core'
import { Sort, SortDirection } from '@angular/material/sort'

import { BrowserStorageService } from '../../core/services'
import { TablePreferenceStorageKey } from '../models'
import { PagingSettings } from '../models/paging-settings'

const DEFAULT_PAGE_SIZE: number = 20
const DEFAULT_PAGE_INDEX: number = 0

@Injectable()
export class TableViewPreferencesService {

    constructor(
        private readonly browserStorageService: BrowserStorageService,
    ) { }

    getPagingSettings(tableName: string): PagingSettings {
        const pageSize: number = this.getTableSetting<number>(TablePreferenceStorageKey.tablePageSize, tableName) || DEFAULT_PAGE_SIZE
        const pageIndex: number = DEFAULT_PAGE_INDEX
        return { pageIndex, pageSize }
    }

    getSort(tableName: string, defaultDirection: SortDirection): Sort {
        const active: string = this.getTableSetting<string>(TablePreferenceStorageKey.tableSortActive, tableName)
        const direction: SortDirection = this.getTableSetting<SortDirection>(TablePreferenceStorageKey.tableSortDirection, tableName) || defaultDirection || 'asc'
        return { active, direction }
    }

    savePagingSettings(settings: PagingSettings, tableName: string): void {
        this.saveTableSetting<number>(TablePreferenceStorageKey.tablePageSize, settings.pageSize, tableName)
    }

    saveSort(sort: Sort, tableName: string): void {
        this.saveTableSetting<string>(TablePreferenceStorageKey.tableSortActive, sort.active, tableName)
        this.saveTableSetting<string>(TablePreferenceStorageKey.tableSortDirection, sort.direction, tableName)
    }

    private getTableSetting<T>(settingsKey: TablePreferenceStorageKey, tableName: string): T | undefined {
        const settings: { [key: string]: T } = this.browserStorageService.getItem<{ [key: string]: T }>(settingsKey) ?? {}
        return settings[tableName]
    }

    private saveTableSetting<T>(name: TablePreferenceStorageKey, value: T, tableName: string): void {
        const settings: { [key: string]: T } = this.browserStorageService.getItem<{ [key: string]: T }>(name) ?? {}
        settings[tableName] = value
        this.browserStorageService.setItem(name, settings)
    }
}
