import { AbstractControl } from '@angular/forms'

import { ConstValidation } from './const.validation'
import { ValidatorHelper } from './validator-helper.service'

export function NumberValidator(control: AbstractControl): { [key: string]: any } | undefined {

    // WARNING! THIS ONLY WORKS FOR US-STYLE NUMBER SEPARATORS
    // WITH DOTS FOR DECIMALS AND COMMAS FOR THOUSANDS.
    // FOR INTL NUMBERS, USE THE NumberInternationalValidator

    const validationHelper: ValidatorHelper = new ValidatorHelper()
    const errorKey: string = ConstValidation.NUMBER_INVALID

    // get the value of the control as a string
    const value: string = control?.value

    // if we don't have a value, there's nothing to verify b/c this is a decimal precision validator,
    // not a required validator
    if (!value) {
        validationHelper.removeError(control, errorKey)
        return undefined
    }

    // test the value against the regex
    // this only allows commas used as thousands and dots used as decimals
    const regex: RegExp = /^[-+]?((\d{1,3}(,\d{3})*)|(\d*))(\.|\.\d*)?$/i

    // if we satisfy the regex, we're good
    if (regex.test(control.value)) {
        validationHelper.removeError(control, errorKey)
        return undefined
    }

    // we have too many digits, so add the error
    validationHelper.addError(control, errorKey)
    return { [errorKey]: 'Invalid number' }
}
