import { HttpParameterCodec, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { environment } from '../../../environments/environment'
import { OrderType } from '../../modules/models'
import { InvoiceAttachmentType } from '../../modules/models/invoice-attachment-type.enum'
// NOTE: this has to be imported specifically to be used in the shared module
import {
    BulkImportStatus,
    BulkImportType,
    BusinessFunction,
    CountersigningStatus,
    CreateBusinessWorkflow
} from '../models'

import { EncodeURICodec } from './encode-uri-codec'

@Injectable({
    providedIn: 'root',
})
export class UrlService {

    readonly api: Api = new Api()

    readonly route: Routes = new Routes()

    readonly external: ExternalUrls = new ExternalUrls()

    readonly path: Paths = new Paths()

    static buildQueryFromObject(source: any, encodeParameters: boolean = false): string {
        if (!source) {
            return ''
        }

        const mapped: { [key: string]: string } = {}
        for (const prop of Object.getOwnPropertyNames(source)) {
            const propValue: any = source[prop]
            if (propValue !== undefined) {
                mapped[prop] = source[prop]
            }
        }

        const encoder: HttpParameterCodec | undefined = encodeParameters
            ? new EncodeURICodec()
            : undefined

        const params: HttpParams = new HttpParams({
            fromObject: mapped,
            encoder,
        })

        const paramsQuery: string = params.toString()

        if (paramsQuery) {
            return '?' + paramsQuery
        }

        return ''
    }

    getRouteWithFilters(path: string, filters: Array<string>): string {
        let params: HttpParams = new HttpParams()
        filters.forEach(f => {
            params = params.set(f, 'true')
        })
        return `${path}?${params.toString()}`
    }

}

// tslint:disable-next-line: max-classes-per-file
class ExternalUrls {

    // keep in alpha order
    get complianceBlog(): string { return 'https://goliquid.io/blog/category/compliance/' }
    get createEin(): string { return 'https://goliquid.io/blog/ein-employer-identification-number/' }
    get createInvoiceAndAddVendorVideo(): string { return 'https://www.youtube.com/embed/p0_eeATkSzk' }
    get createInvoiceAsVendorVideo(): string { return 'https://www.youtube.com/embed/z_qT1o4Uwfg' }
    get createInvoiceWithExistingVendorVideo(): string { return 'https://www.youtube.com/embed/IJjOqXcLQo0' }
    get createPurchaseOrderVideo(): string { return 'https://www.youtube.com/embed/VBxlI99TDPQ' }
    get createWorkOrderVideo(): string { return 'https://www.youtube.com/embed/SxaLEKg3mxw' }
    get inviteVendorsVideo(): string { return 'https://www.youtube.com/embed/yIRceBR3lWk' }
    get irs1099(): string { return 'https://www.irs.gov/businesses/small-businesses-self-employed/am-i-required-to-file-a-form-1099-or-other-information-return' }
    get liquidStatusUrl(): string { return environment.liquidStatusUrl }
    get liquidUrl(): string { return environment.liquidUrl }
    get w9(): string { return 'http://www.irs.gov/FormW9' }
    get w9And1099(): string { return 'https://goliquid.io/blog/liquid-1099s-w9s/' }

    getHostedPaymentForm: (uid: string) => string = (uid: string) => `${environment.paymentSettings.hpfUrl}${uid}`
}

// tslint:disable-next-line: max-classes-per-file
class Paths {

    // keep in alpha order
    get acceptOrgInvite(): string { return '/liquid/organization-accept' }
    get acceptVendorInvite(): string { return 'liquid/team-accept' }
    get acceptExpressClientInvite(): string { return `${this.createBusinessExpress}/client-accept` }
    get clientPage(): string { return 'liquid/clients' }
    get code(): string { return `code` }
    get contacts(): string { return 'liquid/contacts' }
    get contracts(): string { return 'liquid/contracts' }
    get createBusiness(): string { return 'liquid/create-business' }
    get createBusinessExpress(): string { return 'liquid/express-setup' }
    get createBusinessClient(): string { return `${this.createBusiness}/client` }
    get createBusinessVendor(): string { return `${this.createBusiness}/vendor` }
    get homeDashboard(): string { return 'liquid/home' }
    get invoiceDetails(): string { return `${this.invoices}/:action/:id/:businessId` }
    get invoiceIncoming(): string { return `${this.invoices}/incoming` }
    get invoiceOutgoing(): string { return `${this.invoices}/outgoing` }
    get invoiceReminders(): string { return `${this.invoices}/reminders` }
    get invoiceUpload(): string { return `${this.invoices}/upload` }
    get invoices(): string { return 'liquid/invoices' }
    get legalSetup(): string { return 'liquid/legal' }
    get onboardingAdvancedEdit(): string { return `${this.onboardingProcess}/edit-process` }
    get onboardingProcess(): string { return 'liquid/team-onboarding-processes' }
    get organizations(): string { return 'organizations' }
    get payments(): string { return 'payments' }
    get payouts(): string { return 'payouts' }
    get projectLinkCreate(): string { return `${this.projectLinks}/create` }
    get projectLinks(): string { return 'liquid/project-links' }
    get purchaseOrderCreate(): string { return `${this.purchaseOrderDashboard}/draft` }
    get purchaseOrderDashboard(): string { return 'liquid/purchase-orders' }
    get purchaseOrderDetails(): string { return `${this.purchaseOrderDashboard}/details` }
    get vendorPurchaseOrderCreate(): string { return `${this.purchaseOrderDashboard}/draft` }
    get vendorPurchaseOrderDashboard(): string { return 'liquid/vendor-purchase-orders' }
    get vendorPurchaseOrderDetails(): string { return `${this.vendorPurchaseOrderDashboard}/details` }
    get vendorWorkOrderCreate(): string { return `${this.workOrderDashboard}/draft` }
    get vendorWorkOrderCreateAnonymous(): string { return `${this.vendorWorkOrderDashboard}/org-create-accept` }
    get vendorWorkOrderDashboard(): string { return 'liquid/vendor-work-orders' }
    get vendorWorkOrderDetails(): string { return `${this.vendorWorkOrderDashboard}/details` }
    get quickBooks(): string { return 'integrations/quickbooks' }
    get reports1099(): string { return `${this.reportsDashboard}/1099'` }
    get reportsDashboard(): string { return 'liquid/reports' }
    get reportsInvoice(): string { return `${this.reportsDashboard}/invoice` }
    get reportsWorkOrders(): string { return `${this.reportsDashboard}/work-order` }
    get reportsW9(): string { return `${this.reportsDashboard}/w9` }
    get servicePlans(): string { return 'servicePlans' }
    get setName(): string { return 'set-name' }
    get settings(): string { return 'liquid/organization-settings' }
    get settingsApprovals(): string { return `${this.settings}/approvals` }
    get settingsBranding(): string { return `${this.settings}/branding` }
    get settingsBilling(): string { return `${this.settings}/billing` }
    get settingsBillingFields(): string { return `${this.settings}/billing-fields` }
    get settingsCOA(): string { return `${this.settings}/coa` }
    get settingsCompanyInfo(): string { return `${this.settings}/company` }
    get settingsGetPaid(): string { return `${this.settings}/getpaid` }
    get settingsIntegrations(): string { return `${this.settings}/integrations` }
    get settingsPaymentMethod(): string { return `${this.settings}/wallet` }
    get settingsRoles(): string { return `${this.settings}/roles` }
    get settingsSubscription(): string { return `${this.settings}/subscription` }
    get settingsTags(): string { return `${this.settings}/tags` }
    get settingsTaxes(): string { return `${this.settings}/taxes` }
    get settingsUsers(): string { return `${this.settings}/users` }
    get settingsVendors(): string { return `${this.settings}/vendors` }
    get settingsLegalSettings(): string { return `${this.settings}/legal-settings` }
    get tag(): string { return 'tags' }
    get vendorPage(): string { return 'liquid/vendors' }
    get workOrderCreate(): string { return `${this.workOrderDashboard}/draft` }
    get workOrderDashboard(): string { return 'liquid/work-orders' }
    get workOrderDetails(): string { return `${this.workOrderDashboard}/details` }
}

// tslint:disable-next-line: max-classes-per-file
class Routes {

    private paths: Paths = new Paths()

    // keep in alpha order
    readonly acceptExpressClientInvite: () => string = () => `/${this.paths.acceptExpressClientInvite}`
    readonly acceptVendorInvite: (inviteId: string, invoiceId?: string) => string = (inviteId: string, invoiceId?: string) => `${this.paths.acceptVendorInvite}/${inviteId}${!!invoiceId ? `?invoiceId=${invoiceId}` : ''}`
    readonly acceptVendorInviteAndRedirectToCreateAccount: (inviteId: string, invoiceId?: string) => string = (inviteId: string, invoiceId?: string) => this.createAccount(`/${this.acceptVendorInvite(inviteId, invoiceId)}`)
    readonly businessSettings: (section?: string) => string = (section?: string) => `/${this.paths.settings}${!!section ? `/${section}` : ''}`
    readonly clients = (clientId?: string, action?: 'message', teamMemberId?: string, completeOnboardingForClientId?: string) => `/${this.paths.clientPage}${UrlService.buildQueryFromObject({ clientId, action, teamMemberId, completeOnboarding: completeOnboardingForClientId })}`
    readonly coa: () => string = () => `/${this.paths.settingsCOA}`
    readonly createAccount: (redirectUrl: string) => string = (redirectUrl: string) => `${environment.host}/create-account/?url=${encodeURIComponent(redirectUrl)}`
    readonly createBusiness: (params?: { workflow?: CreateBusinessWorkflow, func?: BusinessFunction, stepId?: string }) => string = ({ workflow, func, stepId = '' }: { workflow?: CreateBusinessWorkflow, func?: BusinessFunction, stepId?: string } = {}) => `/${workflow === CreateBusinessWorkflow.ExpressSetup ? this.paths.createBusinessExpress : this.paths.createBusiness}${(!!func ? `/${func}` : '')}${!!stepId ? `/${stepId}` : ''}`
    readonly upDownError: () => string = () => `/down`
    readonly error: () => string = () => `/error`
    readonly getCompleteOnboardingQueryParam: (routeSnapshot: ActivatedRouteSnapshot) => string | undefined = (routeSnapshot: ActivatedRouteSnapshot) => routeSnapshot.queryParamMap.get('completeOnboarding')
    readonly getPaid: () => string = () => `/${this.paths.settingsGetPaid}`
    readonly home: (action?: string) => string = (action?: string) => `/${this.paths.homeDashboard}${!!action ? `?action=${action}` : ''}`
    readonly hostedPaymentCallback: () => string = () => this.getPathWithDomain(`/assets/hpf-callback.html`)
    readonly invoiceEdit: (id: string, workOrderId?: string, clone?: boolean) => string = (id: string, workOrderId?: string, clone?: boolean) => `/${this.paths.invoices}/edit/${id}${!!workOrderId ? `?work-order-id=${workOrderId}` : ''}${!!clone ? `?clone=${clone}` : ''}`
    readonly invoiceForClient: (orderType: OrderType, clientId?: string) => string = (orderType: OrderType, clientId?: string) => `/${this.paths.invoices}/new-client${UrlService.buildQueryFromObject({ 'client-id': clientId, orderType })}`
    readonly invoiceForPurchaseOrder: (purchaseOrderId: string) => string = (purchaseOrderId: string) => `/${this.paths.invoices}/new-purchase-order/${purchaseOrderId}`
    readonly invoiceForWorkOrder: (workOrderId: string) => string = (workOrderId: string) => `/${this.paths.invoices}/new-work-order/${workOrderId}`
    readonly invoiceForVendor: (orderType: OrderType, vendorId?: string) => string = (orderType: OrderType, vendorId?: string) => `/${this.paths.invoices}/new-vendor/${!!vendorId ? vendorId : ''}${UrlService.buildQueryFromObject({ orderType })}`
    readonly invoiceForVendorLed: (id?: string) => string = (id?: string) => `/${this.paths.invoices}/new-external-client${UrlService.buildQueryFromObject({ id })}`
    readonly invoiceView: (invoiceId: string, businessId: string, expressPay?: boolean, redirectToHome?: boolean) => string = (invoiceId: string, businessId: string, expressPay?: boolean, redirectToHome?: boolean) => `/${this.paths.invoices}/view/${invoiceId}/${businessId}${UrlService.buildQueryFromObject({ expressPay, redirectToHome })}`
    readonly invoiceGuest: (invoiceId: string, businessId: string) => string = (invoiceId: string, businessId: string) => `/${this.paths.invoices}/guest/${invoiceId}/${businessId}`
    readonly invoiceUploadNew: () => string = () => `${this.paths.invoices}/new-upload`
    readonly invoiceUpload: (bizFunc: BusinessFunction, invoice?: string, workOrder?: string, purchaseOrder?: string, clientId?: string, orderType?: string, isClone?: boolean) => string = (bizFunc: BusinessFunction, invoice?: string, workOrder?: string, purchaseOrder?: string, clientId?: string, orderType?: string, isClone?: boolean) => `/${this.paths.invoiceUpload}/${bizFunc}${UrlService.buildQueryFromObject({ invoice, workOrder, purchaseOrder, clientId, orderType, isClone })}`
    readonly invoiceUploadClient: (invoice?: string, workOrder?: string, purchaseOrder?: string, orderType?: string) => string = (invoice?: string, workOrder?: string, purchaseOrder?: string, orderType?: string) => this.invoiceUpload('client', invoice, workOrder, purchaseOrder, undefined, orderType)
    readonly invoiceUploadVendor: () => string = () => this.invoiceUpload('vendor')
    readonly invoices: (type?: 'incoming' | 'outgoing', query?: { create?: boolean, import?: boolean }) => string = (type?: 'incoming' | 'outgoing', query?: { create?: boolean }) => `/${this.paths.invoices}${!!type ? `/${type}` : ''}${UrlService.buildQueryFromObject(query)}`
    readonly isAuthCallback: (path: string) => boolean = (path: string) => path.startsWith('/auth-callback')
    readonly isBillingPage: (url: string) => boolean = (url: string) => url.includes(this.settingsBilling())
    readonly isBusinessSettings: (url: string) => boolean = (url: string) => url.includes(this.paths.settingsCompanyInfo)
    readonly isCOA: (url: string) => boolean = (url: string) => url.includes(this.coa())
    readonly isCreateBusiness: (url: string) => boolean = (url: string) => url.includes(this.createBusiness()) || url.includes(this.createBusiness({ workflow: CreateBusinessWorkflow.ExpressSetup }))
    readonly isCreateClient: (url: string) => boolean = (url: string) => url.includes(this.createBusiness({ func: 'client' })) || url.includes(this.createBusiness({ workflow: CreateBusinessWorkflow.ExpressSetup }))
    readonly isExpressFlow: (url: string) => boolean = (url: string) => [
        '/invoices/guest',
        '/express-setup',
        '/express-landing',
    ].some(p => url.includes(p))
    readonly isGetPaid: (url: string) => boolean = (url: string) => url.includes(this.getPaid())
    readonly isHomePage: (url: string) => boolean = (url: string) => url.includes(this.home()) || url === '/'
    readonly isJoinOrgLanding: (url: string) => boolean = (url: string) => url.includes('/join-org')
    readonly isOrgAccept: (url: string) => boolean = (url: string) => url.includes(this.paths.acceptOrgInvite)
    readonly isPaymentMethodsPage: (url: string) => boolean = (url: string) => url.includes(this.settingsPaymentMethods())
    readonly isSettingsGetPaid: (url: string) => boolean = (url: string) => url.includes(this.getPaid())
    readonly isSettingsRoles: (url: string) => boolean = (url: string) => url.includes(this.paths.settingsRoles)
    readonly isSettingsBillingFields: (url: string) => boolean = (url: string) => url.includes(this.settingsBillingFields())
    readonly isSettingsUsers: (url: string) => boolean = (url: string) => url.includes(this.settingsUsers())
    readonly isTeamAccept: (url: string) => boolean = (url: string) => url.includes(this.paths.acceptVendorInvite)
    readonly isVendorList: (url: string) => boolean = (url: string) => url.includes(this.vendorList())
    readonly joinBiz: (inviteId: string) => string = (inviteId: string) => `/join-org/${inviteId}`
    readonly legalSetup: (stepId?: string) => string = (stepId?: string) => `${this.paths.legalSetup}${!!stepId ? `/${stepId}` : ''}`
    readonly liquidInvoice: (invoiceId: string, businessId: string) => string = (invoiceId: string, businessId: string) => `/liquid/plan-invoices/view/${invoiceId}/${businessId}`
    readonly notFound: () => string = () => '/404'
    readonly onboardingProcesses: () => string = () => `/${this.paths.onboardingProcess}`
    readonly onboardingProcessVerification: (processId: string) => string = (processId: string) => `${this.onboardingProcesses()}/verify/${processId}`
    readonly passwordlessCodeEntry: (email: string) => string = (email: string) => `/${this.paths.code}?for=${encodeURIComponent(email)}`
    readonly projectLinkCreate: () => string = () => `/${this.paths.projectLinkCreate}`
    readonly projectLinkEdit: (linkId: string) => string = (linkId: string) => `/${this.paths.projectLinks}/${linkId}`
    readonly projectLinksDashboard: () => string = () => `/${this.paths.projectLinks}`
    readonly purchaseOrderCreate: (vendor?: string, id?: string, step?: string, inviteId?: string) => string = (vendor?: string, id?: string, step?: string, inviteId?: string) => `/${this.paths.purchaseOrderCreate}${!!id ? '/' + id : ''}${!!vendor ? `?vendor=${vendor}` : ''}${!!step ? `${!!vendor ? '&' : '?'}step=${step}` : ''}${!!inviteId ? `${!!vendor || !!step ? '&' : '?'}inviteId=${inviteId}` : ''}`
    readonly purchaseOrderDetails: (id: string, businessId: string, openCta?: boolean, editEstimatedFee?: boolean) => string = (id: string, businessId: string, openCta?: boolean, editEstimatedFee?: boolean) => `/${this.paths.purchaseOrderDetails}/${id}/${businessId}${UrlService.buildQueryFromObject({ openCta, editEstimatedFee })}`
    readonly purchaseOrdersDashboard: (workOrderFields?: string, customerFields?: string) => string = (workOrderFields?: string, customerFields?: string) => `/${this.paths.purchaseOrderDashboard}${UrlService.buildQueryFromObject({ customerFields, workOrderFields })}`
    readonly reportsDashboard: () => string = () => `/${this.paths.reportsDashboard}`
    readonly settingsBilling: (editPlan?: boolean) => string = (editPlan) => `/${this.paths.settingsBilling}${UrlService.buildQueryFromObject({ editPlan })}`
    readonly settingsGetPaid: () => string = () => `/${this.paths.settingsGetPaid}`
    readonly settingsPaymentMethods: (expressMicrodepositSetup?: string) => string = (expressMicrodepositSetup?: string) => `/${this.paths.settingsPaymentMethod}${UrlService.buildQueryFromObject({ expressMicrodepositSetup })}`
    readonly settingsBillingFields: () => string = () => `/${this.paths.settingsBillingFields}`
    readonly settingsTags: () => string = () => `/${this.paths.settingsTags}`
    readonly settingsUsers: (params?: { membership?: string, invite?: boolean }) => string = (queryParams) => `/${this.paths.settingsUsers}${UrlService.buildQueryFromObject(queryParams)}`
    readonly vendorList: () => string = () => `/${this.paths.settingsVendors}`
    readonly vendors: (create?: boolean, openInformation?: string, workOrderFields?: string, customerFields?: string, openTeamInformation?: string) => string = (create?: boolean, openInformation?: string, workOrderFields?: string, customerFields?: string, openTeamInformation?: string) => `/${this.paths.vendorPage}${UrlService.buildQueryFromObject({ create, openInformation, customerFields, workOrderFields, openTeamInformation })}`
    readonly vendorPurchaseOrderCreate: (client?: string, id?: string, step?: string) => string = (client?: string, id?: string, step?: string) => `/${this.paths.vendorPurchaseOrderCreate}${!!id ? '/' + id : ''}${(!!client ? `?client=${client}` : '')}${!!step ? `${!!client ? '&' : '?'}step=${step}` : ''}`
    readonly vendorPurchaseOrderDashboard: () => string = () => `/${this.paths.purchaseOrderDashboard}`
    readonly vendorPurchaseOrderDetails: (workOrderId: string, businessId: string, addInvoice?: boolean) => string = (workOrderId: string, businessId: string, addInvoice: boolean = false) => `/${this.paths.vendorPurchaseOrderDetails}/${workOrderId}/${businessId}${addInvoice ? '?showActions=true' : ''}`
    readonly vendorWorkOrderCreate: (client?: string, id?: string, step?: string) => string = (client?: string, id?: string, step?: string) => `/${this.paths.vendorWorkOrderCreate}${!!id ? '/' + id : ''}${(!!client ? `?client=${client}` : '')}${!!step ? `${!!client ? '&' : '?'}step=${step}` : ''}`
    readonly vendorWorkOrderCreateAnonymous: (workOrderId: string) => string = (workOrderId: string) => `/${this.paths.vendorWorkOrderCreateAnonymous}/${workOrderId}`
    readonly vendorWorkOrderDashboard: () => string = () => `/${this.paths.vendorWorkOrderDashboard}`
    readonly vendorWorkOrderDetails: (workOrderId: string, businessId: string, addInvoice?: boolean) => string = (workOrderId: string, businessId: string, addInvoice: boolean = false) => `/${this.paths.vendorWorkOrderDetails}/${workOrderId}/${businessId}${addInvoice ? '?showActions=true' : ''}`
    readonly workOrderCreate: (vendor?: string, id?: string, step?: string, inviteId?: string) => string = (vendor?: string, id?: string, step?: string, inviteId?: string) => `/${this.paths.workOrderCreate}${!!id ? '/' + id : ''}${!!vendor ? `?vendor=${vendor}` : ''}${!!step ? `${!!vendor ? '&' : '?'}step=${step}` : ''}${!!inviteId ? `${!!vendor || !!step ? '&' : '?'}inviteId=${inviteId}` : ''}`
    readonly workOrderDetails: (id: string, businessId: string, openCta?: boolean, editEstimatedFee?: boolean) => string = (id: string, businessId: string, openCta?: boolean, editEstimatedFee?: boolean) => `/${this.paths.workOrderDetails}/${id}/${businessId}${UrlService.buildQueryFromObject({ openCta, editEstimatedFee })}`
    readonly workOrdersDashboard: (workOrderFields?: string, customerFields?: string) => string = (workOrderFields?: string, customerFields?: string) => `/${this.paths.workOrderDashboard}${UrlService.buildQueryFromObject({ customerFields, workOrderFields })}`

    private getPathWithDomain(path: string): string {
        return `${environment.host}${path}`
    }
}

// tslint:disable-next-line: max-classes-per-file
class Api {

    private readonly countryBankAccount: string = 'country-bank-accounts'
    private readonly paths: Paths = new Paths()

    private get clientVendorRelationship(): string { return `/${this.paths.organizations}/teams/members` }

    // keep in alpha order
    readonly acceptExpressClientInvite: () => string = () => this.getUrl(`/${this.paths.organizations}/teams/invitations/accept`)
    readonly accountSignUp: () => string = () => `/sign-up`
    readonly accountsPayableReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/accountsPayableReportFiles/${fileType}`)
    readonly address: (businessId?: string) => string = (businessId?: string) => this.getUrl(`/${this.paths.organizations}/${(!!businessId ? `${businessId}/` : '')}addresses`)
    readonly assignOrganizationMembershipInvitationToOnboardingProcessInstance = (organizationId: string, onboardingInstanceId: string, invitationId: string): string => this.getUrl(`/onboarding/${this.paths.organizations}/${organizationId}/${onboardingInstanceId}/contractSigner/${invitationId}`)
    readonly autoCreateInvoiceDrafts: () => string = () => this.getUrl(`/work-orders/autoCreateInvoiceDrafts`)
    readonly avatarUrl: (url: string) => string = (url: string = '') => {
        url = url?.trim() || ''
        return !url
            ? undefined
            : (url.startsWith('http')
                ? url
                : `${environment.liquidApiSettings.orgAvatarPrefix}/${url}`)
    }
    readonly bank: (swiftCode: string) => string = (swiftCode: string) => this.getUrl(`/banks/${swiftCode}`)
    readonly businessCurrencyPreference: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/currencyPreference`)
    readonly businessToken: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/oToken`)
    readonly bulkHoursUpload: (vendorId: string) => string = (vendorId: string) => this.getUrl(`/vendors/${vendorId}/orders/hours/uploads`)
    readonly bulkHoursUploadOrders: (vendorId: string) => string = (vendorId: string) => this.getUrl(`/vendors/${vendorId}/orders/hours-import`)
    readonly bulkImportItems: (organizationId: string, statuses: string, importType: BulkImportType) => string = (organizationId: string, statuses: string, importType: BulkImportType) => this.getUrl(`/${this.paths.organizations}/${organizationId}/imports${UrlService.buildQueryFromObject({ statuses, type: importType })}`)
    readonly cashForecastReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/cashForecastReportFiles/${fileType}`)
    readonly clientVendorRelationshipDocument: (clientVendorRelationshipId: string, documentId: string) => string = (clientVendorRelationshipId: string, documentId: string) => `${this.clientVendorRelationshipDocuments(clientVendorRelationshipId)}/${documentId}`
    readonly clientVendorRelationshipDocumentAssociateWorkOrders: (clientVendorRelationshipId: string, documentId: string) => string = (clientVendorRelationshipId: string, documentId: string) => `${this.clientVendorRelationshipDocuments(clientVendorRelationshipId)}/${documentId}/associateWorkOrders`
    readonly clientVendorRelationshipDocumentFile: (clientVendorRelationshipId: string, documentId: string, originalFilename: string) => string = (clientVendorRelationshipId: string, documentId: string, originalFilename: string) => this.getUrl(`/${this.paths.organizations}/teams/members/${clientVendorRelationshipId}/files/${documentId}/${originalFilename}`)
    readonly clientVendorRelationshipDocuments: (clientVendorRelationshipId: string, query?: { countersigningStatuses?: CountersigningStatus[] }) => string = (clientVendorRelationshipId: string, query?: { countersigningStatuses?: CountersigningStatus[] }) => this.getUrl(`/${this.paths.organizations}/teams/members/${clientVendorRelationshipId}/documents${UrlService.buildQueryFromObject(query)}`)
    readonly clientVendorRelationshipGet: (businessId: string, clientVendorRelationshipId: string) => string = (businessId: string, clientVendorRelationshipId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/teams/members/${clientVendorRelationshipId}`)
    readonly clientVendorRelationshipMasterContract: (clientVendorRelationshipId: string, filename: string) => string = (clientVendorRelationshipId: string, filename: string) => this.getUrl(`/${this.paths.organizations}/teams/members/${clientVendorRelationshipId}/masterContracts/${filename}`)
    readonly clientVendorRelationshipUpdate: () => string = () => this.getUrl(this.clientVendorRelationship)
    readonly clientVendorRelationshipUpdateProperties: () => string = () => this.getUrl(`${this.clientVendorRelationship}/properties`)
    readonly contact: (organizationId: string, contactId: string) => string = (organizationId: string, contactId: string) => `${this.contacts(organizationId)}/${contactId}`
    readonly contactImport: (organizationId: string) => string = (organizationId: string) => `${this.contacts(organizationId)}/imports`
    readonly contactImportSettings: (organizationId: string) => string = (organizationId: string) => `${this.contacts(organizationId)}/imports/settings`
    readonly contactImportTemplate: (organizationId: string) => string = (organizationId: string) => `${this.contacts(organizationId)}/imports/template`
    readonly contactImportValidation: (organizationId: string) => string = (organizationId: string) => `${this.contacts(organizationId)}/imports/validate`
    readonly contacts: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/contacts`)
    readonly contactsPaged: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/contacts/paged`)
    readonly contactsSearches: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/contacts/searches`)
    readonly contractSignerInvitations: (businessId: string, pending?: boolean) => string = (businessId: string, pending?: boolean) => this.getUrl(`/onboarding/${this.paths.organizations}/${businessId}/signer-invitations${UrlService.buildQueryFromObject({ pending })}`)
    readonly countries: () => string = () => this.getUrl(`/public/utils/countries`)
    readonly countersignDocuments: (clientVendorRelationshipId: string) => string = (clientVendorRelationshipId: string) => `${this.clientVendorRelationshipDocuments(clientVendorRelationshipId)}/countersign`
    readonly countryCurrencyConfigurationGet: (countryId: string) => string = (countryId: string) => this.getUrl(`/${this.countryBankAccount}/${countryId}`)
    readonly currenciesWithoutDecimals: () => string = () => this.getUrl('/public/utils/currencies-without-decimals')
    readonly createBillingField: () => string = () => this.getUrl(`/integrations/quickbooks/billingFields`)
    readonly createGuestAccount: () => string = () => this.getUrl('/public/profiles/guestAccount')
    readonly createQBField: () => string = () => this.getUrl(`/integrations/quickbooks/fields`)
    readonly dashboard: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/dashboards`) // update
    readonly dashboardBench: (businessId: string) => string = (businessId: string) => this.getUrl(`/dashboards/${businessId}/bench`)
    readonly dashboardExplainers: (businessId: string) => string = (businessId: string) => this.getUrl(`/dashboards/${businessId}/explainers`)
    readonly dashboardInvoicePaymentResult: (businessId: string) => string = (businessId: string) => this.getUrl(`/dashboards/${businessId}/invoicePayments`)
    readonly dashboardInvoiceStatusesResult: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/dashboards/${organizationId}/invoiceStatuses`)
    readonly dashboardUpcomingExpenses: (businessId: string, bizFunc: BusinessFunction) => string = (businessId: string, bizFunc: BusinessFunction) =>
        this.getUrl(`/dashboards/upcoming/${businessId}/${bizFunc}`)
    readonly deleteBillingField: (billingFieldId: string) => string = (billingFieldId: string) => this.getUrl(`/integrations/quickbooks/billingFields/${billingFieldId}`)
    readonly disconnectQuickbooks: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/quickbooks/disconnects`)
    readonly dismissNotification: (notificationId: string) => string = (notificationId: string) => this.getUrl(`/vendors/imports/notifications/${notificationId}`)
    readonly emailVerify: () => string = () => this.getUrl('/profiles/verify')
    readonly expenseAccrualReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/expenseAccrualReportFiles/${fileType}`)
    readonly expressVerify: (invitationId: string) => string = (invitationId: string) => this.getUrl(`/profiles/express/verify/${invitationId}`)
    readonly foreignUsBankAccount: () => string = () => this.getUrl(`/payments/foreignInUs/bankAccounts`)
    readonly getBillingFields: (orgId: string) => string = (orgId: string) => this.getUrl(`/integrations/quickbooks/${orgId}/billingFields`)
    readonly guestUser: (invitationId: string, emailId: string) => string = (invitationId: string, emailId: string) => this.getUrl(`/public/profiles/guest/${invitationId}/${emailId}`)
    readonly hasInvoices: (businessId: string) => string = (businessId: string) => this.getUrl(`/invoices/${businessId}/hasExisting`)
    readonly hasOrders: (businessId: string, orderType: OrderType) => string = (businessId: string, orderType: OrderType) => this.getUrl(`/work-orders/${businessId}/${orderType}/hasAny`)
    readonly hasPayoutAccount: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.payments}/${this.paths.organizations}/${businessId}/payouts/hasAccount`)
    readonly hasProjects: (businessId: string) => string = (businessId: string) => this.getUrl(`/projectLink/${businessId}/hasAny`)
    readonly hasTeamMembers: (businessId: string) => string = (businessId: string) => this.getUrl(`/organizations/${businessId}/hasTeamMembers`)
    readonly hostedPaymentUid: () => string = () => this.getUrl(`/${this.paths.payments}/paymentech/hps/uids`)
    readonly invitationUpdateProperties: () => string = () => this.getUrl(`/${this.paths.organizations}/teams/invitations/properties`)
    readonly invoiceBillingFieldAssociations: (invoiceId: string) => string = (invoiceId: string) => this.getUrl(`/invoices/${invoiceId}/billingfields`)
    readonly invoiceBillingFieldAssociation: (invoiceId: string, billingFieldId: string) => string = (invoiceId: string, billingFieldId: string) => this.getUrl(`/invoices/${invoiceId}/billingfields/${billingFieldId}`)
    readonly invoiceApprovalStatus: () => string = () => this.getUrl('/invoices/approvals')
    readonly invoiceApprovers: (invoiceId: string) => string = (invoiceId: string) => this.getUrl(`/invoices/${invoiceId}/approvalProcesses`)
    readonly invoicesBulkApproval: () => string = () => this.getUrl('/invoices/bulk-approvals')
    readonly invoiceBulkPay: () => string = () => this.getUrl('/invoices/payments/bulk')
    readonly invoiceAttachment: (invoiceId: string, attachmentType: InvoiceAttachmentType) => string = (invoiceId: string, attachmentType: InvoiceAttachmentType) => this.getUrl(`/invoices/${invoiceId}/attachments/downloads?attachmentType=${attachmentType}`)
    readonly invoiceCancel: () => string = () => this.getUrl('/invoices/cancel')
    readonly invoiceChartOfAccountAssociations: () => string = () => this.getUrl('/invoices/chartOfAccountsAssociations')
    readonly invoiceDetails: (invoiceId: string, businessId: string) => string = (invoiceId: string, businessId: string) => this.getUrl(`/invoices/${businessId}/${invoiceId}`)
    readonly invoiceDetailsReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/invoiceDetailReportFiles/${fileType}`)
    readonly invoiceFileCreate: () => string = () => this.getUrl(`/invoices/files/`)
    readonly invoiceFileDownload: (invoiceId: string, fileId: string) => string = (invoiceId: string, fileId: string) => this.getUrl(`/invoices/files/${invoiceId}/download/${fileId}`)
    readonly invoiceFileGet: (invoiceId: string, bizId: string) => string = (invoiceId: string, bizId: string) => this.getUrl(`/invoices/${invoiceId}/files/${bizId}`)
    readonly invoiceFileUpload: (invoiceId: string, originalFilename: string) => string = (invoiceId: string, originalFilename: string) => this.getUrl(`/invoices/${invoiceId}/upload/${originalFilename}`)
    readonly invoiceForInvite: (inviteId: string) => string = (inviteId: string) => this.getUrl(`/invoices/${inviteId}/preview`)
    readonly invoiceIds: (businessId: string, type: 'clients' | 'invoicers') => string = (businessId: string, type: 'clients' | 'invoicers') => this.getUrl(`/invoices/${type}/ids/${businessId}`)
    readonly invoiceImportSettings: () => string = () => this.getUrl('/invoices/imports/settings')
    readonly invoiceImportTemplate: () => string = () => this.getUrl('/invoices/imports/template')
    readonly invoiceImportValidation: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/invoices/imports/validate`)
    readonly invoiceImport: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/invoices/imports`)
    readonly invoiceIntegrations: () => string = () => this.getUrl(`/invoices/integrations`)
    readonly invoiceList: (businessId: string, type: 'clients' | 'invoicers') => string = (businessId: string, type: 'clients' | 'invoicers') => this.getUrl(`/invoices/${type}/${businessId}`)
    readonly invoiceLineItems: (invoiceId: string, businessId: string) => string = (invoiceId: string, businessId: string) => this.getUrl(`/invoices/${businessId}/${invoiceId}/lineItems`)
    readonly invoiceMessages: () => string = () => this.getUrl('/invoices/messages')
    readonly invoiceNotifications: () => string = () => this.getUrl('/invoices/notifications')
    readonly invoicePaymentSchedule: (invoiceId: string) => string = (invoiceId: string) => this.getUrl(`/invoices/${invoiceId}/scheduledPayments`)
    readonly invoiceProcessingDecision: () => string = () => this.getUrl('/invoices/processingDecisions')
    readonly invoiceNewInvoiceNumber: () => string = () => this.getUrl('/invoices/newInvoiceNumber')
    readonly invoiceReport: (businessId: string) => string = (businessId: string) => this.getUrl(`/reports/${businessId}/invoiceReports`)
    readonly invoiceReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/paymentsOutReportFiles/${fileType}`)
    readonly invoiceUploadAttachment: (invoiceId: string, filename: string, createdByOrganizationId: string, attachmentType: InvoiceAttachmentType) => string = (invoiceId: string, filename: string, createdByOrganizationId: string, attachmentType: InvoiceAttachmentType) => this.getUrl(`/invoices/${createdByOrganizationId}/${invoiceId}/${attachmentType}/attachments/${encodeURIComponent(filename)}`)
    readonly invoices: () => string = () => this.getUrl(`/invoices`)
    readonly invoicesFXRateQuotes: (businessId: string) => string = (businessId: string) => this.getUrl(`/invoices/fxRateQuotes/clients/${businessId}`)
    readonly irs1099Report: () => string = () => this.getUrl(`/reports/${this.paths.organizations}/1099AnnualPaymentSummary`)
    readonly journalEntryReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/journalEntries/${fileType}`)
    readonly linkRequest: (reconnect: boolean, businessId: string) => string = (reconnect: boolean, businessId: string) => this.getUrl(`/${this.paths.quickBooks}/${reconnect ? 'reconnectLinkRequests' : 'linkrequests'}/${businessId}`)
    readonly liquidInvoiceDownload: (liquidInvoiceId: string) => string = (liquidInvoiceId: string) => this.getUrl(`/monthly-invoices/${liquidInvoiceId}/downloads/pdfs`)
    readonly liquidInvoiceVendorAssociation: () => string = () => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/vendors/liquidIncAssociations`)
    readonly liquidInvoices: (businessId: string) => string = (businessId: string) => this.getUrl(`/monthly-invoices/${businessId}`)
    readonly liquidInvoicesUpload: () => string = () => this.getUrl(`/monthly-invoices/integrations`)
    readonly masterContractDocumentForOrganizationTeamMember = (organizationTeamMemberId: string) => this.getUrl(`/${this.paths.organizations}/teams/members/${organizationTeamMemberId}/masterContracts`)
    readonly microdepositVerificationTokens: (paymentAccountId: string) => string = (paymentAccountId: string) => this.getUrl(`/${this.paths.payments}/plaid/accounts/${paymentAccountId}/microdepositVerificationTokens`)
    readonly onboardingInvitation: (businessId: string, instanceId: string) => string = (businessId: string, instanceId: string) => this.getUrl(`/onboarding/${this.paths.organizations}/${businessId}/${instanceId}/invitation`)
    readonly ordersCanBeInvoiced: (clientOrganizationId: string) => string = (clientOrganizationId: string) => this.getUrl(`/${this.paths.organizations}/${clientOrganizationId}/invoices/valid-orders`)
    readonly ordersImportFile: (clientOrganizationId: string) => string = (clientOrganizationId: string) => this.getUrl(`/${this.paths.organizations}/${clientOrganizationId}/orders/imports/files`)
    readonly ordersImportTemplate: () => string = () => this.getUrl('/orders/imports/template')
    readonly ordersImport: (clientOrganizationId: string) => string = (clientOrganizationId: string) => this.getUrl(`/${this.paths.organizations}/${clientOrganizationId}/orders/imports`)
    readonly organizationAbstract: () => string = () => this.getUrl(`/${this.paths.organizations}/abstract`)
    readonly organizationSettings = (organizationId: string): string => this.getUrl(`/${this.paths.organizations}/${organizationId}/settings`)
    readonly organizationTeamInvitations: (query?: { email: string, includeInvoiceInvites: boolean, includeAnsweredInvites: boolean }) => string = (query) => this.getUrl(`/${this.paths.organizations}/teams/invitations${UrlService.buildQueryFromObject(query, true)}`)
    readonly organizations: () => string = () => this.getUrl(`/${this.paths.organizations}`)
    readonly otherBankAccount: () => string = () => this.getUrl(`/payments/others/bankAccounts`)
    readonly otherBankAccountProperties: (paymentAccountId: string) => string = (paymentAccountId: string) => this.getUrl(`/${this.paths.payments}/others/bankAccounts/${paymentAccountId}/existingProperties`)
    readonly partialBusiness: () => string = () => this.getUrl(`/profiles/partialBusiness`)
    readonly paymentAccount: () => string = () => this.getUrl(`/${this.paths.payments}/accounts`)
    readonly paymentAccountCreateCreditCard: () => string = () => this.getUrl(`/${this.paths.payments}/paymentech/hps/finalize`)
    readonly paymentAccountCreateFromPlaid: () => string = () => this.getUrl(`/${this.paths.payments}/plaid/bankAccounts`)
    readonly paymentAccountDelete: (accountId: string) => string = (accountId: string) => `${this.paymentAccount()}/${accountId}`
    readonly paymentAccountDetails: () => string = () => this.getUrl(`/${this.paths.payments}/accountholders/details`)
    readonly paymentAccountGetProviderLink: () => string = () => this.getUrl(`/${this.paths.payments}/${this.paths.organizations}/stripe/dashboardLinks`)
    readonly paymentAccountMicrodeposits: () => string = () => this.getUrl(`/${this.paths.payments}/plaid/bankAccounts/microdeposits`)
    readonly paymentAccountVerificationDetails: () => string = () => this.getUrl(`/${this.paths.payments}/microdeposit-verification/details`)
    readonly paymentAccountVerify: () => string = () => this.getUrl(`/${this.paths.payments}/bankAccounts/verifications`)
    readonly paymentAccounts: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.payments}/${this.paths.organizations}/${businessId}/accounts`)
    readonly paymentInternationalFeedback: () => string = () => this.getUrl(`/${this.paths.payments}/international-feedback/responses`)
    readonly paymentInternationalFeedbackGet: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.payments}/international-feedback/responses/${organizationId}`)
    readonly payoutAccount: () => string = () => this.getUrl(`/${this.paths.payments}/stripe/connect/accounts`)
    readonly payoutAccounts: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.payments}/${this.paths.organizations}/${businessId}/payouts/accounts`)
    readonly payouts: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.payments}/${this.paths.organizations}/${businessId}/payouts`)
    readonly profileSettingUpdate: () => string = () => this.getUrl(`/profile-settings`)
    readonly profileSettings: (group: string) => string = (group: string) => this.getUrl(`/profile-settings/${!!group ? `?group=${group}` : ''}`)
    readonly profileVerificationSend: () => string = () => this.getUrl('/profiles/emailVerification')
    readonly projectLink: (linkId: string) => string = (linkId: string) => this.getUrl(`/projectLink/${linkId}`)
    readonly projectLinks: () => string = () => this.getUrl(`/projectLink`)
    readonly projectLinksForOrganization: (orgId: string) => string = (orgId: string) => `${this.projectLinks()}/organization/${orgId}`
    readonly deliverableEstimate: () => string = () => this.getUrl(`/work-orders/estimates`)
    readonly promoCode: (promoCode: string) => string = (promoCode: string) => this.getUrl(`/promo-code/valid/${promoCode}`)
    readonly promoCodeFreeTrial: () => string = () => this.getUrl(`/promo-code/free-trials`)
    readonly quickBooksAccountsAssociation: () => string = () => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/accounts/associations`)
    readonly quickBooksAccountsDisassociation: (quickBooksAccountId: string, liquidFinancialAccountId: string) => string = (quickBooksAccountId: string, liquidFinancialAccountId: string) => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/accounts/associations/${quickBooksAccountId}/${liquidFinancialAccountId}`)
    readonly quickBooksBackfills: () => string = () => this.getUrl(`/accounting/${this.paths.organizations}/tools/quickbooks-backfills`)
    readonly quickBooksBatchAssociation: () => string = () => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/accounts/batches`)
    readonly quickBooksFieldsAssociation: () => string = () => this.getUrl(`/integrations/quickBooks/fields/associations`)
    readonly quickBooksVendorsAssociation: () => string = () => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/vendors/associations`)
    readonly quickBooksVendorsDisassociation: (quickBooksVendorId: string) => string = (quickBooksVendorId: string) => this.getUrl(`/accounting/${this.paths.organizations}/quickBooks/vendors/associations/${quickBooksVendorId}`)
    readonly quickbooksTransactionReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/quickBooksTransactions/${fileType}`)
    readonly quickbooksAccounts: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/accounting/${this.paths.organizations}/${organizationId}/quickBooks/accounts`)
    readonly quickbooksFields: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/integrations/quickbooks/${organizationId}/quickbooksfields`)
    readonly quickbooksFieldsDisassociation: (qbFieldId: string) => string = (qbFieldId: string) => this.getUrl(`/integrations/quickBooks/fields/${qbFieldId}/associations`)
    readonly quickbooksVendors: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/accounting/${this.paths.organizations}/${organizationId}/quickBooks/vendors`)
    readonly sendPasswordlessMagicLink: () => string = () => this.getUrl(`/public/profiles/magicLinks`)
    readonly sendPasswordlessCode: () => string = () => this.getUrl(`/public/profiles/codes`)
    readonly servicePlanCancel: () => string = () => this.getUrl(`/${this.paths.organizations}/${this.paths.servicePlans}/cancellations`)
    readonly servicePlanGet: () => string = () => this.getUrl(`/public/${this.paths.servicePlans}`)
    readonly servicePlanPayAsYouGo: () => string = () => this.getUrl(`/public/${this.paths.servicePlans}/payAsYouGo`)
    readonly servicePlanUpdate: () => string = () => this.getUrl(`/${this.paths.organizations}/${this.paths.servicePlans}`)
    readonly setPaymentAccounts: () => string = () => this.getUrl(`/${this.paths.payouts}/setPaymentAccounts`)
    readonly setupComplete: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.organizations}/${organizationId}/completeSetup`)
    readonly syncQuickBooksAccounts: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/integrations/quickbooks/${organizationId}/quickBooksSyncAccounts`)
    readonly syncQuickBooksFields: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/integrations/quickbooks/${organizationId}/quickBooksSyncFields`)
    readonly syncQuickBooksVendors: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/integrations/quickbooks/${organizationId}/quickBooksSyncVendors`)
    readonly tag: () => string = () => this.getUrl(`/${this.paths.tag}`)
    readonly tagAssociation: () => string = () => this.getUrl(`/${this.paths.tag}/toggleForEntity`)
    readonly tagUpdate: (tagId: string) => string = (tagId: string) => this.getUrl(`/${this.paths.tag}/${tagId}`)
    readonly tags: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/${this.paths.tag}/${organizationId}`)
    readonly upsellGuest: () => string = () => this.getUrl('/profiles/upsellGuest')
    readonly updateBillingField: (billingFieldId: string) => string = (billingFieldId: string) => this.getUrl(`/integrations/quickbooks/billingFields/${billingFieldId}`)
    readonly userDelete: (membershipId: string) => string = (membershipId: string) => this.getUrl(`/${this.paths.organizations}/memberships/${membershipId}`)
    readonly userSetup: () => string = () => this.getUrl('/userSetup')
    readonly vendorDetailsReportFile: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/vendorDetailReportFiles/${fileType}`)
    readonly vendorInvoicePaymentCount: (businessId: string, invoiceId: string) => string = (businessId: string, invoiceId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/${invoiceId}/vendorPaymentCount`)
    readonly vendorImportNotifications: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendors/imports/notifications`)
    readonly vendorImportTemplate: () => string = () => this.getUrl('/vendors/imports/template')
    readonly vendorImportValidation: () => string = () => this.getUrl('/vendors/imports/validate')
    readonly vendorImportSettings: () => string = () => this.getUrl('/vendors/imports/settings')
    readonly vendorImportHistory: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendors/imports/history`)
    readonly vendorImport: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendors/imports`)
    readonly vendorInfos: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendorInfos`)
    readonly vendorList: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendors`)
    readonly vendorHasOutstandingApprovals: (clientId: string, vendorId: string) => string = (clientId: string, vendorId: string) => this.getUrl(`/invoices/${clientId}/${vendorId}/hasOutstandingApprovals`)
    readonly vendorListCounts: (businessId: string) => string = (businessId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/vendors/counts`)
    readonly vendorHistory: (businessId: string, invitationId: string) => string = (businessId: string, invitationId: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/invitations/${invitationId}/history`)
    readonly vendorsCanbeInvoiced: (clientOrganizationId: string) => string = (clientOrganizationId: string) => this.getUrl(`/${this.paths.organizations}/${clientOrganizationId}/invoices/valid-vendors`)
    readonly w8Preview: (stepInstanceId: string) => string = (stepInstanceId: string) => this.getUrl(`/onboarding/preview/steps/${stepInstanceId}/w8s`)
    readonly w8ReportFile: () => string = () => this.getUrl(`/reports/${this.paths.organizations}/w8s`)
    readonly w8Submit: (stepInstanceId: string) => string = (stepInstanceId: string) => this.getUrl(`/onboarding/submissions/steps/${stepInstanceId}/w8s`)
    readonly w9Preview: (stepInstanceId: string) => string = (stepInstanceId: string) => this.getUrl(`/onboarding/preview/steps/${stepInstanceId}/w9s`)
    readonly workOrderCounts: (businessId: string, orderType?: OrderType) => string = (businessId: string, orderType?: string) => this.getUrl(`/${this.paths.organizations}/${businessId}/work-orders/counts${UrlService.buildQueryFromObject({ orderType })}`)
    readonly workOrderDeliverableEstimate: () => string = () => `${this.workOrders()}/estimates`
    readonly workOrderFileCreate: () => string = () => this.getUrl(`/work-orders/files/`)
    readonly workOrderFileDelete: (workOrderId: string, orgId: string) => string = (workOrderId: string, orgId: string) => this.getUrl(`/work-orders/files/${workOrderId}/${orgId}`)
    readonly workOrderFileDownload: (workOrderId: string, fileId: string) => string = (workOrderId: string, fileId: string) => this.getUrl(`/work-orders/files/${workOrderId}/download/${fileId}`)
    readonly workOrderFileUpdate: () => string = () => this.getUrl(`/work-orders/files`)
    readonly workOrderFileUpload: (workOrderId: string, originalFilename: string) => string = (workOrderId: string, originalFilename: string) => this.getUrl(`/work-orders/${workOrderId}/upload/${originalFilename}`)
    readonly workOrderFinancialDetails: () => string = () => this.getUrl('/work-orders/financialDetails')
    readonly workOrdersForProject: (organizationId: string) => string = (organizationId) => this.getUrl(`/organizations/${organizationId}/project-work-orders`)
    readonly workOrderGetFiles: (workOrderId: string, orgId: string) => string = (workOrderId: string, orgId: string) => this.getUrl(`/work-orders/${workOrderId}/files/${orgId}`)
    readonly workOrderInfos: (organizationId: string) => string = (organizationId: string) => this.getUrl(`/work-orders/${organizationId}/workOrderInfos${UrlService.buildQueryFromObject({ orderType: OrderType.WorkOrder })}`)
    readonly workOrderReport: (businessId: string) => string = (businessId: string) => this.getUrl(`/reports/${businessId}/workOrderReports`)
    readonly workOrderReportDownload: (businessId: string, fileType: string) => string = (businessId: string, fileType: string) => this.getUrl(`/reports/${businessId}/workOrderInvoiceReportFiles/${fileType}`)
    readonly workOrderSummaries: (businessId: string, excludeCreatedFromClientInvoice: boolean) => string = (businessId: string, excludeCreatedFromClientInvoice: boolean) => this.getUrl(`/${this.paths.organizations}/${businessId}/workOrderSummaries?excludeCreatedFromClientInvoice=${excludeCreatedFromClientInvoice}`)
    readonly workOrders: () => string = () => this.getUrl(`/work-orders`)
    readonly workOrderBillingFieldAssociation: (workOrderId: string, billingFieldId: string) => string = (workOrderId: string, billingFieldId: string) => this.getUrl(`/work-orders/${workOrderId}/billingfields/${billingFieldId}`)
    readonly workOrderBillingFieldAssociations: (workOrderId: string) => string = (workOrderId: string) => this.getUrl(`/work-orders/${workOrderId}/billingfields`)
    readonly workOrdersPaged: (businessId: string, orderType: OrderType) => string = (businessId: string, orderType: OrderType) => this.getUrl(`/${this.paths.organizations}/${businessId}/work-orders/paged${UrlService.buildQueryFromObject({ orderType })}`)
    readonly youUp: () => string = () => this.getUrl(`/public/youUp`)
    readonly hubspotJWT: () => string = () => this.getUrl('/profiles/hubspot')
    readonly sleekPlanJWT: () => string = () => this.getUrl('/profiles/sleekplan-token')
    readonly zendeskJWT: () => string = () => this.getUrl('/profiles/zendesk')

    private getUrl(path: string): string {
        return `${environment.liquidApiSettings.apiServicePrefix}${path}`
    }
}
