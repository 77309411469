import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

import { SelectOption } from '../../core/models'
import { AddressService } from '../services/address.service'

@Component({
    selector: 'app-country-form',
    templateUrl: './country-form.component.html',
    styleUrls: ['./country-form.component.scss'],
})
export class CountryFormComponent implements OnInit {

    @Input() country: FormControl
    @Input() hideUs: boolean
    @Input() label: string
    @Input() required: boolean

    countries: Array<SelectOption<string>>

    get formLabel(): string { return this.label || 'Country' }

    constructor(
        private addresses: AddressService,
    ) {
    }

    ngOnInit(): void {
        this.countries = !this.hideUs ? this.addresses.countryNames : this.addresses.countryNames.filter(c => c.label !== this.addresses.usa)
    }
}
